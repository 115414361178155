import {ActionTypes} from '../actionTypes';
import axios from 'axios';

export const fetchQuickLinksStart = () => ({type: ActionTypes.FETCH_QUICK_LINKS_START});
export const fetchQuickLinksSuccess = (links) => ({type: ActionTypes.FETCH_QUICK_LINKS_SUCCESS, links});
export const fetchQuickLinksError = (error) => ({type: ActionTypes.FETCH_QUICK_LINKS_ERROR, error});

export const fetchQuickLinks = (company) => {
    return async (dispatch) => {
        try {
            dispatch(fetchQuickLinksStart());
            const response = await axios.get(`/quicklinks/all/${company}`)


            if (response.data) {
                dispatch(fetchQuickLinksSuccess(response.data))
            }
        } catch (err) {
            dispatch(fetchQuickLinksError(err));
        }
    }
}