export const setFormsTextFilter = (text = '') => ({
    type: 'SET_FORMS_TEXT_FILTER',
    text
})

export const setFormsCategoryFilter = (category = '') => ({
    type: 'SET_FORMS_CATEGORY_FILTER',
    category
})

export const setFormsSubCategoryFilter = (subCategory = '') => ({
    type: 'SET_FORMS_SUBCATEGORY_FILTER',
    subCategory
})