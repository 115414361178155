import React, {useState} from 'react'
import {Modal, Button} from 'react-bootstrap'
import RichTextToHTML from '../layout/RichTextToHTML';
import moment from 'moment';
import images from '../newsEvents/images'

const NewsEventsModal = ({currentEvent, currentImage, events}) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [event, setEvent] = useState(currentEvent);
    const [image, setImage] = useState(currentImage);

    const findEvent = (ev) => {
        const newEvent = events.find((e) => e.Id === ev.Id);
        const image = images.find((i) => i.title === ev.Background_Image__c);
        setImage(image ? image.src : '');
        setEvent(newEvent);
    }

    const findPrevious = (ev) => {
        const evIndex = events.findIndex((i) => i.Id === ev.Id);
        const newEvent = events[evIndex === 0 ? events.length - 1 : evIndex - 1];
        const image = images.find((i) => i.title === newEvent.Background_Image__c);
        setImage(image ? image.src : '');
        setEvent(newEvent);
    }

    const findNext = (ev) => {
        const evIndex = events.findIndex((i) => i.Id === ev.Id);
        const newEvent = events[evIndex === events.length - 1 ? 0 : evIndex + 1];
        const image = images.find((i) => i.title === newEvent.Background_Image__c);
        setImage(image ? image.src : '');
        setEvent(newEvent);
    }
    
    const renderModal = () => {

        return (
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title style={{margin: '0 auto'}}>{event && event.Title__c}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <table className="mb-3 mx-auto">
                        <tr>
                            <td>
                                <button type="button" className={`btn btn-news-nav mr-3`} onClick={() => findPrevious(event)}><i className="fas fa-chevron-left"></i></button>
                            </td>
                            <td className="text-center">
                                {events && events.map((e) => {
                                    return <button type="button" onClick={() => findEvent(e)} className={`btn ${e.Id === event.Id ? 'btn-primary' : 'btn-secondary'} btn-circle mx-1`}></button>
                                })}
                            </td>
                            <td>
                                <button type="button" className={`btn btn-news-nav ml-3`} onClick={() => findNext(event)}><i className="fas fa-chevron-right"></i></button>
                            </td>
                        </tr>
                    </table>
                    
                    <img src={image} className="d-block w-100 " alt=""/>
                    <h4 className="mt-3">{event && event.Brief_Description__c}</h4>
                    <p className="p-2 mb-3 bg-light text-dark">{moment(event && event.CreatedDate).format('LL')} By {(event && event.Created_By__r) && event.Created_By__r.Name}</p>
                    <RichTextToHTML html={event && event.Description__c} />
                </Modal.Body>

                <Modal.Footer style={{display: 'block'}}>
                    <div className="text-center">
                        <Button variant="primary" onClick={handleClose}>
                            Close
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        )
    }

    

    return (
        <div>
            {((event && event.Description__c) && event.Description__c.length > 7) && 
            <div className="d-none d-sm-block">
                <button className="btn btn-sm btn-primary mt-2" onClick={handleShow}>Read More</button>
            </div>}
            <div className="d-block d-sm-none" style={{lineHeight: '0'}}>
                <button className="btn btn-link p-0 text-white" onClick={handleShow} style={{textDecoration: 'underline', lineHeight: 1}}>{event && event.Title__c}</button>
            </div>
            {show && renderModal()}

        </div>
    );
}

export default NewsEventsModal;