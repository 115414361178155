import React from 'react'
import {Link} from 'react-router-dom';
import moment from 'moment'
import DeleteServiceRequestLink from './DeleteServiceRequestLink';
import PriorityEditor from './PriorityEditor';

export const columnsForProjects = [
    {
        dataField: 'Id',
        text: 'Record ID',
        hidden: true,
        searchable: false,
        csvExport: false,
        editable: false
    }, {
        dataField: 'Name',
        text: 'ID',
        sort: true,
        formatter: (cellContent, row) => {
            return (
                <Link to={`/serviceRequests/${row.Id}`}>{row.Name.split("-").pop()}</Link>
            )
        },
        editable: false,
        headerStyle: (colum, colIndex) => {
            return { width: '7%', textAlign: 'center'};
        },
        style: (colum, colIndex) => {
            return { verticalAlign: 'middle', textAlign: 'center'};
        }
    }, {
        dataField: 'CreatedDate',
        text: 'Date',
        sort: true,
        editable: false,
        formatter: (cellContent, row) => {
            return (row.CreatedDate && moment(row.CreatedDate).format('ll'))
        },
        headerStyle: (colum, colIndex) => {
            return { width: '13%', textAlign: 'center'}
        },
        style: (colum, colIndex) => {
            return { verticalAlign: 'middle', textAlign: 'center'};
        }
    }, {
        dataField: 'Created_By__r.Name',
        text: 'Submitted By',
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => {
            return { width: '15%', textAlign: 'center'}
        },
        style: (colum, colIndex) => {
            return { verticalAlign: 'middle', textAlign: 'center'};
        }
    }, {
        dataField: 'Category_Lookup__r.Name',
        text: 'Category',
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => {
            return { width: '13%', textAlign: 'center'};
        },
        style: (colum, colIndex) => {
            return { verticalAlign: 'middle', textAlign: 'center'};
        }
    }, {
        dataField: 'Assigned_User__r.Name',
        text: 'Assigned To',
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => {
            return { width: '13%', textAlign: 'center'};
        },
        style: (colum, colIndex) => {
            return { verticalAlign: 'middle', textAlign: 'center'};
        }
    }, {
        dataField: 'Project_Title__c',
        text: 'Project Title',
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => {
            return { width: '27%', textAlign: 'center'};
        },
        style: (colum, colIndex) => {
            return { verticalAlign: 'middle', textAlign: 'center'};
        }
    }, {
        dataField: 'Project_Priority_Rank__c',
        text: 'Rank',
        sort: true,
        editorClasses: 'form-control form-control-sm',
        editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
            <PriorityEditor type="text" { ...editorProps } row={row} value={ value } />
        ),
        headerStyle: (colum, colIndex) => {
            return { width: '12%', textAlign: 'center'};
        },       
        style: (colum, colIndex) => {
            return { verticalAlign: 'middle', textAlign: 'center'};
        }
    }
    // {
    //     dataField: 'Edit',
    //     text: 'Actions',
    //     sort: true,
    //     csvExport: false,
    //     formatter: (cellContent, row) => {
    //         return (
    //         <div className="text-center">
    //             <Link className={`btn btn-link m-0 p-0`} 
    //             to={`/serviceRequests/edit/${row.Id}`}>
    //                 <i className="fas fa-edit"></i></Link>
    //             {` / `}
    //             <DeleteServiceRequestLink request={row} />
    //         </div>)
    //     },
    //     editable: false,
    //     headerStyle: (colum, colIndex) => {
    //         return { width: '12%', textAlign: 'center'};
    //     },
    //     style: (colum, colIndex) => {
    //         return { verticalAlign: 'middle', textAlign: 'center'};
    //     }             
    // },
]

export const defaultSortedForProjects = [
    {
        dataField: "Name",
        order: "desc"
    }
];

export const currencyFormat = (number, currency) => {
    return new Intl.NumberFormat(`en-US`, 
        { 
            style: 'currency', 
            currency: currency || 'CAD', 
            currencyDisplay: 'code' 
        }
    ).format(number);
}

const priorityFormatter = (cellContent) => {
    if(cellContent === '3 - Low') {
        return <span className="badge badge-success" style={{width: '50px'}}>Low</span>
    } else if (cellContent === '2 - Medium') {
        return <span className="badge badge-warning" style={{width: '50px'}}>Medium</span>
    } else if (cellContent === '1 - HIGH') {
        return <span className="badge badge-danger" style={{width: '50px'}}>High</span>
    }

    return <span className="badge badge-secondary" style={{width: '50px'}}>{cellContent}</span>
}