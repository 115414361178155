export const options = [
    {
        value: 'Warehouse',
        sub_categories: [
            {
                value: 'General'
            },
            {
                value: 'Inventory'
            },
            {
                value: 'Shipping'
            }
        ]
    },
    {
        value: 'HR',
        sub_categories: [
            {
                value: 'Accessibility'
            },
            {
                value: 'Bucketlist'
            },
            {
                value: 'Dayforce'
            },
            {
                value: 'Health & Safety'
            },
            {
                value: 'Northstar'
            },
            {
                value: 'Offboarding'
            },
            {
                value: 'Onboarding'
            },
            {
                value: 'Performance Management'
            },
            {
                value: 'RRSP'
            },
            {
                value: 'Violence & Harassment'
            }
        ]
    },
    {
        value: 'IT',
        sub_categories: [
            {
                value: 'Accessibility'
            },
            {
                value: 'Email'
            },
            {
                value: 'Laptop'
            },
            {
                value: 'Launchpad'
            },
            {
                value: 'Opportunity'
            },
            {
                value: 'Outlook'
            },
            {
                value: 'Projects'
            },
            {
                value: 'Purchase Orders'
            },
            {
                value: 'Salesforce'
            }
        ]
    },
    {
        value: 'Operations',
        sub_categories: [
            {
                value: 'Accessibility'
            },
            {
                value: 'Security Awareness'
            }
        ]
    },
    {
        value: 'Finance',
        sub_categories: [
            {
                value: 'Accessibility'
            },
            {
                value: 'Expenses'
            },
            {
                value: 'Invoice'
            }
        ]
    },
    {
        value: 'Account Teams',
        sub_categories: [
            {
                value: 'Accessibility'
            },
            {
                value: 'Mileage'
            },
            {
                value: 'Payroll'
            },
            {
                value: 'Rep Warning/Termination'
            }
        ]
    },
]