import React, {useState} from 'react'
import {Modal, Button} from 'react-bootstrap'

const DetailsModal = (props) => {
    const [show,
        setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const entries = (data) => {
        return Object.keys(data).map((key) => {
            return data[key] && <tr key={key}>
                <th>{key}</th>
                <td>{data[key]}</td>
            </tr>
        })
    } 

    const renderModal = () => {
        return (
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title style={{margin: '0 auto'}}>{props.title}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <table className="table-responsive-sm table-sm">
                        <tbody>
                            {entries(props.data)}
                        </tbody>
                    </table>
                </Modal.Body>

                <Modal.Footer style={{display: 'block'}}>
                    <div className="text-center">
                        <Button variant="primary" onClick={handleClose}>
                            Close
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        )
    }

    

    return (
        <div>
            <button onClick={handleShow} className="btn btn-link font-weight-bold p-0 text-left">
                {props.buttonText}
            </button>

            {show && renderModal()}

        </div>
    );
}

export default DetailsModal;