import React from 'react'

const UserDetails = (props) => {
    const {user} = props 
    return (
        <div className="card">
            <div className="card-header bg-sdi-black text-sdi-yellow card-header-inner-page">Requester Details</div>

            <div className="card-body py-1">
                {user.Name &&
                <div className="mb-2">
                    <span className="font-weight-light">Name: </span>
                    <span className="font-weight-bold">{user.Name}</span>
                </div>}

                {user.Phone &&
                <div className="mb-2">
                    <span className="font-weight-light">Phone: </span>
                    <span className="font-weight-bold">{user.Phone}</span>
                </div>}

                {user.Email &&
                <div className="mb-2">
                    <span className="font-weight-light">Email: </span>
                    <span className="font-weight-bold">{user.Email}</span>
                </div>}

                {user.Department &&
                <div className="mb-2">
                    <span className="font-weight-light">Department: </span>
                    <span className="font-weight-bold">{user.Department}</span>
                </div>}

            </div>
        </div>
    )
}


export default UserDetails;