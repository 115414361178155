import axios from 'axios';
import {ActionTypes} from '../actionTypes';
import { graphConfig } from "../../../components/auth/authConfig";

export const fetchUserPhotoStart = () => ({type: ActionTypes.FETCH_USER_PHOTO_START});
export const fetchUserPhotoSuccess = (photo) => ({type: ActionTypes.FETCH_USER_PHOTO_SUCCESS, photo});
export const fetchUserPhotoError = (error) => ({type: ActionTypes.FETCH_USER_PHOTO_ERROR, error});

export const fetchUserPhoto = (accessToken) => {
    
    return async (dispatch) => {
        try {
            dispatch(fetchUserPhotoStart());
            const options = {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                responseType: 'arraybuffer'
            };
        
            const photo = await axios.get(graphConfig.graphProfileImageEndpoint, options);

            if(photo.data) {
                const blob = new Blob([photo.data]);
                const url = URL.createObjectURL(blob);
                dispatch(fetchUserPhotoSuccess(url))
            }

        } catch (err) {
            dispatch(fetchUserPhotoError(err))
        }
    }
}