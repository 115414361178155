import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./redux/store/configureStore";
// import jwt_decode from "jwt-decode";
// import {sfAuthSuccess} from './redux/actions/sfAuth/sfAuth';
// import setAuthToken from './components/auth/setAuthToken';

// MSAL imports
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./components/auth/authConfig";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-5Z4MVDN",
};

TagManager.initialize(tagManagerArgs);

const msalInstance = new PublicClientApplication(msalConfig);

// if(localStorage.sfToken) {
//   const token = localStorage.sfToken;
//   setAuthToken(token);
//   const decoded = jwt_decode(token);
//   store.dispatch(sfAuthSuccess(decoded));
// }

ReactDOM.render(
  <Provider store={store}>
    <App pca={msalInstance} />
  </Provider>,
  document.getElementById("root")
);
