import moment from 'moment';

export const employeeSearch = (employees, {text, department}) => {
    // employees = employees.filter((emp) => emp.Title !== null);
    return employees.filter((emp) => {
        const textMatch = text ? (
                emp.Name 
                && (emp.Name.toLowerCase().includes(text.toLowerCase()) 
                    || emp.Title.toLowerCase().includes(text.toLowerCase()) 
                    || emp.Department.toLowerCase().includes(text.toLowerCase())
                )
            ) : emp;
        const departmentMatch = department ? (emp.Department && emp.Department.toLowerCase() === department.toLowerCase()) : emp;

        return departmentMatch && textMatch;
    }).map((emp) => {
        const birthDayDiff = emp.Birthdate && daysRemaining(emp.Birthdate);
        const anniversaryDiff = emp.pse__Start_Date__c && daysRemaining(emp.pse__Start_Date__c);
        let daysRem = birthDayDiff;
        let celebrate = 'BRT'

        if(anniversaryDiff && birthDayDiff && anniversaryDiff < birthDayDiff) {
            daysRem = anniversaryDiff;
            celebrate = 'ANV'
        }

        if(daysRem === null) {
            daysRem = 1000;
        }
        

        if(moment(emp.Birthdate).isSame(moment(), 'day') || moment(emp.pse__Start_Date__c).isSame(moment(), 'day')) {
            daysRem = 1;
        }


        return {...emp, celebrationDays: daysRem, celebrationType: celebrate};
    }).sort((a, b) => {
            return a.celebrationDays - b.celebrationDays;
    })
} 


export const findUserPhotoByContact = (users, contact) => {
    const user = (users && contact) && users.find((user) => user.Id === contact.OwnerId)
    if(user) return user.Photo
}


const daysRemaining = (date) => {
    var eventDate = moment(date);
    eventDate.year(moment().format('YYYY'));
    var todaysdate = moment();
    if(eventDate < todaysdate) {
        eventDate.add(1, 'years');
    }

    let diff = eventDate.diff(todaysdate, 'days');
    return diff;
}


// .sort((a, b) => {
//     const birthDayA = moment(a.Birthdate);
//     const birthDayB = moment(b.Birthdate);
//     const startDateA = moment(a.pse__Start_Date__c);
//     const startDateB = moment(b.pse__Start_Date__c);

//     if(birthDayA === birthDayB) {
//         return (startDateA < startDateB) ? -1 : (startDateA > startDateB) ? 1 : 0;
//     } else {
//         return (birthDayA < birthDayB) ? -1 : 1;
//     }
// })