import {ActionTypes} from '../actionTypes';
import axios from 'axios';

export const updateContactStart = () => ({type: ActionTypes.UPDATE_CONTACT_START});
export const updateContactSuccess = (id, updates) => ({type: ActionTypes.UPDATE_CONTACT_SUCCESS, id, updates});
export const updateContactError = (error) => ({type: ActionTypes.UPDATE_CONTACT_ERROR, error});



export const updateContact = (id, requestData = {}) => {
    return async (dispatch) => {

        try {
            dispatch(updateContactStart());

            const data = {
                ...requestData,
                Id: id
            }


            const response = await axios.patch('/contacts/update', data)
            
            if (response.data) {
                dispatch(updateContactSuccess(response.data.contact.Id, response.data.contact))
            }
        } catch (err) {
            dispatch(updateContactError(err.response.data));
        }
    }
}