import React, {useState, useEffect} from 'react';
import {Modal, Button} from 'react-bootstrap';
import HelpdocsAttachments from './HelpDocsAttachments';
import moment from 'moment';



const HelpDocsDetails = ({data}) => {
    const [show, setShow] = useState(false);
    const [error, setError] = useState('')

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    


    const renderModal = () => {
        return (
            <Modal size="lg" show={show} onHide={handleClose}>
                <Modal.Header closeButton style={{borderBottom: '0 none'}}>
                    <Modal.Title style={{margin: '0 auto'}}></Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="card">
                        <div className="card-body">
                            <div className="h5">{data.Name}</div>
                            <div className="mb-4">
                                <span className="badge badge-secondary mr-2">{moment(data.CreatedDate).format('LL')}</span>
                                <span className="badge badge-secondary mr-2">{data.Type__c}</span>
                                <span className="badge badge-secondary mr-2">{data.Training_Group__c}</span>
                                <span className="badge badge-secondary mr-2">{data.Training_Region__c}</span>
                                <span className="badge badge-secondary mr-2">{data.Training_Type__c}</span>
                            </div>

                            <div className="mb-3">{data.Training_Description__c}</div>
                            {data.Video_URL__c && <div>
                                <a className="btn btn-primary" href={data.Video_URL__c} target="_blank" rel="noreferrer">
                                    <i className="fa fa-video mr-2"></i>
                                    <span>Watch Video</span>
                                </a>
                            </div>}
                        </div>
                        {(data.Attachments && data.Attachments.totalSize > 0) && 
                            <HelpdocsAttachments 
                                attachments={data.Attachments.records} setError={setError} />}
                    </div>
                </Modal.Body>
            </Modal>
        )
    }

    

    return (
        <>
            <a onClick={handleShow} className="btn-block text-muted d-flex justify-content-between align-items-center" href="#0">
                <span className="small font-weight-bold">View Details</span>
                <i className="c-icon cil-chevron-right" style={{textDecoration: 'none'}}></i>
            </a>
            

            {show && renderModal()}

        </>
    );
}

export default HelpDocsDetails;