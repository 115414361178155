export const ActionTypes = Object.freeze({
    FETCH_SERVICE_REQUESTS_START: 'FETCH_SERVICE_REQUESTS_START',
    FETCH_SERVICE_REQUESTS_SUCCESS: 'FETCH_SERVICE_REQUESTS_SUCCESS',
    FETCH_SERVICE_REQUESTS_ERROR: 'FETCH_SERVICE_REQUESTS_ERROR',

    SAVE_SERVICE_REQUEST_START: 'SAVE_SERVICE_REQUEST_START',
    SAVE_SERVICE_REQUEST_SUCCESS: 'SAVE_SERVICE_REQUEST_SUCCESS',
    SAVE_SERVICE_REQUEST_ERROR: 'SAVE_SERVICE_REQUEST_ERROR',

    UPDATE_SERVICE_REQUEST_START: 'UPDATE_SERVICE_REQUEST_START',
    UPDATE_SERVICE_REQUEST_SUCCESS: 'UPDATE_SERVICE_REQUEST_SUCCESS',
    UPDATE_SERVICE_REQUEST_ERROR: 'UPDATE_SERVICE_REQUEST_ERROR',

    DELETE_SERVICE_REQUEST_START: 'DELETE_SERVICE_REQUEST_START',
    DELETE_SERVICE_REQUEST_SUCCESS: 'DELETE_SERVICE_REQUEST_SUCCESS',
    DELETE_SERVICE_REQUEST_ERROR: 'DELETE_SERVICE_REQUEST_ERROR',

    SERVICE_REQUEST_RESET_STATUS: 'SERVICE_REQUEST_RESET_STATUS',

    FETCH_SERVICE_REQUEST_ATTACHMENTS_START: 'FETCH_SERVICE_REQUEST_ATTACHMENTS_START',
    FETCH_SERVICE_REQUEST_ATTACHMENTS_SUCCESS: 'FETCH_SERVICE_REQUEST_ATTACHMENTS_SUCCESS',
    FETCH_SERVICE_REQUEST_ATTACHMENTS_ERROR: 'FETCH_SERVICE_REQUEST_ATTACHMENTS_ERROR',

    UPLOAD_SERVICE_REQUEST_ATTACHMENT_START: 'UPLOAD_SERVICE_REQUEST_ATTACHMENT_START',
    UPLOAD_SERVICE_REQUEST_ATTACHMENT_SUCCESS: 'UPLOAD_SERVICE_REQUEST_ATTACHMENT_SUCCESS',
    UPLOAD_SERVICE_REQUEST_ATTACHMENT_ERROR: 'UPLOAD_SERVICE_REQUEST_ATTACHMENT_ERROR',

    DELETE_SERVICE_REQUEST_ATTACHMENT_START: 'DELETE_SERVICE_REQUEST_ATTACHMENT_START',
    DELETE_SERVICE_REQUEST_ATTACHMENT_SUCCESS: 'DELETE_SERVICE_REQUEST_ATTACHMENT_SUCCESS',
    DELETE_SERVICE_REQUEST_ATTACHMENT_ERROR: 'DELETE_SERVICE_REQUEST_ATTACHMENT_ERROR',

    FETCH_USER_START: 'FETCH_USER_START',
    FETCH_USER_SUCCESS: 'FETCH_USER_SUCCESS',
    FETCH_USER_ERROR: 'FETCH_USER_ERROR',

    AUTHENTICATE_USER: 'AUTHENTICATE_USER',

    FETCH_USER_PHOTO_START: 'FETCH_USER_PHOTO_START',
    FETCH_USER_PHOTO_SUCCESS: 'FETCH_USER_PHOTO_SUCCESS',
    FETCH_USER_PHOTO_ERROR: 'FETCH_USER_PHOTO_ERROR',

    SF_AUTH_START: 'SF_AUTH_START',
    SF_AUTH_SUCCESS: 'SF_AUTH_SUCCESS',
    SF_AUTH_ERROR: 'SF_AUTH_ERROR',

    FETCH_USERS_START: 'FETCH_USERS_START',
    FETCH_USERS_SUCCESS: 'FETCH_USERS_SUCCESS',
    FETCH_USERS_ERROR: 'FETCH_USERS_ERROR',

    FETCH_BUSINESS_UNITS_START: 'FETCH_BUSINESS_UNITS_START',
    FETCH_BUSINESS_UNITS_SUCCESS: 'FETCH_BUSINESS_UNITS_SUCCESS',
    FETCH_BUSINESS_UNITS_ERROR: 'FETCH_BUSINESS_UNITS_ERROR',

    FETCH_PROJECTS_START: 'FETCH_PROJECTS_START',
    FETCH_PROJECTS_SUCCESS: 'FETCH_PROJECTS_SUCCESS',
    FETCH_PROJECTS_ERROR: 'FETCH_PROJECTS_ERROR',

    FETCH_ACCOUNTS_START: 'FETCH_ACCOUNTS_START',
    FETCH_ACCOUNTS_SUCCESS: 'FETCH_ACCOUNTS_SUCCESS',
    FETCH_ACCOUNTS_ERROR: 'FETCH_ACCOUNTS_ERROR',

    FETCH_REQUEST_CATEGORIES_START: 'FETCH_REQUEST_CATEGORIES_START',
    FETCH_REQUEST_CATEGORIES_SUCCESS: 'FETCH_REQUEST_CATEGORIES_SUCCESS',
    FETCH_REQUEST_CATEGORIES_ERROR: 'FETCH_REQUEST_CATEGORIES_ERROR',

    FETCH_CONTACTS_START: 'FETCH_CONTACTS_START',
    FETCH_CONTACTS_SUCCESS: 'FETCH_CONTACTS_SUCCESS',
    FETCH_CONTACTS_ERROR: 'FETCH_CONTACTS_ERROR',

    FETCH_EMP_TOURS_START: 'FETCH_EMP_TOURS_START',
    FETCH_EMP_TOURS_SUCCESS: 'FETCH_EMP_TOURS_SUCCESS',
    FETCH_EMP_TOURS_ERROR: 'FETCH_EMP_TOURS_ERROR',

    UPDATE_EMP_TOUR_START: 'UPDATE_EMP_TOUR_START',
    UPDATE_EMP_TOUR_SUCCESS: 'UPDATE_EMP_TOUR_SUCCESS',
    UPDATE_EMP_TOUR_ERROR: 'UPDATE_EMP_TOUR_ERROR',

    FETCH_NEWS_EVENTS_START: 'FETCH_NEWS_EVENTS_START',
    FETCH_NEWS_EVENTS_SUCCESS: 'FETCH_NEWS_EVENTS_SUCCESS',
    FETCH_NEWS_EVENTS_ERROR: 'FETCH_NEWS_EVENTS_ERROR',

    UPDATE_NEWS_EVENTS_START: 'UPDATE_NEWS_EVENTS_START',
    UPDATE_NEWS_EVENTS_SUCCESS: 'UPDATE_NEWS_EVENTS_SUCCESS',
    UPDATE_NEWS_EVENTS_ERROR: 'UPDATE_NEWS_EVENTS_ERROR',

    SAVE_NEWS_EVENTS_START: 'SAVE_NEWS_EVENTS_START',
    SAVE_NEWS_EVENTS_SUCCESS: 'SAVE_NEWS_EVENTS_SUCCESS',
    SAVE_NEWS_EVENTS_ERROR: 'SAVE_NEWS_EVENTS_ERROR',

    NEWS_EVENTS_RESET_STATUS: 'NEWS_EVENTS_RESET_STATUS',

    UPLOAD_NEWS_EVENTS_ATTACHMENT_START: 'UPLOAD_NEWS_EVENTS_ATTACHMENT_START',
    UPLOAD_NEWS_EVENTS_ATTACHMENT_SUCCESS: 'UPLOAD_NEWS_EVENTS_ATTACHMENT_SUCCESS',
    UPLOAD_NEWS_EVENTS_ATTACHMENT_ERROR: 'UPLOAD_NEWS_EVENTS_ATTACHMENT_ERROR',

    DELETE_NEWS_EVENTS_ATTACHMENT_START: 'DELETE_NEWS_EVENTS_ATTACHMENT_START',
    DELETE_NEWS_EVENTS_ATTACHMENT_SUCCESS: 'DELETE_NEWS_EVENTS_ATTACHMENT_SUCCESS',
    DELETE_NEWS_EVENTS_ATTACHMENT_ERROR: 'DELETE_NEWS_EVENTS_ATTACHMENT_ERROR',

    DELETE_NEWS_EVENTS_START: 'DELETE_NEWS_EVENTS_START',
    DELETE_NEWS_EVENTS_SUCCESS: 'DELETE_NEWS_EVENTS_SUCCESS',
    DELETE_NEWS_EVENTS_ERROR: 'DELETE_NEWS_EVENTS_ERROR',

    FETCH_WOW_START: 'FETCH_WOW_START',
    FETCH_WOW_SUCCESS: 'FETCH_WOW_SUCCESS',
    FETCH_WOW_ERROR: 'FETCH_WOW_ERROR',


    UPDATE_WOW_START: 'UPDATE_WOW_START',
    UPDATE_WOW_SUCCESS: 'UPDATE_WOW_SUCCESS',
    UPDATE_WOW_ERROR: 'UPDATE_WOW_ERROR',

    UPDATE_CONTACT_START: 'UPDATE_CONTACT_START',
    UPDATE_CONTACT_SUCCESS: 'UPDATE_CONTACT_SUCCESS',
    UPDATE_CONTACT_ERROR: 'UPDATE_CONTACT_ERROR',

    CONTACT_RESET_STATUS: 'CONTACT_RESET_STATUS',

    UPDATE_APP_RELOAD_TIME: 'UPDATE_APP_RELOAD_TIME',
    UPDATE_APP_LOADED: 'UPDATE_APP_LOADED',

    FETCH_HELP_DOCS_START: 'FETCH_HELP_DOCS_START',
    FETCH_HELP_DOCS_SUCCESS: 'FETCH_HELP_DOCS_SUCCESS',
    FETCH_HELP_DOCS_ERROR: 'FETCH_HELP_DOCS_ERROR',

    FETCH_ORG_CONTACTS_START: 'FETCH_ORG_CONTACTS_START',
    FETCH_ORG_CONTACTS_SUCCESS: 'FETCH_ORG_CONTACTS_SUCCESS',
    FETCH_ORG_CONTACTS_ERROR: 'FETCH_ORG_CONTACTS_ERROR',

    FETCH_QUICK_LINKS_START: 'FETCH_QUICK_LINKS_START',
    FETCH_QUICK_LINKS_SUCCESS: 'FETCH_QUICK_LINKS_SUCCESS',
    FETCH_QUICK_LINKS_ERROR: 'FETCH_QUICK_LINKS_ERROR',

    SAVE_QUICK_LINK_START: 'SAVE_QUICK_LINK_START',
    SAVE_QUICK_LINK_SUCCESS: 'SAVE_QUICK_LINK_SUCCESS',
    SAVE_QUICK_LINK_ERROR: 'SAVE_QUICK_LINK_ERROR',

    UPDATE_QUICK_START_START: 'UPDATE_QUICK_START_START',
    UPDATE_QUICK_START_SUCCESS: 'UPDATE_QUICK_START_SUCCESS',
    UPDATE_QUICK_START_ERROR: 'UPDATE_QUICK_START_ERROR',

    DELETE_QUICK_LINK_START: 'DELETE_QUICK_LINK_START',
    DELETE_QUICK_LINK_SUCCESS: 'DELETE_QUICK_LINK_SUCCESS',
    DELETE_QUICK_LINK_ERROR: 'DELETE_QUICK_LINK_ERROR',

    QUICK_LINKS_RESET_STATUS: 'QUICK_LINKS_RESET_STATUS',

    FETCH_EMP_QUOTE_SUCCESS: 'FETCH_EMP_QUOTE_SUCCESS',

    FETCH_NOTIFICATIONS_START: 'FETCH_NOTIFICATIONS_START',
    FETCH_NOTIFICATIONS_SUCCESS: 'FETCH_NOTIFICATIONS_SUCCESS',
    FETCH_NOTIFICATIONS_ERROR: 'FETCH_NOTIFICATIONS_ERROR',

    UPDATE_NOTIFICATION_START: 'UPDATE_NOTIFICATION_START',
    UPDATE_NOTIFICATION_SUCCESS: 'UPDATE_NOTIFICATION_SUCCESS',
    UPDATE_NOTIFICATION_ERROR: 'UPDATE_NOTIFICATION_ERROR',

    DELETE_NOTIFICATION_START: 'DELETE_NOTIFICATION_START',
    DELETE_NOTIFICATION_SUCCESS: 'DELETE_NOTIFICATION_SUCCESS',
    DELETE_NOTIFICATION_ERROR: 'DELETE_NOTIFICATION_ERROR',


    FETCH_EMPLOYEE_FORECASTS_START: 'FETCH_EMPLOYEE_FORECASTS_START',
    FETCH_EMPLOYEE_FORECASTS_SUCCESS: 'FETCH_EMPLOYEE_FORECASTS_SUCCESS',
    FETCH_EMPLOYEE_FORECASTS_ERROR: 'FETCH_EMPLOYEE_FORECASTS_ERROR',

    SAVE_EMPLOYEE_FORECAST_START: 'SAVE_EMPLOYEE_FORECAST_START',
    SAVE_EMPLOYEE_FORECAST_SUCCESS: 'SAVE_EMPLOYEE_FORECAST_SUCCESS',
    SAVE_EMPLOYEE_FORECAST_ERROR: 'SAVE_EMPLOYEE_FORECAST_ERROR',

    UPDATE_EMPLOYEE_FORECAST_START: 'UPDATE_EMPLOYEE_FORECAST_START',
    UPDATE_EMPLOYEE_FORECAST_SUCCESS: 'UPDATE_EMPLOYEE_FORECAST_SUCCESS',
    UPDATE_EMPLOYEE_FORECAST_ERROR: 'UPDATE_EMPLOYEE_FORECAST_ERROR',

    DELETE_EMPLOYEE_FORECAST_START: 'DELETE_EMPLOYEE_FORECAST_START',
    DELETE_EMPLOYEE_FORECAST_SUCCESS: 'DELETE_EMPLOYEE_FORECAST_SUCCESS',
    DELETE_EMPLOYEE_FORECAST_ERROR: 'DELETE_EMPLOYEE_FORECAST_ERROR',

    EMPLOYEE_FORECAST_RESET_STATUS: 'EMPLOYEE_FORECAST_RESET_STATUS',

    FETCH_CAREERS_START: 'FETCH_CAREERS_START',
    FETCH_CAREERS_SUCCESS: 'FETCH_CAREERS_SUCCESS',
    FETCH_CAREERS_ERROR: 'FETCH_CAREERS_ERROR',

    FETCH_FORMS_START: 'FETCH_FORMS_START',
    FETCH_FORMS_SUCCESS: 'FETCH_FORMS_SUCCESS',
    FETCH_FORMS_ERROR: 'FETCH_FORMS_ERROR',

    FETCH_MASTER_EMPLOYEE_LIST_START: 'FETCH_MASTER_EMPLOYEE_LIST_START',
    FETCH_MASTER_EMPLOYEE_LIST_SUCCESS: 'FETCH_MASTER_EMPLOYEE_LIST_SUCCESS',
    FETCH_MASTER_EMPLOYEE_LIST_ERROR: 'FETCH_MASTER_EMPLOYEE_LIST_ERROR',

    FETCH_EMPLOYEE_PORTAL_LOGS_START: 'FETCH_EMPLOYEE_PORTAL_LOGS_START',
    FETCH_EMPLOYEE_PORTAL_LOGS_SUCCESS: 'FETCH_EMPLOYEE_PORTAL_LOGS_SUCCESS',
    FETCH_EMPLOYEE_PORTAL_LOGS_ERROR: 'FETCH_EMPLOYEE_PORTAL_LOGS_ERROR',

    FETCH_ORG_CHART_BUSINESS_UNITS_START: 'FETCH_ORG_CHART_BUSINESS_UNITS_START',
    FETCH_ORG_CHART_BUSINESS_UNITS_SUCCESS: 'FETCH_ORG_CHART_BUSINESS_UNITS_SUCCESS',
    FETCH_ORG_CHART_BUSINESS_UNITS_ERROR: 'FETCH_ORG_CHART_BUSINESS_UNITS_ERROR'

});