import React from 'react'
import PeopleTimeAwayModal from './PeopleTimeAwayModal'
import moment from 'moment'



export const columns = [
    {
        dataField: 'Id',
        text: 'Record ID',
        hidden: true,
        searchable: false,
        csvExport: false,
        editable: false
    }, {
        dataField: 'Display_Name__c',
        text: 'Name',
        sort: true,
        editable: false,
        formatter: (cellContent, row) => {
            return (<PeopleTimeAwayModal data={row} />)
        },
        headerStyle: (colum, colIndex) => {
            return { width: '25%', textAlign: 'center'}
        },
        style: (colum, colIndex) => {
            return { verticalAlign: 'middle', textAlign: 'center'};
        }
    }, {
        dataField: 'Department_Name__c',
        text: 'Department',
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => {
            return { width: '20%', textAlign: 'center'};
        },
        style: (colum, colIndex) => {
            return { verticalAlign: 'middle', textAlign: 'center'};
        }
    }, {
        dataField: 'vacation_salary_allocation',
        text: 'Allocation',
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => {
            return { width: '15%', textAlign: 'center'}
        },
        style: (colum, colIndex) => {
            return { verticalAlign: 'middle', textAlign: 'center'};
        }
    }, {
        dataField: 'vacation_salary_booked',
        text: 'Booked',
        hidden: true,
    },
    {
        dataField: 'vacation_salary_taken',
        text: 'Taken',
        hidden: true,
    }, {
        dataField: 'vacation_salary_remaining',
        text: 'Remaining',
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => {
            return { width: '15%', textAlign: 'center'}
        },
        style: (colum, colIndex) => {
            return { verticalAlign: 'middle', textAlign: 'center'};
        }
    }, {
        dataField: 'vacation_salary_taken_percentage',
        text: '% Taken',
        hidden: true,
    }, {
        dataField: 'Name',
        text: 'Booked & Taken',
        sort: true,
        csvExport: false,
        sortValue: (cell, row) => row.vacation_salary_taken_percentage,
        editable: false,
        formatter: (cellContent, row) => {
            return (
                <div className="progress-group mb-0">
                    <div className="progress-group-header d-flex justify-content-between">
                        <div className="ms-auto fw-semibold me-2 small">
                            Booked: <span className="font-weight-bold">{row.vacation_salary_booked}</span>
                            <span> </span> 
                            Taken: <span className="font-weight-bold">{row.vacation_salary_taken}</span> 
                        </div>
                        {/* <div className="text-medium-emphasis font-weight-bold small"> ({calculatePercentage(row)}%)</div> */}
                        <div className="text-medium-emphasis font-weight-bold small"> ({row.vacation_salary_taken_percentage}%)</div>
                    </div>
                    <div className="progress-group-bars">
                        <div className="progress progress-thin" style={{height: '4px'}}>
                            <div className="progress-bar bg-success" style={{width: row.vacation_salary_taken_percentage+'%'}} role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>
                </div>
            )
        },
        headerStyle: (colum, colIndex) => {
            return { width: '25%', textAlign: 'center'}
        },
        style: (colum, colIndex) => {
            return { verticalAlign: 'middle', textAlign: 'center'};
        }
    }
]

export const defaultSorted = [
    {
        dataField: "Name",
        order: "desc"
    }
];


const isInt = (n) => {
    return n % 1 === 0;
 }


const calculatePercentage = (row) => {
    const result = (((row.vacation_salary_taken + row.vacation_salary_booked) * 100) / row.vacation_salary_allocation);

    if(result && isFinite(result)) {
        return isInt(result) ? result : parseFloat(result).toFixed(1);
    } else if(result && !isFinite(result)) {
        return 0;
    }

    return result || 0;
}

export const currencyFormat = (number, currency) => {
    return new Intl.NumberFormat(`en-US`, 
        { 
            style: 'currency', 
            currency: currency || 'CAD', 
            currencyDisplay: 'code' 
        }
    ).format(number);
}