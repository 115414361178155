import {connect} from 'react-redux'
import Layout from '../../layout/Layout';
import moment from 'moment';
import { Link } from 'react-router-dom';
import DateInput from '../../formFields/DateInput';
import TextInput from '../../formFields/TextInput';
import SelectInput from '../../formFields/SelectInput';
import Filters from './Filters';
import MCChart from './MCChart';
import COGSChart from './COGSChart';

const CorporateDashboard = ({data}) => {
    const breadcrumb = [
        { name:'Corporate Dashboard', link: '' }
    ]


    return (
        <Layout breadcrumb={breadcrumb}>
            <Filters />

            <div className="row">
                <div className="col-sm-4">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">MC</h5>
                            <MCChart />
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">COGS</h5>
                            <COGSChart/>
                        </div>
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">GM%</h5>
                            <MCChart />
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">GM</h5>
                            <COGSChart/>
                        </div>
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">NI%</h5>
                            <MCChart />
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">NI</h5>
                            <COGSChart/>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default CorporateDashboard;