import axios from 'axios'
import {ActionTypes} from '../actionTypes';


export const uploadServiceRequestAttachmentStart = () => ({type: ActionTypes.UPLOAD_SERVICE_REQUEST_ATTACHMENT_START})
export const uploadServiceRequestAttachmentSuccess = (att) => ({type: ActionTypes.UPLOAD_SERVICE_REQUEST_ATTACHMENT_SUCCESS, att})
export const uploadServiceRequestAttachmentError = (error) => ({type: ActionTypes.UPLOAD_SERVICE_REQUEST_ATTACHMENT_ERROR, error})


export const uploadServiceRequestAttachment = (id, formData) => {
    return async (dispatch) => {
        try {
            dispatch(uploadServiceRequestAttachmentStart())

            console.log(formData);
            
            const response = await axios.post(`/serviceRequest/attachment/upload/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.status == 200) {
                const data = response.data.attachment;
                let attachment = {};

                

                data.Attachments && data.Attachments.records.map((i) => {
                    delete i.attributes
                    attachment = {
                        ServiceRequestId: data.Id,
                        ServiceRequestName: data.Name,
                        ...i
                    }
                })
                dispatch(uploadServiceRequestAttachmentSuccess(attachment))
            }

        } catch (err) {
            dispatch(uploadServiceRequestAttachmentError(err.message))
        }
    }
}