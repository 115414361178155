import {ActionTypes} from '../actionTypes';
import axios from 'axios';
import quotes from '../../../components/layout/quotes';


export const fetchQuoteSuccess = (quote) => ({type: ActionTypes.FETCH_EMP_QUOTE_SUCCESS, quote});

export const fetchQuote = () => {
    return async (dispatch) => {
        try {
            const response = await axios.get('/quote/all')
            if(response.data.length === 0) {
                return dispatch(fetchQuoteSuccess(quotes[0]))
            }

            dispatch(fetchQuoteSuccess(response.data))


        } catch (err) {
            dispatch(fetchQuoteSuccess(quotes[1]))
        }
    }
}