import React, {useState, useEffect} from "react";
import { Route, Redirect } from "react-router-dom";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import {connect} from "react-redux";


const PrivateRoute = ({ component: Component, auth, sfAuth, ...rest }) => {
    const isAuthenticated = useIsAuthenticated();

    return (
    <Route
      {...rest}
      render={props =>
        (auth.isAuthenticated && isAuthenticated) ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
    );
}

const PrivateRoute1 = ({ component: Component, auth, sfAuth, ...rest }) => {
  const isAuthenticated = useIsAuthenticated();
  const [redirect, setRedirect] = useState(null);

  const shouldNavigateAway = (auth2) => {
    if(auth2) {
        setRedirect(true);
    }
  }

  useEffect(() => {
    shouldNavigateAway(isAuthenticated);
  }, [isAuthenticated])


  return (
  <Route
    {...rest}
    render={props =>
      (redirect) ? (
        <Component {...props} />
      ) : (
        <Redirect to="/login" />
      )
    }
  />
  );
} 




const mapStateToProps = (state) => {
  return {
      auth: state.auth,
      sfAuth: state.sfAuth,
  }
}

export default connect(mapStateToProps)(PrivateRoute);