import React from 'react';
import { useField } from 'formik';
import TooltipIcon from './TooltipIcon';

const TextInput = ({ label, tooltip, tooltipText, isRequired, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited) 
    const [field, meta] = useField(props);
    // console.log(props);
    // console.log(field);
    return (
        <div className={`form-group ${isRequired && 'required'}`}>
            <label className="control-label font-weight-bold mb-1" htmlFor={props.id || props.name}>{label}{` `}{tooltip && <TooltipIcon text={tooltipText} id={`${props.id}-tooltip`} />}</label>
            <input className={`form-control ${meta.touched && meta.error && 'is-invalid'}`} {...field} {...props} />
            {meta.touched && meta.error ? (
                <div className="invalid-feedback">{meta.error}</div>
            ) : null}
        </div>
    );
};

export default TextInput;