import React, { useEffect } from "react";
import {
  addPeopleCompanyFilter,
  removePeopleCompanyFilter,
  addAllPeopleCompanyFilter,
  removeAllPeopleCompanyFilter,
  addPeopleDepartmentFilter,
  removePeopleDepartmentFilter,
  addAllPeopleDepartmentFilter,
  removeAllPeopleDepartmentFilter,
  addPeoplePayClassFilter,
  removePeoplePayClassFilter,
  addAllPeoplePayClassFilter,
  removeAllPeoplePayClassFilter,
} from "../../../redux/actions/filters/peopleFilters";
import { ToggleButton, ButtonGroup } from "react-bootstrap";

const PeopleFilter = ({ masterEmployeeList, filters, dispatch }) => {
  const payClass = [
    ...new Set(
      masterEmployeeList
        .filter((j) => j.Pay_Class_Name__c != null)
        .map((i) => i && i.Pay_Class_Name__c)
    ),
  ];
  const deparments = [
    ...new Set(
      masterEmployeeList
        .filter(
          (j) =>
            j.Department_Name__c != null &&
            j.Department_Name__c !== "Project Member"
        )
        .map((i) => i && i.Department_Name__c)
    ),
  ];
  const companies = [
    ...new Set(
      masterEmployeeList
        .filter((j) => j.Company__c != null)
        .map((i) => i && i.Company__c)
    ),
  ];

  const handleDepartment = (e, dep) => {
    if (e.target.checked) {
      dispatch(addPeopleDepartmentFilter(dep));
    } else {
      dispatch(removePeopleDepartmentFilter(dep));
    }
  };

  const handleCompany = (e, co) => {
    if (e.target.checked) {
      dispatch(addPeopleCompanyFilter(co));
    } else {
      dispatch(removePeopleCompanyFilter(co));
    }
  };

  const handlePayClass = (e, pc) => {
    if (e.target.checked) {
      dispatch(addPeoplePayClassFilter(pc));
    } else {
      dispatch(removePeoplePayClassFilter(pc));
    }
  };

  const formateDepartmentName = (dep) => {
    if (dep === "C&L") {
      return "kite";
    } else if (dep === "EX") {
      return "MC";
    }

    return dep;
  };

  const handleSelectAllDepartments = (e, deps) => {
    if (e.target.checked) {
      dispatch(addAllPeopleDepartmentFilter(deps));
    } else {
      dispatch(removeAllPeopleDepartmentFilter());
    }
  };

  const handleSelectAllPayClasses = (e, pClasess) => {
    if (e.target.checked) {
      dispatch(addAllPeoplePayClassFilter(pClasess));
    } else {
      dispatch(removeAllPeoplePayClassFilter());
    }
  };

  const handleSelectAllCompanies = (e, cos) => {
    if (e.target.checked) {
      dispatch(addAllPeopleCompanyFilter(cos));
    } else {
      dispatch(removeAllPeopleCompanyFilter());
    }
  };

  useEffect(() => {
    dispatch(addAllPeopleCompanyFilter(companies));
    dispatch(addAllPeoplePayClassFilter(payClass));
    dispatch(addAllPeopleDepartmentFilter(deparments));
  }, []);

  return (
    <div className="row mb-3">
      <div className="col-sm-12">
        <label className="control-label font-weight-bold mb-1">Company:</label>
        <br />
        {companies &&
          companies.map(
            (i) =>
              i && (
                <ButtonGroup className="mr-2 ml-0 shadow-none" key={i}>
                  <ToggleButton
                    type="checkbox"
                    className={`btn-sm px-3 chk-btn ${
                      filters.company.includes(i)
                        ? "btn-outline-primary"
                        : "btn-outline-unchecked"
                    }`}
                    checked={filters.company.includes(i) ? true : false}
                    onChange={(e) => handleCompany(e, i)}
                  >
                    {filters.company.includes(i) ? (
                      <>
                        <i className="fas fa-check"></i> {i}
                      </>
                    ) : (
                      <>
                        <i
                          className="fas fa-plus"
                          style={{ transform: "rotate(45deg)" }}
                        ></i>{" "}
                        {i}
                      </>
                    )}
                  </ToggleButton>
                </ButtonGroup>
              )
          )}
        <ButtonGroup className="mr-2 ml-0 shadow-none">
          <ToggleButton
            type="checkbox"
            className={`btn-sm px-3 chk-btn ${
              companies.every((elm) => filters.company.includes(elm))
                ? "btn-outline-primary"
                : "btn-outline-unchecked"
            }`}
            checked={
              companies.every((elm) => filters.company.includes(elm))
                ? true
                : false
            }
            onChange={(e) => handleSelectAllCompanies(e, companies)}
          >
            {companies.every((elm) => filters.company.includes(elm)) ? (
              <>
                <i className="fas fa-check"></i> All
              </>
            ) : (
              <>
                <i
                  className="fas fa-plus"
                  style={{ transform: "rotate(45deg)" }}
                ></i>{" "}
                All
              </>
            )}
          </ToggleButton>
        </ButtonGroup>
      </div>
      <div className="col-sm-12">
        <label className="control-label font-weight-bold mb-1 mt-2">
          Pay Class:
        </label>
        <br />
        {payClass &&
          payClass.map((i) => (
            <ButtonGroup className="mr-2 ml-0 shadow-none" key={i}>
              <ToggleButton
                type="checkbox"
                className={`btn-sm px-3 chk-btn ${
                  filters.payClass.includes(i)
                    ? "btn-outline-primary"
                    : "btn-outline-unchecked"
                }`}
                checked={filters.payClass.includes(i) ? true : false}
                onChange={(e) => handlePayClass(e, i)}
              >
                {filters.payClass.includes(i) ? (
                  <>
                    <i className="fas fa-check"></i> {i}
                  </>
                ) : (
                  <>
                    <i
                      className="fas fa-plus"
                      style={{ transform: "rotate(45deg)" }}
                    ></i>{" "}
                    {i}
                  </>
                )}
              </ToggleButton>
            </ButtonGroup>
          ))}
        <ButtonGroup className="mr-2 ml-0 shadow-none">
          <ToggleButton
            type="checkbox"
            className={`btn-sm px-3 chk-btn ${
              payClass.every((elm) => filters.payClass.includes(elm))
                ? "btn-outline-primary"
                : "btn-outline-unchecked"
            }`}
            checked={
              payClass.every((elm) => filters.payClass.includes(elm))
                ? true
                : false
            }
            onChange={(e) => handleSelectAllPayClasses(e, payClass)}
          >
            {payClass.every((elm) => filters.payClass.includes(elm)) ? (
              <>
                <i className="fas fa-check"></i> All
              </>
            ) : (
              <>
                <i
                  className="fas fa-plus"
                  style={{ transform: "rotate(45deg)" }}
                ></i>{" "}
                All
              </>
            )}
          </ToggleButton>
        </ButtonGroup>
      </div>
      <div className="col-sm-12">
        <label className="control-label font-weight-bold mb-1 mt-2">
          Department:
        </label>
        <br />
        {deparments &&
          deparments.map((i) => (
            <ButtonGroup className="mr-2 ml-0 shadow-none" key={i}>
              <ToggleButton
                type="checkbox"
                className={`btn-sm px-3 chk-btn ${
                  filters.department.includes(i)
                    ? "btn-outline-primary"
                    : "btn-outline-unchecked"
                }`}
                checked={filters.department.includes(i) ? true : false}
                onChange={(e) => handleDepartment(e, i)}
              >
                {filters.department.includes(i) ? (
                  <>
                    <i className="fas fa-check"></i> {formateDepartmentName(i)}
                  </>
                ) : (
                  <>
                    <i
                      className="fas fa-plus"
                      style={{ transform: "rotate(45deg)" }}
                    ></i>{" "}
                    {formateDepartmentName(i)}
                  </>
                )}
              </ToggleButton>
            </ButtonGroup>
          ))}
        <ButtonGroup className="mr-2 ml-0 shadow-none">
          <ToggleButton
            type="checkbox"
            className={`btn-sm px-3 chk-btn ${
              deparments.every((elm) => filters.department.includes(elm))
                ? "btn-outline-primary"
                : "btn-outline-unchecked"
            }`}
            checked={
              deparments.every((elm) => filters.department.includes(elm))
                ? true
                : false
            }
            onChange={(e) => handleSelectAllDepartments(e, deparments)}
          >
            {deparments.every((elm) => filters.department.includes(elm)) ? (
              <>
                <i className="fas fa-check"></i> All
              </>
            ) : (
              <>
                <i
                  className="fas fa-plus"
                  style={{ transform: "rotate(45deg)" }}
                ></i>{" "}
                All
              </>
            )}
          </ToggleButton>
        </ButtonGroup>
      </div>
    </div>
  );
};

export default PeopleFilter;
