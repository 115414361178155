import React, {useState, useEffect} from 'react';
import Tour from 'reactour'
import {isMobile} from 'react-device-detect';


const TourRunner = ({content = [], mobileContent = [], isClosed, updateSalesforce}) => {
    const [isTourOpen, setIsTourOpen] = useState(false);

    useEffect(() => {
        if(isClosed && isClosed === true) {
            setIsTourOpen(false);
        } else {
            setTimeout(() => {
                setIsTourOpen(true);
            }, 2000)
        }

        
    }, [isClosed])
    return (
        <Tour
            steps={isMobile ? mobileContent : content}
            isOpen={isTourOpen}
            onRequestClose={() => setIsTourOpen(false)}
            rounded={5}
            closeWithMask={false}
            showNumber={true}
            showNavigationNumber={true}
            // onBeforeClose={updateSalesforce}
            children={
                <div className="text-center">
                    <button 
                        onClick={() => {
                            updateSalesforce();
                            setIsTourOpen(false);
                        }}
                        className="btn btn-link font-weight-normal">Don't show this tour again!</button>
                </div>
            }
        />
    )
}

export default TourRunner;