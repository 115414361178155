import React from 'react'
import avatar from '../../../images/6.jpg'
import user from '../../../images/user.jpg'
import {connect} from 'react-redux'
import { Link } from 'react-router-dom';
import SignOutButtonLink from '../../auth/SignOutButtonLink';


const Account = (props) => {

    const displayName = (givenName, surname) => {
        return givenName.charAt(0)+surname.charAt(0);
    }

    return (
        <li className="c-header-nav-item dropdown" data-tour="dash-2">
            <a
                className="c-header-nav-link"
                data-toggle="dropdown" 
                href="#"
                role="button"
                aria-haspopup="true"
                aria-expanded="false">
                {/*<div className="c-avatar"><img className="c-avatar-img" src={avatar}/></div>*/}
                <div className="c-avatar">
                    {props.userPhoto ? 
                    <img className="c-avatar-img" src={props.userPhoto}/> : 
                    <div>
                        <div className="rounded-circle d-none d-sm-block header-profile-image">
                            {props.user && displayName(props.user.givenName, props.user.surname)}
                        </div>
                    </div>}
                </div>
                
            </a>
            <div className="dropdown-menu dropdown-menu-right pt-0">
                {/* <div className="dropdown-header bg-light py-2">
                    <strong>Account</strong>
                </div>
                <a className="dropdown-item" href="#">
                    <i className="fas fa-download c-icon mr-2"></i>
                    Updates<span className="badge badge-info ml-auto">42</span>
                </a>
                <a className="dropdown-item" href="#">
                    <i className="fas fa-download c-icon mr-2"></i>
                    Messages<span className="badge badge-success ml-auto">42</span>
                </a>
                <a className="dropdown-item" href="#">
                    <i className="fas fa-download c-icon mr-2"></i>
                    Tasks<span className="badge badge-danger ml-auto">42</span>
                </a>
                <a className="dropdown-item" href="#">
                    <i className="fas fa-download c-icon mr-2"></i>
                    Comments<span className="badge badge-warning ml-auto">42</span>
                </a> 
                <div className="dropdown-header bg-light py-2">
                    <strong>Settings</strong>
                </div>*/}
                <Link className="dropdown-item" to="/profile">
                    <i className="cil-user c-icon mr-2"></i>
                    Profile</Link>
                {/*<a className="dropdown-item" href="#">
                    <i className="fas fa-cogs c-icon mr-2"></i>
                    Settings</a>
                <a className="dropdown-item" href="#">
                    <i className="fas fa-download c-icon mr-2"></i>
                    Payments<span className="badge badge-secondary ml-auto">42</span>
                </a>
                <a className="dropdown-item" href="#">
                    <i className="fas fa-download c-icon mr-2"></i>
                    Projects<span className="badge badge-primary ml-auto">42</span>
                </a> 
                <div className="dropdown-divider"></div>*/}

                {/* <a className="dropdown-item" href="#" >
                    <i className="fas fa-sign-out-alt c-icon mr-2"></i>
                    Logout</a> */}
                <SignOutButtonLink />
            </div>
        </li>
    )
}



export default connect()(Account)