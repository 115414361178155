import React from 'react'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, {Search}  from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import {DropdownButton, Dropdown, ButtonGroup, Button} from 'react-bootstrap';

const DatatableSm = (props) => {
    const {SearchBar} = Search;

    const paginationOptions = paginationFactory({
        page: 1,
        paginationSize: 5,
        sizePerPage: 10,
        firstPageText: 'First',
        prePageText: 'Previous',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: true,
        sizePerPageList: [
            {
                text: '5',
                value: 5
            }, {
                text: '10',
                value: 10
            }, {
                text: '50',
                value: 50
            }, {
                text: 'All',
                value: props.data.length
            }
        ]
    });

    const CustomToggleList = ({columns, onColumnToggle, toggles}) => (
        <DropdownButton
            as={ButtonGroup}
            id="dropdown-basic-button"
            title="Columns"
            size="sm"
            variant="secondary">
            {columns
                .map(column => ({
                ...column,
                toggle: toggles[column.dataField]
            }))
                .map(column => {
                    if (column.dataField !== "Id") {
                        return (
                            <Dropdown.Item
                                type="button"
                                key={column.dataField}
                                active={column.toggle}
                                data-toggle="button"
                                aria-pressed={column.toggle
                                ? 'true'
                                : 'false'}
                                onClick={() => onColumnToggle(column.dataField)}>
                                {column.text}
                            </Dropdown.Item>
                        )
                    }
                })
}
        </DropdownButton>
    );

    const MyExportCSV = (props) => {
        const handleClick = () => {
            props.onExport();
        };
        return (
            <Button variant="primary" size="sm" news-tour="5" as={ButtonGroup} onClick={handleClick}>Export</Button>
        );
    };

    const rowClasses = (row, rowIndex) => {
        let classes = null;
      
        if (
            row.Purch_Approval_Status__c === 'Pending Approval' || 
            row.Proj_Estimate_Approval_Status__c  === 'Pending Approval' || 
            row.Proj_Estimate_Approval_Status__c  === 'Pending Estimate'
        ) {
            classes = 'bg-sdi-yellow-light text-dark';
        }
      
        return classes;
      };

    return (
        <ToolkitProvider
            bootstrap4
            data={props.data}
            columns={props.columns}
            defaultSorted={props.sortBy}
            keyField="Id"
            
            columnToggle
            search
            
            exportCSV={{
                fileName: props.csvFileName ? props.csvFileName : `${'file'}.csv`,
                separator: ',',
                ignoreHeader: false,
                noAutoBOM: false,
            }}>
            {props => (
                <div>
                    <div className="row">
                        <div className="col" data-tour="it-req-3" news-tour="4">
                            <SearchBar id="dtsearchbar" className='form-control-sm' { ...props.searchProps }/>
                        </div>
                        <div className="col text-right" data-tour="it-req-4">
                            {/* <ButtonGroup>
                                <CustomToggleList { ...props.columnToggleProps }/> */}
                                <MyExportCSV { ...props.csvProps }/>
                            {/* </ButtonGroup> */}
                        </div>
                    </div><BootstrapTable
                        bootstrap4
                        keyField="Id"
                        rowClasses={rowClasses}
                        pagination={paginationOptions}
                        // classes="table table-sm table-hover"
                        classes={'table table-sm table-hover table-responsive-sm table-responsive-md table-responsive-lg table-responsive-xl '}
                        { ...props.baseProps } /></div>
            )}
        </ToolkitProvider>
    )
}

export default DatatableSm