// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    clientId: "b6b43d44-193b-4570-91f4-c74fbf97a45b",
    redirectUri: "https://portal.sdimktg.com/",
    postLogoutRedirectUri: "/login",
  },
};

// STAGING (NOT MAIN):
//https://sdi-employee-portal.azurewebsites.net/
// PRODUCTION:
//https://portal.sdimktg.com/
//window.location.origin
// STAGING:
//https://sdi-employee-portal-staging.azurewebsites.net/

// Add here scopes for id token to be used at MS Identity Platform endpoints....
export const loginRequest = {
  scopes: ["User.Read"],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
  graphProfileImageEndpoint: "https://graph.microsoft.com/v1.0/me/photo/$value",
  graphOrgContacts: "https://graph.microsoft.com/v1.0/users",
};
