import swal from 'sweetalert';


export const deleteConfirmation = () => {
    return swal({
        title: "Warning",
        text: "Are you sure you want to delete this record? This action cannot be undone.",
        icon: "warning",
        dangerMode: true,
        buttons: {
            confirm: {
                text: 'Delete',
                value: true,
                visible: true,
                className: 'btn btn-sm btn-danger'
            },
            cancel: {
                text: 'Cancel',
                value: null,
                visible: true,
                className: 'btn btn-sm btn-secondary'
            }
        }
    })
}


export const deleteConfirmed = () => {
    return swal({
        title: 'Deleted!',
        text: 'Record has been deleted!',
        icon: 'success',
        buttons: {
            confirm: {
                text: 'Done!',
                value: true,
                visible: true,
                className: 'btn btn-sm btn-primary'
            }
        }
    })
}


export const deleteError = (error) => {
    return swal({
        title: 'Oops!',
        text: error,
        icon: 'error',
        buttons: {
            confirm: {
                text: 'Ok',
                value: true,
                visible: true,
                className: 'btn btn-sm btn-primary'
            }
        }
    })
}