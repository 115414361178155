import React, {useState} from 'react'
import {Modal, Button, Table} from 'react-bootstrap'
import moment from 'moment';


const ApprovalHistory = ({approvals, notes}) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const formatStatus = (cellContent) => {
        if(cellContent === 'Approved') {
            return <span className="badge badge-success">{cellContent}</span>
        } else if (cellContent === 'Rejected') {
            return <span className="badge badge-danger">{cellContent}</span>
        } 
    
        return <span className="badge badge-warning">{cellContent}</span>
    }

    const renderApprovals = () => {
        return (
            <Modal size="lg" show={show} onHide={handleClose}>
                <Modal.Header closeButton style={{borderBottom: '0 none'}}>
                    <Modal.Title style={{margin: '0 auto'}}>Approval History</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>Created Date</th>
                                <th>Completed Date</th>
                                <th>Status</th>
                                <th>Comments</th>
                            </tr>
                        </thead>
                        <tbody>
                            {approvals.map((approval) => {
                                return <tr>
                                    <td>{moment(approval.CreatedDate).format('ll')}</td>
                                    <td>{approval.CompletedDate && moment(approval.CompletedDate).format('ll')}</td>
                                    <td>{formatStatus(approval.Status)}</td>
                                    <td>{approval.StepsAndWorkitems && approval.StepsAndWorkitems.records[0].Comments}</td>
                                </tr>
                            })}
                        </tbody>
                    </Table>
                </Modal.Body>
            </Modal>
        )
    }

    return (
        <>
        {notes } <button onClick={handleShow} className="btn btn-link btn-sm p-0 font-weight-normal" style={{lineHeight: '1'}}>
            (History)
        </button>

            {show && renderApprovals()}
        </>
    )
}

export default ApprovalHistory;