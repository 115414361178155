import moment from "moment";

export const peopleFilter = (peopleList, { company, payClass, department }) => {
  const people =
    peopleList &&
    peopleList.filter((i) => {
      //const payClassMatch = payClass ? i.Pay_Class_Name__c.includes(payClass) : i;
      const payClassMatch =
        payClass && i ? payClass.includes(i.Pay_Class_Name__c) : i;
      const departmentMatch =
        department && i ? department.includes(i.Department_Name__c) : i;
      const companyMatch = company && i ? company.includes(i.Company__c) : i;
      //const companyMatch = company && i.Company__c ? i.Company__c.includes(company) : i;

      return payClassMatch && departmentMatch && companyMatch;
    });

  const fYear = getFiscalYear();

  const fYearMonths = [];

  for (var i = 1; i < 13; i++) {
    if (i > 5) {
      fYearMonths.push(moment(fYear.start + "-" + i + "-1"));
    } else {
      fYearMonths.push(moment(fYear.end + "-" + i + "-1"));
    }
  }

  const results = fYearMonths
    .sort((a, b) => {
      return a - b;
    })
    .map((month) => {
      // console.log("This month", month);
      // console.log("Next month", moment(month).add(1, "month"));
      //Status is 'active' hire date is equal or less then the month and the year
      let active_as_of_1st = [];

      //when hire date is within the month
      let new_hire_nn = [];

      //when hire date is within the month
      let new_hire_r = [];

      //when status change is within the month
      let changes = [];

      let voluntary = [];
      let involuntary = [];
      let active_as_of_eom = [];

      const yearMonth = moment(month).format("MMM - YYYY");

      people &&
        people.forEach((i) => {
          if (
            moment(i.Hire_Date__c).isSameOrAfter(month) &&
            moment(i.Hire_Date__c).isBefore(moment(month).add(1, "month"))
          ) {
            new_hire_r.push(i);
          }

          if (
            i.Employment_Status__c.toUpperCase() === "ACTIVE".toUpperCase() &&
            // ||
            //   i.Employment_Status_Reason_XRefCode__c.toUpperCase() ===
            //     "Salary Continuance".toUpperCase()
            i.Employment_Status_Reason_XRefCode__c.toUpperCase() !==
              "NEWHIRE".toUpperCase() &&
            i.Employment_Status_Reason_XRefCode__c.toUpperCase() !==
              "REHIRE".toUpperCase() &&
            i.Employment_Status_Reason_XRefCode__c.toUpperCase() !==
              "NEW HIRE REPLACEMENT".toUpperCase() &&
            i.Employment_Status_Reason_XRefCode__c.toUpperCase() !==
              "NEW HIRE NET NEW".toUpperCase() &&
            i.Employment_Status_Reason_XRefCode__c.toUpperCase() !==
              "REHIRE".toUpperCase() &&
            i.Employment_Status_Reason_XRefCode__c.toUpperCase() !==
              "RETURN TO WORK".toUpperCase() &&
            moment(i.Employment_Status_Effective_From__c).month() ===
              moment(month).month() &&
            moment(i.Employment_Status_Effective_From__c).year() ===
              moment(month).year()
          ) {
            changes.push(i);
          }

          if (
            // i.Employment_Status_Reason_XRefCode__c === "Salary Continuance" ||
            moment(i.Termination_Date__c).isSameOrAfter(month) &&
            moment(i.Termination_Date__c).isAfter(i.Hire_Date__c) &&
            moment(i.Termination_Date__c).isBefore(
              moment(month).add(1, "month")
            )
          ) {
            involuntary.push(i);
          }

          if (
            moment(i.Hire_Date__c).isBefore(month) &&
            i.Employment_Status__c !== "PRESTART" &&
            i.Employment_Status_Reason_XRefCode__c !== "Salary Continuance" &&
            (i.Termination_Date__c === null ||
              moment(i.Termination_Date__c).isSameOrAfter(month) ||
              moment(i.Hire_Date__c).isAfter(i.Termination_Date__c))
          ) {
            active_as_of_1st.push(i);
          }

          if (
            moment(i.Hire_Date__c).isBefore(moment(month).add(1, "month")) &&
            i.Employment_Status__c !== "PRESTART" &&
            i.Employment_Status_Reason_XRefCode__c !== "Salary Continuance" &&
            (i.Termination_Date__c === null ||
              moment(i.Termination_Date__c).isSameOrAfter(
                moment(month).add(1, "month")
              ) ||
              moment(i.Hire_Date__c).isAfter(i.Termination_Date__c))
          ) {
            active_as_of_eom.push(i);
          }
        });

      return {
        month: yearMonth,
        date: month,
        active_as_of_1st,
        new_hire_nn,
        new_hire_r,
        changes,
        voluntary,
        involuntary,
        active_as_of_eom,
      };
    });

  const data = {
    type: "Permanent",
    location: "SDI Canada",
    results,
  };

  return data;
};

export const peopleTimeAwayFilter = (
  peopleList,
  { company, payClass, department }
) => {
  const people =
    peopleList &&
    peopleList
      .filter((i) => {
        const departmentMatch =
          department && i ? department.includes(i.Department_Name__c) : i;
        const companyMatch = company && i ? company.includes(i.Company__c) : i;

        return departmentMatch && companyMatch;
      })
      .map((i) => {
        let tempItem = Object.assign({}, i);

        let vacation_salary_allocation = getAllocationTotal(
          i.Employee_Time_Away__r,
          "Vacation_Salary"
        );
        let vacation_salary_taken = getTakenTotal(
          i.Employee_Time_Away__r,
          "Vacation_Salary"
        );
        let vacation_salary_booked = getBookedTotal(
          i.Employee_Time_Away__r,
          "Vacation_Salary"
        );
        let vacation_caryover_allocation = getAllocationTotal(
          i.Employee_Time_Away__r,
          "Carry_Over"
        );
        let vacation_caryover_taken = getTakenTotal(
          i.Employee_Time_Away__r,
          "Carry_Over"
        );
        let vacation_caryover_booked = getBookedTotal(
          i.Employee_Time_Away__r,
          "Carry_Over"
        );
        let sick_allocation = getAllocationTotal(
          i.Employee_Time_Away__r,
          "Sick"
        );
        let sick_taken = getTakenTotal(i.Employee_Time_Away__r, "Sick");
        let sick_booked = getBookedTotal(i.Employee_Time_Away__r, "Sick");
        let personal_day_allocation = getAllocationTotal(
          i.Employee_Time_Away__r,
          "Personal Day"
        );
        let personal_day_taken = getTakenTotal(
          i.Employee_Time_Away__r,
          "Personal Day"
        );
        let personal_day_booked = getBookedTotal(
          i.Employee_Time_Away__r,
          "Personal Day"
        );
        let float_allocation = getAllocationTotal(
          i.Employee_Time_Away__r,
          "Float"
        );
        let float_taken = getTakenTotal(i.Employee_Time_Away__r, "Float");
        let float_booked = getBookedTotal(i.Employee_Time_Away__r, "Float");
        let volunteer_day_allocation = getAllocationTotal(
          i.Employee_Time_Away__r,
          "Volunteer Day"
        );
        let volunteer_day_taken = getTakenTotal(
          i.Employee_Time_Away__r,
          "Volunteer Day"
        );
        let volunteer_day_booked = getBookedTotal(
          i.Employee_Time_Away__r,
          "Volunteer Day"
        );
        let vacation_allocation = getAllocationTotal(
          i.Employee_Time_Away__r,
          "Vacation"
        );
        let vacation_taken = getTakenTotal(i.Employee_Time_Away__r, "Vacation");
        let vacation_booked = getBookedTotal(
          i.Employee_Time_Away__r,
          "Vacation"
        );

        let vacation_salary_taken_percentage = getTakenPercentage(
          vacation_salary_allocation,
          vacation_salary_booked,
          vacation_salary_taken
        );
        let vacation_salary_remaining = getRemainingTotal(
          vacation_salary_allocation,
          vacation_salary_booked,
          vacation_salary_taken
        );

        return {
          ...tempItem,
          vacation_salary_allocation,
          vacation_salary_taken,
          vacation_salary_booked,
          vacation_salary_taken_percentage,
          vacation_salary_remaining,
          vacation_caryover_allocation,
          vacation_caryover_taken,
          vacation_caryover_booked,
          sick_allocation,
          sick_taken,
          sick_booked,
          personal_day_allocation,
          personal_day_taken,
          personal_day_booked,
          float_allocation,
          float_taken,
          float_booked,
          volunteer_day_allocation,
          volunteer_day_taken,
          volunteer_day_booked,
          vacation_allocation,
          vacation_taken,
          vacation_booked,
        };
      });

  return people;
};

export const getAllocationTotal = (data, type) => {
  let total = 0;

  const items =
    data &&
    data.find((i) => i.Request_Type__c === type && i.Type__c === "Entitlement");
  total = items ? items.Total_Days__c : 0;

  return total;
};

export const getTakenTotal = (data, type) => {
  let total = 0;

  const items =
    data &&
    data.filter(
      (i) =>
        i.Request_Type__c === type &&
        i.Type__c === "Used" &&
        i.Status__c === "Taken"
    );
  total = items
    ? items.reduce((a, b) => a + Math.abs(b.Total_Days__c), total)
    : 0;

  return isInt(total) ? total : parseFloat(total);
};

export const getBookedTotal = (data, type) => {
  let total = 0;

  const items =
    data &&
    data.filter(
      (i) =>
        i.Request_Type__c === type &&
        i.Type__c === "Used" &&
        i.Status__c === "Booked"
    );
  total = items
    ? items.reduce((a, b) => a + Math.abs(b.Total_Days__c), total)
    : 0;

  return isInt(total) ? total : parseFloat(total);
};

export const getTakenPercentage = (allocation, booked, taken) => {
  const result = ((taken + booked) * 100) / allocation;

  if (result && isFinite(result)) {
    return isInt(result) ? result : parseFloat(result).toFixed(1);
  } else if (result && !isFinite(result)) {
    return 0;
  }

  return result || 0;
};

export const getRemainingDays = (data, type) => {
  const allocatedDays = getAllocationTotal(data, type);
  const bookedDays = getBookedTotal(data, type);
  const takenDays = getTakenTotal(data, type);

  return getRemainingTotal(allocatedDays, bookedDays, takenDays);
};

export const getRemainingTotal = (allocation, booked, taken) => {
  const result = allocation - (taken + booked);

  if (result && isFinite(result)) {
    return isInt(result) ? result : parseFloat(result).toFixed(1);
  } else if (result && !isFinite(result)) {
    return 0;
  }

  return result || 0;
};

const isInt = (n) => {
  return n % 1 === 0;
};

const getFiscalYear = (date) => {
  var thisFiscalStart = "";
  var thisFiscalEnd = "";

  if (moment(date).quarter() === 3 || moment(date).quarter() === 4) {
    thisFiscalStart = moment(date)
      .month("June")
      .startOf("month")
      .format("YYYY-MM-DD");
    thisFiscalEnd = moment(date)
      .add(1, "year")
      .month("May")
      .endOf("month")
      .format("YYYY-MM-DD");
  } else {
    thisFiscalStart = moment(date)
      .subtract(1, "year")
      .month("June")
      .startOf("month")
      .format("YYYY-MM-DD");
    thisFiscalEnd = moment(date)
      .month("May")
      .endOf("month")
      .format("YYYY-MM-DD");
  }

  return {
    start: moment(thisFiscalStart).year(),
    end: moment(thisFiscalEnd).year(),
  };
};

export const getSubordinates = (peopleList, user) => {
  let arrayOfLevels = [];
  let finalResult = [];
  let personLoggedIn =
    peopleList &&
    peopleList.find((person) => person.Employee_Number__c === user.EmployeeID);

  //Everyone in ELT and HR
  const access = user.Access ? user.Access.split(";") : [];

  let oneLevelDown =
    peopleList &&
    peopleList.filter((i) => i.Manager_XRefCode__c === user.EmployeeID);
  let counter = 0;

  let managerInSubordinates =
    oneLevelDown &&
    oneLevelDown?.find((person) => {
      return person?.Employee_Number__c === personLoggedIn?.Manager_XRefCode__c;
    });

  if (
    managerInSubordinates ||
    access.includes("ELT") ||
    access.includes("HR") ||
    access.includes("Admin")
  ) {
    return peopleList;
  }

  arrayOfLevels.push(oneLevelDown);

  while (arrayOfLevels[counter] && arrayOfLevels[counter].length > 0) {
    let currentArray = arrayOfLevels[counter];
    let levelDown = [];

    currentArray.forEach((person) => {
      const result = peopleList.filter(
        (j) => j.Manager_XRefCode__c === person.Employee_Number__c
      );
      levelDown.push(...result);
    });

    arrayOfLevels.push(levelDown);

    counter++;
  }

  if (arrayOfLevels.length > 0) {
    for (let ary of arrayOfLevels) {
      finalResult.push(...ary);
    }
  }

  personLoggedIn && finalResult.unshift(personLoggedIn);

  return finalResult;
};
