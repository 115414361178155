import Layout from './layout/Layout';

const NotFound = () => {

    const breadcrumb = [
        { name:'404', link: '' },
    ]

    return (
        <Layout breadcrumb={breadcrumb}>
            <div className="d-flex flex-row align-items-center" style={{minHeight: '75vh'}}>
                <div className="container">
                    <div className="d-flex justify-content-center">
                    <div className="d-flex">
                        <h1 className="float-start display-3 mr-4">404</h1>
                        <div className="clearfix">
                            <h4 className="pt-3">Oops!</h4>
                            <p className="text-medium-emphasis">The page you are looking for was not found.</p>
                        </div>
                    </div>
                    </div>
                    
                </div>
            </div>
        </Layout>
    )
}

export default NotFound
