import React, {useEffect, useState} from 'react';
import Carousel from 'react-bootstrap/Carousel'
import image3 from '../../images/image3.jpg'
import NewsEventsModal from './NewsEventsModal';
import axios from 'axios';
import {connect} from "react-redux";
import {updateNewsEventsSuccess} from '../../redux/actions/newsEvents/updateNewsEvents';
import images from '../newsEvents/images'



const renderBadge = (type) => {
    if(type === 'Learning & Development') {
        return <span className="d-none d-sm-block badge badge-success news-header h6">{type}</span>
    } else if(type === 'News') {
        return <span className="d-none d-sm-block badge badge-warning news-header h6">{type}</span>
    } else if(type === 'Event') {
        return <span className="d-none d-sm-block badge badge-info news-header h6">{type}</span>
    } else if(type === 'Trend') {
        return <span className="d-none d-sm-block badge badge-danger news-header h6">{type}</span>
    } else if(type === 'Corporate Value') {
        return <span className="d-none d-sm-block badge badge-primary news-header h6">{type}</span>
    } else if(type === 'CwR') {
        return <span className="d-none d-sm-block badge badge-light news-header h6">{type}</span>
    } else if(type === 'SDI Balance') {
        return <span className="d-none d-sm-block badge badge-secondary news-header h6">{type}</span>
    }
    return <span className="d-none d-sm-block badge badge-warning news-header h6">{type}</span>
}



const NewsEventsItem = ({event, dispatch, events}) => {
    

    const image = images.find((image) => image.title === event.Background_Image__c);

    // useEffect(() => {
    //     const download = async () => {
    //         if(event.Attachments && event.Attachments.records[0] && event.ImageUrl === null) {
    //             const response = await axios.get(`/newsEvents/attachment/${event.Attachments.records[0].Id}`, {responseType: 'arraybuffer'});
    
        
    //             if (response.status == 200) {
    //                 const blob = new Blob([response.data]);
    //                 const url = URL.createObjectURL(blob);
    //                 dispatch(updateNewsEventsSuccess(event.Id, {ImageUrl: url}))
    //             }
    //         }
    //     }

    //     download();
        
    // }, [])
    return (
        <>
            <img src={image ? image.src : 'https://via.placeholder.com/1200x400.png'} className="carousel-image d-block w-100 " alt=""/>
            {renderBadge(event.Type__c)}
            <Carousel.Caption className="py-1">
                <div className="d-none d-sm-block event-title">{event.Title__c}</div>   
                <div className="d-none d-sm-block">{event.Brief_Description__c}</div>
                <NewsEventsModal currentEvent={event} currentImage={image && image.src} events={events} />
            </Carousel.Caption>
        </>
    )
}


export default connect(null)(NewsEventsItem);