import React, {useState, useEffect} from 'react'
import {Modal, Button} from 'react-bootstrap'
import DatatableSm from '../../layout/DatatableSm';
import {columns, defaultSorted} from './columns'

const Contact = ({people, title, btnText}) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const renderModal = () => {
        return (
            <Modal size="lg" show={show} onHide={handleClose}>
                <Modal.Header closeButton style={{borderBottom: '0 none'}}>
                    <Modal.Title style={{margin: '0 auto'}}>{title}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <DatatableSm
                        csvFileName={title+'.csv'}
                        data={people}
                        columns={columns}
                        sortBy={defaultSorted}
                    />
                </Modal.Body>
            </Modal>
        )
    }

    const renderButton = (btnText, arrayLength) => {
        if(btnText) {
            return <a onClick={handleShow} className="btn btn-sm btn-primary" href="#0">{btnText}</a>
        }

        return <a onClick={handleShow} href="#0">{arrayLength}</a>
    }
    

    return (
        <>
            {renderButton(btnText, people.length)}
            
            {show && renderModal()}

        </>
    );
}

export default Contact;