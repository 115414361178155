import {ActionTypes} from '../actionTypes';
import axios from 'axios';

export const fetchHelpDocsStart = () => ({type: ActionTypes.FETCH_HELP_DOCS_START});
export const fetchHelpDocsSuccess = (payload) => ({type: ActionTypes.FETCH_HELP_DOCS_SUCCESS, payload});
export const fetchHelpDocsError = (error) => ({type: ActionTypes.FETCH_HELP_DOCS_ERROR, error});

export const fetchHelpDocs = () => {
    return async (dispatch) => {
        try {
            dispatch(fetchHelpDocsStart());
            const response = await axios.get('/helpDocs/all')


            if (response.status === 200) {
                dispatch(fetchHelpDocsSuccess(response.data.helpDocs))
            }
        } catch (err) {
            dispatch(fetchHelpDocsError(err));
        }
    }
}