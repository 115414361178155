import {ActionTypes} from '../actionTypes';
import axios from 'axios';


export const deleteEmployeeForecastStart = () => ({type: ActionTypes.DELETE_EMPLOYEE_FORECAST_START})
export const deleteEmployeeForecastSuccess = (Id) => ({type: ActionTypes.DELETE_EMPLOYEE_FORECAST_SUCCESS, Id})
export const deleteEmployeeForecastError = (error) => ({type: ActionTypes.DELETE_EMPLOYEE_FORECAST_ERROR, error})


export const deleteEmployeeForecast = ({Id}) => {
    return async (dispatch) => {
        try {
            dispatch(deleteEmployeeForecastStart())
            const response = await axios.delete(`/employeeForecast/delete/${Id}`)

            if (response.status == 200) {
                dispatch(deleteEmployeeForecastSuccess(response.data.id))
            }

        } catch (err) {
            dispatch(deleteEmployeeForecastError(err))
        }
    }
}