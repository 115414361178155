import React from "react";
import Layout from "../layout/Layout";
import { Tabs, Tab } from "react-bootstrap";
import { connect } from "react-redux";
import { selectEmployeesByDepartment } from "../../redux/selectors/orgChartsSelectors";
import OrgChartPeople from "./OrgChartPeople";

import "./zoom.css";

const OrgCharts = ({ companies, masterList, businessUnits }) => {
  const breadcrumb = [{ name: "Enterprise Org Charts", link: "" }];

  return (
    <Layout breadcrumb={breadcrumb}>
      <div className="card">
        <div className="card-body">
          <Tabs
            defaultActiveKey={companies[0]?.company
              ?.replace(/\s+/g, "-")
              .toLowerCase()}
            id="org-charts"
            className="mb-3"
          >
            {companies?.map((i) => (
              <Tab
                className="pb-3 "
                eventKey={i.department.replace(/\s+/g, "-").toLowerCase()}
                title={i.department}
                key={i.department}
              >
                <OrgChartPeople
                  people={i.people}
                  department={i.department}
                  businessUnits={businessUnits}
                  masterList={masterList}
                />
              </Tab>
            ))}
          </Tabs>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state, props) => {
  return {
    companies: selectEmployeesByDepartment(
      state?.masterEmployeeList?.data?.filter(
        (i) =>
          i.Department_Name__c !== "Field" &&
          i.Department_Name__c !== "Project Member"
      )
    ),
    masterList: state?.masterEmployeeList?.data,
    businessUnits: state?.orgChartBusinessUnits?.data,
  };
};

export default connect(mapStateToProps)(OrgCharts);
