import React, {useState, useEffect} from 'react';
import Layout from '../layout/Layout';
import {connect} from 'react-redux';
import FormsList from './FormsList';
import Pagination from '../layout/Pagination';
import FormsFilter from './FormsFilter';
import {formsSearch} from '../../redux/selectors/formsSelectors';

const Forms = ({forms, dispatch, filters}) => {
    const [records, setRecords] = useState([]);
    const [recordsPerPage, setRecordsPerPage] = useState(9);
    const [currentPage, setCurrentPage] = useState(1);

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = records.slice(indexOfFirstRecord, indexOfLastRecord);
    const setPage = (pageNumber) => setCurrentPage(pageNumber);

    useEffect(() => {
        setRecords(forms);
    }, [forms])

    const breadcrumb = [
        { name:'Corporate Forms', link: '' }
    ]
    return (
        <Layout breadcrumb={breadcrumb}>
            <div className="card">
                <div className="card-header bg-sdi-black text-sdi-yellow">
                    Corporate Forms
                </div>
                <div className="card-body">
                    <FormsFilter dispatch={dispatch} setCurrentPage={setCurrentPage} filters={filters} forms={forms} />

                    <FormsList forms={currentRecords} />

                    <Pagination 
                        totalRecords={forms.length}
                        recordsPerPage={recordsPerPage}
                        setPage={setPage}
                        currentPage={currentPage}
                    />

                </div>
            </div>
        </Layout>
    )
}

const mapStateToProps = (state) => {
    return {
        forms: formsSearch(state.forms.data, state.formsFilters),
        filters: state.formsFilters,
    }
}

export default connect(mapStateToProps)(Forms)
