import * as Yup from 'yup';


export const validationSchema = () => {
    return Yup.object({
        // onBehalfOf: Yup.string()
        //     .matches(/[a-zA-Z0-9]{15}|[a-zA-Z0-9]{18}$/, 'Please select an option from the list'),
        category: Yup.string().required('Required'),
        priority: Yup.string().required('Required'),
        description: Yup
            .string()
            .max(100, 'Must be 100 characters or less')
            .required('Required'),
        requestDetails: Yup
            .string()
            .min(9, 'Required')
            .max(2009, 'Must be 2000 characters or less')
            .required('Required'),
        neededBy: Yup.string().required('Required').nullable(),
    })
}

export const validationSchemaSupport = () => {
    return Yup.object({
        // onBehalfOf: Yup.string()
        //     .matches(/[a-zA-Z0-9]{15}|[a-zA-Z0-9]{18}$/, 'Please select an option from the list'),
        project: Yup.string()
            .matches(/[a-zA-Z0-9]{15}|[a-zA-Z0-9]{18}$/, 'Please select an option from the list'),
        category: Yup.string().required('Required'),
        priority: Yup.string().required('Required'),
        description: Yup
            .string()
            .max(100, 'Must be 100 characters or less')
            .required('Required'),
        requestDetails: Yup
            .string()
            .min(9, 'Required')
            .max(2009, 'Must be 2000 characters or less')
            .required('Required'),
        neededBy: Yup.string().required('Required').nullable(),
    })
}

export const validationSchemaPurchase = () => {
    return Yup.object({
        // onBehalfOf: Yup.string()
        //     .matches(/[a-zA-Z0-9]{15}|[a-zA-Z0-9]{18}$/, 'Please select an option from the list'),
        // vendor: Yup.string()
        //     .matches(/[a-zA-Z0-9]{15}|[a-zA-Z0-9]{18}$/, 'Please select an option from the list'),
        category: Yup.string().required('Required'),
        priority: Yup.string().required('Required'),
        project: Yup.string()
            .matches(/[a-zA-Z0-9]{15}|[a-zA-Z0-9]{18}$/, 'Please select an option from the list')
            .required('Required'),
        description: Yup
            .string()
            .max(100, 'Must be 100 characters or less')
            .required('Required'),
        requestDetails: Yup
            .string()
            .min(9, 'Required')
            .max(2009, 'Must be 2000 characters or less')
            .required('Required'),
        neededBy: Yup.string().required('Required').nullable(),
        productQuantity: Yup.string().matches(/^-?\d{1,6}\.?\d{0,2}$/, 'Quantity must be a number').nullable(),
        productBrand: Yup.string().max(100, 'Must be 100 characters or less').nullable(),
        productModal: Yup.string().max(100, 'Must be 100 characters or less').nullable(),
        deliveryDetails: Yup.string().max(2009, 'Must be 2000 characters or less').nullable()
    })
}

export const validationSchemaQuote = () => {
    return Yup.object({
        project: Yup.string()
            .matches(/[a-zA-Z0-9]{15}|[a-zA-Z0-9]{18}$/, 'Please select an option from the list'),
        // onBehalfOf: Yup.string()
        //     .matches(/[a-zA-Z0-9]{15}|[a-zA-Z0-9]{18}$/, 'Please select an option from the list'),
        // vendor: Yup.string()
        //     .matches(/[a-zA-Z0-9]{15}|[a-zA-Z0-9]{18}$/, 'Please select an option from the list'),
        category: Yup.string().required('Required'),
        priority: Yup.string().required('Required'),
        description: Yup
            .string()
            .max(100, 'Must be 100 characters or less')
            .required('Required'),
        requestDetails: Yup
            .string()
            .min(9, 'Required')
            .max(2009, 'Must be 2000 characters or less')
            .required('Required'),
        neededBy: Yup.string().required('Required').nullable(),
        productQuantity: Yup.string().matches(/^-?\d{1,6}\.?\d{0,2}$/, 'Quantity must be a number').nullable(),
        productBrand: Yup.string().max(100, 'Must be 100 characters or less'),
        productModal: Yup.string().max(100, 'Must be 100 characters or less')
    })
}

export const validationSchemaProject = () => {
    return Yup.object({
        project: Yup.string()
            .matches(/[a-zA-Z0-9]{15}|[a-zA-Z0-9]{18}$/, 'Please select an option from the list'),
        // onBehalfOf: Yup.string()
        //     .matches(/[a-zA-Z0-9]{15}|[a-zA-Z0-9]{18}$/, 'Please select an option from the list'),
        category: Yup.string().required('Required'),
        priority: Yup.string().required('Required'),
        projectTitle: Yup
            .string()
            .max(100, 'Must be 100 characters or less')
            .required('Required'),
        projectType: Yup.string().required('Required'),
        projectImportance: Yup
            .string()
            .min(9, 'Required')
            .required('Required')
            .max(2009, 'Must be 2000 characters or less')
            .required('Required')
            .nullable(),
        projectProblem: Yup
            .string()
            .min(9, 'Required')
            .required('Required')
            .max(2009, 'Must be 2000 characters or less')
            .required('Required')
            .nullable(),
        projectGoal: Yup
            .string()
            .min(9, 'Required')
            .required('Required')
            .max(2009, 'Must be 2000 characters or less')
            .required('Required')
            .nullable(),
        projectSuccess: Yup
            .string()
            .min(9, 'Required')
            .required('Required')
            .max(2009, 'Must be 2000 characters or less')
            .required('Required')
            .nullable(),
        projectBudget: Yup
            .string()
            .max(255, 'Must be 255 characters or less')
            .required('Required')
            .nullable(),
        deliveredDate: Yup
            .string()
            .max(2000, 'Must be 2000 characters or less')
            .required('Required').nullable(),
        // client: Yup.string()
        //     .when('category', (val) => {
        //     if(val === 'aFa4u000000PCukCAG') {
        //         return Yup.string().required('Required');
        //     }

        //     return Yup.string().matches(/[a-zA-Z0-9]{15}|[a-zA-Z0-9]{18}$/, 'Please select an option from the list');
        // })
    })
}


export const purchaseApprovalValidationSchema = () => {
    return Yup.object({
        purchApprovalNotes: Yup.string().required('Required'),
        purchApprovalStatus: Yup.string().required('Required'),
    })
}

export const projectApprovalValidationSchema = () => {
    return Yup.object({
        projApprovalNotes: Yup.string().required('Required'),
        projApprovalStatus: Yup.string().required('Required'),
    })
}

export const projectEstApprovalValidationSchema = () => {
    return Yup.object({
        projEstApprovalNotes: Yup.string().required('Required'),
        projEstApprovalStatus: Yup.string().required('Required'),
    })
}