import * as Yup from 'yup';


export const validationSchema = () => {
    return Yup.object({
        Employee_Portal_About_Me__c: Yup
            .string()
            .max(250, 'Must be 250 characters or less')
            .required('Required'),

    })
}