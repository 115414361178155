import {ActionTypes} from '../actions/actionTypes';

const initialState = {
    appLoaded: null,
    appReloadTime: new Date()
};


export default (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.UPDATE_APP_LOADED:
            return {...state, appLoaded: action.payload}
        case ActionTypes.UPDATE_APP_RELOAD_TIME:
            return {...state, appReloadTime: action.time}
        default:
            return state;
    }
}

