import React, {useState} from 'react';
import Layout from '../layout/Layout';
import {connect} from 'react-redux';
import NotFound from '../NotFound';
import moment from 'moment';
import HelpdocsAttachments from './HelpDocsAttachments';

const HelpDocsItem = ({doc, ...props}) => {
    const [error, setError] = useState('')

    const breadcrumb = [
        { name:'Training Library', link: '/helpDocs' },
        { name: 'Training Details', link: '' }
    ]


    if(!doc.Id) {
        return (
            <NotFound />
        )
    }

    return (
        <Layout breadcrumb={breadcrumb}>
            <div className="card">
                <div className="card-body">
                    <div className="h5">{doc.Name}</div>
                    <div className="mb-4">
                        <span className="badge badge-secondary mr-2">{moment(doc.CreatedDate).format('LL')}</span>
                        <span className="badge badge-secondary mr-2">{doc.Type__c}</span>
                        <span className="badge badge-secondary mr-2">{doc.Training_Group__c}</span>
                        <span className="badge badge-secondary mr-2">{doc.Training_Region__c}</span>
                        <span className="badge badge-secondary mr-2">{doc.Training_Type__c}</span>
                    </div>

                    <div className="mb-3">{doc.Training_Description__c}</div>
                    {doc.Video_URL__c && <div>
                        <a className="btn btn-primary" href={doc.Video_URL__c} target="_blank" rel="noreferrer">
                            <i className="fa fa-video mr-2"></i>
                            <span>Watch Video</span>
                        </a>
                    </div>}
                </div>
                {(doc.Attachments && doc.Attachments.totalSize > 0) && 
                    <HelpdocsAttachments 
                        attachments={doc.Attachments.records} setError={setError} />}
            </div>
        </Layout>
    )
}


const mapStateToProps = (state, props) => {
    return {
        doc: state.helpDocs.data.find((item) => item.Id === props.match.params.id) || {},
    }
}
export default connect(mapStateToProps)(HelpDocsItem);