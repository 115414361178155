import React from 'react';
import { useField } from 'formik';
import TooltipIcon from './TooltipIcon';


const SwitchInput = ({ label, tooltip, tooltipText, isRequired, ...props }) => {
  // React treats radios and checkbox inputs differently other input types, select, and textarea.
  // Formik does this too! When you specify `type` to useField(), it will
  // return the correct bag of props for you -- a `checked` prop will be included
  // in `field` alongside `name`, `value`, `onChange`, and `onBlur`
  const [field, meta] = useField({ ...props, type: 'checkbox' });
  return (
    <div className={`form-group ${isRequired && 'required'}`}>
        <label className="control-label font-weight-bold mb-1">{label}{` `}{tooltip && <TooltipIcon text={tooltipText} id={`${props.id}-tooltip`} />}</label><br />
        <label className="c-switch c-switch-label c-switch-pill c-switch-primary mb-0">
            <input type="checkbox" className="c-switch-input" {...field} {...props} />
            <span className="c-switch-slider" data-checked="Yes" data-unchecked="No"></span>
        </label> 
        
      
      {meta.touched && meta.error ? (
        <div className="invalid-feedback">{meta.error}</div>
      ) : null}
    </div>
  );
};


export default SwitchInput;