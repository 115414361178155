import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import EmployeeTablePagination from '../dashboard/EmployeeTablePagination';
import {quickLinksSearch} from '../../redux/selectors/quickLinksSelectors';
import {setQuickLinksTextFilter} from '../../redux/actions/filters/quickLinksFilters';
import EditQuickLink from './EditQuickLink';




const QuickLinksItem = ({link}) => {
    

    return (
        <tr data-tour="dash-9">
            <td className="text-center">
                <i className={`c-icon c-icon-xl ${link.Icon__c}`} style={{color: `${link.Icon_Color__c}`}}></i>
            </td>
            <td>
                <a href={`${link.URL__c}`} className="text-dark font-weight-bold" target="__blank">{link.Label__c}</a>
            </td>
            <td className="text-cener" data-tour="dash-10">
                <EditQuickLink link={link} />
            </td>
        </tr>
    )
}

const QuickLinks = ({links, dispatch, filters}) => {
    const [records, setRecords] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(12);

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = records.slice(indexOfFirstRecord, indexOfLastRecord);
    const setPage = (pageNumber) => setCurrentPage(pageNumber);

    useEffect(() => {
        setRecords(links);
    }, [links])

    return (
        <>
        <input 
            data-tour="dash-8"
            type="text"
            className="form-control mb-2" 
            placeholder="Search..."
            value={filters.text}
            onChange={(e) => {
                setCurrentPage(1);
                dispatch(setQuickLinksTextFilter(e.target.value));
            }} />
        <table className="table table-sm table-hover table-outline mb-2">
            <tbody>
                {currentRecords && currentRecords.map((link) => <QuickLinksItem link={link} key={link.Id} />)}
            </tbody>
        </table>
        <EmployeeTablePagination currentPage={currentPage} recordsPerPage={recordsPerPage} totalRecords={records.length} setPage={setPage} />
        </>
    )
}



const mapStateToProps = (state) => {
    return {
        filters: state.quickLinksFilters,
        links: quickLinksSearch(state.quickLinks.data, state.quickLinksFilters),
    }
}

export default connect(mapStateToProps)(QuickLinks);