import {ActionTypes} from '../actions/actionTypes';

const initialState = {
    dataLoaded: null,
    status: null,
    error: null,
    data: []
}

export default (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.FETCH_HELP_DOCS_START:
            return {...state, status: 'loading', dataLoaded: false}
        case ActionTypes.FETCH_HELP_DOCS_SUCCESS:
            return {...state, data: [...action.payload], status: 'loaded', dataLoaded: true}
        case ActionTypes.FETCH_HELP_DOCS_ERROR:
            return {...state, error: action.error, status: 'error', dataLoaded: false}
        default:
            return state;
    }
}