import * as Yup from 'yup';


export const validationSchema = () => {
    return Yup.object({
        Label__c: Yup
            .string()
            .max(100, 'Must be 100 characters or less')
            .required('Required'),
        URL__c: Yup
            .string()
            .matches(
                /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                'Enter correct url!'
            )
            .required('Required'),
        Icon__c:  Yup.string().required('Required'),
    })
}