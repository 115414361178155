import { useMsal } from "@azure/msal-react";

const SignInButton = () => {
    const { accounts, instance, inProgress } = useMsal();

    const handleLogin = () => {
        instance.loginRedirect();
    }


    return (
    <button className="btn btn-pill btn-block btn-dark" onClick={handleLogin} disabled={inProgress === 'login'}>
      <i className="fab fa-microsoft mr-3"></i>
        Sign in
    </button>
    // <>
    // <button onClick={handleLogin} disabled={inProgress === 'login'}> Login </button>
    // </>
    
    )
}


export default SignInButton;