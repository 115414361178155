import { Component, useEffect, useState } from "react";
import {connect} from "react-redux";
import { MsalAuthenticationTemplate, withMsal, useMsal } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser";
import { loginRequest } from "./authConfig";
import {fetchUser} from '../../redux/actions/users/fetchUser';
import {fetchUserPhoto} from '../../redux/actions/users/fetchUserPhoto';
import {Redirect, Link} from 'react-router-dom';
import { browserHistory } from 'react-router';
import DataLoader from "../dataloader/DataLoader3";


function UserContentTwo(props) {
    const { instance, inProgress, accounts } = useMsal();
    const [apiData, setApiData] = useState(null);
    const [err, setErr] = useState(null);


    useEffect(() => {
        if (props.auth.userState !== "user-loaded" && props.auth.photoState !== 'photo-loaded' && inProgress === InteractionStatus.None) {
            const accessTokenRequest = {
                scopes: ["user.read"],
                account: accounts[0]
            }
            instance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
                // Acquire token silent success
                let accessToken = accessTokenResponse.accessToken;
                // Call your API with token
                props.dispatch(fetchUser(accessToken));
                props.dispatch(fetchUserPhoto(accessToken));
            }).catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect(accessTokenRequest).then(function(accessTokenResponse) {
                        // Acquire token interactive success
                        let accessToken = accessTokenResponse.accessToken;
                        // Call your API with token
                        props.dispatch(fetchUser(accessToken));
                        props.dispatch(fetchUserPhoto(accessToken));
                    }).catch(function(error) {
                        // Acquire token interactive failure
                        setErr(error);
                        console.log(error);
                    });
                }
                setErr(error);
                console.log(error);
            })
        }
    }, [instance, accounts, inProgress, apiData]);

    if(err) {
        return (
            <div className="align-items-center vertical-center"  style={{width: '100%'}}>
                <div className="container text-center" >{err}</div>
            </div> 
        )
    }

    if(
        props.auth 
        && props.auth.userState === "user-loaded" 
        && props.auth.photoState === 'photo-loaded'
    ) {
        return <Redirect to='/dataloader' />
        // return <DataLoader />
    }

    return (
        <div className="align-items-center vertical-center"  style={{width: '100%'}}>
            <div className="container text-center" >Redirecting...</div>
        </div>
    );
}

class UserContent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            graphData: null,
        }
    }


    componentDidMount() {
        if (this.props.msalContext.accounts[0] && this.props.msalContext.inProgress === "none") {
            this.props.msalContext.instance.acquireTokenSilent({
                ...loginRequest,
                account: this.props.msalContext.accounts[0]
            }).then((response) => {
                this.props.dispatch(fetchUser(response.accessToken));
                this.props.dispatch(fetchUserPhoto(response.accessToken));
            });
        }
    }
    

    render() {
        if(
            this.props.auth 
            && this.props.auth.userState === "user-loaded" 
            && this.props.auth.photoState === 'photo-loaded'
        ) {
            return <Redirect to='/dataloader' />
        }

        return (
            <div className="align-items-center vertical-center"  style={{width: '100%'}}>
                <div className="container text-center" >Redirecting...</div>
            </div>
        );
    }

}


class GetUserInformation extends Component {
    render() {
        
        const authRequest = {
            ...loginRequest
        };

        return (
            <MsalAuthenticationTemplate 
                interactionType={InteractionType.Redirect} 
                authenticationRequest={authRequest} 
                errorComponent={ErrorComponent} 
                loadingComponent={Loading}
            >
                <UserContentTwo msalContext={this.props.msalContext} dispatch={this.props.dispatch} auth={this.props.auth} />
            </MsalAuthenticationTemplate>
        );
    }
}



const ErrorComponent = ({error}) => {
    // if(error.errorCode === "popup_window_error") {
    //     return <Redirect to="/login" />
    // }
    return <div>An Error Occurred: {error.errorCode}</div>;
}

const Loading = () => {
    return (
        <div className="align-items-center vertical-center"  style={{width: '100%'}}>
            <div className="container text-center" ><Link to="dataloader">Authentication in progress...</Link></div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}


export default connect(mapStateToProps)(withMsal(GetUserInformation));
