import {ActionTypes} from '../actionTypes';
import axios from 'axios';

export const updateQuickLinkStart = () => ({type: ActionTypes.UPDATE_QUICK_START_START});
export const updateQuickLinkSuccess = (id, updates) => ({type: ActionTypes.UPDATE_QUICK_START_SUCCESS, id, updates});
export const updateQuickLinkError = (error) => ({type: ActionTypes.UPDATE_QUICK_START_ERROR, error});



export const updateQuickLink = (id, linkdata = {}) => {
    return async (dispatch) => {

        try {
            dispatch(updateQuickLinkStart());

            const data = {
                ...linkdata,
                Id: id
            }

            const response = await axios.patch('/quicklinks/update', data)
            
            if (response.data) {
                dispatch(updateQuickLinkSuccess(response.data.link.Id, response.data.link))
            }
        } catch (err) {
            dispatch(updateQuickLinkError(err.message));
        }
    }
}