import React from 'react'
import {Link} from 'react-router-dom';
import moment from 'moment'
import DeleteForecastLink from './DeleteForecastLink'

export const columns = [
    {
        dataField: 'Id',
        text: 'Record ID',
        hidden: true,
        searchable: false,
        csvExport: false,
        editable: false
    }, {
        dataField: 'Name',
        text: 'ID',
        sort: true,
        formatter: (cellContent, row) => {
            return (
                <Link data-tour="it-req-6" to={`/employeeForecasts/${row.Id}`}>{row.Name.split("-").pop()}</Link>
            )
        },
        editable: false,
        headerStyle: (colum, colIndex) => {
            return { width: '10%', textAlign: 'center'};
        },
        style: (colum, colIndex) => {
            return { verticalAlign: 'middle', textAlign: 'center'};
        }
    }, {
        dataField: 'CreatedDate',
        text: 'Date',
        sort: true,
        editable: false,
        formatter: (cellContent, row) => {
            return (row.CreatedDate && moment(row.CreatedDate).format('ll'))
        },
        headerStyle: (colum, colIndex) => {
            return { width: '15%', textAlign: 'center'}
        },
        style: (colum, colIndex) => {
            return { verticalAlign: 'middle', textAlign: 'center'};
        }
    }, {
        dataField: 'Division__c',
        text: 'Division',
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => {
            return { width: '15%', textAlign: 'center'};
        },
        style: (colum, colIndex) => {
            return { verticalAlign: 'middle', textAlign: 'center'};
        }
    }, {
        dataField: 'Job_Title__c',
        text: 'Job Title',
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => {
            return { width: '15%', textAlign: 'center'}
        },
        style: (colum, colIndex) => {
            return { verticalAlign: 'middle', textAlign: 'center'};
        }
    }, {
        dataField: 'Planned_Start_Date__c',
        text: 'Planned Start Date',
        sort: true,
        editable: false,
        formatter: (cellContent, row) => {
            return (row.Planned_Start_Date__c && moment(row.Planned_Start_Date__c).format('ll'))
        },
        headerStyle: (colum, colIndex) => {
            return { width: '15%', textAlign: 'center'};
        },
        style: (colum, colIndex) => {
            return { verticalAlign: 'middle', textAlign: 'center', fontWeight: 'bold'};
        }
    }, {
        dataField: 'Type__c',
        text: 'Type',
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => {
            return { width: '15%', textAlign: 'center'};
        },
        style: (colum, colIndex) => {
            return { verticalAlign: 'middle', textAlign: 'center'};
        }
    }, 
    
    {
        dataField: 'Edit',
        text: 'Actions',
        sort: true,
        csvExport: false,
        formatter: (cellContent, row) => {
            return (
            <div className="text-center" data-tour="it-req-7">
                <Link className={`btn btn-link m-0 p-0 ${row.Locked && 'disabled'}`} 
                to={`/employeeForecasts/edit/${row.Id}`}>
                    <i className="fas fa-edit"></i></Link>
                {` / `}
                <DeleteForecastLink request={row} />
            </div>)
        },
        editable: false,
        headerStyle: (colum, colIndex) => {
            return { width: '12%', textAlign: 'center'};
        },
        style: (colum, colIndex) => {
            return { verticalAlign: 'middle', textAlign: 'center'};
        }             
    },
]

export const defaultSorted = [
    {
        dataField: "Name",
        order: "desc"
    }
];

