import React,{useState, useEffect} from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useField } from 'formik';
import TooltipIcon from './TooltipIcon';


const TypeaheadInput = ({setExtraFields, isRequired, name, selected, touched, label, setFieldTouched, setFieldValue, errors, choices, tooltip, tooltipText,  ...props}) => {
    const [selectedOption, setSelectedOption] = useState(selected ? selected : [])

    const onOptionChange = (selectedOption) => {
        
        const value = (selectedOption.length > 0) ? selectedOption[0].id : '';
        setFieldValue(name, value);
        setSelectedOption(selectedOption);
    }


    useEffect(() => {
        if(selected) {
            let value = ''
            if(props.multiple) {
                const rawVal = selectedOption.length > 0 ? selectedOption.map((s) => s.label) : []
                value = rawVal.join(';')
            } else {
                value = (selectedOption.length > 0) ? selectedOption[0].id : '';
            }
            setFieldValue(name, value);
        } 

        // setFieldValue(name, '');
        // setSelectedOption([]);
    }, [selected, name, setFieldValue, selectedOption])


    return (
        <div className={`form-group ${isRequired && 'required'}`}>
            <label className="control-label font-weight-bold mb-1">{label}{` `}{tooltip && <TooltipIcon text={tooltipText} id={`${props.id}-tooltip`} />}</label>
            <Typeahead
                clearButton
                id={name}
                multiple={false}
                onChange={onOptionChange}
                onInputChange={(text, event) => {
                    console.log(text);
                    setFieldValue(name, text);
                } }
                onBlur={(e) => setFieldTouched(name, true)}
                isInvalid={touched && touched[name] && errors[name]}
                options={choices}
                selected={selectedOption ? selectedOption : []}
                {...props}
            />
            {touched && touched[name] && errors[name] ? (
                <div className="invalid-feedback" style={{display: 'block'}}>{errors[name]}</div>
            ) : null}
        </div>
        
    )
}



// const TypeaheadInput = ({name, touched, value, label, setFieldValue, errors, choices, id, ...props}) => {
//     const [singleSelections, setSingleSelections] = useState([]);

//     useEffect(() => {
//         if (singleSelections.length > 0) {
//             setFieldValue(name, singleSelections[0].id)
//         }
//     }, [singleSelections])

//     const [field, meta] = useField(props);

//     return (
//         <div className="form-group">
//             <label>{label}</label>
//             <Typeahead
//                 id={id || name}
//                 multiple={false}
//                 labelKey="label"
//                 options={choices}
//                 onChange={(selection) => setSingleSelections(selection)}
//                 isInvalid={!!errors[name]}
//                 selected={singleSelections}
//                 {...props}
//                 className={`${meta.touched && meta.error && 'is-invalid'}`}
//             />
//             {meta.touched && meta.error ? (
//                 <div className="invalid-feedback">{errors[name]}</div>
//             ) : null}
//         </div>
//     )
// }

export default TypeaheadInput