import React, { useState, useRef, createRef } from "react";
import { Tree, TreeNode } from "react-organizational-chart";
import { listToTree } from "../../redux/selectors/orgChartsSelectors";
import { displayName, backgroundColor } from "../employees/functions";
import Draggable from "react-draggable";

const OrgChartPeople = ({ masterList, people, businessUnits, department }) => {
  //Zoom
  const [zoom, setZoom] = useState(0.6);

  //Dragables
  const [activeDrags, setActiveDrags] = useState(0);
  const draggableEntity = useRef();

  const onStart = () => {
    setActiveDrags(activeDrags + 1);
  };

  const onStop = () => {
    setActiveDrags(activeDrags - 1);
  };

  const resetDraggable = () => {
    draggableEntity.current.state.x = 0;
    draggableEntity.current.state.y = 0;
    onStart();
  };

  //Data
  const dragHandlers = { onStart: onStart, onStop: onStop };

  const businessUnit = businessUnits.filter(
    (i) => i.Business_Unit__c === department
  );
  // console.log(businessUnit);

  const businessUnitLeader =
    businessUnit &&
    masterList.find(
      (i) => i.Employee_Number__c === businessUnit[0].Leader_Employee_Number__c
    );
  const subs = listToTree(people, businessUnitLeader);

  // const businessUnitLeaders =
  //   businessUnit &&
  //   masterList.filter((i) =>
  //     businessUnit.some(
  //       (item) => i.Employee_Number__c === item.Leader_Employee_Number__c
  //     )
  //   );

  // const subbs = businessUnitLeaders?.map((leader) => {
  //   return listToTree(people, leader);
  // });

  // const test = masterList.filter((i) => i.Department_Name__c === 'PM')

  let nodeId = 0;

  const hideChildren = (e) => {
    const parent = e.target.parentElement;
    const nextSibling = parent.nextElementSibling;

    const iconElementArray = [...parent.children].find(
      (element) => element.nodeName === "A"
    ).children;
    const iconElement = [...iconElementArray].find(
      (element) => element.nodeName === "I"
    );

    if (iconElement.classList.contains("fa-chevron-down")) {
      iconElement.classList.remove("fa-chevron-down");
      iconElement.classList.add("fa-chevron-up");
    } else {
      iconElement.classList.remove("fa-chevron-up");
      iconElement.classList.add("fa-chevron-down");
    }

    if (nextSibling) {
      nextSibling.classList.toggle("org-chart-hide");
    }
  };

  const createTree = (data) => {
    nodeId++;
    return (
      data?.children &&
      data?.children?.map((item) => (
        <TreeNode
          label={
            <div
              id={nodeId}
              className="shadow d-inline-block pb-2 "
              style={{ width: "180px" }}
            >
              <div className="small bg-primary text-white p-2 mb-2">
                {item.Job_Title__c}
              </div>
              <div
                className="rounded-circle org-chart-image border mb-1"
                style={{
                  backgroundColor: backgroundColor(
                    `${item.First_Name__c} ${item.Last_Name__c}`
                  ),
                }}
              >
                {displayName(`${item.First_Name__c} ${item.Last_Name__c}`)}
              </div>
              <div className="mb-2">
                {item.First_Name__c} {item.Last_Name__c}
              </div>
              {item.children.length > 0 && (
                <a
                  className="p-2 text-decoration-none text-dark no-cursor no-drag"
                  style={{ cursor: "pointer" }}
                  onClick={hideChildren}
                  href="#0"
                  role="button"
                >
                  <i
                    className={`fas fa-chevron-down`}
                    style={{ pointerEvents: "none" }}
                  ></i>
                </a>
              )}
            </div>
          }
        >
          {item.children && createTree(item)}
        </TreeNode>
      ))
    );
  };

  return (
    <div className="box">
      {/* <div className='form-group w-25'>
                <span>Zoom:</span>
                <span>
                    <input type="range" className="form-control-range" />
                </span>
            </div> */}
      {/* <div className='btn-group'> */}
      <button
        disabled={zoom <= 0.2}
        className="btn btn-secondary pt-2 mr-2"
        onClick={() => setZoom(zoom - 0.1)}
      >
        <i className="cil-zoom-out h4"></i>
      </button>
      <button
        className="btn btn-secondary pt-2 mr-2"
        onClick={() => {
          setZoom(0.6);
          resetDraggable();
        }}
      >
        <i className="cil-action-undo h4"></i>
      </button>
      <button
        disabled={zoom >= 2}
        className="btn btn-secondary pt-2"
        onClick={() => setZoom(zoom + 0.1)}
      >
        <i className="cil-zoom-in h4"></i>
      </button>
      {/* </div> */}
      <div className="vh-100 overflow-auto position-relative">
        <Draggable cancel=".no-drag" {...dragHandlers} ref={draggableEntity}>
          <div className="cursor" style={{ cursor: "all-scroll" }}>
            <div style={{ transform: `scale(${zoom})` }}>
              {/* {subbs?.map((sub) => ( */}
              <div className="mb-5">
                <Tree
                  label={
                    <div
                      className="shadow d-inline-block pb-2"
                      style={{ width: "180px" }}
                    >
                      <div className="small bg-primary text-white p-2 mb-2">
                        {subs?.Job_Title__c}
                      </div>
                      <div
                        className="rounded-circle org-chart-image border mb-1"
                        style={{
                          backgroundColor: backgroundColor(
                            `${subs?.First_Name__c} ${subs?.Last_Name__c}`
                          ),
                        }}
                      >
                        {displayName(
                          `${subs?.First_Name__c} ${subs?.Last_Name__c}`
                        )}
                      </div>
                      <div className="mb-2">
                        {subs?.First_Name__c} {subs?.Last_Name__c}
                      </div>
                      <a
                        className="p-2 text-decoration-none text-dark no-cursor no-drag"
                        style={{ cursor: "pointer" }}
                        onClick={hideChildren}
                        href="#0"
                        role="button"
                      >
                        <i
                          className={`fas fa-chevron-down`}
                          style={{ pointerEvents: "none" }}
                        ></i>
                      </a>
                    </div>
                  }
                >
                  {createTree(subs)}
                </Tree>
              </div>
              {/* ))} */}
            </div>
          </div>
        </Draggable>
      </div>
    </div>
  );
};

export default OrgChartPeople;
