import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);
  
const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        datalabels: {
            display: false
        },
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'People Metrics',
        },
    },
};






const PeopleChart = ({data}) => {
    const labels = data && data.map((d) => d.month);

    const chartData = {
        labels,
        datasets: [
            {
                label: 'Active as of 1st',
                data: data && data.map((d) => d.active_as_of_1st.length),
                borderColor: 'rgb(0, 128, 255)',
                backgroundColor: 'rgba(0, 128, 255, 0.5)',
            }, {
                label: 'Net New Hire',
                data: data && data.map((d) => d.new_hire_nn.length),
                borderColor: 'rgb(128, 0, 255)',
                backgroundColor: 'rgba(128, 0, 255, 0.5)',
            }, {
                label: 'New Hire',
                data: data && data.map((d) => d.new_hire_r.length),
                borderColor: 'rgb(255, 191, 0)',
                backgroundColor: 'rgba(255, 191, 0, 0.5)',
            }, {
                label: 'Changes',
                data: data && data.map((d) => d.changes.length),
                borderColor: 'rgb(255, 64, 0)',
                backgroundColor: 'rgba(255, 64, 0, 0.5)',
            }, {
                label: 'Voluntary',
                data: data && data.map((d) => d.voluntary.length),
                borderColor: 'rgb(64, 255, 0)',
                backgroundColor: 'rgba(64, 255, 0, 0.5)',
            }, {
                label: 'Involuntary',
                data: data && data.map((d) => d.involuntary.length),
                borderColor: 'rgb(255, 0, 0)',
                backgroundColor: 'rgba(255, 0, 0, 0.5)',
            }, {
                label: 'Active as of EOM',
                data: data && data.map((d) => d.active_as_of_eom.length),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            }
        ]
    }

    return (
        <Line options={options} data={chartData} />
    )
}


export default PeopleChart;