import React from 'react';
import Layout from '../layout/Layout';
import ServiceRequestForm from './ServiceRequestForm';
import {connect} from 'react-redux';
import {saveServiceRequest} from '../../redux/actions/serviceRequests/saveServiceRequest';
import {serviceRequestResetStatus} from '../../redux/actions/serviceRequests/resetStatus';

const NewServiceRequest = ({businessUnit, clients, vendors, projects, users, user, categories, status, error, savedRecordId, dispatch}) => {

    const breadcrumb = [
        { name:'IT Requests', link: '/serviceRequests' },
        { name:'New', link: '' }
    ]


    return (
        <Layout breadcrumb={breadcrumb}>
            <div className="card">
                <div className="card-header bg-sdi-black text-sdi-yellow">
                    New IT Request
                </div>
                <div className="card-body">
                    <ServiceRequestForm 
                        savedRecordId={savedRecordId}
                        // clients={clients} 
                        // vendors={vendors}
                        projects={projects} 
                        users={users} 
                        user={user}
                        businessUnit={businessUnit}
                        categories={categories}
                        onSubmit={(serviceRequest) => {
                            dispatch(saveServiceRequest(serviceRequest))
                        }}
                        resetStatus={() => {
                            dispatch(serviceRequestResetStatus())
                        }}
                        status={status}
                        error={error} />
                </div>
            </div>
        </Layout>
    )
}



const mapStateToProps = (state) => {
    return {
        // clients: state.accounts.data.filter((account) => account.RecordType.Name === 'Client'),
        // vendors: state.accounts.data.filter((account) => account.RecordType.Name === 'Contractor/Vendor' || account.RecordType.Name === 'Contractor/Vendor US'),
        projects: state.projects.data,
        users: state.users.data,
        categories: state.requestCategories.data,
        status: state.serviceRequests.status,
        error: state.serviceRequests.error,
        user: state.sfAuth.user,
        savedRecordId: state.serviceRequests.recordId,
        businessUnit: state.businessUnits.data.find((unit) => state.sfAuth.user.BusinessUnit === unit.Name) || {}
    }
}
export default connect(mapStateToProps)(NewServiceRequest);