import React, {useState} from 'react'
import {Modal, Button} from 'react-bootstrap'
import {connect} from 'react-redux';
import {saveQuickLink} from '../../redux/actions/quickLinks/saveQuickLink';
import {quickLinksResetStatus} from '../../redux/actions/quickLinks/resetStatus';
import QuickLinksForm from './QuickLinksForm';


const AddQuickLink = ({dispatch, status, error, user}) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const renderModal = () => {
        return (
            <Modal show={show} onHide={handleClose} size="lg" backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title style={{margin: '0 auto'}}>New Link</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <QuickLinksForm 
                        user={user}
                        onSubmit={(data) => {
                            dispatch(saveQuickLink(data))
                        }}
                        resetStatus={() => {
                            dispatch(quickLinksResetStatus())
                        }}
                        error={error}
                        status={status}
                        closeModal={handleClose}
                         />
                    
                </Modal.Body>
            </Modal>
        )
    }

    return (
        <>
            <button className="btn btn-sm btn-primary" onClick={handleShow}>
                <i className="fas fa-plus"></i> 
            </button>
            {show && renderModal()}
        </>
    )
}



const mapStateToProps = (state, props) => {
    return {
        user: state.sfAuth.user,
        status: state.quickLinks.status,
        error: state.quickLinks.error,
        
    }
}

export default connect(mapStateToProps)(AddQuickLink);