import {ActionTypes} from '../actionTypes';
import axios from 'axios';

export const fetchMasterEmployeeListStart = () => ({type: ActionTypes.FETCH_MASTER_EMPLOYEE_LIST_START});
export const fetchMasterEmployeeListSuccess = (list) => ({type: ActionTypes.FETCH_MASTER_EMPLOYEE_LIST_SUCCESS, list});
export const fetchMasterEmployeeListError = (error) => ({type: ActionTypes.FETCH_MASTER_EMPLOYEE_LIST_ERROR, error});

export const fetchMasterEmployeeList = () => {
    return async (dispatch) => {
        try {
            dispatch(fetchMasterEmployeeListStart());
            const response = await axios.get('/masterEmployeeList/all')


            if (response.data) {
                const data = response.data.map((i) => {
                    let temp = Object.assign({}, i);

                    if(i.Employee_Time_Away__r) {
                        const timeAway = i.Employee_Time_Away__r.records
                        temp.Employee_Time_Away__r = timeAway;
                    }

                    return temp
                })
                dispatch(fetchMasterEmployeeListSuccess(data))
            }
        } catch (err) {
            dispatch(fetchMasterEmployeeListError(err));
        }
    }
}