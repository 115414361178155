import React, {useState, useEffect} from 'react'
import { fileSizeString, handleIcons, handleViewDisabled } from './functions'
import moment from 'moment'


const ServiceRequestAttachmentItem = (props) => {
    const {attachment, downloadAttachment, viewAttachment, deleteAttachment} = props
    const [downloading, setDownloading] = useState(false);
    const [viewing, setViewing] = useState(false);
    const [deleting, setDeleting] = useState(false);

    // useEffect(() => {
    //     if(!downloadStatus) setDownloading(false)
    // }, [status])

    const handleDownload = async () => {
        await setDownloading(true)
        await downloadAttachment(attachment);
        setDownloading(false)
    }

    const handleView = async () => {
        await setViewing(true);
        await viewAttachment(attachment);
        setViewing(false);
    }

    const handleDelete = async () => {
        // await setDeleting(true);
        await deleteAttachment(attachment);
        // setDeleting(false);
    }
    
    return (
        <tr>
            <td>
                <i className={`fas ${handleIcons(attachment.ContentType)} fa-lg`}></i>
            </td>
            <td>
                <button
                    className={`btn btn-link zero-padding`}
                    onClick={() => handleViewDisabled(attachment.ContentType)
                    ? downloadAttachment(attachment)
                    : viewAttachment(attachment)}>
                    {attachment.Name}
                </button>
            </td>
            <td>{fileSizeString(attachment.BodyLength)}</td>
            <td>{moment(attachment.CreatedDate).format('ll')}</td>
            <td >
                <div className="btn-group btn-group-sm float-right" role="group">
                    <button
                        onClick={handleDelete}
                        type="button"
                        className="btn btn-primary zero-padding"
                        disabled={deleting}>
                            {deleting ? <><span className="spinner-border spinner-border-sm"></span></> : 'Delete'}
                        </button>
                    <button
                        onClick={handleDownload}
                        type="button"
                        className="btn btn-primary zero-padding"
                        disabled={downloading}>
                            {downloading ? <><span className="spinner-border spinner-border-sm"></span></> : 'Download'}
                        </button>
                    <button
                        onClick={handleView}
                        type="button"
                        className="btn btn-primary zero-padding"
                        disabled={handleViewDisabled(attachment.ContentType) || viewing}>
                            {viewing ? <><span className="spinner-border spinner-border-sm"></span></> : 'View'}
                        </button>
                </div>
            </td>
        </tr>
    )
}

export default ServiceRequestAttachmentItem