import React from 'react'
import {Link} from 'react-router-dom';
import moment from 'moment'
import DeleteServiceRequestLink from './DeleteServiceRequestLink';

export const columns = [
    {
        dataField: 'Id',
        text: 'Record ID',
        hidden: true,
        searchable: false,
        csvExport: false,
        editable: false
    }, {
        dataField: 'Name',
        text: 'ID',
        sort: true,
        formatter: (cellContent, row) => {
            return (
                <Link data-tour="it-req-6" to={`/serviceRequests/${row.Id}`}>{row.Name.split("-").pop()}</Link>
            )
        },
        editable: false,
        headerStyle: (colum, colIndex) => {
            return { width: '7%', textAlign: 'center'};
        },
        style: (colum, colIndex) => {
            return { verticalAlign: 'middle', textAlign: 'center'};
        }
    }, {
        dataField: 'CreatedDate',
        text: 'Date',
        sort: true,
        editable: false,
        formatter: (cellContent, row) => {
            return (row.CreatedDate && moment(row.CreatedDate).format('ll'))
        },
        headerStyle: (colum, colIndex) => {
            return { width: '12%', textAlign: 'center'}
        },
        style: (colum, colIndex) => {
            return { verticalAlign: 'middle', textAlign: 'center'};
        }
    }, {
        dataField: 'Parent_Type__c',
        text: 'Type',
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => {
            return { width: '13%', textAlign: 'center'}
        },
        style: (colum, colIndex) => {
            return { verticalAlign: 'middle', textAlign: 'center'};
        }
    }, {
        dataField: 'Brief_Desc__c',
        text: 'Brief Description',
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => {
            return { width: '20%', textAlign: 'center'}
        },
        style: (colum, colIndex) => {
            return { verticalAlign: 'middle', textAlign: 'center'};
        }
    }, {
        dataField: 'Priority__c',
        text: 'Priority',
        sort: true,
        editable: false,
        formatter: (cellContent, row) => {
            return priorityFormatter(cellContent)
        },
        headerStyle: (colum, colIndex) => {
            return { width: '10%', textAlign: 'center'};
        },
        style: (colum, colIndex) => {
            return { verticalAlign: 'middle', textAlign: 'center', fontWeight: 'bold'};
        }
    }, {
        dataField: 'Assigned_User__r.Name',
        text: 'Assigned To',
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => {
            return { width: '13%', textAlign: 'center'};
        },
        style: (colum, colIndex) => {
            return { verticalAlign: 'middle', textAlign: 'center'};
        }
    }, {
        dataField: 'Status__c',
        text: 'Status',
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => {
            return { width: '18%', textAlign: 'center'};
        },
        style: (colum, colIndex) => {
            return { verticalAlign: 'middle', textAlign: 'center', fontWeight: 'bold'};
        }
    }, {
        dataField: 'Edit',
        text: 'Actions',
        sort: true,
        csvExport: false,
        formatter: (cellContent, row) => {
            return (
            <div className="text-center" data-tour="it-req-7">
                <Link className={`btn btn-link m-0 p-0 ${row.Locked && 'disabled'}`} 
                to={`/serviceRequests/edit/${row.Id}`}>
                    <i className="fas fa-edit"></i></Link>
                {` / `}
                <DeleteServiceRequestLink request={row} />
            </div>)
        },
        editable: false,
        headerStyle: (colum, colIndex) => {
            return { width: '8%', textAlign: 'center'};
        },
        style: (colum, colIndex) => {
            return { verticalAlign: 'middle', textAlign: 'center'};
        }             
    },
]

export const defaultSorted = [
    {
        dataField: "Name",
        order: "desc"
    }
];

export const currencyFormat = (number, currency) => {
    return new Intl.NumberFormat(`en-US`, 
        { 
            style: 'currency', 
            currency: currency || 'CAD', 
            currencyDisplay: 'code' 
        }
    ).format(number);
}

const priorityFormatter = (cellContent) => {
    if(cellContent === '3 - Low') {
        return <span className="badge badge-success" style={{width: '50px'}}>Low</span>
    } else if (cellContent === '2 - Medium') {
        return <span className="badge badge-warning" style={{width: '50px'}}>Medium</span>
    } else if (cellContent === '1 - HIGH') {
        return <span className="badge badge-danger" style={{width: '50px'}}>High</span>
    }

    return <span className="badge badge-secondary" style={{width: '50px'}}>{cellContent}</span>
}