import Layout from '../layout/Layout';
import {connect} from "react-redux";
import moment from 'moment';
import EditProfile from './EditProfile';


const Profile = (props) => {
    const {user, userPhoto} = props.auth;
    const {user: sfUser} = props.sfAuth;
    const {contact} = props;

    const displayName = (givenName, surname) => {
        return givenName.charAt(0)+surname.charAt(0);
    }

    const formatBirthDate = (value) => {
        return moment(value).calendar({
            sameDay: '[Today]',
            nextDay: '[Tomorrow]',
            nextWeek: '[Next] dddd',
            lastDay: '[Yesterday]',
            lastWeek: 'dddd',
            sameElse: 'MMM DD'
        });
    }



    const formatStartDate = (value) => {
        const date = moment(value);
        const month = moment(value).month();
        const day = moment(value).date();
        let thisDate = moment().set("month", month);
        thisDate.set('date', day);


        const years = moment(thisDate).diff(date, 'years', false);
        const months = moment(thisDate).diff(date.add(years, 'years'), 'months', false);

        const yearString = years === 1 ? years+' Year' : years+' Years';
        const monthsString = months === 1 ? months+' Month' : months+' Months';

        if(years === 0) {
            return ''+date.format("MMM DD")+' - Anniversary';
        }

        return date.format("MMM DD")+' - '+yearString;
    }

    const breadcrumb = [
        { name:'Profile', link: '' }
    ]

    return (
        <Layout breadcrumb={breadcrumb}>
            <div className="card">
                {/* <div className="card-header">
                    <div className="float-right">
                        <EditProfile contact={contact} />
                    </div>
                </div> */}
                <div className="card-body">
                    <div className="card-title float-right mt-3">
                        {contact && Object.keys(contact).length === 0 && contact.constructor === Object ? '' :
                            <EditProfile 
                                user={props.user}
                                status={props.status}
                                error={props.error}
                                dispatch={props.dispatch}
                                contact={contact} />}
                    </div>
                    <div className="pt-2 mb-3 text-muted">
                        <div className="row">
                            <div className="col-md-3 text-center">
                            {userPhoto ? 
                                (<div>
                                    <img className="rounded-circle border border-3" src={userPhoto} style={{width: '12em'}} alt="" />
                                </div>) :
                                (<div>
                                    <div className="rounded-circle dashboard-profile-image">
                                        {user && displayName(user.givenName, user.surname)}
                                    </div>
                                </div>)
                                }
                            </div>

                            <div className="col-md-9">
                                <div className="ml-3">
                                    <h1 className="display-4 text-sdi-black text-account-name mb-3">{user && user.displayName}</h1>
                                    
                                    <div className="row mb-1">
                                        <div className="col-md-3">
                                            <div className="font-weight-bold">Title</div>
                                        </div>
                                        <div className="col-md-9">
                                            <div className="font-weight-light">{contact && contact.Title}</div>
                                        </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-md-3">
                                            <div className="font-weight-bold">Email</div>
                                        </div>
                                        <div className="col-md-9">
                                            <div className="font-weight-light">{user && user.mail}</div>
                                        </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-md-3">
                                            <div className="font-weight-bold">Department</div>
                                        </div>
                                        <div className="col-md-9">
                                            <div className="font-weight-light">{contact && contact.Department}</div>
                                        </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-md-3">
                                            <div className="font-weight-bold">Birthdate</div>
                                        </div>
                                        <div className="col-md-9">
                                            <div className="font-weight-light">{contact && contact.Birthdate && formatBirthDate(contact.Birthdate)}</div>
                                        </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-md-3">
                                            <div className="font-weight-bold">Work Anniversary</div>
                                        </div>
                                        <div className="col-md-9">
                                            <div className="font-weight-light">{contact && contact.pse__Start_Date__c && formatStartDate(contact.pse__Start_Date__c)}</div>
                                        </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-md-3">
                                            <div className="font-weight-bold"><span className="mr-3">About Me</span>
                                                
                                            </div>
                                        </div>
                                        <div className="col-md-9">
                                            <div className="font-weight-light">{contact.Employee_Portal_About_Me__c}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}


const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        sfAuth: state.sfAuth,
        contact: state.contacts.data.find((c) => c.Office_365_Email__c === state.sfAuth.user.Office365Login) || {},

        user: state.sfAuth.user,
        status: state.contacts.status,
        error: state.contacts.error,
    }
}

export default connect(mapStateToProps)(Profile);