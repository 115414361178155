import React,{useState, useEffect, useMemo} from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import {connect} from 'react-redux';
import TextInput from '../formFields/TextInput';
import {projectApprovalValidationSchema} from './validationSchema';
import SelectInput from '../formFields/SelectInput';
import {updateServiceRequest} from '../../redux/actions/serviceRequests/updateServiceRequest';
import {serviceRequestResetStatus} from '../../redux/actions/serviceRequests/resetStatus';
import axios from 'axios';

const PurchaseRequestApproval = ({status, serviceRequest, dispatch, user}) => {
    const [saving, setSaving] = useState(null);

    useEffect(() => {
        if(status === "error") {
            setSaving(false);
        }
        return () => {
            // if(status === "updated") {
                setSaving(false);
                dispatch(serviceRequestResetStatus());
            // }
        }
    }, [])



    const approve = async (values) => {

        try {
            const data = {
                id: serviceRequest.Id, 
                comment: values.projApprovalNotes, 
                updateApprovedBy: false,
            }

            const url = values.projApprovalStatus === "Approved" ? '/serviceRequest/approve' : '/serviceRequest/reject';
            const response = await axios.post(url, data)

            if (response.data && (response.data.instanceStatus === "Approved" || response.data.instanceStatus === "Rejected")) {
                dispatch(updateServiceRequest(serviceRequest.Id, values));
            } else {
                console.log('There is an error in the approval process.');
            }

        } catch (err) {
            console.log(err);
        }
    }



    return (
        <Formik
            initialValues={ 
                {requestType: 'projRequestApproval', projApprovalNotes: '', projApprovalStatus: ''}
            }
            validationSchema = {projectApprovalValidationSchema}
            onSubmit={(values, { setSubmitting }) => {
                    setSaving(true);
                    approve({...values, approvedBy: user.Name});
                    // dispatch(updateServiceRequest(serviceRequest.Id, values));
                    setSubmitting(false);
            }}
        >
        {({isSubmitting, values, errors, touched, handleChange, handleBlur, setFieldValue, setFieldTouched}) => {
            return (
                <Form className="needs-validation">
                    <div className="card text-sdi-black mb-3">
                            <div className="card-header bg-sdi-yellow">
                            Project Request Pre-Approval
                            </div>
                            <div className="card-body">
                                {/* <h5 className="card-title">Project Request Pre-Approval</h5> */}
                                <p className="card-text">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <TextInput name="projApprovalNotes" type="text" isRequired={true} label="Notes" />
                                        </div>
                                        <div className="col-md-3">
                                            <SelectInput isRequired={true} label="Status" name="projApprovalStatus">
                                                <option value="--None--">--None--</option>
                                                <option value="Approved">Approved</option>
                                                <option value="Rejected">Rejected</option>
                                            </SelectInput>
                                        </div>
                                        <div className="col-md-3">
                                            <button className="btn btn-sdi-dark btn-block float-right mt-4"
                                                disabled={saving} type="submit">
                                                {saving ? 
                                                <>
                                                    <span className="spinner-border spinner-border-sm"></span>
                                                    {' Submiting...'}
                                                </>
                                                : 'Submit'}
                                            </button>
                                        </div>
                                    </div>
                                </p>
                            </div>
                    </div>
                </Form>
            )}}
        </Formik>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.sfAuth.user,
        status: state.serviceRequests.status,
        error: state.serviceRequests.error
    }
}

export default connect(mapStateToProps)(PurchaseRequestApproval);