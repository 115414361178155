import React,{useState, useEffect, useMemo} from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import moment from 'moment';
import TextInput from '../formFields/TextInput';
import {validationSchema} from './validationSchema';
import SwitchInput from '../formFields/SwitchInput';
import TypeaheadInput from '../formFields/TypeaheadInput';
import DateInput from '../formFields/DateInput';
import SelectInput from '../formFields/SelectInput';
import TextareaInput from '../formFields/TextareaInput';
import RichTextEditor from '../formFields/RichTextEditor';

import images from './images'


const NewsEventsForm = ({status, error, user, event, onSubmit, resetStatus, closeModal}) => {
    const [initialValues, setInitialValues] = useState({
        Created_By__c: event ? event.Created_By__c : user.Id,
        Type__c: event ? event.Type__c : '',
        Visible_From_Date__c: event ? moment(event.Visible_From_Date__c) : '',
        Visible_To_Date__c: event ? moment(event.Visible_To_Date__c) : '',
        Title__c: event ? event.Title__c : '',
        Brief_Description__c: event ? event.Brief_Description__c : '',
        Description__c: event ? event.Description__c : '',
        Background_Image__c: event ? event.Background_Image__c : ''
    });


    useEffect(() => {
        if(status === 'updated' || status === 'saved') {
            resetStatus();
            closeModal();
        }
    }, [status]);


    return (
        <Formik
            initialValues={ 
                initialValues
            }
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
                    // setSaving(true);
                    onSubmit(values);
                    setSubmitting(false);
            }}
        >
            {({isSubmitting, values, errors, touched, handleChange, handleBlur, setFieldValue, setFieldTouched}) => {
                return (
                    <Form className="needs-validation container">
                        {status === 'error' &&
                        <div className="alert alert-danger">{error}</div>}
                        {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                    <div className="row">
                        <div className="col-sm-4">
                            <SelectInput isRequired={true} label="Type" name="Type__c">
                                <option value="">Choose a type...</option>
                                <option value="News">News</option>
                                <option value="Event">Event</option>
                                <option value="Learning & Development">Learning & Development</option>
                                <option value="Corporate Value">Corporate Value</option>
                                <option value="Trend">Trend</option>
                                <option value="CwR">CwR</option>
                                <option value="SDI Balance">SDI Balance</option>
                            </SelectInput>
                        </div>
                        <div className="col-sm-4">
                            <DateInput isRequired={true} label="Visible From" name="Visible_From_Date__c" minDate={new Date()} />
                        </div>
                        <div className="col-sm-4">
                            <DateInput isRequired={true} label="Visible To" name="Visible_To_Date__c" minDate={new Date()} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <TextInput isRequired={true} label="Title" name="Title__c" type="text" placeholder="" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <TextInput label="Brief Description" name="Brief_Description__c" type="text" placeholder="" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <RichTextEditor 
                                imageUpload={true}
                                label="Details"
                                name="Description__c"
                                // isRequired={true}
                                    />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-12">
                            <label className="control-label font-weight-bold mb-1">Background</label>
                        </div>
                        {images.map(({id, src, title}) => 
                        <div className="col-sm-4" key={id}>
                            <label>
                                <Field 
                                    className="img-radio" 
                                    type="radio" 
                                    name="Background_Image__c" 
                                    value={title} 
                                    checked={values.Background_Image__c === title}
                                />
                                <img className="d-block w-100" src={src} />
                            </label>
                        </div>
                        )}
                        
                    </div>
                    <div className="row mb-3">
                        <div className="col-md-4"></div>
                        <div className="col-md-4 text-center">
                            <button 
                                className="btn btn-primary btn-block"
                                disabled={status === 'saving' || status === 'updating'} 
                                type="submit">
                            {status === 'saving' || status === 'updating' ? 
                            <>
                                <span className="spinner-border spinner-border-sm"></span>
                                {' Saving...'}
                            </>
                            : 'Save'}
                            </button>
                        </div>
                        <div className="col-md-4"></div>
                    </div>
                </Form>
                )
            }}
        </Formik>
    )
}


export default NewsEventsForm;