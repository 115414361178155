const serviceRequestTabsReducerDefaultState = {
    key: 'myRequests'
}



export default (state = serviceRequestTabsReducerDefaultState, action) => {
    switch (action.type) {
        case 'SET_SERVICE_REQUEST_TAB':
            return {...state, key: action.key}
        default:
            return state;
    }
}