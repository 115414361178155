import { useEffect, useState } from "react";
import {connect} from "react-redux";

// Msal imports
import { MsalAuthenticationTemplate, useMsal, useAccount } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../auth/authConfig";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";


//actions
import {fetchUser} from '../../redux/actions/users/fetchUser';
import {fetchUserPhoto} from '../../redux/actions/users/fetchUserPhoto';
import {sfAuth} from '../../redux/actions/sfAuth/sfAuth';
import {fetchServiceRequests} from '../../redux/actions/serviceRequests/fetchServiceRequests';
import {fetchAccounts} from '../../redux/actions/accounts/fetchAccounts';
import {fetchProjects} from '../../redux/actions/projects/fetchProjects';
import {fetchRequestCategories} from '../../redux/actions/requestCategories/fetchRequestCategories';
import {fetchAllUsers} from '../../redux/actions/users/fetchAllUsers';
import {fetchServiceRequestAttachments} from '../../redux/actions/serviceRequestAttachments/fetchServiceRequestAttachments';
import {fetchBusinessUnits} from '../../redux/actions/businessUnits/fetchBusinessUnits';
import {fetchContacts} from '../../redux/actions/contacts/fetchContacts';
import {fetchEmpTours} from '../../redux/actions/sfAuth/fetchEmpTours';
import {fetchNewsEvents} from '../../redux/actions/newsEvents/fetchNewsEvents';
import {fetchWow} from '../../redux/actions/wow/fetchWow';
import {fetchEmployeePortalLogs} from '../../redux/actions/employeePortalLogs/fetchEmployeePortalLogs';
import {updateAppReloadTime} from '../../redux/actions/settings/updateAppSettings';

import { callGraphMe, callGraphProfileImage } from "../auth/MsGraphApiCall";

import {Redirect, Link} from 'react-router-dom';
import SignOutButtonSecondary from '../auth/SignOutButtonSecondary'
import SignInButton from "../auth/SignInButton";


const DataLoader = (props) => {
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [error, setError] = useState('')
    const {isAuthenticated, user} = props.sfAuth;


    const lastLocation = (props.location.state && props.location.state.referrer) ? props.location.state.referrer : '/';

    const loadData = (account, accessToken, sfIsAuthenticated) => {
        props.dispatch(fetchUser(accessToken));
        props.dispatch(fetchUserPhoto(accessToken));

        if(props.auth.userState === "error") {
            setError('Error loading your Microsoft account.')
        }

        if(account) {
            props.dispatch(sfAuth({email: account.username}));

            if(sfIsAuthenticated) {
                props.dispatch(updateAppReloadTime(new Date()));
                props.dispatch(fetchEmpTours());
                props.dispatch(fetchBusinessUnits());
                props.dispatch(fetchServiceRequests(user.Access));
                props.dispatch(fetchServiceRequestAttachments());
                props.dispatch(fetchAccounts());
                props.dispatch(fetchProjects());
                props.dispatch(fetchRequestCategories());
                props.dispatch(fetchAllUsers());
                props.dispatch(fetchContacts());
                props.dispatch(fetchNewsEvents());
                props.dispatch(fetchEmployeePortalLogs());
                props.dispatch(fetchWow());
            }
        }
    }

    useEffect(() => {
        console.log(account);
        console.log(inProgress);

        setError('');
        if (account && inProgress === "none") {
            instance.acquireTokenSilent({
                ...loginRequest,
                account: account
            }).then((response) => {
                console.log('--------main loader');
                // console.log(response);
                loadData(account, response.accessToken, props.sfAuth.isAuthenticated);
            }).catch((err) =>{
                setError(err.message)
                // instance.acquireTokenPopup(loginRequest).then(resp => {
                //     console.log('------------------second data loader');
                //     loadData(account, resp.accessToken, props.sfAuth.isAuthenticated);
                // }).catch(err => {
                //     setError(err.message);
                // })





                // if(err.name === "InteractionRequiredAuthError") {

                //     return <Redirect to="/login" />
                //     instance.acquireTokenPopup({
                //         ...loginRequest,
                //         account: account
                //     }).then((response => {
                //         console.log(response);
                //     })).catch(err => {
                //         console.log(err);
                //     })
                // }
            });
        }


    }, [account, inProgress, instance, isAuthenticated]);

    if(!props.auth.isAuthenticated)
            return <Redirect to="/login" />

    if (
        // auth.state === 'Authenticated' &&
        props.sfAuth.isAuthenticated &&
        // props.auth.userState === 'loaded' &&
        props.techTickets.status === 'loaded' &&
        props.accounts.status === 'loaded' &&
        props.projects.status === 'loaded' &&
        props.users.status === 'loaded' &&
        props.requestCategories.status === 'loaded' &&
        props.serviceRequestAttachments.status === 'loaded' &&
        props.contacts.status === 'loaded' &&
        props.wallOfWow.status === 'loaded'
    ) {
        return (
            <Redirect to={lastLocation} />
        )
    }

    if(!props.sfAuth.isAuthenticated && props.sfAuth.status === "Error") {
        return (
        <div className="align-items-center vertical-center" style={{width: '100%'}}>
            <div className="container text-center" style={{maxWidth: '400px'}}>
                <div className="mb-2">We could not find you on Salesforce, Contact Administrator.</div>
                <div><SignOutButtonSecondary /></div>
            </div>
        </div>
        )
    }

    if(error) {
        return (
        <div className="align-items-center vertical-center" style={{width: '100%'}}>
            <div className="container text-center" style={{maxWidth: '400px'}}>
                <div>{error}</div>
                <div><SignInButton /></div>
            </div>
        </div>
        )
    }

    return (
        <div className="align-items-center vertical-center" style={{width: '100%'}}>
            <div className="container text-center" >
                <div className="spinner-border" style={{width: '3rem', height: '3rem'}}>
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        sfAuth: state.sfAuth,
        techTickets: state.serviceRequests,
        accounts: state.accounts,
        projects: state.projects,
        users: state.users,
        requestCategories: state.requestCategories,
        serviceRequestAttachments: state.serviceRequestAttachments,
        contacts: state.contacts,
        wallOfWow: state.wallOfWow
    }
}

export default connect(mapStateToProps)(DataLoader)
