import React, {useState, useEffect} from 'react'
import {Modal, Button} from 'react-bootstrap'
import RichTextToHTML from '../layout/RichTextToHTML';
import moment from 'moment';
import NewsEventsForm from './NewsEventsForm';
import {connect} from 'react-redux';
import {saveNewsEvents} from '../../redux/actions/newsEvents/saveNewsEvents';
import {updateNewsEventsSuccess} from '../../redux/actions/newsEvents/updateNewsEvents';
import ImageUploadForm from './ImageUploadForm';
import {newsEventsResetStatus} from '../../redux/actions/newsEvents/resetStatus';
import {uploadNewsEventsAttachment} from '../../redux/actions/newsEvents/uploadNewsEventsAttachment';
import {deleteNewsEventsAttachment} from '../../redux/actions/newsEvents/deleteNewsEventsAttachment';
import NewsEventsImage from './NewsEventImage';


const NewsEventsNew = ({user, dispatch, status, error, savedEventId, event}) => {
    const [show, setShow] = useState(false);

    const handleClose = () => {
        dispatch(newsEventsResetStatus());
        setShow(false);
    } 
    const handleShow = () => setShow(true);

    // useEffect(() => {
    //     dispatch(newsEventsResetStatus());
    // }, [])

    const renderModal = () => {
        return (
            <Modal show={show} onHide={handleClose} size="lg" backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title style={{margin: '0 auto'}}>New</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    
                    <NewsEventsForm 
                        user={user}
                        closeModal={handleClose}
                        onSubmit={(data) => {
                            dispatch(saveNewsEvents(data))
                        }}
                        resetStatus={() => {
                            dispatch(newsEventsResetStatus())
                        }}
                        status={status}
                        error={error} />



                    {/* {event && event.Attachments && event.Attachments.records[0] ? 
                    <NewsEventsImage 
                        event={event} 
                        status={status}
                        image={event.Attachments.records[0]}
                        deleteImage={(id, eventId) => {
                            dispatch(deleteNewsEventsAttachment(id, eventId))
                        }}
                        updateNews={(id, data) => {
                            dispatch(updateNewsEventsSuccess(id, data));
                        }} /> :
                    <ImageUploadForm 
                        status={status}
                        error={error}
                        eventId={savedEventId}
                        onSubmit={(data) => {
                            dispatch(uploadNewsEventsAttachment(savedEventId, data))
                        }} 
                        resetStatus={() => {
                            dispatch(newsEventsResetStatus())
                        }}
                    />} */}

                </Modal.Body>

                {/* <Modal.Footer style={{display: 'block'}}>
                    <div className="text-center">
                        <Button variant="primary" onClick={handleClose}>
                            Close
                        </Button>
                    </div>
                </Modal.Footer> */}
            </Modal>
        )
    }

    return (
        <div>
            <button className="btn btn-sm btn-primary" onClick={handleShow} news-tour="2">
                <i className="fas fa-plus"></i> New Event/News
            </button>
            {show && renderModal()}
        </div>
    )
}


const mapStateToProps = (state, props) => {
    const savedEventId = state.newsEvents.recordId;
    return {
        user: state.sfAuth.user,
        status: state.newsEvents.status,
        error: state.newsEvents.error,
        savedEventId: savedEventId,
        event: state.newsEvents.data.find((e) => e.Id === savedEventId)
    }
}

export default connect(mapStateToProps)(NewsEventsNew);