import React from 'react';
import Carousel from 'react-bootstrap/Carousel'
import NewsEventsItem from './NewsEventsItem';

const NewsEvents = ({newsEvents}) => {
    return (
        <Carousel fade>
            {newsEvents && newsEvents.map((item) => (
            <Carousel.Item key={item.Id}>
                <NewsEventsItem event={item} events={newsEvents} />
            </Carousel.Item>
            ))}
        </Carousel>
    )
}


export default NewsEvents;