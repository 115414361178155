import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';


const options = {
    cMapUrl: 'cmaps/',
    cMapPacked: true,
};


const FormsPDFViewer = ({file}) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  }

  const nextPage = () => {
      if(pageNumber === numPages) {
          return setPageNumber(1)
      }
      setPageNumber(pageNumber+1);
  }

  const previousPage = () => {
    if(pageNumber === 1) {
        return setPageNumber(numPages)
    }
    setPageNumber(pageNumber-1);
}

  return (
    <div>
      <Document
        file={file}
        onLoadSuccess={onDocumentLoadSuccess}
        options={options}
      >
        <Page pageNumber={pageNumber} />
      </Document>
        <nav className="mt-2">
            <ul className="pagination justify-content-center">
                <li className="page-item">
                    <button className="page-link" onClick={previousPage}>Previous</button>
                </li>
                <li className="page-item active">
                    <span className="page-link">{pageNumber} of {numPages}</span>
                </li>
                <li className="page-item">
                    <button className="page-link" onClick={nextPage}>Next</button>
                </li>
            </ul>
        </nav>
    </div>
  );
}

export default FormsPDFViewer;