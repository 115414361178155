import {ActionTypes} from '../actionTypes';
import axios from 'axios';

export const updateEmployeeForecastStart = () => ({type: ActionTypes.UPDATE_EMPLOYEE_FORECAST_START});
export const updateEmployeeForecastSuccess = (id, updates) => ({type: ActionTypes.UPDATE_EMPLOYEE_FORECAST_SUCCESS, id, updates});
export const updateEmployeeForecastError = (error) => ({type: ActionTypes.UPDATE_EMPLOYEE_FORECAST_ERROR, error});



export const updateEmployeeForecast = (id, rawData = {}) => {
    return async (dispatch) => {


        const readyData = await prepareFields(rawData);

        try {
            dispatch(updateEmployeeForecastStart());

            const data = {
                ...readyData,
                Id: id
            }

            const response = await axios.patch('/employeeForecast/update', data)
            
            if (response.data) {
                dispatch(updateEmployeeForecastSuccess(response.data.employeeForecast.Id, response.data.employeeForecast))
            }
        } catch (err) {
            dispatch(updateEmployeeForecastError(err.response.data));
        }
    }
}


const prepareFields = async (data) => {
    if(data.Is_Employee_Hired__c === false) {
        data.Employee_Name__c = '';
        data.Employee_Setup_Form_Provided__c = false;
    } 
    
    if (data.Requires_Laptop__c === false) {
        data.Laptop_Desired_By__c = null;
        data.HW_SW_Requirements__c = '';
        data.Shipping_Required__c = '';
    }

    return data;
}