import axios from 'axios';
import {ActionTypes} from '../actionTypes';
import { graphConfig } from "../../../components/auth/authConfig";

export const fetchUserStart = () => ({type: ActionTypes.FETCH_USER_START});
export const fetchUserSuccess = (user) => ({type: ActionTypes.FETCH_USER_SUCCESS, user});
export const fetchUserError = (error) => ({type: ActionTypes.FETCH_USER_ERROR, error});

export const fetchUser = (accessToken) => {
    
    return async (dispatch) => {
        try {
            dispatch(fetchUserStart());
            const options = {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                }
            };
        
            const user = await axios.get(graphConfig.graphMeEndpoint, options);

            if(user.data) {
                dispatch(fetchUserSuccess(user.data))
            }

        } catch (err) {
            dispatch(fetchUserError(err))
        }
    }
}