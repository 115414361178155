import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {employeeSearch} from '../../redux/selectors/employeeSelectors';
import EmployeeModal from '../employees/EmployeeModal';
import EmployeeTablePagination from './EmployeeTablePagination';
import {setEmployeeTextFilter, setEmployeeDropdownFilter} from '../../redux/actions/filters/employeeFitlers';

const EmployeeTable = (props) => {
    const [records, setRecords] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(5);

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = records.slice(indexOfFirstRecord, indexOfLastRecord);
    const setPage = (pageNumber) => setCurrentPage(pageNumber);

    
    useEffect(() => {
        setRecords(props.employees);
    }, [props.employees])


    return (
        <>
        <input 
            data-tour="dash-11"
            type="text"
            className="form-control mb-2" 
            placeholder="Search..."
            value={props.filters.text}
            onChange={(e) => {
                setCurrentPage(1);
                props.dispatch(setEmployeeTextFilter(e.target.value));
            }} />

        <div className="list-group mb-2" data-tour="dash-12">
            {currentRecords && currentRecords.map((emp) => (
                <div className="list-group-item list-group-item-action pt-0 pb-0" key={emp.Id}>
                    <EmployeeModal emp={emp} location="dashboard" allEmployees={props.allEmployees} dispatch={props.dispatch} />
                </div>
            ))}
        </div>

        <EmployeeTablePagination currentPage={currentPage} recordsPerPage={recordsPerPage} totalRecords={records.length} setPage={setPage} />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        filters: state.employeeFilters,
        employees: employeeSearch(state.contacts.data, state.employeeFilters),
        allEmployees: state.contacts.data,
    }
}

export default connect(mapStateToProps)(EmployeeTable);