import React from 'react'

const ProjectDetails = (props) => {
    const {project} = props;
    return (
        <div className="card">
            <div className="card-header bg-sdi-black text-sdi-yellow card-header-inner-page">Project Details</div>

            <div className="card-body py-1">
                {(project.Name) &&
                <div className="mb-2">
                    <span className="font-weight-light">Name: </span>
                    <span className="font-weight-bold">{project.Name}</span>
                </div>}

                {(project.pse__Project_ID__c) && 
                <div className="mb-2">
                    <span className="font-weight-light">Project ID: </span>
                    <span className="font-weight-bold">{project.pse__Project_ID__c}</span>
                </div>}

                {(project.pse__Group__r) &&
                <div className="mb-2">
                    <span className="font-weight-light">Group: </span>
                    <span className="font-weight-bold">{project.pse__Group__r.Name}</span>
                </div>}

                {(project.pse__Project_Manager__r) &&
                <div className="mb-2">
                    <span className="font-weight-light">Project Manager: </span>
                    <span className="font-weight-bold">{project.pse__Project_Manager__r.Name}</span>
                    {!project.Project_Manager_Active__c && <span className="text-uppercase small font-weight-bold text-danger"> (Inactive Employee)</span>}
                </div>}

                {(project.Purchase_Manager__r) &&
                <div className="mb-2">
                    <span className="font-weight-light">Purchase Manager: </span>
                    <span className="font-weight-bold">{project.Purchase_Manager__r.Name}</span>
                    {!project.Purchase_Manager_Active__c && <span className="text-uppercase small font-weight-bold text-danger"> (Inactive Employee)</span>}
                </div>}

                {(project.pse__Account__r) &&
                <div className="mb-2">
                    <span className="font-weight-light">Account: </span>
                    <span className="font-weight-bold">{project.pse__Account__r.Name}</span>
                </div>}

                {(project.pse__Account__r && project.pse__Account__r.BillingAddress) &&
                <div className="mb-2">
                    <span className="font-weight-light">Account Billing Address: </span>
                    <span className="font-weight-bold">{`
                        ${project.pse__Account__r.BillingAddress.street && project.pse__Account__r.BillingAddress.street} 
                        ${project.pse__Account__r.BillingAddress.city && project.pse__Account__r.BillingAddress.city} 
                        ${project.pse__Account__r.BillingAddress.state && project.pse__Account__r.BillingAddress.state} 
                        ${project.pse__Account__r.BillingAddress.postalCode && project.pse__Account__r.BillingAddress.postalCode} 
                        ${project.pse__Account__r.BillingAddress.country && project.pse__Account__r.BillingAddress.country}`}
                    </span>
                </div>}

                {(project.pse__Account__r && project.pse__Account__r.Email__c) &&
                <div className="mb-2">
                    <span className="font-weight-light">Account Email: </span>
                    <span className="font-weight-bold">{project.pse__Account__r.Email__c}</span>
                </div>}

                {(project.pse__Account__r && project.pse__Account__r.Phone) &&
                <div className="mb-2">
                    <span className="font-weight-light">Account Phone: </span>
                    <span className="font-weight-bold">{project.pse__Account__r.Phone}</span>
                </div>}

                {(project.pse__Account__r && project.pse__Account__r.Payment_Terms__c) &&
                <div className="mb-2">
                    <span className="font-weight-light">Account Payment Terms: </span>
                    <span className="font-weight-bold">{project.pse__Account__r.Payment_Terms__c}</span>
                </div>}

                {(project.pse__Account__r && project.pse__Account__r.CurrencyIsoCode) &&
                <div className="mb-2">
                    <span className="font-weight-light">Account Currency: </span>
                    <span className="font-weight-bold">{project.pse__Account__r.CurrencyIsoCode}</span>
                </div>}

            </div>
        </div>
    )
}


export default ProjectDetails;