import React, {useState, useEffect} from 'react'
import {connect} from 'react-redux'
import moment from 'moment'
import {Link} from 'react-router-dom';
import axios from 'axios';
import download from 'js-file-download';
import ImageModal from './imageModal';
import {deleteServiceRequestAttachment} from '../../../redux/actions/serviceRequestAttachments/deleteServiceRequestAttachment'
import {deleteConfirmation, deleteConfirmed, deleteError} from '../../sweetAlerts/deleteAlerts'
import ServiceRequestAttachmentItem from './ServiceRequestAttachmentItem';
import UploadModal from './uploadModal';



const ServiceRequestAttachments = (props) => {
    const [error, setError] = useState('')
    const [modalData, setModalData] = useState(null)
    const [modalShow, setModalShow] = useState(false)
    const [fileDetails, setFileDetails] = useState(null)

    const downloadAttachment = async(file) => {
        try {
            const res = await axios.get(`/serviceRequest/attachment/download`, {
                params: { id: file.Id, name: file.Name },
                responseType: 'arraybuffer'
            })

            if (res.status == 200) {
                download(res.data, file.Name);
            }

        } catch (error) {
            setError(error.message)
        }
    }



    const viewAttachment = async(file) => {
        setFileDetails(file)

        try {
            const res = await axios.get(`/serviceRequest/attachment/download`, {
                params: { id: file.Id, name: file.Name },
                responseType: 'arraybuffer'
            })

            if (res.status == 200) {

                const blob = new Blob([res.data]);
                const url = URL.createObjectURL(blob);
                setModalData(url)
                setModalShow(true)

                if (modalShow) {
                    URL.revokeObjectURL(url);
                }
            }
        } catch (error) {
            setError(error.message)
        }
    }


    const deleteAttachment = async (file) => {
        const willDelete = await deleteConfirmation();

        if(willDelete) {
            const Id = props.locationId
            props.dispatch(deleteServiceRequestAttachment(file.Id))
        }
    }


    return (
        <div className="card">
            <div className="card-header bg-sdi-black text-sdi-yellow">
                Attachments
                {!props.form && (
                <div className="float-right">
                    <UploadModal serviceRequestId={props.serviceRequest.Id} />
                </div>
                )}
            </div>
            <div className="card-body" data-tour="it-req-details-3">
                {props.form && (
                    <div className="row">
                        <div className="col-sm-2"></div>
                        <div className="col-sm-8">
                            <div className="mt-3 mb-4 text-center">
                                <div className="mb-3">(Optional) If you would like to include any supporting files please upload them here by clicking the "Upload" button, or else click "Done" to complete.</div>
                                <UploadModal serviceRequestId={props.serviceRequest.Id} />
                            </div>
                        </div>
                        <div className="col-sm-2"></div>
                    </div>)}

                <div className={`${props.form && 'row'}`}>
                {props.form && (
                    <div className="col-sm-2"></div>
                )}
                <div className={`${props.form && 'col-sm-8'}`}>
                    <div className="table-responsive-sm">
                        <table className="table table-sm">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Title</th>
                                    <th>Size</th>
                                    <th>Uploaded On</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.ServiceRequestAttachments && props.ServiceRequestAttachments.map((att) => (
                                    <ServiceRequestAttachmentItem 
                                    attachment={att} 
                                    deleteAttachment={deleteAttachment}
                                    downloadAttachment={downloadAttachment} 
                                    viewAttachment={viewAttachment} />
                                ))}
                            </tbody>
                            <ImageModal
                                fileDetails={fileDetails}
                                show={modalShow}
                                setShow={setModalShow}
                                data={modalData}
                            />
                        </table>
                    </div>

                    {props.form && (
                        <div className="row">
                            <div className="col-sm-2"></div>
                            <div className="col-sm-8 p-0">
                                <div className="row mt-1 mb-4">
                                    <div className="col-md-4"></div>
                                    <div className="col-md-4 text-center">
                                        <Link className="btn btn-primary btn-block text-uppercase" to={`/serviceRequests/${props.serviceRequest.Id}`}>Done</Link>
                                    </div>
                                    <div className="col-md-4"></div>
                                </div>
                            </div>
                            <div className="col-sm-2"></div>
                        </div>
                    )}
                </div>
                {props.form && (
                    <div className="col-sm-2"></div>
                )}
                </div>
            </div>
        </div>
    )
}



const mapStateToProps = (state, props) => {
    return {
        ServiceRequestAttachments: state
            .serviceRequestAttachments
            .data
            .filter((item) => item.ServiceRequestId === props.serviceRequest.Id) || []
    }
}

export default connect(mapStateToProps)(ServiceRequestAttachments)