import React, {useEffect, useState} from 'react';
import Layout from '../layout/Layout';
import {connect} from "react-redux";
import {setEmployeeTextFilter, setEmployeeDropdownFilter} from '../../redux/actions/filters/employeeFitlers';
import {employeeSearch, findUserPhotoByContact} from '../../redux/selectors/employeeSelectors';
import Pagination from '../layout/Pagination';
import EmployeeModal from './EmployeeModal';
import TourRunner from '../tour/TourRunner';
import {updateEmpTour} from '../../redux/actions/sfAuth/updateEmpTours';
import {empDirectorySteps} from '../tour/tourContent';
import {empDirectoryMobileSteps} from '../tour/tourMobileContent';
import EmployeeDirectoryItem from './EmployeeDirectoryItem';

const EmployeeDirectory = (props) => {

    const [records, setRecords] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(8);

    

    useEffect(() => {
        setRecords(props.employees);
    }, [props.employees])

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = records.slice(indexOfFirstRecord, indexOfLastRecord);
    const setPage = (pageNumber) => setCurrentPage(pageNumber);
    
    const breadcrumb = [
        { name:'Employee Directory', link: '' }
    ]



    return (
        <Layout breadcrumb={breadcrumb}>
            <TourRunner 
                content={empDirectorySteps} 
                mobileContent={empDirectoryMobileSteps}
                isClosed={props.sfAuth.tours && props.sfAuth.tours.Employee_Directory__c}
                updateSalesforce={() => {
                    props.dispatch(updateEmpTour(props.sfAuth.tours.Id, {Id: props.sfAuth.tours.Id, Employee_Directory__c: true}))
                }} 
            />
            <div className="card">
                <div className="card-body">
                    
                    <div className="mb-4 mx-sm-5">
                        <div className="form-group row">
                            <div className="col-sm-2 text-right">
                                <label className="h6"></label>
                            </div>
                                <div className="col">
                                    <input 
                                        data-tour="emp-directory-1"
                                        type="text" 
                                        className="form-control" 
                                        placeholder="Search"
                                        value={props.filters.text}
                                        onChange={(e) => {
                                            e.preventDefault();
                                            setCurrentPage(1);
                                            props.dispatch(setEmployeeTextFilter(e.target.value));
                                        }} />
                                </div>
                            <div className="col-sm-2"></div>
                        </div>
                    </div>
                    <div className="row">
                        {currentRecords && currentRecords.map((emp) => 
                        
                        <div className="col-md-3" key={emp.Id}>                        
                            <div className="card border">
                                <div className="card-body text-center">
                                    <EmployeeDirectoryItem emp={emp} dispatch={props.dispatch} key={emp.Id} />
                                </div>
                                <div className="card-footer">
                                    <EmployeeModal emp={emp} allEmployees={props.allEmployees} dispatch={props.dispatch} key={emp.Id} />
                                </div>
                            </div>
                        </div>
                        )}
                    </div>
                    <Pagination 
                        totalRecords={records.length}
                        recordsPerPage={recordsPerPage}
                        setPage={setPage}
                        currentPage={currentPage}
                    />
                </div>
            </div>
        </Layout>
    )
}


const mapStateToProps = (state) => {
    return {
        sfAuth: state.sfAuth,
        users: state.users.data,
        filters: state.employeeFilters,
        employees: employeeSearch(state.contacts.data, state.employeeFilters),
        allEmployees: state.contacts.data,
        departments: [...new Set(state.contacts.data.filter(rec => rec.Department !== null).map(emp => emp.Department))] || []
    }
}


export default  connect(mapStateToProps)(EmployeeDirectory);