import {ActionTypes} from '../actionTypes';
import axios from 'axios';

export const fetchServiceRequestsStart = () => ({type: ActionTypes.FETCH_SERVICE_REQUESTS_START});
export const fetchServiceRequestsSuccess = (tickets) => ({type: ActionTypes.FETCH_SERVICE_REQUESTS_SUCCESS, tickets});
export const fetchServiceRequestsError = (error) => ({type: ActionTypes.FETCH_SERVICE_REQUESTS_ERROR, error});

export const fetchServiceRequests = (access) => {
    return async (dispatch) => {
        try {
            dispatch(fetchServiceRequestsStart());
            const response = await axios.get(`/serviceRequest/all/${access}`)

            if (response.data) {
                dispatch(fetchServiceRequestsSuccess(response.data))
            }
        } catch (err) {
            dispatch(fetchServiceRequestsError(err));
        }
    }
}