import {ActionTypes} from '../actionTypes';
import axios from 'axios';

export const fetchProjectsStart = () => ({type: ActionTypes.FETCH_PROJECTS_START});
export const fetchProjectsSuccess = (projects) => ({type: ActionTypes.FETCH_PROJECTS_SUCCESS, projects});
export const fetchProjectsError = (error) => ({type: ActionTypes.FETCH_PROJECTS_ERROR, error});

export const fetchProjects = () => {
    return async (dispatch) => {
        try {
            dispatch(fetchProjectsStart());
            const response = await axios.get('/projects/all')


            if (response.data) {
                dispatch(fetchProjectsSuccess(response.data))
            }
        } catch (err) {
            dispatch(fetchProjectsError(err));
        }
    }
}