
export const formsSearch = (docs, {text, category, subCategory}) => {
    return docs.filter((doc) => {
        
        const textMatch = text ? (doc.Name__c && (doc.Name__c.toLowerCase().includes(text.toLowerCase()))) : doc;

        const categoryMatch = category ? (doc.Category__c && doc.Category__c.toLowerCase() === category.toLowerCase()) : doc;
        const subCategoryMatch = subCategory ? (doc.Sub_Category__c && doc.Sub_Category__c.toLowerCase() === subCategory.toLowerCase()) : doc;

        return textMatch && categoryMatch && subCategoryMatch;
    })
};