import React, {useState} from 'react'
import {connect} from "react-redux";
import AddQuickLink2 from "./AddQuickLink2";
import QuickLinkItem from "./QuickLinkItem";

const QuickLinkList = ({links}) => {
    const [showMore, setShowMore] = useState(false)

    const data = showMore ? links : links.slice(0, 11)
    return (
        <div className="px-2 mt-4">
            <div className="row">
                {data && data.map((link) => <QuickLinkItem link={link} />)}
                <AddQuickLink2 />
            </div>
            <div className="text-right mb-4">
                {showMore
                    ? <button className="btn btn-link p-0 m-0 border-0" onClick={() => setShowMore(false)}>see less →</button>
                    : <button className="btn btn-link p-0 m-0 border-0" href="#" onClick={() => setShowMore(true)}>see more →</button>}
            </div>
        </div>
    )
}



const mapStateToProps = (state) => {
    return {
        links: state.quickLinks.data,
    }
}

export default connect(mapStateToProps)(QuickLinkList);