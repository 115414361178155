import React, {useState} from 'react';
import { connect } from 'react-redux'
import {ToggleButton, ButtonGroup } from 'react-bootstrap'
import {getRadios} from './dateFunctions'
import {setLogsTimeFilter} from '../../../redux/actions/filters/logsFilters'
import {fetchEmployeePortalLogs} from '../../../redux/actions/employeePortalLogs/fetchEmployeePortalLogs'

const LogsFilter = ({timeFitler, dispatch, loading, setFilterName}) => {
    const radios = getRadios()

    const handleFilter = (value, radio) => {
        dispatch(setLogsTimeFilter(value))
        setFilterName(radio.name)

        const fitlerData = radios.find((i) => i.value === value)
        dispatch(fetchEmployeePortalLogs(fitlerData))
    }

    return (
        <div className="row">
            <div className="col-sm-12">
                <label className="control-label font-weight-bold mb-1">Filter:</label><br />
                <div className="form-inline">
                    <div className="form-group">
                    {radios.map((radio, idx) => (
                        <ButtonGroup className="mr-2 ml-0 shadow-none" id={`radio-${idx}`}>
                            <ToggleButton
                            className={`btn-sm px-3 chk-btn ${timeFitler === radio.value ? 'btn-outline-primary' : 'btn-outline-unchecked'}`}
                                type="radio"
                                variant={'outline-primary'}
                                name="radio"
                                value={radio.value}
                                checked={timeFitler === radio.value}
                                onChange={(e) => handleFilter(e.currentTarget.value, radio)}
                                disabled={loading === 'loading' && timeFitler === radio.value}
                            >
                                {loading === 'loading' && timeFitler === radio.value ? 
                                    <><span className="spinner-border spinner-border-sm mr-1"> </span>Loading...</> : 
                                    radio.name}
                            </ToggleButton>
                        </ButtonGroup>
                        ))}
                    </div>
                    {/* <div className="form-group">
                        <div className="mr-3 font-weight-bold">{loading}</div>
                    </div>
                    <div className="">
                        <div className="form-row">
                            <div className="col">
                                <input className="form-control" type="text" placeholder="Select start date..." />
                            </div>
                            <div className="col">
                                <input className="form-control" type="text" placeholder="Select end date..." />
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        timeFitler: state.userLogsFilter.time
    }
}
export default connect(mapStateToProps)(LogsFilter)