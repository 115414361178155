import {ActionTypes} from '../actionTypes';
import axios from 'axios';

export const fetchEmployeePortalLogsStart = () => ({type: ActionTypes.FETCH_EMPLOYEE_PORTAL_LOGS_START});
export const fetchEmployeePortalLogsSuccess = (userLogs) => ({type: ActionTypes.FETCH_EMPLOYEE_PORTAL_LOGS_SUCCESS, userLogs});
export const fetchEmployeePortalLogsError = (error) => ({type: ActionTypes.FETCH_EMPLOYEE_PORTAL_LOGS_ERROR, error});

export const fetchEmployeePortalLogs = (data) => {
    return async (dispatch) => {
        try {
            dispatch(fetchEmployeePortalLogsStart());
            const response = await axios.post('/userLogs/all', data)

            // console.log(response);

            if (response.data) {
                dispatch(fetchEmployeePortalLogsSuccess(response.data))
            }
        } catch (err) {
            dispatch(fetchEmployeePortalLogsError(err));
        }
    }
}