import { useMsal } from "@azure/msal-react";


const SignOutButtonLink = () => {
    const { instance } = useMsal();

    const handleLogout = () => {
        instance.logout();
        localStorage.removeItem('sfToken')
    }


    return (
    <button className="dropdown-item" onClick={handleLogout}>
        <i className="cil-account-logout c-icon mr-2"></i>
            Logout
    </button>)
}

export default SignOutButtonLink;