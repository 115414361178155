import {ActionTypes} from '../actionTypes';
import axios from 'axios';

export const fetchNewsEventsStart = () => ({type: ActionTypes.FETCH_NEWS_EVENTS_START});
export const fetchNewsEventsSuccess = (payload) => ({type: ActionTypes.FETCH_NEWS_EVENTS_SUCCESS, payload});
export const fetchNewsEventsError = (error) => ({type: ActionTypes.FETCH_NEWS_EVENTS_ERROR, error});

export const fetchNewsEvents = () => {
    return async (dispatch) => {
        try {
            dispatch(fetchNewsEventsStart());
            const response = await axios.get('/newsEvents/all')


            if (response.data) {
                const newData = response.data.map((item) => ({...item, ImageUrl: null}));

                dispatch(fetchNewsEventsSuccess(newData))
            }
        } catch (err) {
            dispatch(fetchNewsEventsError(err));
        }
    }
}