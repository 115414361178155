
export const uniqueUserAccess = (data) => {


    
    const visits = data && data.reduce((acc, curr) => {
        
        return acc[curr.SDI_Company__c] ? ++acc[curr.SDI_Company__c] : acc[curr.SDI_Company__c] = 1, acc
    }, {})

    let total = 0
    const labels = []
    const chartValues = []
    const rawData = []

    if(visits) {
        for (const [key, value] of Object.entries(visits)) {
            total += value
        }

        for (const [key, value] of Object.entries(visits)) {
            labels.push(key)
            chartValues.push((value / total * 100).toFixed(0))

            rawData.push({
                name: key,
                value: value,
            })
        }
    }
    

    return {
        total: data && data.length,
        data: rawData,
        chartData: {
            labels: labels,
            data: chartValues
        }
    }
}


export const mobileDesktopAccess = (data) => {

    let totalDesktop = 0
    let totalMobile = 0

    if(data) {
        for(const company of data) {
            for(const department of company.departments) {
                for(const device of department.devices) {
                    if(device.name === 'Desktop') {
                        totalDesktop += device.total
                        for(const browser of device.browsers) {
                            totalDesktop += browser.value
                        }
                    } else if(device.name === 'Mobile') {
                        for(const browser of device.browsers) {
                            totalMobile += browser.value
                        }
                    }
                }
            }
        }
    }

    let total = totalDesktop + totalMobile
    let desktopPercent = (totalDesktop / total * 100).toFixed(1)
    let mobilePercent = (totalMobile / total * 100).toFixed(1)

    return {
        total: null,
        data: [
            {
                name: 'Desktop',
                value: totalDesktop
            },
            {
                name: 'Mobile',
                value: totalMobile
            } 
        ],
        chartData: {
            labels: ['Mobile', 'Desktop'],
            data: [mobilePercent, desktopPercent]
        }
    }
}


export const browserAccess = (data) => {
    let total = data && data.reduce((accumulator, item) => {
        return accumulator + item.value
    }, 0)

    
    
    return {
        total: null,
        data: data && data.map((i) => ({
            name: i.label,
            value: i.value
        })),
        chartData: {
            labels: data && data.map((i) => i.label),
            data: data && data.map((i) => (i.value / total * 100).toFixed(1))
        }
    }
}


export const techTicketsCalculations = (data) => {
    return {
        total: null,
        data: data && data.sort((a, b) => b.expr0 - a.expr0).filter((j) => j.SDI_Company__c != null).map((i) => ({
            name: i.SDI_Company__c,
            value: i.expr0
        })),
        chartData: {
            labels: data && data.filter((j) => j.SDI_Company__c != null).map((i) => i.SDI_Company__c),
            data: data && data.filter((j) => j.SDI_Company__c != null).map((i) => i.expr0)
        }
    }
}


export const accessByMonths = (data) => {


    const columns = data && data.groupingsDown.groupings.map((i) => {
        return { 
            label: i.label, 
            key: i.key,
            value: i.value,
            visits: data.factMap[i.key+"!T"].aggregates[0].value
        }
    })

    return {
        total: null,
        data: columns && columns.map((i) => ({
            name: i.label,
            value: i.visits
        })),
        chartData: {
            labels: columns && columns.map((i) => i.label),
            data: columns && columns.map((i) => i.visits)
        }
    }
}