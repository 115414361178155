import {ActionTypes} from '../actionTypes';
import axios from 'axios';

export const saveNewsEventsStart = () => ({type: ActionTypes.SAVE_NEWS_EVENTS_START});
export const saveNewsEventsSuccess = (event) => ({type: ActionTypes.SAVE_NEWS_EVENTS_SUCCESS, event});
export const saveNewsEventsError = (error) => ({type: ActionTypes.SAVE_NEWS_EVENTS_ERROR, error});

export const saveNewsEvents = (requestData = {}) => {
    return async (dispatch) => {

        try {

            dispatch(saveNewsEventsStart());

            const response = await axios.post('/newsEvents/create', requestData)

            if (response.data) {
                dispatch(saveNewsEventsSuccess({...response.data.event, ImageUrl: null}))
            }
        } catch (err) {
            dispatch(saveNewsEventsError(err.message));
        }
    }
}