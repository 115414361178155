export const addPeopleTimeAwayCompanyFilter = (company = '') => ({
    type: 'ADD_PEOPLE_TIME_AWAY_COMPANY_FILTER',
    company
})

export const removePeopleTimeAwayCompanyFilter = (company = '') => ({
    type: 'REMOVE_PEOPLE_TIME_AWAY_COMPANY_FILTER',
    company
})

export const addPeopleTimeAwayDepartmentFilter = (department = '') => ({
    type: 'ADD_PEOPLE_TIME_AWAY_DEPARTMENT_FILTER',
    department
})

export const removePeopleTimeAwayDepartmentFilter = (department = '') => ({
    type: 'REMOVE_PEOPLE_TIME_AWAY_DEPARTMENT_FILTER',
    department
})


// all
export const addAllPeopleTimeAwayCompanyFilter = (companies) => ({
    type: 'ADD_ALL_PEOPLE_TIME_AWAY_COMPANY_FILTER',
    companies
})

export const removeAllPeopleTimeAwayCompanyFilter = () => ({
    type: 'REMOVE_ALL_PEOPLE_TIME_AWAY_COMPANY_FILTER'
})

export const addAllPeopleTimeAwayDepartmentFilter = (departments) => ({
    type: 'ADD_ALL_PEOPLE_TIME_AWAY_DEPARTMENT_FILTER',
    departments
})

export const removeAllPeopleTimeAwayDepartmentFilter = () => ({
    type: 'REMOVE_ALL_PEOPLE_TIME_AWAY_DEPARTMENT_FILTER'
})