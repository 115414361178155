export const selectEmployeesByCompany = (employeeList) => {
  // const companies = [...new Set(employeeList?.filter((j) => j.Company__c != null).map((i) => i && i.Company__c))]
  const deparments = [
    ...new Set(
      employeeList
        ?.filter((j) => j.Department_Name__c != null)
        .map((i) => i && i.Department_Name__c)
    ),
  ];

  return deparments?.map((company) => ({
    company: company,
    people: employeeList.filter((emp) => emp.Department_Name__c === company),
  }));
};

export const selectEmployeesByDepartment = (employeeList) => {
  const deparments = [
    ...new Set(
      employeeList
        ?.filter((j) => j.Department_Name__c != null)
        .map((i) => i && i.Department_Name__c)
    ),
  ];

  return deparments?.map((dep) => ({
    department: dep,
    people: employeeList.filter((emp) => emp.Department_Name__c === dep),
  }));
};

export const isManager = (empNumber, people) => {
  return people?.find((i) => i.Manager_XRefCode__c === empNumber);
};

export const listToTree = (people, leader) => {
  people.unshift(leader);

  var map = {},
    node,
    roots = [],
    i;

  for (i = 0; i < people.length; i += 1) {
    if (people[i]) {
      if (people[i].Employee_Number__c === "000001") {
        people[i].Manager_XRefCode__c = "";
      }

      map[people[i].Employee_Number__c] = i; // initialize the map
      people[i].children = []; // initialize the children
    }
  }

  // const l = {};
  // l[leader.Employee_Number__c] = i + 1;
  // map = {
  //   ...map,
  //   ...l,
  // };

  // console.log("init ", map);

  for (i = 0; i < people.length; i += 1) {
    if (people[i]) {
      node = people[i];

      // if (node.Employee_Number__c !== "000090") {
      const item = people[map[node.Manager_XRefCode__c]];
      item?.children?.push(node);
      if (item) roots.push(item);
      // }
    }
  }

  // console.log("peope ", people);

  return roots.length > 0 && leader
    ? roots.find((i) => i.Employee_Number__c === leader.Employee_Number__c)
    : {};
};

// =========================================================================================

export const labelLeadersManagers = (masterList, people) => {
  const managerIds = people.map((i) => i.Manager_XRefCode__c);

  managerIds?.forEach((id) => {
    const manager = people?.find(
      (p) =>
        p.Employee_Number__c === id &&
        !managerIds.includes(p.Manager_XRefCode__c)
    );

    console.log(manager && manager);

    // if(manager) {
    const managerIndex = people.indexOf(manager);
    people[managerIndex] = { ...manager, isManager: true };
    // } else {
    // const managerOnMaster = masterList?.find((i) => (i.Employee_Number__c !== "000090" || i.Employee_Number__c !== '000001') && i.Employee_Number__c === id)

    // if(managerOnMaster) {
    // 	people.push({
    // 		...managerOnMaster,
    // 		isManager: true
    // 	})
    // }
    // }
  });

  people.map((i) => ({
    ...i,
    children: [],
  }));

  return people;
};

export const listToTree2 = (list) => {
  list.sort((x, y) => Number(y.isManager) - Number(x.isManager));

  var map = {},
    node,
    roots = [],
    i;

  for (i = 0; i < list.length; i += 1) {
    map[list[i].Employee_Number__c] = i; // initialize the map
    list[i].children = []; // initialize the children
  }

  for (i = 0; i < list.length; i += 1) {
    node = list[i];
    if (
      node.Employee_Number__c !== "000090" ||
      node.Employee_Number__c !== "000001"
    ) {
      const item = list[map[node.Manager_XRefCode__c]];
      item?.children?.push(node);
      if (item) roots.push(item);
    } else {
      //roots.push(node);
    }
  }

  return roots;
};
