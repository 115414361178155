import { useMsal } from "@azure/msal-react";


const SignOutButton = () => {
    const { instance } = useMsal();

    const handleLogout = () => {
        instance.logout();
        localStorage.removeItem('sfToken')
    }


    return (
        <a href="#" onClick={handleLogout} className="c-sidebar-nav-link c-sidebar-nav-link-danger">
          <i className="fas fa-sign-out-alt c-sidebar-nav-icon"></i>
            Log Out
        </a>
    );
}

export default SignOutButton;