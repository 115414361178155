const LogsWedget = ({bg, icon, title, value}) => {
    return (
        <div className={`card ${bg === 'white' ? 'text-dark' : 'text-white'} bg-${bg}`}>
            <div className="card-body">
                {icon ? <div className="mb-4 text-right">
                    <i className={`h2 ${icon}`}></i>
                </div> : '' }

                <div className="h3">{value}</div>

                <small className="text-uppercase h6 text-muted">{title}</small>

                {/* <div className="progress progress-white mt-3" style={{height: '4px'}}>
                    <div className="progress-bar" style={{width: '25%'}}></div>
                </div> */}
            </div>
        </div>
    )
}

export default LogsWedget