const helpDocsFiltersReducerDefaultState = {
    text: '',
    category: '',
    subCategory: ''
}



export default (state = helpDocsFiltersReducerDefaultState, action) => {
    switch (action.type) {
        case 'SET_HELP_DOCS_TEXT_FILTER':
            return {...state, text: action.text}
        case 'SET_HELP_DOCS_CATEGORY_FILTER':
            return {...state, category: action.category}
        case 'SET_HELP_DOCS_SUBCATEGORY_FILTER':
            return {...state, subCategory: action.subCategory}
        default:
            return state;
    }
}