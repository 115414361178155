import React, {useState, useEffect} from 'react'
import { Tree, TreeNode } from 'react-organizational-chart';
import {displayName, displayNameTable, formatBirthDate, formatStartDate, formatAnniversary, backgroundColor} from './functions';
import {updateContactSuccess} from '../../redux/actions/contacts/updateContact';
import {contactResetStatus} from '../../redux/actions/contacts/resetStatus';
import axios from 'axios';


const EmployeeName = ({emp, changeEmp, dispatch}) => {
    // useEffect(() => {
    //     const download = async () => {
    //         if(emp.pse__Salesforce_User__r && emp.pse__Salesforce_User__r.SmallPhotoUrl !== 'https://sdi--c.na147.content.force.com/profilephoto/005/T' && emp.ImageUrl === null) {
    //             const response = await axios.post(`/contacts/picture`, {url: emp.pse__Salesforce_User__r.SmallPhotoUrl});
        
    //             if (response.status === 200) {
    //                 dispatch(updateContactSuccess(emp.Id, {ImageUrl: response.data}))
    //                 dispatch(contactResetStatus())
    //             }
    //         }
    //     }

    //     download();
        
    // }, [emp])

    if(emp.Photo) {
        return (
            <>
            <img className="rounded-circle border mb-2 user-image-small" src={`data:image/jpeg;base64,${emp.Photo}`} alt=""></img>
            <div className="h6"><button className="btn btn-link p-0 m-0" onClick={() => changeEmp(emp.Id)} style={{textDecoration: 'underline'}}>{emp.Name}</button></div>
            <div className="small">{emp.Title}</div>
            </>
        )
    }

    return (
        <>
            <div className="rounded-circle employee-profile-image-dashboard border mb-2" style={{backgroundColor: backgroundColor(emp.Name)}}>
                {displayName(emp.Name)}
            </div>
            
            
            <div className="h6"><button className="btn btn-link p-0 m-0" onClick={() => changeEmp(emp.Id)} style={{textDecoration: 'underline'}}>{emp.Name}</button></div>
            <div className="small">{emp.Title}</div>
        </>
    )
}


const EmployeeDetails = ({emp, subordinates, changeEmp, dispatch, allEmployees}) => {

    return (
    <>
        <div className="card border">
            <div className="card-body text-center">
                {emp.Photo 
                    ?
                    <img className="rounded-circle border mb-2 " style={{width: '5.6em'}} src={`data:image/jpeg;base64,${emp.Photo}`} alt=""></img>
                    :
                    <div className="rounded-circle employee-profile-image border mb-2" style={{backgroundColor: backgroundColor(emp.Name)}}>
                        {displayName(emp.Name)}
                    </div>
                }
                    
                    <div className="h6">{emp.Name}</div>
                    <div className="small">{emp.Title}</div>
            </div>
            <div className="card-footer">
                <table>
                    <tr>
                        <td className="text-center pr-3" ><i className="cil-group" style={{fontSize: '1.1rem', lineHeight: '1.428'}}></i></td>
                        <td><span>{emp.Department}</span></td>
                    </tr>
                    <tr>
                        <td className="text-center pr-3"><i className="cil-envelope-closed" style={{fontSize: '1.1rem', lineHeight: '1.428'}}></i></td>
                        <td><span><a className="text-dark" href={`mailto:${emp.Email}`} >{emp.Email}</a></span></td>
                    </tr>
                    <tr>
                        <td className="text-center pr-3"><i className="cib-microsoft" style={{fontSize: '1.1rem', lineHeight: '1.428'}}></i></td>
                        <td><span><a className="text-dark" href={`https://teams.microsoft.com/l/chat/0/0?users=${emp.Email}`} target="_blank" rel="noreferrer">Teams</a></span></td>
                    </tr>
                    {emp.Birthdate && <tr>
                        <td className="text-center pr-3"><i className="cil-birthday-cake" style={{fontSize: '1.1rem', lineHeight: '1.428'}}></i></td>
                        <td><span>{formatBirthDate(emp.Birthdate)}</span></td>
                    </tr>}
                    {emp.pse__Start_Date__c && <tr>
                        <td className="text-center pr-3"><i className="cil-badge" style={{fontSize: '1.1rem', lineHeight: '1.428'}}></i></td>
                        <td><span>{formatStartDate(emp.pse__Start_Date__c)}</span></td>
                    </tr>}
                </table>
                
                
                {emp.Employee_Portal_About_Me__c &&
                <div className="mt-3">
                    <span><blockquote className="blockquote-footer" style={{fontSize: '14px'}}>{emp.Employee_Portal_About_Me__c}</blockquote></span>
                </div>}
            </div>
        </div>
        <div className="container text-center" style={{overflowX: 'auto'}}>
            {subordinates.length > 0 && 
            <Tree 
                lineWidth={'2px'}
                lineColor={'#c3c3c3'} 
                label={<div className="font-weight-bold">{emp.Name}</div>}>
                {subordinates.map((sub) => (
                    <TreeNode label={<div className="mt-1">{<EmployeeName emp={allEmployees.find((i) => i.Id === sub.Id)} changeEmp={changeEmp} dispatch={dispatch} />}</div>} />
                ))}
            </Tree>}
        </div>
    </>
    )
}


export default EmployeeDetails;