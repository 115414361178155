const quotes = [
    { 
        id: 1, 
        Quote__c: '“Live as if you were to die tomorrow. Learn as if you were to live forever.”', 
        Author__c: 'Mahatma Gandhi', 
    }, { 
        id: 2, 
        Quote__c: '“That which does not kill us makes us stronger.”', 
        Author__c: 'Friedrich Nietzsche', 
    }, { 
        id: 3, 
        Quote__c: '“Be who you are and say what you feel, because those who mind don’t matter and those who matter don’t mind.”', 
        Author__c: 'Bernard M. Baruch', 
    }, { 
        id: 4, 
        Quote__c: '“We must not allow other people’s limited perceptions to define us.”', 
        Author__c: 'Virginia Satir', 
    }, { 
        id: 5, 
        Quote__c: '“Do what you can, with what you have, where you are.”', 
        Author__c: 'Theodore Roosevelt', 
    }, { 
        id: 6, 
        Quote__c: '“Be yourself; everyone else is already taken.”', 
        Author__c: 'Oscar Wilde', 
    }, { 
        id: 7, 
        Quote__c: '“This above all: to thine own self be true.”', 
        Author__c: 'William Shakespeare', 
    }, { 
        id: 8, 
        Quote__c: '“If you cannot do great things, do small things in a great way.”', 
        Author__c: 'Napoleon Hill', 
    }, { 
        id: 9, 
        Quote__c: '“If opportunity doesn’t knock, build a door.”', 
        Author__c: 'Milton Berle', 
    }, { 
        id: 10, 
        Quote__c: '“Wise men speak because they have something to say; fools because they have to say something.”', 
        Author__c: 'Plato', 
    }, { 
        id: 11, 
        Quote__c: 'Everything we do is instilled with a deep sense of Trust & Integrity.', 
        Author__c: 'It begins with our VALUES!', 
    }, { 
        id: 12, 
        Quote__c: 'Our success is based in the power of relationship – with ourselves, with our clients and with the world around us.', 
        Author__c: 'SDI Value - Human', 
    }, { 
        id: 13, 
        Quote__c: 'We are that partner that clients can most lean on – and we treat their business as if it were our own.', 
        Author__c: 'SDI Value - Partner', 
    }, { 
        id: 14, 
        Quote__c: 'We can make anything we put our minds to.', 
        Author__c: 'SDI Value - Invent', 
    }, { 
        id: 15, 
        Quote__c: 'People from all walks but also with wildly different skills.', 
        Author__c: 'SDI Value - Diversity', 
    }, { 
        id: 16, 
        Quote__c: 'The deeply ingrained belief that there is always a better way.', 
        Author__c: 'SDI Value - Better', 
    }, { 
        id: 17, 
        Quote__c: 'For people who believe they can do the impossible, SDI offers a place to grow as a whole person. Your dream is as important as ours. Together, us humans will create the new and better agency of the future.', 
        Author__c: 'Employee Value Proposition', 
    }, { 
        id: 18, 
        Quote__c: 'To disrupt & improve every category we work in, by bringing more human to the way we do it.', 
        Author__c: 'It is our Vision!', 
    }, { 
        id: 19, 
        Quote__c: 'We believe the world needs more human… We exist to release human potential, one experience at a time.', 
        Author__c: 'This is our Purpose!', 
    }, {
        id: 20,
        Quote__c: 'Gratitude makes sense of our past, brings peace for today, and creates a vision for tomorrow.',
        Author__c: 'Melody Beattie'
    }
];


export default quotes;