import React, {useState} from 'react'
import {Modal, Button} from 'react-bootstrap'
import AboutMeForm from './AboutMeForm';
import {updateContact} from '../../redux/actions/contacts/updateContact';
import {contactResetStatus} from '../../redux/actions/contacts/resetStatus';


const EditProfile = ({contact, dispatch, status, error}) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const renderModal = () => {
        return (
            <Modal show={show} onHide={handleClose} size="lg" backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title style={{margin: '0 auto'}}>Edit Profile</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <AboutMeForm 
                        contact={contact} 
                        closeModal={handleClose}
                        status={status}
                        error={error}
                        onSubmit={(data) => {
                            dispatch(updateContact(contact.Id, data))
                        }}
                        resetStatus={() => {
                            dispatch(contactResetStatus())
                        }}
                         />
                    
                </Modal.Body>
            </Modal>
        )
    }

    return (
        <>
            <button className="btn btn-sm btn-primary" onClick={handleShow}>
                <i className="fas fa-edit"></i> Edit
            </button>
            {show && renderModal()}
        </>
    )
}





export default EditProfile;