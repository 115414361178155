import React, { useState } from 'react';
import { connect } from 'react-redux'
import {Spinner} from 'react-bootstrap'
import Layout from '../../layout/Layout';
import LogsWedget from './LogsWedget';
import LogsWedgetChart from './LogsWedgetChart';
import {uniqueUserAccess, mobileDesktopAccess, browserAccess, techTicketsCalculations, accessByMonths} from '../../../redux/selectors/portalDashboardSelectors'

import LogsByCompanyChart from './LogsByCompanyChart';
import LogsFilter from './LogsFilter';
import LogsByTimeChart from './LogsByTimeChart'
import LogsByVisitors from './LogsByVisitors';

const EmployeePortalLog = ( {userLogs, uniqueUserAccess, mobileDesktopAccess, browserAccess, techTicketsCalculations, accessByMonths, loading, accessByUsers} ) => {

    const breadcrumb = [
        { name: 'Employee Portal Metrics', link: '' }
    ];

    const [filterName, setFilterName] = useState('All')

    return (

        <Layout breadcrumb={breadcrumb}>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-12 mb-3">
                            <LogsFilter loading={loading} setFilterName={(val) => setFilterName(val)} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-lg-12">
                                    <LogsByTimeChart title={`Visits by Period`} filterName={filterName}  data={accessByMonths && accessByMonths} />
                                </div>
                                <div className="col-sm-12 col-lg-6">
                                    <LogsByCompanyChart data={userLogs && userLogs.logs_data.sort((a, b) => b.sub_total - a.sub_total)} />
                                </div>
                                <div className="col-sm-12 col-lg-6">
                                    <LogsWedgetChart chartType="bar" title={`IT Requests by Company`} data={techTicketsCalculations && techTicketsCalculations} />
                                </div>
                                <div className="col-sm-12 col-lg-6">
                                    <LogsWedgetChart chartType="doughnut" title={`Visitors by Company`} data={uniqueUserAccess && uniqueUserAccess} />
                                </div>
                                <div className="col-sm-12 col-lg-6">
                                    <LogsWedgetChart chartType="doughnut" title={`Visits by Browser`} data={browserAccess && browserAccess} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 order-first order-sm-last">
                            <div className="row">
                                <div className="col-sm-12 mb-4">
                                    <LogsByVisitors data={accessByUsers} filterName={filterName} />
                                </div>
                                <div className="col-sm-12">
                                    <LogsWedget bg="danger" icon="cil-group" title="Total Visitors" value={userLogs && userLogs.users_data.length} />
                                </div>

                                <div className="col-sm-12">
                                    <LogsWedget bg="info" icon="cil-touch-app" title="Total Visits" value={userLogs && userLogs.total_visits} />
                                </div>
                                <div className="col-sm-12">
                                    <LogsWedgetChart chartType="doughnut" title={`Visits by Device`} data={mobileDesktopAccess && mobileDesktopAccess} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

const mapStateToProps = (state) => {
    return {
        userLogs: state.userLogs.data,
        uniqueUserAccess: uniqueUserAccess(state.userLogs.data && state.userLogs.data.users_data),
        mobileDesktopAccess: mobileDesktopAccess(state.userLogs.data && state.userLogs.data.logs_data),
        browserAccess: browserAccess(state.userLogs.data && state.userLogs.data.columnsCalculated),
        techTicketsCalculations: techTicketsCalculations(state.userLogs.data && state.userLogs.data.techTickets),
        accessByMonths: accessByMonths(state.userLogs.data && state.userLogs.data.metricsTime),
        loading: state.userLogs.status,
        accessByUsers: state.userLogs.data && state.userLogs.data.users_data 
    }
}

export default  connect(mapStateToProps)(EmployeePortalLog);