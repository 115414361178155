import { Component, useEffect, useState } from "react";
import {connect} from "react-redux";

// Msal imports
import { useMsal, useAccount } from "@azure/msal-react";

//actions
import {sfAuth} from '../../redux/actions/sfAuth/sfAuth';
import {fetchServiceRequests} from '../../redux/actions/serviceRequests/fetchServiceRequests';
import {fetchAccounts} from '../../redux/actions/accounts/fetchAccounts';
import {fetchProjects} from '../../redux/actions/projects/fetchProjects';
import {fetchRequestCategories} from '../../redux/actions/requestCategories/fetchRequestCategories';
import {fetchAllUsers} from '../../redux/actions/users/fetchAllUsers';
import {fetchServiceRequestAttachments} from '../../redux/actions/serviceRequestAttachments/fetchServiceRequestAttachments';
import {fetchBusinessUnits} from '../../redux/actions/businessUnits/fetchBusinessUnits';
import {fetchContacts} from '../../redux/actions/contacts/fetchContacts';
import {fetchEmpTours} from '../../redux/actions/sfAuth/fetchEmpTours';
import {fetchNewsEvents} from '../../redux/actions/newsEvents/fetchNewsEvents';
import {fetchWow} from '../../redux/actions/wow/fetchWow';
import {fetchEmployeePortalLogs} from '../../redux/actions/employeePortalLogs/fetchEmployeePortalLogs';
import {updateAppReloadTime} from '../../redux/actions/settings/updateAppSettings';



import {Redirect, Link} from 'react-router-dom';
import SignOutButtonSecondary from '../auth/SignOutButtonSecondary'


const DataLoader = (props) => {
    const { accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [error, setError] = useState(null)
    const {isAuthenticated, user, dispatch} = props.sfAuth;

    const lastLocation = (props.location.state && props.location.state.referrer) ? props.location.state.referrer : '/';



    useEffect(() => {
        props.dispatch(sfAuth({email: account.username}));
        if(isAuthenticated) {
            props.dispatch(updateAppReloadTime(new Date()));
            props.dispatch(fetchEmpTours());
            props.dispatch(fetchBusinessUnits());
            props.dispatch(fetchServiceRequests(user.Access));
            props.dispatch(fetchServiceRequestAttachments());
            props.dispatch(fetchAccounts());
            props.dispatch(fetchProjects());
            props.dispatch(fetchRequestCategories());
            props.dispatch(fetchAllUsers());
            props.dispatch(fetchContacts());
            props.dispatch(fetchNewsEvents());
            props.dispatch(fetchEmployeePortalLogs());
            props.dispatch(fetchWow());
        }
    }, [isAuthenticated]);


    if (
        props.sfAuth.isAuthenticated &&
        props.techTickets.status === 'loaded' &&
        props.accounts.status === 'loaded' &&
        props.projects.status === 'loaded' &&
        props.users.status === 'loaded' &&
        props.requestCategories.status === 'loaded' &&
        props.serviceRequestAttachments.status === 'loaded' &&
        props.contacts.status === 'loaded' &&
        props.wallOfWow.status === 'loaded' &&
        props.userLogs.status === 'loaded'
    ) {
        return (
            <Redirect to="/" />
        )
    }

    if(!props.sfAuth.isAuthenticated && props.sfAuth.status === "Error") {
        return (
        <div className="align-items-center vertical-center" style={{width: '100%'}}>
            <div className="container text-center" style={{maxWidth: '400px'}}>
                <div className="mb-2">We could not find you on Salesforce, Contact Administrator.</div>
                <div><SignOutButtonSecondary /></div>
            </div>
        </div>
        )
    }

    // if(error) {
    //     return (
    //     <div className="align-items-center vertical-center" style={{width: '100%'}}>
    //         <div className="container text-center" style={{maxWidth: '400px'}}>
    //             <div>{error}</div>
    //             <div><SignInButton /></div>
    //         </div>
    //     </div>
    //     )
    // }

    return (
        <div className="align-items-center vertical-center" style={{width: '100%'}}>
            <div className="container text-center" >
                <div className="mb-3">Loading user information...</div>
                <div className="spinner-border" style={{width: '3rem', height: '3rem'}}>
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        sfAuth: state.sfAuth,
        techTickets: state.serviceRequests,
        accounts: state.accounts,
        projects: state.projects,
        users: state.users,
        requestCategories: state.requestCategories,
        serviceRequestAttachments: state.serviceRequestAttachments,
        contacts: state.contacts,
        wallOfWow: state.wallOfWow,
        userLogs: state.userLogs,
    }
}

export default connect(mapStateToProps)(DataLoader)
