import {ActionTypes} from '../actions/actionTypes';

const initialState = {
    dataLoaded: null,
    status: null,
    error: null,
    data: [],
    recordId: null
}

export default (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.NEWS_EVENTS_RESET_STATUS:
            return {...state, status: null, error: null, recordId: null}
        case ActionTypes.SAVE_NEWS_EVENTS_START:
            return {...state, status: 'saving', error: null}
        case ActionTypes.SAVE_NEWS_EVENTS_ERROR:
            return {...state, error: action.error, status: 'error'}
        case ActionTypes.SAVE_NEWS_EVENTS_SUCCESS:
            return {...state, data: [...state.data, action.event], status: 'saved', recordId: action.event.Id, error: null}
        case ActionTypes.FETCH_NEWS_EVENTS_START:
            return {...state, status: 'loading', dataLoaded: false}
        case ActionTypes.FETCH_NEWS_EVENTS_SUCCESS:
            return {...state, data: [...action.payload], status: 'loaded', dataLoaded: true}
        case ActionTypes.FETCH_NEWS_EVENTS_ERROR:
            return {...state, error: action.error, status: 'error', dataLoaded: false}
        case ActionTypes.UPDATE_NEWS_EVENTS_START:
            return {...state, status: 'updating', error: null}
        case ActionTypes.UPDATE_NEWS_EVENTS_ERROR:
            return {...state, error: action.error, status: 'error'}
        case ActionTypes.UPDATE_NEWS_EVENTS_SUCCESS:
            return {...state, data: state.data.map((request) => {
                if(request.Id === action.id) {
                    return {
                        ...request,
                        ...action.updates
                    }
                } else {
                    return request;
                }
            }), status: 'updated', error: null};
        case ActionTypes.UPLOAD_NEWS_EVENTS_ATTACHMENT_START:
            return {...state, status: 'uploading', error: null}
        case ActionTypes.UPLOAD_NEWS_EVENTS_ATTACHMENT_ERROR:
            return {...state, error: action.error, status: 'error'}
        case ActionTypes.UPLOAD_NEWS_EVENTS_ATTACHMENT_SUCCESS:
            return {...state, data: state.data.map((request) => {
                if(request.Id === action.id) {
                    return {
                        ...request,
                        ...action.updates
                    }
                } else {
                    return request;
                }
            }), status: 'uploaded', error: null};
        case ActionTypes.DELETE_NEWS_EVENTS_ATTACHMENT_START:
            return {...state, status: 'deleting', error: null}
        case ActionTypes.DELETE_NEWS_EVENTS_ATTACHMENT_ERROR:
            return {...state, error: action.error, status: 'error'}
        case ActionTypes.DELETE_NEWS_EVENTS_ATTACHMENT_SUCCESS:
            return {...state,
                data: state.data.map((event) => {
                if(event.Id === action.eventId) {
                    return {
                        ...event,
                        ImageUrl: null,
                        Attachments: {
                            records: event.Attachments.records.filter((rec) => rec.Id !== action.Id)
                        }
                    }
                    
                } else {
                    return event;
                }
            }), status: 'deleted', error: null}
        case ActionTypes.DELETE_NEWS_EVENTS_START:
            return {...state, status: 'deleting', error: null}
        case ActionTypes.DELETE_NEWS_EVENTS_ERROR:
            return {...state, error: action.error, status: 'error'}
        case ActionTypes.DELETE_NEWS_EVENTS_SUCCESS:
            return {...state, data: state.data.filter(({Id}) => Id !== action.Id), status: 'deleted', error: null}
        default:
            return state;
    }
}