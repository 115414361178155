import React, {useState, useEffect} from 'react';
import {Modal, Button} from 'react-bootstrap';
import moment from 'moment';
import download from 'js-file-download';
import axios from 'axios';
import FormsPDFViewer from './FormsPDFViewer';


const FormsPDFItem = ({pdf}) => {
    const [show, setShow] = useState(false);
    const [error, setError] = useState('');

    const [fileData, setFileData] = useState(null);
    const [downloading, setDownloading] = useState(null);


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const downloadAttachment = async () => {
        setDownloading(true);
        try {
            const res = await axios.get(`/serviceRequest/attachment/download`, {
                params: { id: pdf.Id, name: pdf.Name },
                responseType: 'arraybuffer'
            })
    
            if (res.status === 200) {
                setDownloading(false);
                download(res.data, pdf.Name);
            }
    
        } catch (error) {
            setDownloading(false);
            setError(error.message)
        }
    }

    const viewAttachment = async () => {
        setDownloading(true);
        try {
            const res = await axios.get(`/serviceRequest/attachment/download`, {
                params: { id: pdf.Id, name: pdf.Name },
                responseType: 'arraybuffer'
            })

            if (res.status === 200) {

                const blob = new Blob([res.data]);
                const url = URL.createObjectURL(blob);
                setFileData(url)
                setDownloading(false)
            }
        } catch (error) {
            setDownloading(false)
            setError(error.message)
            setError(error.message)
        }
    }

    const handleViewPDF = async () => {
        await handleShow();
        await viewAttachment();
    }
    


    const renderModal = () => {
        return (
            <Modal size="lg" show={show} onHide={handleClose}>
                <Modal.Header closeButton style={{borderBottom: '0 none'}}>
                    <Modal.Title style={{margin: '0 auto'}}></Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="card">
                        <div className="card-body">
                            {pdf && <div className="p-2 mb-3 bg-light text-dark d-flex justify-content-between">
                                <div className="font-weight-bold">{pdf.Name}</div>
                                <div>{moment(pdf.CreatedDate).format('LL')}</div>
                            </div>}
                            {pdf && pdf.Description__c && <div>{pdf.Description__c}</div>}
                            <div className="mx-3"></div>
                            {downloading ? <div className="text-center">Downloading...</div> : <FormsPDFViewer file={fileData} />}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }

    

    return (
        <>
            <div className="row">
                <div className="col" style={{paddingRight: 0}}>
                    <button onClick={handleViewPDF} className="btn btn-sm btn-white btn-block m-0" style={{borderRadius: '0 0 0 0.25em'}}>View PDF</button>
                </div>
                <div className="col" style={{paddingLeft: 0}}>
                    <button onClick={downloadAttachment} disabled={downloading} className="btn btn-sm btn-light btn-block m-0" style={{borderRadius: '0 0 0.25em 0'}}>
                        {downloading ? <><span className="spinner-border spinner-border-sm"></span> Downloading</> : <><i className="fas fa-download"></i> Download</>}
                    </button>
                </div>
            </div>

            {show && renderModal()}

        </>
    );
}

export default FormsPDFItem;