import React, {useState, useEffect} from 'react'
import moment from 'moment';

const handleIcons = (ContentType) => {
    if (ContentType === 'application/pdf') return 'fa-file-pdf text-pdf'
    else if (ContentType === 'application/msword' || ContentType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')
        return 'fa-file-word text-primary'
    else if (ContentType === 'application/vnd.ms-excel' || ContentType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') 
        return 'fa-file-excel text-success'
    else if(ContentType === "application/vnd.openxmlformats-officedocument.presentationml.presentation")
        return 'fa-file-powerpoint text-powerpoint'
    else return 'fa-file-alt text-dark'
}

const HelpDocAttachmentItem = ({attachment, download, viewAttachment}) => {
    const [downloading, setDownloading] = useState(false);

    const handleDownload = async () => {
        await setDownloading(true)
        await download(attachment);
        setDownloading(false)
    }

    const handleView = async () => {
        await viewAttachment(attachment);
    }

    return (
        <li class="list-group-item d-flex justify-content-between align-items-center">
                <span>
                    <i className={`fa ${handleIcons(attachment.ContentType)} mr-3`} style={{fontSize: '1.2rem'}}></i>
                    <a href="#0" onClick={attachment.ContentType === 'application/pdf' ? handleView : handleDownload}>{attachment.Name}</a>
                    <span className="small ml-3 badge badge-secondary badge-pill">{moment(attachment.CreatedDate).format('LL')}</span>
                </span>
                
                <span>
                    <button onClick={handleDownload} disabled={downloading} className="btn btn-sm btn-primary">
                        {downloading ? <><span className="spinner-border spinner-border-sm"></span> Downloading</> : 'Download'}
                    </button>
                </span>
            </li>
    )
}


export default HelpDocAttachmentItem;