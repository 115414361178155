import React, {useState} from 'react'
import {Modal, Button} from 'react-bootstrap'
import {connect} from 'react-redux';
// import AboutMeForm from './AboutMeForm';
import {updateQuickLink} from '../../redux/actions/quickLinks/updateQuickLink';
import {quickLinksResetStatus} from '../../redux/actions/quickLinks/resetStatus';
import QuickLinksForm from './QuickLinksForm';
import Dropdown from "react-bootstrap/Dropdown";
import {deleteConfirmation, deleteConfirmed, deleteError} from '../sweetAlerts/deleteAlerts'
import {deleteQuickLink} from '../../redux/actions/quickLinks/deleteQuickLink';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href="#0"
      ref={ref}
      onClick={e => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      <i className="c-icon c-icon-md fas fa-ellipsis-v" style={{color: '#C8C8C8'}}></i>
    </a>
));


const EditQuickLink = ({link, dispatch, status, error, user}) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const handleDelete = async (Id) => {
        const willDelete = await deleteConfirmation();

        if(willDelete) {
            dispatch(deleteQuickLink({Id})).then(() => {
                dispatch(quickLinksResetStatus())
            })
        }
    }


    const renderModal = () => {
        return (
            <Modal show={show} onHide={handleClose} size="lg" backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title style={{margin: '0 auto'}}>Edit ({link.Label__c})</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <QuickLinksForm 
                        user={user}
                        onSubmit={(data) => {
                            dispatch(updateQuickLink(link.Id, data))
                        }}
                        resetStatus={() => {
                            dispatch(quickLinksResetStatus())
                        }}
                        error={error}
                        status={status}
                        closeModal={handleClose}
                        link={link}
                         />
                    
                </Modal.Body>
            </Modal>
        )
    }

    return (
        <>
            <Dropdown>
                <Dropdown.Toggle as={CustomToggle} />
                <Dropdown.Menu size="sm" title="">
                <Dropdown.Item onClick={handleShow} disabled={link.Corporate_Link__c}>Edit</Dropdown.Item>
                <Dropdown.Item onClick={() => handleDelete(link.Id)} disabled={link.Corporate_Link__c}>Delete</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            {show && renderModal()}
        </>
    )
}



const mapStateToProps = (state, props) => {
    return {
        user: state.sfAuth.user,
        status: state.quickLinks.status,
        error: state.quickLinks.error,
        
    }
}

export default connect(mapStateToProps)(EditQuickLink);