import {ActionTypes} from '../actions/actionTypes';

const initialState = {
    dataLoaded: null,
    status: null,
    error: null,
    data: []
}

const employeeForecasts = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.EMPLOYEE_FORECAST_RESET_STATUS:
            return {...state, status: null, error: null, recordId: null}

        case ActionTypes.FETCH_EMPLOYEE_FORECASTS_START:
            return {...state, status: 'loading', dataLoaded: false}

        case ActionTypes.FETCH_EMPLOYEE_FORECASTS_SUCCESS:
            return {...state, data: [...action.payload], status: 'loaded', dataLoaded: true}

        case ActionTypes.FETCH_EMPLOYEE_FORECASTS_ERROR:
            return {...state, error: action.error, status: 'error', dataLoaded: false}

        case ActionTypes.SAVE_EMPLOYEE_FORECAST_START:
            return {...state, status: 'saving', error: null}

        case ActionTypes.SAVE_EMPLOYEE_FORECAST_ERROR:
            return {...state, error: action.error, status: 'error'}

        case ActionTypes.SAVE_EMPLOYEE_FORECAST_SUCCESS:
            return {...state, data: [...state.data, action.payload], status: 'saved', recordId: action.payload.Id, error: null}

        case ActionTypes.UPDATE_EMPLOYEE_FORECAST_START:
            return {...state, status: 'updating', error: null}

        case ActionTypes.UPDATE_EMPLOYEE_FORECAST_ERROR:
            return {...state, error: action.error, status: 'error'}

        case ActionTypes.UPDATE_EMPLOYEE_FORECAST_SUCCESS:
            return {...state, data: state.data.map((forecast) => {
                if(forecast.Id === action.id) {
                    return {
                        ...forecast,
                        ...action.updates
                    }
                } else {
                    return forecast;
                }
            }), status: 'updated', error: null};
        case ActionTypes.DELETE_EMPLOYEE_FORECAST_START:
            return {...state, status: 'deleting', error: null}

        case ActionTypes.DELETE_EMPLOYEE_FORECAST_ERROR:
            return {...state, error: action.error, status: 'error'}
            
        case ActionTypes.DELETE_EMPLOYEE_FORECAST_SUCCESS:
            return {...state, data: state.data.filter(({Id}) => Id !== action.Id), status: 'deleted', error: null}
        default:
            return state;
    }
}


export default employeeForecasts;