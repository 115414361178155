import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Home } from "./pages/Home";

// MSAL imports
import { MsalProvider } from "@azure/msal-react";
import SignInSignOutButton from "./components/auth/SignInSignOutButton";
import DataLoader from "./components/dataloader/DataLoader";
import Login from "./components/auth/Login";
import Dashboard from "./components/dashboard/Dashboard";
import PrivateRoute from './components/auth/PrivateRoute'
import MiniFormik from './components/serviceRequests/Formik';
import NewServiceRequest from './components/serviceRequests/NewServiceRequest';
import EditServiceRequest from './components/serviceRequests/EditServiceRequest';
import ServiceRequestItem from './components/serviceRequests/ServiceRequestItem';
import ServiceRequests from "./components/serviceRequests/ServiceRequests";

import {createBrowserHistory} from "history";
import {LastLocationProvider} from 'react-router-last-location';
import Profile from "./components/user/Profile";
import EmployeeDirectory from "./components/employees/EmployeeDirectory";
import NewsEventsList from "./components/newsEvents/NewsEventsList";
import GetUserInformation from "./components/auth/GetUserInformation";
import HelpDocs from "./components/helpDocs/HelpDocs";
import NotificationsList from "./components/notifications/NotificationsList";
import CovidProtocols from "./components/covidProtocols/CovidProtocols";
import EmployeeForecasts from "./components/employeeForecast/EmployeeForecasts";
import EmployeeForecast from "./components/employeeForecast/EmployeeForecast";
import NewEmployeeForecast from "./components/employeeForecast/NewEmployeeForecast";
import EditEmployeeForecast from "./components/employeeForecast/EditEmployeeForecast";
import NotFound from "./components/NotFound";
import NewsEventsItem from "./components/newsEvents/NewsEventsItem";
import HelpDocsItem from "./components/helpDocs/HelpDocsItem";
import CareersList from "./components/careers/CareersList";
import Forms from "./components/forms/Forms";
import AttachmentForm from "./components/serviceRequests/AttachmentForm";
import CorporateDashboard from "./components/performanceDashboards/corporateDashboard/CorporateDashboard";
import PeopleDashboard from "./components/performanceDashboards/peopleDashboard/PeopleDashboard";
import MyDashboard from "./components/performanceDashboards/myDashboard/MyDashboard";
import EmployeePortalLogs from "./components/performanceDashboards/portalDashboard/EmployeePortalLogs";
import OrgCharts from "./components/orgCharts/OrgCharts";

export const history = createBrowserHistory();

function App({pca}) {
  return (
    <Router history={history}>
      <LastLocationProvider>
        <MsalProvider instance={pca}>
          {/* <SignInSignOutButton />
          <center>Welcome to the Microsoft Authentication Library For React Quickstart</center> */}
          <Pages />
        </MsalProvider>
      </LastLocationProvider>
    </Router>
  );
}


function Pages() {
  return (
    <Switch>

      <Route path="/getUserInformation"><GetUserInformation /></Route>
      <Route exact path="/" component={Dashboard}/>
      <Route path="/login" component={Login} />
      <Route path="/dataloader" component={DataLoader} />
      <Route path="/profile" component={Profile} />
      <Route exact path="/serviceRequests" component={ServiceRequests} />
      <Route path="/serviceRequestTypes/:type" component={ServiceRequests} />
      <Route path="/serviceRequests/new" component={NewServiceRequest} />
      <Route path="/serviceRequests/edit/:id" component={EditServiceRequest} />
      <Route path="/serviceRequests/:id" component={ServiceRequestItem} />
      <Route path="/serviceRequest/attachments/:id" component={AttachmentForm} />
      <Route path="/employees" component={EmployeeDirectory} />
      <Route path="/account" component={MiniFormik} />
      <Route exact path="/newsEvents" component={NewsEventsList} />
      <Route path="/newsEvents/:id" component={NewsEventsItem} />
      <Route exact path="/helpDocs" component={HelpDocs} />
      <Route path="/helpDocs/:id" component={HelpDocsItem} />
      <Route path="/covidProtocols" component={CovidProtocols} />
      <Route path="/notifications" component={NotificationsList} />
      <Route exact path="/employeeForecasts" component={EmployeeForecasts} />
      <Route path="/employeeForecasts/new" component={NewEmployeeForecast} />
      <Route path="/employeeForecasts/edit/:id" component={EditEmployeeForecast} />
      <Route path="/employeeForecasts/:id" component={EmployeeForecast} />
      <Route path="/careers" component={CareersList} />
      <Route path="/forms" component={Forms} />
      <Route path="/corporateMetrics" component={CorporateDashboard} />
      <Route path="/peopleMetrics" component={PeopleDashboard} />
      <Route path="/my-time-off" component={MyDashboard} />
      <Route path="/EmployeePortalLogs" component={EmployeePortalLogs} />
      <Route path="/org-charts" component={OrgCharts} />



      {/* <Route path="/getUserInformation"><GetUserInformation /></Route>
      <Route path="/login" component={Login} />
      <PrivateRoute exact path="/" component={Dashboard}/>
      <PrivateRoute path="/dataloader" component={DataLoader} />
      <PrivateRoute path="/profile" component={Profile} />
      <PrivateRoute exact path="/serviceRequests" component={ServiceRequests} />
      <PrivateRoute path="/serviceRequestTypes/:type" component={ServiceRequests} />
      <PrivateRoute path="/serviceRequests/new" component={NewServiceRequest} />
      <PrivateRoute path="/serviceRequests/edit/:id" component={EditServiceRequest} />
      <PrivateRoute path="/serviceRequests/:id" component={ServiceRequestItem} />
      <PrivateRoute path="/employees" component={EmployeeDirectory} />
      <PrivateRoute path="/account" component={MiniFormik} />
      <PrivateRoute path="/newsEvents" component={NewsEventsList} /> */}
      <Route path="*" component={NotFound} />
    </Switch>
  )
}

export default App;
