import LogsByVisitorsModal from "./LogsByVisitorsModal"


const LogsByVisitors = ({data, filterName}) => {

    return (
        <div className="list-group bg-info text-white">
            <div className="list-group-item text-center h5 mb-0">Top 3 Visitors <span className="h6 text-muted">({filterName})</span></div>
            {data && data.sort((a, b) => b.expr0 - a.expr0).slice(0, 3).map((user) => (
                <div className="list-group-item d-flex justify-content-between align-items-start">
                    <div className="py-0">
                        <div>{user.Name}</div>
                        <div className="text-muted small">{user.SDI_Company__c}</div>
                    </div>
                    <span className="badge bg-white rounded-pill text-info">{user.expr0}</span>
                </div>
            ))}
            <div className="list-group-item text-center py-0">
                <LogsByVisitorsModal people={data} />
            </div>
        </div>
    )
}


export default LogsByVisitors