import React,{useState, useEffect} from 'react';
import {EditorState, convertToRaw, ContentState, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML } from 'draft-convert';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'draft-js/dist/Draft.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import TooltipIcon from './TooltipIcon';
import axios from 'axios';


import { useField, useFormikContext } from 'formik';

const RichTextEditor = ({label, tooltip, tooltipText, isRequired, imageUpload, ...props}) => {
    const [field, meta] = useField(props);
    const { setFieldValue } = useFormikContext();
    const [editorState, setEditorState] = useState();

    // New
    const [uploadedImages, setUploadedImages] = useState([]);

    const uploadImageCallBack = (file) => {
        // let images = uploadedImages;
    
        // const imageObject = {
        //   file: file,
        //   localSrc: URL.createObjectURL(file),
        // }
    
        // images.push(imageObject);
    
        // setUploadedImages(images);
        
        // return new Promise(
        //   (resolve, reject) => {
        //     resolve({ data: { link: imageObject.localSrc } });
        //   }
        // );



        // return new Promise(
        //     (resolve, reject) => {
        //         if (file) {
        //             let reader = new FileReader();
        //             reader.onload = (e) => {
        //                 resolve({ data: { link: e.target.result } })
        //             };
        //             reader.readAsDataURL(file);
        //         }
        //     }
        // );

        return new Promise((resolve, reject) => {
            const data = new FormData();
            data.append("storyImage", file);

            axios.post('/fileupload', data, 
            {
                headers: { 'Content-Type': 'multipart/form-data' }
            }).then(responseImage => {
                console.log(responseImage);
                resolve({ data: { link: responseImage.data.content.ContentDownloadUrl } });
            })
        });
    }

    useEffect(() => {
        const contentBlock = htmlToDraft(field.value || '');

        if(contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            setEditorState(editorState);
        } else {
            setEditorState(EditorState.createEmpty());
        }
    }, [])

    

    const onEditorStateChange = async (state) => {
        await setEditorState(state);
        const currentContentAsHTML = draftToHtml(convertToRaw(state.getCurrentContent()));
        setFieldValue(field.name, currentContentAsHTML);
    }

    const options = ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 
    'link', 'embedded', 'emoji', 'remove', 'history'];

    if(imageUpload) options.push('image')


    return (
        <div className={`form-group ${isRequired && 'required'}`}>
            <label className="control-label font-weight-bold mb-1" htmlFor={props.id || props.name}>{label}{` `}{tooltip && <TooltipIcon text={tooltipText} id={`${props.id}-tooltip`} />}</label>
            <Editor 
                editorState={editorState}
                onEditorStateChange={onEditorStateChange}
                wrapperClassName={meta.touched && meta.error ? "wrapper-invalid-class" : "wrapper-class"}
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                toolbar={{
                    options: options,
                    image: { 
                        uploadCallback: uploadImageCallBack, 
                        alt: { present: true, mandatory: false },
                        uploadEnabled: true,
                        previewImage: true,
                        alignmentEnabled: true,
                        urlEnabled: false,
                        inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                        defaultSize: {
                            height: 'auto',
                            width: '200',
                       },
                    },
                  }} 
                  />
            {meta.touched && meta.error ? (
                <div className="invalid-feedback" style={{display: 'block'}}>{meta.error}</div>
            ) : null} 
        </div>
    )
}

export default RichTextEditor;





// import React, { Component } from 'react';
// import { convertFromRaw, EditorState } from 'draft-js';

// import Editor, { composeDecorators } from '@draft-js-plugins/editor';

// import createImagePlugin from '@draft-js-plugins/image';

// import createAlignmentPlugin from '@draft-js-plugins/alignment';

// import createFocusPlugin from '@draft-js-plugins/focus';

// import createResizeablePlugin from '@draft-js-plugins/resizeable';

// import createBlockDndPlugin from '@draft-js-plugins/drag-n-drop';

// import createDragNDropUploadPlugin from '@draft-js-plugins/drag-n-drop-upload';
// // import editorStyles from './editorStyles.module.css';
// // import mockUpload from './mockUpload';

// const focusPlugin = createFocusPlugin();
// const resizeablePlugin = createResizeablePlugin();
// const blockDndPlugin = createBlockDndPlugin();
// const alignmentPlugin = createAlignmentPlugin();
// const { AlignmentTool } = alignmentPlugin;

// const decorator = composeDecorators(
//   resizeablePlugin.decorator,
//   alignmentPlugin.decorator,
//   focusPlugin.decorator,
//   blockDndPlugin.decorator
// );
// const imagePlugin = createImagePlugin({ decorator });

// const dragNDropFileUploadPlugin = createDragNDropUploadPlugin({
// //   handleUpload: mockUpload,
//   addImage: imagePlugin.addImage,
// });

// const plugins = [
//   dragNDropFileUploadPlugin,
//   blockDndPlugin,
//   focusPlugin,
//   alignmentPlugin,
//   resizeablePlugin,
//   imagePlugin,
// ];

// /* eslint-disable */
// const initialState = {
//   entityMap: {
//     0: {
//       type: 'IMAGE',
//       mutability: 'IMMUTABLE',
//       data: {
//         src: '/images/canada-landscape-small.jpg',
//       },
//     },
//   },
//   blocks: [
//     {
//       key: '9gm3s',
//       text:
//         'You can have images in your text field. This is a very rudimentary example, but you can enhance the image plugin with resizing, focus or alignment plugins.',
//       type: 'unstyled',
//       depth: 0,
//       inlineStyleRanges: [],
//       entityRanges: [],
//       data: {},
//     },
//     {
//       key: 'ov7r',
//       text: ' ',
//       type: 'atomic',
//       depth: 0,
//       inlineStyleRanges: [],
//       entityRanges: [
//         {
//           offset: 0,
//           length: 1,
//           key: 0,
//         },
//       ],
//       data: {},
//     },
//     {
//       key: 'e23a8',
//       text: 'See advanced examples further down …',
//       type: 'unstyled',
//       depth: 0,
//       inlineStyleRanges: [],
//       entityRanges: [],
//       data: {},
//     },
//   ],
// };
// /* eslint-enable */

// export default class CustomImageEditor extends Component {
//   state = {
//     editorState: EditorState.createWithContent(convertFromRaw(initialState)),
//   };

//   onChange = (editorState) => {
//     this.setState({
//       editorState,
//     });
//   };

//   focus = () => {
//     this.editor.focus();
//   };

//   render() {
//     return (
//       <div>
//         {/* <div className={editorStyles.editor} onClick={this.focus}> */}
//           <Editor
//             editorState={this.state.editorState}
//             onChange={this.onChange}
//             plugins={plugins}
//             ref={(element) => {
//               this.editor = element;
//             }}
//           />
//           <AlignmentTool />
//         {/* </div> */}
//       </div>
//     );
//   }
// }