import React from 'react';
import {connect} from 'react-redux';
import {deleteConfirmation, deleteConfirmed, deleteError} from '../sweetAlerts/deleteAlerts'
import {deleteServiceRequest} from '../../redux/actions/serviceRequests/deleteServiceRequest';
import {serviceRequestResetStatus} from '../../redux/actions/serviceRequests/resetStatus';

const DeleteServiceRequestLink = (props) => {
    const handleDelete = async (Id) => {
        const willDelete = await deleteConfirmation();

        if(willDelete) {
            props.dispatch(deleteServiceRequest({Id})).then(() => {
                props.dispatch(serviceRequestResetStatus())
            })
        }
    }

    // if(props.status == 'deleted') {
    //     deleteConfirmed();
    //     props.dispatch(serviceRequestResetStatus())
    // } 
    
    // if(props.status == 'error') deleteError(props.error);

    

    return (
        <a className={`btn btn-link text-danger m-0 p-0 ${props.request.Locked && 'disabled'}`} onClick={() => handleDelete(props.request.Id)}>
            <i className="fas fa-trash-alt"></i>
        </a>
    )
}


export default connect(null)(DeleteServiceRequestLink);