import {ActionTypes} from '../actionTypes';
import axios from 'axios';


export const deleteServiceRequestStart = () => ({type: ActionTypes.DELETE_SERVICE_REQUEST_START})
export const deleteServiceRequestSuccess = (Id) => ({type: ActionTypes.DELETE_SERVICE_REQUEST_SUCCESS, Id})
export const deleteServiceRequestError = (error) => ({type: ActionTypes.DELETE_SERVICE_REQUEST_ERROR, error})


export const deleteServiceRequest = ({Id}) => {
    return async (dispatch) => {
        try {
            dispatch(deleteServiceRequestStart())
            const response = await axios.delete(`/serviceRequest/delete/${Id}`)

            if (response.status == 200) {
                dispatch(deleteServiceRequestSuccess(response.data.id))
            }

        } catch (err) {
            dispatch(deleteServiceRequestError(err))
        }
    }
}