import {ActionTypes} from '../actions/actionTypes';

const initialState = {
    dataLoaded: null,
    status: null,
    error: null,
    data: [],
    recordId: null
}

export default (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SERVICE_REQUEST_RESET_STATUS:
            return {...state, status: null, error: null}
        case ActionTypes.SAVE_SERVICE_REQUEST_START:
            return {...state, status: 'saving', error: null}
        case ActionTypes.SAVE_SERVICE_REQUEST_ERROR:
            return {...state, error: action.error, status: 'error'}
        case ActionTypes.SAVE_SERVICE_REQUEST_SUCCESS:
            return {...state, data: [...state.data, action.request], status: 'saved', recordId: action.request.Id, error: null}
        case ActionTypes.FETCH_SERVICE_REQUESTS_START:
            return {...state, status: 'loading', error: null, dataLoaded: false}
        case ActionTypes.FETCH_SERVICE_REQUESTS_SUCCESS:
            return {...state, data: [...action.tickets], status: 'loaded', error: null, dataLoaded: true}
        case ActionTypes.FETCH_SERVICE_REQUESTS_ERROR:
            return {...state, error: action.error, status: 'error', dataLoaded: false}
        case ActionTypes.DELETE_SERVICE_REQUEST_START:
            return {...state, status: 'deleting', error: null}
        case ActionTypes.DELETE_SERVICE_REQUEST_ERROR:
            return {...state, error: action.error, status: 'error'}
        case ActionTypes.DELETE_SERVICE_REQUEST_SUCCESS:
            return {...state, data: state.data.filter(({Id}) => Id !== action.Id), status: 'deleted', error: null}
        case ActionTypes.UPDATE_SERVICE_REQUEST_START:
            return {...state, status: 'updating', error: null}
        case ActionTypes.UPDATE_SERVICE_REQUEST_ERROR:
            return {...state, error: action.error, status: 'error'}
        case ActionTypes.UPDATE_SERVICE_REQUEST_SUCCESS:
            return {...state, data: state.data.map((request) => {
                if(request.Id === action.id) {
                    return {
                        ...request,
                        ...action.updates
                    }
                } else {
                    return request;
                }
            }), status: 'updated', error: null};
        default:
            return state;
    }
}