import moment from "moment"
import React, {useEffect, useState} from "react";
import CareersDashboardPagination from "./CareersDashboardPagination";

const CareersDashboardList = ({careers}) => {
    const [records, setRecords] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(3);

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = records.slice(indexOfFirstRecord, indexOfLastRecord);
    const setPage = (pageNumber) => setCurrentPage(pageNumber);

    
    useEffect(() => {
        setRecords(careers);
    }, [careers])

    return (
        <>
        <div className="list-group mb-2">
            {currentRecords && currentRecords.map((career) => <CareersDashboardItem key={career.Id} career={career} />)}
        </div>
        <CareersDashboardPagination currentPage={currentPage} recordsPerPage={recordsPerPage} totalRecords={records.length} setPage={setPage} />
        </>
    )
}

export default CareersDashboardList


const CareersDashboardItem = ({career}) => {
    return (
        <a href={career.Job_Details_Url__c} alt="" target="_blank" rel="noreferrer" className="list-group-item list-group-item-action">
            <div className="d-flex w-100 justify-content-between">
                <div className="mb-1">
                    <span className="font-weight-bold">{career.Job_Title__c} </span>
                    {moment().diff(career.Date_Posted__c, 'days') <= 7 && <span className="badge badge-danger">New</span>}
                </div>
                <small>{moment(career.Date_Posted__c).format('ll')}</small>
            </div>
            <p className="mb-1">
                {career.City__c && career.City__c+', '}
                {career.State__c && career.State__c+', '}
                {career.Country__c && career.Country__c}
            </p>
        </a>
    )
}