import { useState } from "react";
import {setFormsTextFilter, 
    setFormsCategoryFilter,
    setFormsSubCategoryFilter
} from '../../redux/actions/filters/formsFilters';

import { options } from "./categoryList";


const FormsFilter = ({dispatch, setCurrentPage, filters, forms}) => {
    const [subCats, setSubCats] = useState('')

    const handleSearch = (e) => {
        e.preventDefault();
        setCurrentPage(1);
        dispatch(setFormsTextFilter(e.target.value));
    }


    const handleCategoryFilter = (e) => {
        e.preventDefault();
        setSubCats(options.find((i) => i.value === e.target.value) || '')
        setCurrentPage(1);
        dispatch(setFormsCategoryFilter(e.target.value));
    }

    const handleSubCategoryFilter = (e) => {
        e.preventDefault();
        setCurrentPage(1);
        dispatch(setFormsSubCategoryFilter(e.target.value));
    }

    const clearSearch = (e) => {
        e.preventDefault();
        setCurrentPage(1);
        dispatch(setFormsTextFilter(''));
        dispatch(setFormsCategoryFilter(''));
        dispatch(setFormsSubCategoryFilter(''));
    }

    return (
    <>
        <div className="bg-light px-2 px-sm-5 pt-3">
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <label className="text-dark font-weight-bold mb-1">Search</label>
                        <input 
                            type="text" 
                            className="form-control" 
                            onChange={handleSearch} 
                            value={filters.text}
                            placeholder="Search by title or type" />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label className="text-dark font-weight-bold mb-1">Filter by Categories</label>
                        <div className="row">
                            <div className="col">
                                <select className="form-control" onChange={handleCategoryFilter} value={filters.category}>
                                    <option value={''}>Category...</option>
                                    {options.map((option) => <option value={option.value} key={option.value}>{option.value}</option>)}
                                </select>
                            </div>
                            <div className="col">
                                <select 
                                    className="form-control" 
                                    disabled={subCats === '' || (subCats && subCats.sub_categories.length === 0)}
                                    onChange={handleSubCategoryFilter}
                                    value={filters.subCategory}>
                                    <option value={''}>Subcategory...</option>
                                    {subCats && subCats.sub_categories ? 
                                        subCats.sub_categories.map((j) => <option value={j.value} key={j.value}>{j.value}</option>) :
                                        ''}
                                    
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
        <div className="bg-light text-dark px-2 px-sm-5 py-3 mb-4">
            <span className="font-weight-bold mr-5 d-none d-sm-inline">{forms && forms.length} documents</span>
            <span style={{borderRight: '1px solid black'}} className="mr-3 d-none d-sm-inline"></span>
            <span className="font-weight-bold mr-2">Filtered by: </span>
            {/* <span className="mr-2 font-weight-bold">Category:</span> */}
            <span className="mr-2">{filters.category}{filters.category && ','}</span>
            {/* <span className="mr-2 font-weight-bold">SubCategory:</span> */}
            <span>{filters.subCategory}</span>
            <span className="float-right"><button className="btn btn-light btn-sm m-0 text-dark border-0" onClick={clearSearch}>Clear Search</button></span>
        </div>
    </>
    )
}

export default FormsFilter
