import React, {useEffect} from 'react';
import Layout from '../layout/Layout';
import {connect} from 'react-redux';
import NotFound from '../NotFound';
import moment from 'moment';
import images from './images'
import RichTextToHTML from '../layout/RichTextToHTML';

const NewsEventsItem = ({newsEvent, ...props}) => {


    const breadcrumb = [
        { name:'News & Events', link: '/newsEvents' },
        { name: newsEvent && newsEvent.Title__c && newsEvent.Title__c, link: '' }
    ]

    const image = images.find((image) => image.title === newsEvent.Background_Image__c);

    if(!newsEvent.Id) {
        return (
            <NotFound />
        )
    }

    return (
        <Layout breadcrumb={breadcrumb}>
            <div className="card">
                {/* <div className="card-header">{newsEvent.Title__c}</div> */}
                <div className="card-body">
                    <div className="card-title h3">{newsEvent.Title__c}</div>
                    <img src={image} className="d-block w-100 " alt=""/>
                    <h4>{newsEvent && newsEvent.Brief_Description__c}</h4>
                    <p className="p-2 mb-3 bg-light text-dark">{moment(newsEvent && newsEvent.CreatedDate).format('LL')} By {(newsEvent && newsEvent.Created_By__r) && newsEvent.Created_By__r.Name}</p>
                    <RichTextToHTML html={newsEvent && newsEvent.Description__c} />
                </div>
            </div>
        </Layout>
    )
}


const mapStateToProps = (state, props) => {
    return {
        newsEvent: state.newsEvents.data.find((item) => item.Id === props.match.params.id) || {},
    }
}
export default connect(mapStateToProps)(NewsEventsItem);