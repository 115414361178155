const peopleFiltersReducerDefaultState = {
    company: [
        // "SDI Canada",
        // "SDI Doha Sports",
        // "SDI Doha",
        // "SDI",
        // "SDI Canada - Field Management",
        // "SDI USA",
        // "SDI - Field Management",
        // "SDI Canada Field - Sports",
        // "SDI Canada Field - MC",
        // "SDI Canada Field - kite",
        // "Good Things",
        // "SDI USA Mktg",
        // "Fuel Media",
        // "SDI USA - Field Management",
        // "Polite Payroll"
    ],
    department: [
        // "Field",
        // "SDI Doha",
        // "kite",
        // "C&L",
        // "Midnight Circus",
        // "HR",
        // "Sports",
        // "Operations",
        // "EX",
        // "SDI Doha Sports",
        // "GoodThings",
        // "Project Member",
        // "PM",
        // "Fuel Media",
        // "Warehouse",
        // "Finance",
        // "Executive",
        // "Creative",
        // "Fuel Media IT"
    ]
}


// eslint-disable-next-line import/no-anonymous-default-export
export default (state = peopleFiltersReducerDefaultState, action) => {
    switch (action.type) {
        case 'ADD_PEOPLE_TIME_AWAY_COMPANY_FILTER':
            return {...state, company: [...state.company, action.company]}
        case 'REMOVE_PEOPLE_TIME_AWAY_COMPANY_FILTER':
            return {...state, company: state.company.filter((co) => co !== action.company)}
        case 'ADD_PEOPLE_TIME_AWAY_DEPARTMENT_FILTER':
            return {...state, department: [...state.department, action.department]}
        case 'REMOVE_PEOPLE_TIME_AWAY_DEPARTMENT_FILTER':
            return {...state, department: state.department.filter((dep) => dep !== action.department)}
        case 'ADD_ALL_PEOPLE_TIME_AWAY_COMPANY_FILTER':
            return {...state, company: action.companies}
        case 'REMOVE_ALL_PEOPLE_TIME_AWAY_COMPANY_FILTER':
            return {...state, company: []}
        case 'ADD_ALL_PEOPLE_TIME_AWAY_DEPARTMENT_FILTER':
            return {...state, department: action.departments}
        case 'REMOVE_ALL_PEOPLE_TIME_AWAY_DEPARTMENT_FILTER':
            return {...state, department: []}
        default:
            return state;
    }
}