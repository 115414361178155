import React, {useState, useEffect} from 'react'
import {displayName, backgroundColor} from './functions';
import axios from 'axios';
import {updateContactSuccess} from '../../redux/actions/contacts/updateContact';
import {contactResetStatus} from '../../redux/actions/contacts/resetStatus';

const EmployeeDirectoryItem = ({emp, dispatch}) => {

    // useEffect(() => {
    //     const download = async () => {
    //         if(emp.pse__Salesforce_User__r && emp.pse__Salesforce_User__r.SmallPhotoUrl !== 'https://sdi--c.na147.content.force.com/profilephoto/005/T' && emp.ImageUrl === null) {
    //             const response = await axios.post(`/contacts/picture`, {url: emp.pse__Salesforce_User__r.SmallPhotoUrl});
        
    //             if (response.status === 200) {
    //                 dispatch(updateContactSuccess(emp.Id, {ImageUrl: response.data}))
    //                 dispatch(contactResetStatus())
    //             }
    //         }
    //     }

    //     download();
        
    // }, [emp])

    return (
        <>
        {emp.Photo ? 
        <div className="">
            <img className="rounded-circle border mb-2 user-image-large" src={`data:image/jpeg;base64,${emp.Photo}`} alt=""></img>
        </div>
        : 
        <div className="rounded-circle employee-profile-image border mb-2" style={{backgroundColor: backgroundColor(emp.Name)}}>
            {displayName(emp.Name)}
        </div> 
        }
        <div className="h6">{emp.Name}</div>
        <div className="small">{emp.Title}</div>
        </>
    )
}


export default EmployeeDirectoryItem;