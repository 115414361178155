import moment from 'moment';

export const backgroundColor = (name) => {
    const nameParts = name.split(" ");
    let color = '#'+nameParts[0].charCodeAt(0)+nameParts[0].charCodeAt(1)+nameParts[1].charCodeAt(0);
    while(color.length > 7) {
        color = color.slice(0, -1);
    }
    return color+'60';
}

export const displayName = (name) => {
    const nameParts = name.split(" ");
    return nameParts[0].charAt(0)+nameParts[1].charAt(0);
}


export const displayNameTable = (name) => {
    const nameParts = name.split(" ");
    return nameParts[0].charAt(0)+nameParts[1].charAt(0);
}


export const formatBirthDate = (value) => {
    const date = moment(value).calendar({
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        nextWeek: '[Next] dddd',
        lastDay: 'MMM DD',
        lastWeek: 'MMM DD',
        sameElse: 'MMM DD'
    });

    return date + " - Birthday";
}


export const formatStartDate = (value) => {
    const date = moment(value);
    const month = moment(value).month();
    const day = moment(value).date();
    let thisDate = moment().set("month", month);
    thisDate.set('date', day);


    const years = moment(thisDate).diff(date, 'years', false);

    const yearString = years === 1 ? years+' Year' : years+' Years';

    if(years === 0) {
        return ''+date.format("MMM DD")+' - Anniversary';
    }

    return date.format("MMM DD")+' - '+yearString+' Anniversary';
}


export const formatAnniversary = (value) => {
    const date = moment(value).set('year', moment().format('YYYY')).calendar({
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        nextWeek: '[Next] dddd',
        lastDay: 'MMM DD',
        lastWeek: 'MMM DD',
        sameElse: 'MMM DD'
    });
    return date + " - Anniversary"
}