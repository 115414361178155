import React, {useState} from 'react'
import {Modal} from 'react-bootstrap'
import DatatableSm from '../../layout/DatatableSm';
import {columns, defaultSorted} from './logsByVisitorsColumns'

const LogsByVisitorsModal = ({people}) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const renderModal = () => {
        return (
            <Modal size="lg" show={show} onHide={handleClose}>
                <Modal.Header closeButton style={{borderBottom: '0 none'}}>
                    <Modal.Title style={{margin: '0 auto'}}>Visitors List</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <DatatableSm
                        csvFileName={'logs_by_visitors.csv'}
                        data={people}
                        columns={columns}
                        sortBy={defaultSorted}
                    />
                </Modal.Body>
            </Modal>
        )
    }



    return (
        <>
            <a onClick={handleShow} className="btn btn-link text-white p-0 m-0" href="#0">see list</a>
            
            {show && renderModal()}

        </>
    );
}

export default LogsByVisitorsModal;