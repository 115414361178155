import React from 'react';
import {connect} from 'react-redux';
import Layout from '../layout/Layout';
import ServiceRequestForm from './ServiceRequestForm';
import {updateServiceRequest} from '../../redux/actions/serviceRequests/updateServiceRequest';
import {serviceRequestResetStatus} from '../../redux/actions/serviceRequests/resetStatus';

const EditServiceRequest = ({businessUnit, clients, vendors, projects, users, user, categories, status, error, serviceRequest, dispatch, ...props}) => {

    const breadcrumb = [
        { name:'IT Requests', link: '/serviceRequests' },
        { name: serviceRequest && serviceRequest.Name.split("-").pop(), link: `/serviceRequests/${serviceRequest && serviceRequest.Id}` },
        { name:`Edit`, link: '' }
    ]

    return (
        <Layout breadcrumb={breadcrumb}>
            <div className="card">
                <div className="card-header bg-sdi-black text-sdi-yellow">
                    Edit IT Request ({serviceRequest && serviceRequest.Name.split("-").pop()})
                </div>
                <div className="card-body">
                    <ServiceRequestForm 
                        savedRecordId={props.match.params.id}
                        // clients={clients} 
                        // vendors={vendors}
                        projects={projects} 
                        users={users} 
                        user={user}
                        businessUnit={businessUnit}
                        categories={categories}
                        onSubmit={(serviceRequest) => {
                            dispatch(updateServiceRequest( props.match.params.id, serviceRequest))
                        }}
                        resetStatus={() => {
                            dispatch(serviceRequestResetStatus())
                        }}
                        status={status}
                        error={error}
                        serviceRequest={serviceRequest} />
                </div>
            </div>
        </Layout>
    )
}


const mapStateToProps = (state, props) => {
    return {
        // clients: state.accounts.data,
        // vendors: state.accounts.data.filter((account) => account.RecordType.Name === 'Contractor/Vendor' || account.RecordType.Name === 'Contractor/Vendor US'),
        projects: state.projects.data,
        users: state.users.data,
        categories: state.requestCategories.data,
        status: state.serviceRequests.status,
        error: state.serviceRequests.error,
        user: state.sfAuth.user,
        serviceRequest: state.serviceRequests.data.find((req) => req.Id === props.match.params.id),
        businessUnit: state.businessUnits.data.find((unit) => state.sfAuth.user.BusinessUnit === unit.Name) || {}
    }
}
export default connect(mapStateToProps)(EditServiceRequest);