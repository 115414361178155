import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import ChartDataModal from './ChartDataModal';

const LogsByTimeChart = ({data, title, filterName}) => {
    return (
        <div className={`card`}>
            <div className="card-body ">
                <div className="d-flex justify-content-between">
                    <div className="h5">{title} <span className="h6 text-muted">({filterName})</span></div>
                    <small className="text-uppercase h5 text-muted">
                        <ChartDataModal data={data.data} title={title} />
                    </small>
                </div>

                <div style={{height: '25vh'}}>
                    <Chart labels={data.chartData.labels} chartData={data.chartData.data} />
                </div>
            </div>
        </div>
    )
}

export default LogsByTimeChart



const Chart = ({labels, chartData}) => {
    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    );


    const options = {
        indexAxis: 'x',
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 2,
        plugins: {
            datalabels: {
              display: true
            },
            legend: {
                display: false,
                position: 'top',
                tooltips: {
                    callbacks: {
                        label: tooltipItem => `${tooltipItem.yLabel}: ${tooltipItem.xLabel}`, 
                        title: () => null,
                    }
                }
            },
            title: {
                display: false,
                text: 'Chart.js Bar Chart',
            },
        },
    };



    const data = {
        labels,
        datasets: [
          {
            label: 'Total Visits',
            data: chartData,
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
          }
        ],
    };

    return (
        <Line options={options} data={data} />
    )
}