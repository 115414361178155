import React from "react";
import PeopleListModal from "./PeopleListModal";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import TooltipIcon from "../../formFields/TooltipIcon";

const PeopleTable = ({ data, masterEmployeeList }) => {
  return (
    <>
      <div className="float-right">
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="btn btn-sm btn-primary mb-3 shadow-sm"
          table="headcount-table"
          filename="Employee-Headcount"
          sheet="Headcount"
          buttonText="Export"
        />
      </div>
      <PeopleListModal
        people={masterEmployeeList}
        btnText={`Active Employee List (${masterEmployeeList.length})`}
        title={`Active Employee List (${masterEmployeeList.length})`}
      />

      <table
        id="headcount-table"
        className="table table-striped table-sm table-responsive-sm"
      >
        <thead>
          <tr>
            <th className="text-center">Month - Year</th>
            <th className="text-center">
              Active as of 1st{" "}
              <TooltipIcon text="1st of month" id="active-as-1st" />
            </th>
            {/* <th className="text-center">
              Net New Hire{" "}
              <TooltipIcon text="Additional headcount" id="new-hire-net" />
            </th> */}
            <th className="text-center">
              New Hires{" "}
              <TooltipIcon
                text="Field New Hires, Internal New Hire Replacements and Rehires"
                id="new-hires"
              />
            </th>
            <th className="text-center">
              Changes{" "}
              <TooltipIcon
                text="includes: promotion, merit, department change, title change, transfers, part-time to full-time, etc."
                id="changes"
              />
            </th>
            {/* <th className="text-center">
              Voluntary{" "}
              <TooltipIcon
                text="resignations, job abandonment, did not work, etc."
                id="voluntary"
              />
            </th> */}
            <th className="text-center">
              Departures{" "}
              <TooltipIcon
                text="terminations/dismissals, shortage of work, end of contract, etc."
                id="departures"
              />
            </th>
            <th className="text-center">
              Active as of EOM{" "}
              <TooltipIcon
                text="Active at the end of month"
                id="active-as-eom"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((i) => (
              <tr key={i.month}>
                <th className="text-center">{i.month}</th>
                <td className="text-center">
                  {i.active_as_of_1st.length > 0 && (
                    <PeopleListModal
                      people={i.active_as_of_1st}
                      title="Employees Active as of 1st"
                    />
                  )}
                </td>
                {/* <td className="text-center">
                  {i.new_hire_nn.length > 0 && (
                    <PeopleListModal
                      people={i.new_hire_nn}
                      title="Net New Hires"
                    />
                  )}
                </td> */}
                <td className="text-center">
                  {i.new_hire_r.length > 0 && (
                    <PeopleListModal people={i.new_hire_r} title="New Hires" />
                  )}
                </td>
                <td className="text-center">
                  {i.changes.length > 0 && (
                    <PeopleListModal
                      people={i.changes}
                      title="Employee Changes"
                    />
                  )}
                </td>
                {/* <td className="text-center">
                  {i.voluntary.length > 0 && (
                    <PeopleListModal
                      people={i.voluntary}
                      title="Employee Voluntary Leaves"
                    />
                  )}
                </td> */}
                <td className="text-center">
                  {i.involuntary.length > 0 ? (
                    <PeopleListModal
                      people={i.involuntary}
                      title="Departed Employees"
                    />
                  ) : (
                    "0"
                  )}
                </td>
                <td className="text-center">
                  {i.active_as_of_eom.length > 0 && (
                    <PeopleListModal
                      people={i.active_as_of_eom}
                      title="Employees Active as of EOM"
                    />
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
};

export default PeopleTable;
