import moment from 'moment';
import RichTextToHTML from '../layout/RichTextToHTML';

const EmployeeForecastDetail = ({employeeForecast}) => {

    const requirements = employeeForecast && employeeForecast.HW_SW_Requirements__c && employeeForecast.HW_SW_Requirements__c.split(';')
    return (
        <>
            <div className="row">
                <div className="col-6 col-md-3 mb-3">
                    <div className="text-uppercase font-weight-light">
                        Date
                    </div>
                    <div className="font-weight-bold">{moment(employeeForecast && employeeForecast.CreatedDate).format('LL')}</div>
                </div>
                <div className="col-6 col-md-3 mb-3">
                    <div className="text-uppercase font-weight-light">
                        Submitted By
                    </div>
                    <div className="font-weight-bold">{employeeForecast && employeeForecast.Submitted_By__r && employeeForecast.Submitted_By__r.Name}</div>
                </div>
                <div className="col-6 col-md-3 mb-3">
                    <div className="text-uppercase font-weight-light">
                        Job Title
                    </div>
                    <div className="font-weight-bold">{employeeForecast && employeeForecast.Job_Title__c}</div>
                </div>
                <div className="col-6 col-md-3 mb-3">
                    <div className="text-uppercase font-weight-light">
                        Division
                    </div>
                    <div className="font-weight-bold">{employeeForecast && employeeForecast.Division__c}</div>
                </div>
                <div className="col-6 col-md-3 mb-3">
                    <div className="text-uppercase font-weight-light">
                        Type
                    </div>
                    <div className="font-weight-bold">{employeeForecast && employeeForecast.Type__c}</div>
                </div>
                <div className="col-6 col-md-3 mb-3">
                    <div className="text-uppercase font-weight-light">
                        Planned Start Date
                    </div>
                    <div className="font-weight-bold">{employeeForecast && employeeForecast.Planned_Start_Date__c}</div>
                </div>
                <div className="col-6 col-md-3 mb-3">
                    <div className="text-uppercase font-weight-light">
                        Employee Name
                    </div>
                    <div className="font-weight-bold">{employeeForecast && employeeForecast.Employee_Name__c}</div>
                </div>
                <div className="col-6 col-md-3 mb-3">
                    <div className="text-uppercase font-weight-light">
                        Employee Setup Form Provided
                    </div>
                    <div className="font-weight-bold">{employeeForecast && employeeForecast.Employee_Setup_Form_Provided__c && <i className="cil-check h4"></i>}</div>
                </div>
                <div className="col-6 col-md-3 mb-3">
                    <div className="text-uppercase font-weight-light">
                        Requires Laptop
                    </div>
                    <div className="font-weight-bold">{employeeForecast && employeeForecast.Requires_Laptop__c  && <i className="cil-check h4"></i>}</div>
                </div>
                {/* <div className="col-6 col-md-3 mb-3">
                    <div className="text-uppercase font-weight-light">
                        Laptop Desired By
                    </div>
                    <div className="font-weight-bold">{employeeForecast && employeeForecast.Laptop_Desired_By__c}</div>
                </div> */}
                <div className="col-6 col-md-3 mb-3">
                    <div className="text-uppercase font-weight-light">
                        Shipping
                    </div>
                    <div className="font-weight-bold">{employeeForecast && employeeForecast.Shipping_Required__c}</div>
                </div>
                <div className="col-6 col-md-6 mb-3">
                    <div className="text-uppercase font-weight-light">
                        HW/SW Requirements	
                    </div>
                    <div className="font-weight-bold">{requirements && requirements.join(', ')}</div>
                </div>
                <div className="col-6 col-md-12 mb-3">
                    <div className="text-uppercase font-weight-light">
                        Other Important Info
                    </div>
                    <RichTextToHTML html={employeeForecast && employeeForecast.Other_Important_Info__c} />
                </div>
            </div>
        </>
    )
}

export default EmployeeForecastDetail
