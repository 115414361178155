import canadaFlag from '../../images/canada-flag.png';
import usaFlag from '../../images/usa-flag.png';
import qatarFlag from '../../images/qatar-flag.png';
import FormsPDFItem from './FormsPDFItem';


const renderIcons = (region) => {

    return (
        <>
            {(region === 'SDI Canada' || region === 'SDI Canada & USA') && <img src={canadaFlag} style={{height: '22px', marginBottom: '.2rem', padding: '.29rem'}} className="mr-1" alt="" />} 
            {(region === 'SDI USA' || region === 'SDI Canada & USA') &&  <img src={usaFlag} style={{height: '22px', marginBottom: '.2rem', padding: '.29rem'}} className="mr-1" alt="" />}
            {region === 'SDI Doha' && <img src={qatarFlag} style={{height: '22px', marginBottom: '.2rem', padding: '.29rem'}} className="mr-1" alt="" />}
            {region === 'All SDI' && <i className="fas fa-globe-americas text-primary mr-1 h6 p-1"></i>}
        </>
    )
}


const FormsItem = ({form}) => {
    return (
        <div className="col-md-6 col-lg-4" key={form.Id}>
            <div className="card">
                <div className="card-body p-3">
                    <div className="d-flex align-items-center">
                        <div className="p-2 mfe-3">
                            <i className="fa fa-file-pdf c-icon c-icon-xxl text-pdf"></i>
                        </div>
                        <div>
                            <div className="font-weight-bold small mb-2">{form.Name__c}</div>
                            <div data-tour="training-2">
                                {renderIcons(form.Region__c)}
                            </div>
                            <div className="text-muted text-uppercase font-weight-bold small">
                            </div>                                        
                        </div>
                    </div>
                </div>
                <div className="card-footer p-0">
                    <FormsPDFItem pdf={(form.Attachments && form.Attachments.totalSize > 0) && form.Attachments.records[0]} />
                </div>
            </div>
        </div>
    )
}

export default FormsItem
