export const setPeopleCompanyFilter = (company = '') => ({
    type: 'SET_PEOPLE_COMPANY_FILTER',
    company
})

export const setPeoplePayClassFilter = (payClass = '') => ({
    type: 'SET_PEOPLE_PAY_CLASS_FILTER',
    payClass
})

export const addPeoplePayClassFilter = (payClass = '') => ({
    type: 'ADD_PEOPLE_PAY_CLASS_FILTER',
    payClass
})

export const addAllPeoplePayClassFilter = (payClasses) => ({
    type: 'ADD_ALL_PEOPLE_PAY_CLASS_FILTER',
    payClasses
})

export const removeAllPeoplePayClassFilter = () => ({
    type: 'REMOVE_ALL_PEOPLE_PAY_CLASS_FILTER',
})

export const removePeoplePayClassFilter = (payClass = '') => ({
    type: 'REMOVE_PEOPLE_PAY_CLASS_FILTER',
    payClass
})

export const addPeopleCompanyFilter = (company = '') => ({
    type: 'ADD_PEOPLE_COMPANY_FILTER',
    company
})

export const removePeopleCompanyFilter = (company = '') => ({
    type: 'REMOVE_PEOPLE_COMPANY_FILTER',
    company
})

export const addAllPeopleCompanyFilter = (companies) => ({
    type: 'ADD_ALL_PEOPLE_COMPANY_FILTER',
    companies
})

export const removeAllPeopleCompanyFilter = () => ({
    type: 'REMOVE_ALL_PEOPLE_COMPANY_FILTER'
})

export const addPeopleDepartmentFilter = (department = '') => ({
    type: 'ADD_PEOPLE_DEPARTMENT_FILTER',
    department
})

export const removePeopleDepartmentFilter = (department = '') => ({
    type: 'REMOVE_PEOPLE_DEPARTMENT_FILTER',
    department
})

export const addAllPeopleDepartmentFilter = (departments) => ({
    type: 'ADD_All_PEOPLE_DEPARTMENT_FILTER',
    departments
})

export const removeAllPeopleDepartmentFilter = () => ({
    type: 'REMOVE_All_PEOPLE_DEPARTMENT_FILTER',
})