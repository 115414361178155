
export const myRequests = (requests, user) => {
    return requests
        ? requests.filter((request) => request.Created_By__c === user.Id || request.Request_For__c === user.Id).map((req) => {
            let locked = false;
            if(req.Status__c === 'Complete' || (req.Status__c.includes('Pending') && req.Status__c !== 'Pending IT Assignment' && req.Status__c !== 'Pending User Reply')) {
                locked = true;
            }

            return {...req, Locked: locked}
        })
        : []
}

export const requestsForApproval = (requests, user) => {
    return requests
        ? requests.filter((request) => 
                request.Proj_Pre_Approver__c === user.Id || 
                request.Proj_Estimate_Approver__c === user.Id ||
                request.Purch_Approver__c === user.Id ||
                request.Purch_Secondary_Approver__c === user.Id
            )
        : []
}

export const findRequest = (requests, id) => {
    const request = requests && id ? requests.find((req) => req.Id === id) : {}
    return {...request, Locked: (request && (request.Status__c === 'Complete' || (request.Status__c.includes('Pending') && request.Status__c !== 'Pending IT Assignment' && request.Status__c !== 'Pending User Reply'))) ? true : false}
}