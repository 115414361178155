import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import smallLogo from "../../../images/Logos/sdi-marketing-squarelogo.png";
import greyLogo from "../../../images/Logos/SDI_logo_grey.png";
import whiteLogo from "../../../images/Logos/SDI_logo_white.png";
import largLogo from "../../../images/Logos/DSC_Logo_on_blue_background.png";
import SignOutButton from "../../auth/SignOutButton";
import Contact from "../../contact/Contact";

const Sidebar = ({ sfUser, links, subordinates }) => {
  const [isActiveCL, setActiveCL] = useState(false);
  const [isActivePD, setActivePD] = useState(false);
  const [isActiveMM, setActiveMM] = useState(false);
  // const [access, setAccess] = useState(sfUser.Access ? sfUser.Access.split(";") : []);
  const access = sfUser.Access ? sfUser.Access.split(";") : [];

  const toggleClassCL = () => {
    setActiveCL(!isActiveCL);
  };

  const toggleClassPD = () => {
    setActivePD(!isActivePD);
  };

  const toggleClassMM = () => {
    setActiveMM(!isActiveMM);
  };

  return (
    <div
      className="c-sidebar c-sidebar-dark c-sidebar-fixed c-sidebar-lg-show"
      id="sidebar"
    >
      <div className="c-sidebar-brand d-md-down-none">
        <img src={whiteLogo} style={{ width: "40px" }} />
        {/*<div className="c-sidebar-brand-full h2">SDImktg</div>*/}
        {/* <div className="c-sidebar-brand-minimized h2">SDI</div> */}
        {/* <img className="c-sidebar-brand-minimized" width="46" height="46" src={smallLogo} /> */}
      </div>

      <div className="c-sidebar-brand d-block d-sm-block d-md-none">
        <button
          className="c-class-toggler d-lg-none mfe-auto btn btn-dark"
          style={{
            minHeight: "100%",
            width: "70px",
            backgroundColor: "transparent",
            float: "right",
          }}
          type="button"
          data-target="#sidebar"
          data-class="c-sidebar-show"
        >
          <i className="fas fa-chevron-left c-icon c-icon-lg"></i>
        </button>
      </div>

      <ul className="c-sidebar-nav">
        <li className="c-sidebar-nav-item">
          <NavLink
            className="c-sidebar-nav-link"
            exact
            activeClassName="c-active"
            to="/"
          >
            <i className="cil-home c-sidebar-nav-icon"></i>
            {/* <i className="fas fa-home c-sidebar-nav-icon"></i> */}
            Home<span className="badge badge-info"></span>
          </NavLink>
        </li>

        <li className="c-sidebar-nav-item" data-tour="dash-13">
          <NavLink
            className="c-sidebar-nav-link"
            activeClassName="c-active"
            to="/serviceRequests"
          >
            <i className="cil-library c-sidebar-nav-icon"></i>
            {/* <i className="fas fa-file-alt c-sidebar-nav-icon"></i> */}
            IT Requests
          </NavLink>
        </li>

        <li className="c-sidebar-nav-item" data-tour="dash-13">
          <NavLink
            className="c-sidebar-nav-link"
            activeClassName="c-active"
            to="/employeeForecasts"
          >
            <i className="cil-group c-sidebar-nav-icon"></i>
            <div>Employee Forecast</div>
            {/* <div className="badge bg-danger">Coming soon!</div> */}
          </NavLink>
        </li>

        <li className="c-sidebar-nav-item" data-tour="dash-14">
          <NavLink
            className="c-sidebar-nav-link"
            activeClassName="c-active"
            to="/employees"
          >
            <i className="cil-address-book c-sidebar-nav-icon"></i>
            {/* <i className="fas fa-users c-sidebar-nav-icon"></i> */}
            Employee Directory
          </NavLink>
        </li>
        <li className="c-sidebar-nav-item" data-tour="dash-14">
          <NavLink
            className="c-sidebar-nav-link"
            activeClassName="c-active"
            to="/org-charts"
          >
            <i className="cib-gumtree c-sidebar-nav-icon"></i>
            {/* <i className="fas fa-users c-sidebar-nav-icon"></i> */}
            Enterprise Org Charts
          </NavLink>
        </li>

        <li
          data-tour="dash-15"
          className={`c-sidebar-nav-item c-sidebar-nav-dropdown ${
            isActiveCL ? "c-show" : ""
          }`}
        >
          <a
            className="c-sidebar-nav-link c-sidebar-nav-dropdown-toggle"
            href="#0"
            onClick={toggleClassCL}
          >
            <i className="cil-link c-sidebar-nav-icon"></i>
            {/* <i className="fas fa-link c-sidebar-nav-icon"></i> */}
            Corporate Links
          </a>

          <ul className="c-sidebar-nav-dropdown-items">
            {links &&
              links.map((link) => (
                <li className="c-sidebar-nav-item" key={link.Id}>
                  <a
                    className="c-sidebar-nav-link font-weight-normal"
                    target="_blank"
                    href={`${link.URL__c}`}
                    rel="noreferrer"
                  >
                    {link.Label__c}
                  </a>
                </li>
              ))}
          </ul>
        </li>

        <li className="c-sidebar-nav-item" data-tour="dash-14">
          <NavLink
            className="c-sidebar-nav-link"
            activeClassName="c-active"
            to="/my-time-off"
          >
            <i className="cil-beach-access c-sidebar-nav-icon"></i>
            My Time Away
          </NavLink>
        </li>

        {access && (access.includes("ELT") || access.includes("Admin")) && (
          <li className="c-sidebar-nav-item" data-tour="dash-14">
            <NavLink
              className="c-sidebar-nav-link"
              activeClassName="c-active"
              to="/EmployeePortalLogs"
            >
              <i className="cil-chart c-sidebar-nav-icon"></i>
              Portal Metrics
            </NavLink>
          </li>
        )}
        {subordinates && subordinates.length > 1 && (
          <li className="c-sidebar-nav-item" data-tour="dash-14">
            <NavLink
              className="c-sidebar-nav-link"
              activeClassName="c-active"
              to="/peopleMetrics"
            >
              <i className="cil-chart c-sidebar-nav-icon"></i>
              People Metrics
            </NavLink>
          </li>
        )}

        {access && (access.includes("ELT") || access.includes("Admin")) && (
          <li className="c-sidebar-nav-item">
            <NavLink
              className="c-sidebar-nav-link"
              activeClassName="c-active"
              to="/newsEvents"
            >
              <i className="cil-newspaper c-sidebar-nav-icon"></i>
              {/* <i className="fas fa-newspaper c-sidebar-nav-icon"></i> */}
              News & Events
            </NavLink>
          </li>
        )}
        <li className="c-sidebar-nav-item" data-tour="dash-16">
          <NavLink
            className="c-sidebar-nav-link"
            activeClassName="c-active"
            to="/helpDocs"
          >
            <i className="cil-info c-sidebar-nav-icon"></i>
            Training Library
          </NavLink>
        </li>
        <li className="c-sidebar-nav-item" data-tour="dash-16">
          <NavLink
            className="c-sidebar-nav-link"
            activeClassName="c-active"
            to="/covidProtocols"
          >
            <i className="cil-clipboard c-sidebar-nav-icon"></i>
            Covid-19 Protocols
          </NavLink>
        </li>
        <li className="c-sidebar-nav-item" data-tour="dash-16">
          <NavLink
            className="c-sidebar-nav-link"
            activeClassName="c-active"
            to="/careers"
          >
            <i className="cil-briefcase c-sidebar-nav-icon"></i>
            Job Opportunities
          </NavLink>
        </li>
        <li className="c-sidebar-nav-item" data-tour="dash-16">
          <NavLink
            className="c-sidebar-nav-link"
            activeClassName="c-active"
            to="/forms"
          >
            <i className="cil-notes c-sidebar-nav-icon"></i>
            Corporate Forms
          </NavLink>
        </li>
        <li className="c-sidebar-nav-item mt-auto">
          <Contact sfUser={sfUser} />
        </li>
      </ul>
      <button
        className="c-sidebar-minimizer c-class-toggler"
        type="button"
        data-target="_parent"
        data-class="c-sidebar-minimized"
      ></button>
    </div>
  );
};

export default Sidebar;
