import { useEffect } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Logo from '../../images/Logos/SDI_MR.png'
import {authenticateUser} from '../../redux/actions/users/authenticateUser';



const Login = (props) => {
    const isAuthenticated = useIsAuthenticated();
    const { instance, inProgress } = useMsal();

    const handleLogin = () => {
        instance.loginRedirect();
    }

    useEffect(() => {
        if(isAuthenticated) {
            props.dispatch(authenticateUser(isAuthenticated));
        }
    }, [isAuthenticated]);

    

    if(isAuthenticated && props.auth.isAuthenticated) {
        return <Redirect to="/" />
    } else {
        return (
            <div className="bg-gradient-primary" style={{width: '100%'}}>
                <div className="container" style={{ maxWidth: '27em' }}>
                    <div className="row justify-content-center mt-5">
                        <div className="col">
                            <div className="card-group">
                                <div className="card p-4">
                                    <div className="card-body">
                                        <div className="text-center mb-5">
                                            <img src={Logo} style={{width: '80%'}} alt="" />
                                        </div>
                                        <button className="btn btn-pill btn-block btn-dark" onClick={handleLogin} disabled={inProgress === 'login'}>
                                            <i className="fab fa-microsoft mr-3"></i>
                                            Sign in
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    // return (
    //     <>
    //         <AuthenticatedTemplate>
    //             <Redirect to="/" />
    //             {/* <div>Welcome {accounts[0] && accounts[0].username} 
    //                 <Link to="/getUserInformation">click here</Link>
    //             </div> */}
    //         </AuthenticatedTemplate>
  
    //         <UnauthenticatedTemplate>
    //             <div className="bg-gradient-primary" style={{width: '100%'}}>
    //                  <div className="container" style={{ maxWidth: '27em' }}>
    //                      <div className="row justify-content-center mt-5">
    //                          <div className="col">
    //                              <div className="card-group">
    //                                  <div className="card p-4">
    //                                      <div className="card-body">
    //                                          <h1 className="text-dark">{inProgress}</h1>
    //                                          <div className="text-center mb-5">
    //                                              <img src={Logo} style={{width: '80%'}} alt="" />
    //                                          </div>
    //                                          <SignInSignOutButton />
    //                                      </div>
    //                                  </div>
    //                              </div>
    //                          </div>
    //                      </div>
    //                  </div>
    //              </div>
    //         </UnauthenticatedTemplate>
    //     </>
    // );
  }

  const mapStateToProps = (state) => {
      return {
          auth: state.auth
      }
  }

  export default connect(mapStateToProps)(Login);