import React from 'react';
import Layout from '../layout/Layout';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import EmployeeForecastForm from './EmployeeForecastForm';
import {saveEmployeeForecast} from '../../redux/actions/employeeForecast/saveEmployeeForecast';
import {employeeForecastResetStatus} from '../../redux/actions/employeeForecast/resetStatus';

const NewEmployeeForecast = ({error, user, dispatch, savedRecordId, status}) => {

    const breadcrumb = [
        { name:'Employee Forecast', link: '/employeeForecasts' },
        { name: 'New', link: '' }
    ] 

    

    return (
        <Layout breadcrumb={breadcrumb}>
            <div className="card">
                <div className="card-header bg-sdi-black text-sdi-yellow">
                    New Employee Forecast
                </div>
                <div className="card-body" data-tour="it-req-details-1">
                    <EmployeeForecastForm 
                        error={error} 
                        sfUser={user} 
                        onSubmit={(forecast) => dispatch(saveEmployeeForecast(forecast))}
                        resetStatus={() => {
                            dispatch(employeeForecastResetStatus())
                        }}
                        savedRecordId={savedRecordId}
                        status={status}
                         />
                </div>
            </div>
        </Layout>
    )
}



const mapStateToProps = (state, props) => {
    return {
        error: state.employeeForecasts.error,
        user: state.sfAuth.user,
        savedRecordId: state.employeeForecasts.recordId,
        status: state.employeeForecasts.status,
    }
}
export default connect(mapStateToProps)(NewEmployeeForecast);