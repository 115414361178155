import moment from "moment";

export const columns = [
  {
    dataField: "Id",
    text: "Record ID",
    hidden: true,
    searchable: false,
    csvExport: false,
    editable: false,
  },
  {
    dataField: "Employee_Name__c",
    text: "Name",
    sort: true,
    editable: false,
    // formatter: (cellContent, row) => {
    //   return row.First_Name__c + " " + row.Last_Name__c;
    // },
    headerStyle: (colum, colIndex) => {
      return { width: "25%", textAlign: "center" };
    },
    style: (colum, colIndex) => {
      return { verticalAlign: "middle", textAlign: "center" };
    },
  },
  // {
  //   dataField: "Last_Name__c",
  //   text: "Last Name",
  //   hidden: true,
  //   searchable: false,
  //   csvExport: true,
  //   editable: false,
  // },
  {
    dataField: "Hire_Date__c",
    text: "Hire Date",
    sort: true,
    editable: false,
    formatter: (cellContent, row) => {
      return row.Hire_Date__c && moment(row.Hire_Date__c).format("ll");
    },
    headerStyle: (colum, colIndex) => {
      return { width: "15%", textAlign: "center" };
    },
    style: (colum, colIndex) => {
      return { verticalAlign: "middle", textAlign: "center" };
    },
  },
  {
    dataField: "Termination_Date__c",
    text: "Departure Date",
    sort: true,
    editable: false,
    formatter: (cellContent, row) => {
      return (
        row.Termination_Date__c && moment(row.Termination_Date__c).format("ll")
      );
    },
    headerStyle: (colum, colIndex) => {
      return { width: "15%", textAlign: "center" };
    },
    style: (colum, colIndex) => {
      return { verticalAlign: "middle", textAlign: "center" };
    },
  },
  {
    dataField: "Department_Name__c",
    text: "Department",
    sort: true,
    editable: false,
    headerStyle: (colum, colIndex) => {
      return { width: "15%", textAlign: "center" };
    },
    style: (colum, colIndex) => {
      return { verticalAlign: "middle", textAlign: "center" };
    },
  },
  {
    dataField: "Employment_Status__c",
    text: "Current Status",
    sort: true,
    editable: false,
    formatter: (cellContent, row) => {
      return toTitleCase(cellContent);
    },
    headerStyle: (colum, colIndex) => {
      return { width: "20%", textAlign: "center" };
    },
    style: (colum, colIndex) => {
      return { verticalAlign: "middle", textAlign: "center" };
    },
  },
  {
    dataField: "Employment_Status_Reason_XRefCode__c",
    text: "Current Reason",
    sort: true,
    editable: false,
    formatter: (cellContent, row) => {
      return toTitleCase(cellContent);
    },
    headerStyle: (colum, colIndex) => {
      return { width: "25%", textAlign: "center" };
    },
    style: (colum, colIndex) => {
      return { verticalAlign: "middle", textAlign: "center" };
    },
  },
];

export const defaultSorted = [
  {
    dataField: "Name",
    order: "desc",
  },
];

const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};
