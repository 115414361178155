import {ActionTypes} from '../actionTypes';
import axios from 'axios';

export const fetchEmployeeForecastsStart = () => ({type: ActionTypes.FETCH_EMPLOYEE_FORECASTS_START});
export const fetchEmployeeForecastsSuccess = (payload) => ({type: ActionTypes.FETCH_EMPLOYEE_FORECASTS_SUCCESS, payload});
export const fetchEmployeeForecastsError = (error) => ({type: ActionTypes.FETCH_EMPLOYEE_FORECASTS_ERROR, error});

export const fetchEmployeeForecasts = () => {
    return async (dispatch) => {
        try {
            dispatch(fetchEmployeeForecastsStart());
            const response = await axios.get('/employeeForecast/all')


            if (response.data) {
                dispatch(fetchEmployeeForecastsSuccess(response.data))
            }
        } catch (err) {
            dispatch(fetchEmployeeForecastsError(err));
        }
    }
}