import React, {useState, useEffect} from 'react'
import {Modal, Button} from 'react-bootstrap'
import {uploadServiceRequestAttachment} from '../../../redux/actions/serviceRequestAttachments/uploadServiceRequestAttachment'
import {connect} from 'react-redux'

const UploadModal = props => {
    const [show, setShow] = useState(false);

    const [file, setFile] = useState(null)
    const [filename, setFilename] = useState('Choose file')
    const [error, setError] = useState('')

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const onChange = e => {
        setFile(e.target.files[0])
        setFilename(e.target.files[0].name)
    }

    const onSubmit = async e => {
        e.preventDefault();
        setError('')
        const formData = new FormData();
        formData.append('file', file);

        //10MB = 10485760 15MB = 15728640 20MB = 20971520 25MB = 26214400 
        if (file.size > 15728640) {
            setError('File too large, (Max upload size: 15 MB)')
            return
        }

        if (!file.name.toLowerCase().match(/\.(doc|docx|xls|xlsx|ppt|pdf|bmp|jpg|jpeg|png|gif|ai|eps)$/)) {
            setError('Invalid file, allowed extensions are: "jpg, jpeg, bmp, gif, png, doc, docx, xls,' +
                    ' xlsx, ppt, pdf, ai, eps"')
            return
        }


        props.dispatch(uploadServiceRequestAttachment(props.serviceRequestId, formData)).then(() => {
            setFile(null);
            setFilename('Choose file');
        })
    }



    return (
        <>
            <button className="btn btn-sm btn-primary" onClick={handleShow}>
                <i class="fas fa-upload"></i> Upload
            </button>

            <Modal size="lg" show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title style={{margin: '0 auto'}}>
                        Upload Documents
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {error && <div className="alert alert-danger">{error}</div>}
                        <form onSubmit={onSubmit}>
                            <div className="row">
                                <div className="col">
                                    <div className='custom-file'>
                                        <input
                                            type='file'
                                            className='custom-file-input'
                                            id='customFile'
                                            onChange={onChange}/>
                                        <label className='custom-file-label' htmlFor='customFile'>
                                            {filename}
                                        </label>
                                    </div>
                                </div>
                                <div className="col">
                                    <button disabled={!file || props.status == 'uploading'} type='submit' className='btn btn-primary btn-block'>
                                        {props.status == 'uploading' ? 
                                        <>
                                            <span className="spinner-border spinner-border-sm"></span>
                                            {' Uploading...'}
                                        </>
                                        : 'Upload'}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
                <Modal.Footer style={{display: 'block'}}>
                    <div className="text-center">
                        <Button variant="primary" onClick={handleClose}>
                            Done
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}



const mapStateToProps = (state, props) => {
    return {
        status: state.serviceRequestAttachments.status
    }
}

export default connect(mapStateToProps)(UploadModal)