import React from 'react';
import Layout from '../layout/Layout';
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import {columns, defaultSorted} from './columns'
import Datatable from '../layout/datatable';


const EmployeeForecasts = ({employeeForecasts, dispatch}) => {

    const breadcrumb = [
        { name:'Employee Forecast', link: '' }
    ]

    return (
        <Layout breadcrumb={breadcrumb}>
            <div className="card">
                <div className="card-header bg-sdi-black text-sdi-yellow">
                Employee Forecast
                    <div className="float-right">
                        <Link className="btn btn-sm btn-primary " to={`/employeeForecasts/new`} data-tour="it-req-1">
                            <i className="fas fa-plus"></i> New Employee Forecast
                        </Link>
                    </div>
                </div>
                <div className="card-body" news-tour="3">
                    <Datatable
                        csvFileName={'news-events.csv'}
                        data={employeeForecasts}
                        columns={columns}
                        sortBy={defaultSorted}
                    />
                </div>
            </div>
        </Layout>
    )
}


const mapStateToProps = (state, props) => {
    return {
        employeeForecasts: state.employeeForecasts.data 
    }
}

export default connect(mapStateToProps)(EmployeeForecasts);