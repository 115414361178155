import React, { useEffect } from "react";
import {
  addPeopleTimeAwayCompanyFilter,
  removePeopleTimeAwayCompanyFilter,
  addPeopleTimeAwayDepartmentFilter,
  removePeopleTimeAwayDepartmentFilter,
  addAllPeopleTimeAwayCompanyFilter,
  removeAllPeopleTimeAwayCompanyFilter,
  addAllPeopleTimeAwayDepartmentFilter,
  removeAllPeopleTimeAwayDepartmentFilter,
} from "../../../redux/actions/filters/peopleTimeAwayFilters";
import { ToggleButton, ButtonGroup } from "react-bootstrap";

const PeopleTimeAwayFilter = ({ masterEmployeeList, filters, dispatch }) => {
  const deparments = [
    ...new Set(
      masterEmployeeList
        .filter(
          (j) =>
            j.Department_Name__c != null &&
            j.Department_Name__c !== "Field" &&
            j.Department_Name__c !== "Project Member"
        )
        .map((i) => i.Department_Name__c)
    ),
  ];
  const companies = [
    ...new Set(
      masterEmployeeList
        .filter((j) => j.Company__c != null)
        .map((i) => i.Company__c)
    ),
  ];

  const handleDepartment = (e, dep) => {
    if (e.target.checked) {
      dispatch(addPeopleTimeAwayDepartmentFilter(dep));
    } else {
      dispatch(removePeopleTimeAwayDepartmentFilter(dep));
    }
  };

  const handleCompany = (e, co) => {
    console.log(e.target.checked);
    if (e.target.checked) {
      dispatch(addPeopleTimeAwayCompanyFilter(co));
    } else {
      dispatch(removePeopleTimeAwayCompanyFilter(co));
    }
  };

  const formateDepartmentName = (dep) => {
    if (dep === "C&L") {
      return "kite";
    } else if (dep === "EX") {
      return "MC";
    }

    return dep;
  };

  const handleSelectAllDepartments = (e, deps) => {
    if (e.target.checked) {
      dispatch(addAllPeopleTimeAwayDepartmentFilter(deps));
    } else {
      dispatch(removeAllPeopleTimeAwayDepartmentFilter());
    }
  };

  const handleSelectAllCompanies = (e, cos) => {
    if (e.target.checked) {
      dispatch(addAllPeopleTimeAwayCompanyFilter(cos));
    } else {
      dispatch(removeAllPeopleTimeAwayCompanyFilter());
    }
  };

  useEffect(() => {
    dispatch(addAllPeopleTimeAwayCompanyFilter(companies));
    dispatch(addAllPeopleTimeAwayDepartmentFilter(deparments));
  }, []);

  return (
    <div className="row mb-3">
      <div className="col-sm-12">
        <label className="control-label font-weight-bold mb-1">Company:</label>
        <br />
        {companies &&
          companies.map(
            (i) =>
              i && (
                <ButtonGroup className="mr-2 ml-0 shadow-none" key={i}>
                  <ToggleButton
                    type="checkbox"
                    className={`btn-sm px-3 chk-btn ${
                      filters.company.includes(i)
                        ? "btn-outline-primary"
                        : "btn-outline-unchecked"
                    }`}
                    checked={filters.company.includes(i) ? true : false}
                    onChange={(e) => handleCompany(e, i)}
                  >
                    {filters.company.includes(i) ? (
                      <>
                        <i className="fas fa-check"></i> {i}
                      </>
                    ) : (
                      <>
                        <i
                          className="fas fa-plus"
                          style={{ transform: "rotate(45deg)" }}
                        ></i>{" "}
                        {i}
                      </>
                    )}
                  </ToggleButton>
                </ButtonGroup>
              )
          )}
        <ButtonGroup className="mr-2 ml-0 shadow-none">
          <ToggleButton
            type="checkbox"
            className={`btn-sm px-3 chk-btn ${
              companies.every((elm) => filters.company.includes(elm))
                ? "btn-outline-primary"
                : "btn-outline-unchecked"
            }`}
            checked={
              companies.every((elm) => filters.company.includes(elm))
                ? true
                : false
            }
            onChange={(e) => handleSelectAllCompanies(e, companies)}
          >
            {companies.every((elm) => filters.company.includes(elm)) ? (
              <>
                <i className="fas fa-check"></i> All
              </>
            ) : (
              <>
                <i
                  className="fas fa-plus"
                  style={{ transform: "rotate(45deg)" }}
                ></i>{" "}
                All
              </>
            )}
          </ToggleButton>
        </ButtonGroup>
      </div>

      <div className="col-sm-12">
        <label className="control-label font-weight-bold mb-1 mt-2">
          Department:
        </label>
        <br />
        {deparments &&
          deparments.map((i) => (
            <ButtonGroup className="mr-2 ml-0 shadow-none" key={i}>
              <ToggleButton
                type="checkbox"
                className={`btn-sm px-3 chk-btn ${
                  filters.department.includes(i)
                    ? "btn-outline-primary"
                    : "btn-outline-unchecked"
                }`}
                checked={filters.department.includes(i) ? true : false}
                onChange={(e) => handleDepartment(e, i)}
              >
                {filters.department.includes(i) ? (
                  <>
                    <i className="fas fa-check"></i> {formateDepartmentName(i)}
                  </>
                ) : (
                  <>
                    <i
                      className="fas fa-plus"
                      style={{ transform: "rotate(45deg)" }}
                    ></i>{" "}
                    {formateDepartmentName(i)}
                  </>
                )}
              </ToggleButton>
            </ButtonGroup>
          ))}
        <ButtonGroup className="mr-2 ml-0 shadow-none">
          <ToggleButton
            type="checkbox"
            className={`btn-sm px-3 chk-btn ${
              deparments.every((elm) => filters.department.includes(elm))
                ? "btn-outline-primary"
                : "btn-outline-unchecked"
            }`}
            checked={
              deparments.every((elm) => filters.department.includes(elm))
                ? true
                : false
            }
            onChange={(e) => handleSelectAllDepartments(e, deparments)}
          >
            {deparments.every((elm) => filters.department.includes(elm)) ? (
              <>
                <i className="fas fa-check"></i> All
              </>
            ) : (
              <>
                <i
                  className="fas fa-plus"
                  style={{ transform: "rotate(45deg)" }}
                ></i>{" "}
                All
              </>
            )}
          </ToggleButton>
        </ButtonGroup>
      </div>
    </div>
  );
};

export default PeopleTimeAwayFilter;
