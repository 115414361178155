import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
// import 'chartjs-plugin-doughnutlabel';
import ChartDataLabels from 'chartjs-plugin-datalabels';



ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.register(ChartDataLabels);
// ChartJS.register(DoughnutLabel)

const options = {
    plugins: {
      ChartDataLabels,
      datalabels: {
        display: true,
        formatter: (value) => {
          return value + '';
        },
        font: {
            size: 18,
        }
      },
    }
}




const VacationChart = ({data}) => {
  const chartData = {
    labels: ['Vacation Booked', 'Vacation Taken', 'Vacation Remaining'],
    datasets: [
      {
        label: 'Vacation',
        data: [data.bookedVacation, data.takenVacation, data.remainingVaction],
        backgroundColor: [
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 99, 132, 0.2)',
          'rgba(255, 206, 86, 0.2)',
        ],
        borderColor: [
          'rgba(54, 162, 235, 1)',
          'rgba(255, 99, 132, 1)',
          'rgba(255, 206, 86, 1)',
        ],
        borderWidth: 1,
      },
    ],
};

    return <Doughnut data={chartData} options={options} />;
}

export default VacationChart
