import {ActionTypes} from '../actions/actionTypes';

const initialState = {
    dataLoaded: null,
    status: null,
    error: null,
    data: []
}

export default (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.FETCH_NOTIFICATIONS_START:
            return {...state, status: 'loading', dataLoaded: false}
        case ActionTypes.FETCH_NOTIFICATIONS_SUCCESS:
            return {...state, data: [...action.payload], status: 'loaded', dataLoaded: true}
        case ActionTypes.FETCH_NOTIFICATIONS_ERROR:
            return {...state, error: action.error, status: 'error', dataLoaded: false}
        case ActionTypes.UPDATE_NOTIFICATION_START:
            return {...state, status: 'updating', error: null}
        case ActionTypes.UPDATE_NOTIFICATION_ERROR:
            return {...state, error: action.error, status: 'error'}
        case ActionTypes.UPDATE_NOTIFICATION_SUCCESS:
            return {...state, data: state.data.map((notificaton) => {
                if(notificaton.Id === action.id) {
                    return {
                        ...notificaton,
                        ...action.updates
                    }
                } else {
                    return notificaton;
                }
            }), status: 'updated', error: null};
        case ActionTypes.DELETE_NOTIFICATION_START:
            return {...state, status: 'deleting', error: null}
        case ActionTypes.DELETE_NOTIFICATION_ERROR:
            return {...state, error: action.error, status: 'error'}
        case ActionTypes.DELETE_NOTIFICATION_SUCCESS:
            return {...state, data: state.data.filter(({Id}) => Id !== action.Id), status: 'deleted', error: null}
        default:
            return state;
    }
}