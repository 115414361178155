import React, {useState, useEffect} from 'react'
import {Modal, Button} from 'react-bootstrap'
import axios from 'axios';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import TextInput from '../formFields/TextInput';
import RichTextEditor from '../formFields/RichTextEditor';
import {validationSchema} from './validationSchema';



const Contact = ({sfUser}) => {
    const [initialValues, setInitialValues] = useState({
        Subject__c: '',
        Message__c: '',
        Submitted_By__c: sfUser.Id
    });

    const [saving, setSaving] = useState(null);
    const [show, setShow] = useState(false);
    const [error, setError] = useState(null);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const submitform = async (values) => {
        setSaving(true);
        setError(null);

        try {
            const response = await axios.post('/contact', values);

            if(response.data.success) {
                setSaving(false);
                handleClose();
            }

        } catch (err) {
            setSaving(false);
            setError(err.message);
        }
        
    }

    useEffect(() => {
        setSaving(false)
    }, []);

    const renderModal = () => {
        return (
            <Modal size="lg" show={show} onHide={handleClose}>
                <Modal.Header closeButton style={{borderBottom: '0 none'}}>
                    <Modal.Title style={{margin: '0 auto'}}>Contact Portal Team</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Formik
                        initialValues={ 
                            initialValues
                        }
                        validationSchema={validationSchema}
                        onSubmit={(values, { setSubmitting }) => {
                                setSaving(true);
                                submitform(values);
                                setSubmitting(false);
                        }}
                    >
                        {({isSubmitting, values, errors, touched, handleChange, handleBlur, setFieldValue, setFieldTouched}) => {
                            return (
                                <Form className="needs-validation container">
                                    {error &&
                                        <div className="alert alert-danger">{error}</div>
                                    }
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <TextInput 
                                                label="Subject" 
                                                name="Subject__c"
                                                isRequired={true}
                                                // tooltip={true}
                                                // tooltipText="Enter all the necessary details of this request"
                                                 />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <RichTextEditor 
                                                label="Message"
                                                name="Message__c"
                                                isRequired={true}
                                                // tooltip={true}
                                                // tooltipText="Enter all the necessary details of this request"
                                                />
                                        </div>
                                    </div>
                                    <TextInput name="Submitted_By__c" hidden/>
                                    <div className="row mt-3 mb-5">
                                        <div className="col-md-4"></div>
                                        <div className="col-md-4 text-center">
                                            <button 
                                            className="btn btn-primary btn-block text-uppercase" disabled={saving} type="submit">
                                            {saving ? 
                                            <>
                                                <span className="spinner-border spinner-border-sm"></span>
                                                {' Submiting...'}
                                            </>
                                            : 'Submit'}
                                            </button>
                                        </div>
                                        <div className="col-md-4"></div>
                                    </div>
                                </Form>
                            )
                        }}
                    </Formik>
                </Modal.Body>
            </Modal>
        )
    }

    

    return (
        <>
            <a onClick={handleShow} className="c-sidebar-nav-link c-sidebar-nav-link-success sidebar-contact" href="#0">
                <i className="cil-envelope-letter c-sidebar-nav-icon"></i>
                Contact Portal Team
            </a>
            

            {show && renderModal()}

        </>
    );
}

export default Contact;