import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Doughnut, Bar } from 'react-chartjs-2';
import ChartDataModal from './ChartDataModal';

const LogsByCompanyChart = ({data}) => {


    const labels = data && data.map((i) => i.company)
    const chartData = data && data.map((i) => i.sub_total)

    

    return (
        <div className={`card`}>
            <div className="card-body">
                <div className="d-flex justify-content-between">
                    <div className="h5">Visits by Company</div>
                    <small className="text-uppercase h5 text-muted">
                        <ChartDataModal data={data && data.map((item) => (
                                {
                                    name: item.company, 
                                    value: item.sub_total
                                }
                            ))} title={'Total Visits/Company'} />
                    </small>
                </div>

                <Chart labels={labels} chartData={chartData} />
            </div>
        </div>
    )
}

export default LogsByCompanyChart



const Chart = ({labels, chartData}) => {
    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    );


    const options = {
        indexAxis: 'x',
        responsive: true,
        plugins: {
            datalabels: {
                color: 'black',
                // backgroundColor: 'gray',
                display: true
            },
            legend: {
                display: false,
                position: 'top',
                tooltips: {
                    callbacks: {
                        label: tooltipItem => `${tooltipItem.yLabel}: ${tooltipItem.xLabel}`, 
                        title: () => null,
                    }
                }
            },
            title: {
                display: false,
                text: 'Chart.js Bar Chart',
            },
        },
        scales: {
            x: {
                display: true,
            },
            y: {
                display: false,
            }
        }
    };



    const data = {
        labels,
        datasets: [
          {
            label: 'Total Visits',
            data: chartData,
            backgroundColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 0.5,
          }
        ],
    };

    return (
        <Bar options={options} data={data} />
    )
}