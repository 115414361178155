import React, {useEffect, useState} from 'react';

const Pagination = ({recordsPerPage, totalRecords, setPage, currentPage}) => {
    const [upperPageBound, setUpperPageBound] = useState(3);
    const [lowerPageBound, setLowerPageBound] = useState(0);
    const [pageBound, setPageBound] = useState(3);

    const increment = () => {
        setUpperPageBound(upperPageBound + pageBound);
        setLowerPageBound(lowerPageBound + pageBound);
        setPage(upperPageBound+1);
    }

    const decrement = () => {
        setUpperPageBound(upperPageBound - pageBound);
        setLowerPageBound(lowerPageBound - pageBound);
        setPage(upperPageBound-pageBound);
    }

    const next = () => {
        if((currentPage +1) > upperPageBound) {
            setUpperPageBound(upperPageBound + pageBound);
            setLowerPageBound(lowerPageBound + pageBound);
        }
        setPage(currentPage+1)
    }

    const previous = () => {
        if((currentPage -1) % pageBound === 0) {
            setUpperPageBound(upperPageBound - pageBound);
            setLowerPageBound(lowerPageBound - pageBound);
        }
        setPage(currentPage-1);
    }
    
    const pageNumbers = [];

    for(let i = 1; i <= Math.ceil(totalRecords / recordsPerPage); i++){
        pageNumbers.push(i);
    }

    const renderPrevious = () => {
        return (
            <li className={`page-item ${pageNumbers.indexOf(currentPage) === 0 && "disabled"}`}>
                <a className="page-link" href="#" tabIndex="-1" onClick={previous}>Previous</a>
            </li>
        )
    }

    const renderDecrement = () => {
        if(lowerPageBound >= 1) {
            return (
                <li className={`page-item`}>
                    <a className="page-link" href="#" tabIndex="-1" onClick={decrement}> &hellip; </a>
                </li>
            )
        }
    }

    const renderPageNumbers = () => {
        return pageNumbers.map(number => {
            if(number === 1 && currentPage === 1) {
                return (
                    <li key={number} className={`page-item ${number === currentPage && "active"}`}>
                        <a onClick={() => setPage(number)} href="#" className="page-link">
                            {number}
                        </a>
                    </li>
                )
            } else if ((number < upperPageBound + 1) && number > lowerPageBound) {
                return (
                    <li key={number} className={`page-item ${number === currentPage && "active"}`}>
                        <a onClick={() => setPage(number)} href="#" className="page-link">
                            {number}
                        </a>
                    </li>
                )
            }
        })
    }

    const renderIncrement = () => {
        if(pageNumbers.length > upperPageBound) {
            return (
                <li className={`page-item`}>
                    <a className="page-link" href="#" tabIndex="-1" onClick={increment}> &hellip; </a>
                </li>
            )
        }
    }

    const renderNext = () => {
        return (
            <li className={`page-item ${pageNumbers.length === currentPage && "disabled"}`}>
                <a className="page-link" href="#" onClick={next}>Next</a>
            </li>
        )
    }

    return (
        <nav>
            <ul className="pagination justify-content-center">
                
                {renderPrevious()}
                {renderDecrement()}
                {renderPageNumbers()}
                {renderIncrement()}
                {renderNext()}
                
            </ul>
        </nav>
    )
}

export default Pagination;