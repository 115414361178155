import {ActionTypes} from '../actionTypes';
import axios from 'axios';


export const deleteNewsEventsStart = () => ({type: ActionTypes.DELETE_NEWS_EVENTS_START})
export const deleteNewsEventsSuccess = (Id) => ({type: ActionTypes.DELETE_NEWS_EVENTS_SUCCESS, Id})
export const deleteNewsEventsError = (error) => ({type: ActionTypes.DELETE_NEWS_EVENTS_ERROR, error})


export const deleteNewsEvents = ({Id}) => {
    return async (dispatch) => {
        try {
            dispatch(deleteNewsEventsStart())
            const response = await axios.delete(`/newsEvents/delete/${Id}`)

            if (response.status === 200) {
                dispatch(deleteNewsEventsSuccess(response.data.id))
            }

        } catch (err) {
            dispatch(deleteNewsEventsError(err))
        }
    }
}