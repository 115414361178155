import { connect } from "react-redux";
import Layout from "../../layout/Layout";
import {
  peopleFilter,
  peopleTimeAwayFilter,
  getSubordinates,
} from "../../../redux/selectors/peopleDashboardSelector";
import PeopleTable from "./PeopleTable";
import PeopleChart from "./PeopleChart";
import moment from "moment";
import PeopleFilter from "./PeopleFilter";
import { Tabs, Tab, Badge, Nav } from "react-bootstrap";
import DatatableSm from "../../layout/DatatableSm";
import { columns, defaultSorted } from "./timeAwayColumn";
import PeopleTimeAwayFilter from "./PeopleTimeAwayFilter";

const PeopleDashboard = ({
  data,
  timeaway,
  masterEmployeeList,
  filters,
  timeAwayFilters,
  dispatch,
}) => {
  const breadcrumb = [{ name: "People Metrics", link: "" }];

  return (
    <Layout breadcrumb={breadcrumb}>
      <div className="card ">
        {/* <div className="card-header bg-sdi-black text-sdi-yellow">
                    People Metrics
                </div> */}
        <div className="card-body">
          <Tab.Container defaultActiveKey="headcount">
            <Nav variant="tabs" defaultActiveKey="second">
              <Nav.Item>
                <Nav.Link
                  eventKey="headcount"
                  className="notification-badge-container"
                >
                  Headcount
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="timeaway"
                  className="notification-badge-container"
                >
                  Time Away
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="headcount">
                {masterEmployeeList.length > 0 ? (
                  <div className="pt-4">
                    <PeopleFilter
                      masterEmployeeList={masterEmployeeList}
                      filters={filters}
                      dispatch={dispatch}
                    />
                    <PeopleTable
                      data={data.results.filter((i) =>
                        moment(i.date).isBefore(moment())
                      )}
                      masterEmployeeList={masterEmployeeList.filter(
                        (i) =>
                          i.Employment_Status__c.toLowerCase() ===
                          "ACTIVE".toLowerCase()
                        // &&
                        // i.Employment_Status_Reason_XRefCode__c !==
                        //   "Salary Continuance"
                      )}
                    />
                    <div>
                      * Please note that field numbers are indicative and not
                      absolute.
                    </div>
                    <div style={{ height: "35vh" }}>
                      <PeopleChart
                        data={data.results.filter((i) =>
                          moment(i.date).isBefore(moment())
                        )}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="my-5 text-center">No Data to Display</div>
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="timeaway">
                {masterEmployeeList.length > 0 ? (
                  <div className="pt-4">
                    <PeopleTimeAwayFilter
                      masterEmployeeList={masterEmployeeList}
                      filters={timeAwayFilters}
                      dispatch={dispatch}
                    />
                    <DatatableSm
                      csvFileName={"Time-Away.csv"}
                      data={timeaway}
                      columns={columns}
                      sortBy={defaultSorted}
                    />
                  </div>
                ) : (
                  <div className="my-5 text-center">No Data to Display</div>
                )}
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  const subordinates = getSubordinates(
    state.masterEmployeeList.data,
    state.sfAuth.user || ""
  );

  return {
    data: peopleFilter(subordinates, state.peopleFilters),
    timeaway: peopleTimeAwayFilter(
      subordinates.filter(
        (i) =>
          i &&
          i.Employment_Status__c.toLowerCase() === "ACTIVE".toLowerCase() &&
          i.Department_Name__c.toLowerCase() !== "Field".toLowerCase()
      ),
      state.peopleTimeAwayFilters
    ),
    masterEmployeeList: subordinates,
    filters: state.peopleFilters,
    timeAwayFilters: state.peopleTimeAwayFilters,
  };
};

export default connect(mapStateToProps)(PeopleDashboard);
