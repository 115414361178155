import EditQuickLink from "./EditQuickLink"

const QuickLinkItem = ({link}) => {
    return (
        <div className="col-6 col-sm-3 col-md-3 col-lg-2 mb-3 px-2">
            <div className="text-center p-0 bg-white rounded h-100 d-block border shadow-sm link-dash" >
                <a className="pt-3 pb-2 px-2 text-dark text-decoration-none" href={`${link.URL__c}`} target="__blank">
                    <div>
                        <i className={`c-icon c-icon-2xl ${link.Icon__c}`} style={{color: `${link.Icon_Color__c}`}}></i>
                        {!link.Corporate_Link__c && <div style={{position: 'absolute', top: '10px', right: '20px'}}><EditQuickLink link={link} /></div>}
                    </div>
                    
                    <div>
                        {link.Label__c}
                    </div>
                </a>
            </div>
        </div>
    )
}

export default QuickLinkItem
