import {ActionTypes} from '../actions/actionTypes';

const initialState = {
    dataLoaded: null,
    status: null,
    error: null,
    data: []
}

export default (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.QUICK_LINKS_RESET_STATUS:
            return {...state, status: null, error: null, recordId: null}
        case ActionTypes.FETCH_QUICK_LINKS_START:
            return {...state, status: 'loading', dataLoaded: false}
        case ActionTypes.FETCH_QUICK_LINKS_SUCCESS:
            return {...state, data: [...action.links], status: 'loaded', dataLoaded: true}
        case ActionTypes.FETCH_QUICK_LINKS_ERROR:
            return {...state, error: action.error, status: 'error', dataLoaded: false}
        case ActionTypes.SAVE_QUICK_LINK_START:
            return {...state, status: 'saving', error: null}
        case ActionTypes.SAVE_QUICK_LINK_ERROR:
            return {...state, error: action.error, status: 'error'}
        case ActionTypes.SAVE_QUICK_LINK_SUCCESS:
            return {...state, data: [...state.data, action.link], status: 'saved', recordId: action.link.Id, error: null}
        case ActionTypes.UPDATE_QUICK_START_START:
            return {...state, status: 'updating', error: null}
        case ActionTypes.UPDATE_QUICK_START_ERROR:
            return {...state, error: action.error, status: 'error'}
        case ActionTypes.UPDATE_QUICK_START_SUCCESS:
            return {...state, data: state.data.map((link) => {
                if(link.Id === action.id) {
                    return {
                        ...link,
                        ...action.updates
                    }
                } else {
                    return link;
                }
            }), status: 'updated', error: null};
        case ActionTypes.DELETE_QUICK_LINK_START:
            return {...state, status: 'deleting', error: null}
        case ActionTypes.DELETE_QUICK_LINK_ERROR:
            return {...state, error: action.error, status: 'error'}
        case ActionTypes.DELETE_QUICK_LINK_SUCCESS:
            return {...state, data: state.data.filter(({Id}) => Id !== action.Id), status: 'deleted', error: null}
        default:
            return state;
    }
}