import {ActionTypes} from '../actionTypes';
import axios from 'axios';

export const fetchWowStart = () => ({type: ActionTypes.FETCH_WOW_START});
export const fetchWowSuccess = (wow) => ({type: ActionTypes.FETCH_WOW_SUCCESS, wow});
export const fetchWowError = (error) => ({type: ActionTypes.FETCH_WOW_ERROR, error});

export const fetchWow = () => {
    return async (dispatch) => {
        try {
            dispatch(fetchWowStart());
            const response = await axios.get('/wow/all')


            if (response.data) {
                const newData = response.data.map((item) => ({...item, ImageUrl: null}));
                dispatch(fetchWowSuccess(newData))
            }
        } catch (err) {
            dispatch(fetchWowError(err));
        }
    }
}