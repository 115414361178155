import React, {useState} from 'react'
import moment from 'moment'
import {Link} from 'react-router-dom'
import {updateNotification} from '../../../redux/actions/notifications/updateNotification'

const Notifications = ({notifications, techTickets, dispatch}) => {
    const nCount = notifications ? notifications.filter(i => !i.Is_Read__c).length : 0
    return (
        <li className="c-header-nav-item dropdown mx-2">
            <a className="c-header-nav-link" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="true">
                <i className="far fa-bell c-icon"></i>
                {nCount !== 0 && <span className="badge badge-pill badge-danger">{nCount}</span>}
            </a>
            <div 
                className="dropdown-menu dropdown-menu-right dropdown-menu-lg pt-0 shadow"
            >
                <div className="dropdown-header bg-light">
                    <strong >You have {nCount} unopened notifications</strong>
                </div>
                


                {notifications && notifications.slice(0, 7).map((i) => (
                    <NotificationItem 
                        notification={i} 
                        key={i.Id} 
                        techTicket={techTickets && techTickets.find((t) => t.Id === i.Tech_Service_Ticket__c)}
                        dispatch={dispatch} />
                ))}
                <Link className="dropdown-item border-top text-center" to="/notifications"  style={{display: 'block'}}><strong>View all notifications</strong></Link>

            </div>
        </li>
    )
}

export default Notifications








const NotificationItem = ({notification, techTicket, dispatch}) => {

    const handleUpdate = () => {
        dispatch(updateNotification(notification.Id, {Is_Read__c: true}))
    }

    const renderIcon = (type) => {
        switch(type) {
            case 'IT request completed':
                return "cil-info text-info";
            case 'IT request rejected':
                return 'cil-x-circle text-danger';
            case 'IT request approved':
                return 'cil-check-circle text-success';
            case 'IT request updated':
                return 'cil-sync text-success';
            case 'IT request assigned':
                return 'cil-user-plus text-success';
            case 'New Announcement':
                return 'cil-newspaper text-info';
            case 'New Corporate Link':
                return 'cil-link text-primary';
            case 'New Training':
                return 'cil-info text-primary';
            default:
                return 'cil-info text-info'
        }
    }




    const selectUrl = (notification) => {
        switch(notification.Type__c) {
            case 'IT request completed':
                return `/serviceRequests/${notification.Tech_Service_Ticket__c}`;
            case 'IT request rejected':
                return `/serviceRequests/${notification.Tech_Service_Ticket__c}`;
            case 'IT request approved':
                return `/serviceRequests/${notification.Tech_Service_Ticket__c}`;
            case 'IT request updated':
                return `/serviceRequests/${notification.Tech_Service_Ticket__c}`;
            case 'IT request assigned':
                return `/serviceRequests/${notification.Tech_Service_Ticket__c}`;
            case 'New Announcement':
                return `/newsEvents/${notification.Employee_Announcement__c}`;
            case 'New Corporate Link':
                return '/';
            case 'New Training':
                return `/helpDocs/${notification.Training__c}`;
            default:
                return '/notifications'
        }
    }


    const formatName = (type, techTicket = {}) => {
        switch(type) {
            case 'IT request completed':
                return `IT Request ${techTicket.Name && techTicket.Name.split("-").pop()} Completed`;
            case 'IT request rejected':
                return `IT Request ${techTicket.Name && techTicket.Name.split("-").pop()} Rejected`;
            case 'IT request approved':
                return `IT Request ${techTicket.Name && techTicket.Name.split("-").pop()} Approved`;
            case 'IT request updated':
                return `IT Request ${techTicket.Name && techTicket.Name.split("-").pop()} Updated`;
            case 'IT request assigned':
                return `IT Request ${techTicket.Name && techTicket.Name.split("-").pop()} Assigned`;
            default:
                return type
        }
    }

    return (
        <Link 
            className={`dropdown-item ${notification.Is_Read__c ? 'bg-light' : ''}`} 
            to={selectUrl(notification)}
            onClick={handleUpdate} >
            <i className={`${renderIcon(notification.Type__c)} icon me-2 h5`}></i>
            <div className="ml-3" style={{maxWidth: '20rem'}}>
                <div className={`${!notification.Is_Read__c ? 'font-weight-bold' : ''}`}>
                    {formatName(notification.Type__c, techTicket)}
                </div>
                {/* <span className="d-none d-sm-block text-truncate"> {notification.Description__c}</span> */}
                <small className="text-muted float-left mt-1" >{moment(notification.CreatedDate).calendar()}</small>
            </div>
        </Link>
    )
}