import React, {useEffect, useState} from 'react';
import Carousel from 'react-bootstrap/Carousel'
import image3 from '../../images/image3.jpg'
import NewsEventsModal from './NewsEventsModal';
import axios from 'axios';
import {connect} from "react-redux";
import {updateWowSuccess} from '../../redux/actions/wow/updateWow';
import WallofWowWinner from '../../images/christina_sloane.png';
import placeholder from '../../images/placeholder.png';



const WallOfWowItem = ({wow, dispatch}) => {


    useEffect(() => {
        const download = async () => {
            if(wow.Attachments && wow.Attachments.records[0] && wow.ImageUrl === null) {
                const response = await axios.get(`/wow/attachment/${wow.Attachments.records[0].Id}`, {responseType: 'arraybuffer'});
    
        
                if (response.status == 200) {
                    var arrayBufferView = new Uint8Array( response.data );
                    var blob = new Blob( [ arrayBufferView ], { type: "image/jpeg" } );
                    var urlCreator = window.URL || window.webkitURL;
                    var imageUrl = urlCreator.createObjectURL( blob );
                    dispatch(updateWowSuccess(wow.Id, {ImageUrl: imageUrl}))
                }
            }
        }

        download();
        
    }, [])
    return (
        <>
            <img src={placeholder} className="carousel-image d-block w-100"/>
            <Carousel.Caption style={
                    {
                        backgroundColor: 'transparent',
                        color: 'black',
                        textAlign: 'left',
                        paddingLeft: '20px',
                        marginBottom: '0',
                        paddingBottom: '0',
                        maxWidth: '100%',
                        width: '100%',
                        left: 0,
                        maxHeight: '100%',
                        top: 0
                    }
                }>
                    <div className="row">
                        <div className="col">
                            <h5 className="card-title tt">Wall of Wow</h5>
                            <p className="card-text my-2 bb">
                                <div className="font-weight-bold">{wow.Month_Year__c}</div>
                            </p>
                            <p className="card-text my-2 bb">
                                <small>Winner</small>
                                <div className="font-weight-bold">{wow.Nominees_Name__c}</div>
                            </p>
                            <p className="card-text my-2 bb">
                                <small>Nominated By</small>
                                <div className="font-weight-bold">{wow.Nominated_By__c}</div>
                            </p>
                            <p className="card-text my-2 bb">
                                <small>Value</small>
                                <div className="font-weight-bold">{wow.Value__c}</div>
                            </p>
                        </div>
                        <div className="col">
                            <img src={wow.ImageUrl} className=" d-block w-100" style={{bottom: 0}} />
                        </div>
                    </div>

                    {/* <div className="row">
                        <div className="col">
                            <h5 className="card-title">Wall of Wow</h5>
                            <p className="card-text my-2">
                                <div className="font-weight-bold">{wow.Month_Year__c}</div>
                            </p>
                            <p className="card-text my-2">
                                <small>Winner</small>
                                <div className="font-weight-bold">{wow.Nominees_Name__c}</div>
                            </p>
                            <p className="card-text my-2">
                                <small>Nominated By</small>
                                <div className="font-weight-bold">{wow.Nominated_By__c}</div>
                            </p>
                            <p className="card-text my-2">
                                <small>Value</small>
                                <div className="font-weight-bold">{wow.Value__c}</div>
                            </p>
                        </div>
                        <div className="col">
                            <img src={wow.ImageUrl} className=" d-block w-100" style={{bottom: 0}} />
                        </div>
                    </div> */}
                
            </Carousel.Caption>
        </>
    )
}


export default connect(null)(WallOfWowItem);