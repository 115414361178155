import React, {useState, useEffect} from 'react';
import moment from 'moment';
import DetailsModal from '../layout/DetailsModal';
import axios from 'axios';
import ApprovalHistory from './ApprovalHistory';
import RichTextToHTML from '../layout/RichTextToHTML';

const ServiceRequestStatus = (props) => {
    const {serviceRequest, assignedUser, projPreApprover, projEstApprover} = props;
    const assignedUserDetails = assignedUser && {
        'Name': assignedUser.Name,
        'Title': assignedUser.Title,
        'Department': assignedUser.Department,
        'Email': assignedUser.Email
    }

    const projPreApproverDetails = projPreApprover && {
        'Name': projPreApprover.Name,
        'Title': projPreApprover.Title,
        'Department': projPreApprover.Department,
        'Email': projPreApprover.Email
    }

    const projEstApproverDetails = projEstApprover && {
        'Name': projEstApprover.Name,
        'Title': projEstApprover.Title,
        'Department': projEstApprover.Department,
        'Email': projEstApprover.Email
    }


    const [approvalNotes, setApprovalNotes] = useState(null);
    const [approvals, setApprovals] = useState([]);

    useEffect(() => {
        const queryApprovals = async () => {
            try {

                if(serviceRequest.Parent_Type__c === 'Purchase Request' || serviceRequest.Parent_Type__c === 'Project Brief') {
                    const response  = await axios.get(`/serviceRequest/approvalList/${serviceRequest.Id}`);
                
                    if(response.data) {
                        response.data.sort((a, b) => new Date(b.CreatedDate) - new Date(a.CreatedDate));
                        setApprovals(response.data);
                        if(serviceRequest.Submitted_By_Equal_to_Proj_Pre_Approver__c) {
                            setApprovalNotes(serviceRequest.Proj_Pre_Approval_Notes__c);
                        } else if(serviceRequest.Submitted_By_Equal_to_Purch_Approver__c) {
                            setApprovalNotes(serviceRequest.Purch_Approval_Notes__c);
                        } else {
                            setApprovalNotes(response.data[0] && response.data[0].StepsAndWorkitems && response.data[0].StepsAndWorkitems.records[0].Comments);
                        }
                    }
                }

            } catch (err) {
                console.log(err);
            }
        }

        queryApprovals();
    }, [serviceRequest.Status__c])


    const currencyFormat = (number, currency) => {
        return new Intl.NumberFormat(`en-US`, 
            { 
                style: 'currency', 
                currency: currency || 'CAD', 
                currencyDisplay: 'code' 
            }
        ).format(number);
    }


    return (
        <>
            <div className="row mb-3">
                {serviceRequest.Parent_Type__c === 'Purchase Request'&& <>
                <div className="col-6 col-md-3 mb-3">
                    <div className="text-uppercase font-weight-light">
                        Status
                    </div>
                    <div className="font-weight-bold">{serviceRequest.Purch_Approval_Status__c}</div>
                </div>
                <div className="col-6 col-md-3 mb-3">
                    <div className="text-uppercase font-weight-light">
                        Approved By
                    </div>
                    <div className="font-weight-bold">{serviceRequest.Purch_Approved_By__c}</div>
                </div>
                <div className="col-6 col-md-3 mb-3">
                    <div className="text-uppercase font-weight-light">
                        Approved On
                    </div>
                    <div className="font-weight-bold">{serviceRequest.Purch_Approved_On__c && moment(serviceRequest.Purch_Approved_On__c).format('LL')}</div>
                </div>
                <div className="col-6 col-md-3 mb-3">
                    <div className="text-uppercase font-weight-light">
                        Approval Notes
                    </div>
                    <div className="font-weight-bold">{approvals && <ApprovalHistory notes={approvalNotes} approvals={approvals} />}</div>
                </div>
                </>}
                {serviceRequest.Parent_Type__c === 'Project Brief' && <>
                <div className="col-12 mb-3">
                    <div className="font-weight-bold">Pre-Approval</div>
                    <hr className="m-0" />
                </div>
                <div className="col-6 col-md-3 mb-3">
                    <div className="text-uppercase font-weight-light">
                        Status
                    </div>
                    <div className="font-weight-bold">{serviceRequest.Proj_Pre_Approval_Status__c}</div>
                </div>
                <div className="col-6 col-md-3 mb-3">
                    <div className="text-uppercase font-weight-light">
                        Approver
                    </div>
                    <div className="font-weight-bold">{projPreApproverDetails && <DetailsModal buttonText={projPreApprover && projPreApprover.Name} title={'Pre-Approver'} data={projPreApproverDetails} />}</div>
                </div>
                <div className="col-6 col-md-3 mb-3">
                    <div className="text-uppercase font-weight-light">
                        Approved On
                    </div>
                    <div className="font-weight-bold">{serviceRequest.Proj_Pre_Approved_On__c && moment(serviceRequest.Proj_Pre_Approved_On__c).format('LL')}</div>
                </div>
                <div className="col-6 col-md-3 mb-3">
                    <div className="text-uppercase font-weight-light">
                        Approval Notes
                    </div>
                    <div className="font-weight-bold">{approvals && <ApprovalHistory notes={approvalNotes} approvals={approvals} />}</div>
                </div>

                <div className="col-12 mb-3">
                    <div className="font-weight-bold">Estimate Approval</div>
                    <hr className="m-0" />
                </div>
                <div className="col-6 col-md-3 mb-3">
                    <div className="text-uppercase font-weight-light">
                        Status
                    </div>
                    <div className="font-weight-bold">{serviceRequest.Proj_Estimate_Approval_Status__c}</div>
                </div>
                <div className="col-6 col-md-3 mb-3">
                    <div className="text-uppercase font-weight-light">
                        Approver
                    </div>
                    <div className="font-weight-bold">{projEstApproverDetails && <DetailsModal buttonText={projEstApprover && projEstApprover.Name} title={'Estimate Approver'} data={projEstApproverDetails} />}</div>
                </div>
                <div className="col-6 col-md-3 mb-3">
                    <div className="text-uppercase font-weight-light">
                        Approved On
                    </div>
                    <div className="font-weight-bold">{serviceRequest.Approved_On__c && moment(serviceRequest.Approved_On__c).format('LL')}</div>
                </div>
                <div className="col-6 col-md-3 mb-3">
                    <div className="text-uppercase font-weight-light">
                        Estimate Sent On
                    </div>
                    <div className="font-weight-bold">{serviceRequest.Estimate_Sent_On__c && moment(serviceRequest.Estimate_Sent_On__c).format('LL')}</div>
                </div>
                <div className="col-6 col-md-3 mb-3">
                    <div className="text-uppercase font-weight-light">
                        Estimated Hours
                    </div>
                    <div className="font-weight-bold">{serviceRequest.Est_Hours_Reqd__c}</div>
                </div>
                <div className="col-6 col-md-3 mb-3">
                    <div className="text-uppercase font-weight-light">
                        Estimated Rate
                    </div>
                    <div className="font-weight-bold">{serviceRequest.Proj_Estimated_Rate__c}</div>
                </div>
                <div className="col-6 col-md-3 mb-3">
                    <div className="text-uppercase font-weight-light">
                        Estimated Cost
                    </div>
                    <div className="font-weight-bold">{serviceRequest.Est_Costs__c && currencyFormat(serviceRequest.Est_Costs__c, 'CAD')}</div>
                </div>
                <div className="col-6 col-md-3 mb-3">
                    <div className="text-uppercase font-weight-light">
                        Estimate Note
                    </div>
                    <div className="font-weight-bold">{serviceRequest.Proj_Estimate_Note__c}</div>
                </div>

                {/* <div className="col-6 col-md-6 mb-3">
                    <div className="text-uppercase font-weight-light">
                        Approval Notes
                    </div>
                    <div className="font-weight-bold">{approvals && <ApprovalHistory notes={(approvals[0] && approvals[0].StepsAndWorkitems) &&  approvals[0].StepsAndWorkitems.records[0].Comments} approvals={approvals} />}</div>
                </div> */}
                <div className="col-12 mb-3">
                    <div className="font-weight-bold">Status</div>
                    <hr className="m-0" />
                </div>
                </>}
                <div className="col-6 col-md-3 mb-3">
                    <div className="text-uppercase font-weight-light">
                        Status
                    </div>
                    <div className="font-weight-bold">{serviceRequest.Status__c}</div>
                </div>
                <div className="col-6 col-md-3 mb-3">
                    <div className="text-uppercase font-weight-light">
                        Assigned To
                    </div>
                    <div className="font-weight-bold">{assignedUserDetails && <DetailsModal buttonText={assignedUserDetails && assignedUserDetails.Name} title={'Assigned To'} data={assignedUserDetails} />}</div>
                </div> 
                <div className="col-6 col-md-3 mb-3">
                    <div className="text-uppercase font-weight-light">
                        Expected Delivery Date
                    </div>
                    <div className="font-weight-bold">{serviceRequest.Est_Time_Of_Completion__c && moment(serviceRequest.Est_Time_Of_Completion__c).format('LL')}</div>
                </div>
                <div className="col-6 col-md-3 mb-3">
                    <div className="text-uppercase font-weight-light">
                        Completed On
                    </div>
                    <div className="font-weight-bold">{serviceRequest.Completed_On__c && moment(serviceRequest.Completed_On__c).format('LL')}</div>
                </div>                  
                <div className="col-12 col-md-12 mb-3">
                    <div className="text-uppercase font-weight-light">
                        Status Notes
                    </div>
                    <RichTextToHTML html={serviceRequest.Status_Notes__c} />
                </div>
                
            </div>
        </>
    )
    
}

export default ServiceRequestStatus;