import {ActionTypes} from '../actionTypes';
import axios from 'axios';

export const saveEmployeeForecastStart = () => ({type: ActionTypes.SAVE_EMPLOYEE_FORECAST_START});
export const saveEmployeeForecastSuccess = (payload) => ({type: ActionTypes.SAVE_EMPLOYEE_FORECAST_SUCCESS, payload});
export const saveEmployeeForecastError = (error) => ({type: ActionTypes.SAVE_EMPLOYEE_FORECAST_ERROR, error});

export const saveEmployeeForecast = (rawData = {}) => {
    return async (dispatch) => {

        const readyData = await prepareFields(rawData);

        try {

            dispatch(saveEmployeeForecastStart());

            const response = await axios.post('/employeeForecast/create', readyData)

            if (response.data) {
                dispatch(saveEmployeeForecastSuccess({...response.data.employeeForecast}))
            }
        } catch (err) {
            dispatch(saveEmployeeForecastError(err.response.data));
        }
    }
}


const prepareFields = async (data) => {
    if(data.Is_Employee_Hired__c === false) {
        delete data.Employee_Name__c;
        delete data.Employee_Setup_Form_Provided__c;
    } 
    
    if (data.Requires_Laptop__c === false) {
        // delete data.Laptop_Desired_By__c;
        delete data.HW_SW_Requirements__c;
        delete data.Shipping_Required__c;
    }

    return data;
}