import React, {useState, useEffect} from 'react'
import {Modal, Button} from 'react-bootstrap'
import EmployeeDetails from './EmployeeDetails';
import EmployeeListItem from './EmployeeListItem';

const EmployeeModal = ({location, allEmployees = [], ...props}) => {
    const [show, setShow] = useState(false);
    const [emp, setEmp] = useState(props.emp);
    const [subordinates, setSubordinates] = useState(allEmployees.filter((rec) => rec.ReportsToId === props.emp.Id));

    const handleClose = () => {
        setShow(false);
    }
    const handleShow = () => {
        setEmp(props.emp);
        setSubordinates(allEmployees.filter((rec) => rec.ReportsToId === props.emp.Id));
        setShow(true);
    }
    

    const changeEmp = (id) => {
        const employee = allEmployees.find((e) => e.Id === id);
        const subs = allEmployees.filter((e) => e.ReportsToId === employee.Id);

        setEmp(employee);
        setSubordinates(subs);
    }

    const renderModal = () => {
        
        return (
            <Modal dialogClassName="modal-10w" show={show} onHide={handleClose}>
                <Modal.Header closeButton style={{borderBottom: '0 none'}}>
                    <Modal.Title style={{margin: '0 auto'}}></Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <EmployeeDetails emp={emp} subordinates={subordinates} allEmployees={allEmployees} changeEmp={changeEmp} dispatch={props.dispatch} />
                </Modal.Body>
            </Modal>
        )
    }

    

    return (
        <div>
            {location === 'dashboard' 
            ?
            <EmployeeListItem handleShow={handleShow} emp={props.emp} dispatch={props.dispatch} />
            :
            <button data-tour="emp-directory-3" onClick={handleShow} className="btn btn-link btn-block p-0 font-weight-normal text-decoration-none " style={{lineHeight: '1'}}>
                More about me
            </button>}
            

            {show && renderModal()}

        </div>
    );
}

export default EmployeeModal;