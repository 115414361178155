import React from 'react';
import {Link} from 'react-router-dom'


const Breadcrumb = (props) => {
    return (
        <div className="c-subheader px-3" data-tour="it-req-details-4">  
            <ol className="breadcrumb border-0 m-0 font-weight-bold">
                <li className="breadcrumb-item">
                    {props.breadcrumb ? <Link to="/">Home</Link> : 'Dashboard'}
                </li>
                {props.breadcrumb && props.breadcrumb.map((item) => (
                    <li className="breadcrumb-item" key={item.name}>
                        {item.link ? <Link to={item.link} >{item.name}</Link> : item.name}
                    </li>
                ))}
            </ol>
        </div>
    )
}

export default Breadcrumb;