import React, {useState, useEffect} from 'react'
import {displayName, displayNameTable, formatBirthDate, formatStartDate, formatAnniversary, backgroundColor} from './functions';
import moment from 'moment';
import axios from 'axios';
import {updateContactSuccess} from '../../redux/actions/contacts/updateContact';
import {contactResetStatus} from '../../redux/actions/contacts/resetStatus';

const EmployeeListItem = ({handleShow, emp, dispatch}) => {

    // useEffect(() => {
    //     const download = async () => {
    //         if(emp.pse__Salesforce_User__r && emp.pse__Salesforce_User__r.SmallPhotoUrl !== 'https://sdi--c.na147.content.force.com/profilephoto/005/T' && emp.ImageUrl === null) {
    //             const response = await axios.post(`/contacts/picture`, {url: emp.pse__Salesforce_User__r.SmallPhotoUrl});
        
    //             if (response.status === 200) {
    //                 dispatch(updateContactSuccess(emp.Id, {ImageUrl: response.data}))
    //                 dispatch(contactResetStatus())
    //             }
    //         }
    //     }

    //     download();
        
    // }, [emp])

    const renderCelebrations = (birthDay, anniversary) => {
        const currBYear = moment(birthDay).year(moment().format('YYYY'));
        const currAYear = moment(anniversary).year(moment().format('YYYY'));

        if(birthDay && anniversary) {
            if((moment(currAYear).isSame(moment(), 'day'))) {
                return <span className="small mr-2">Welcome to SDI!</span>
            } else if((moment(currBYear).isSame(moment(), 'day'))) {
                return <span className="small mr-2">{formatBirthDate(birthDay)}</span>
            } else if(emp.celebrationType === 'BRT') {
                return <span className="small mr-2">{formatBirthDate(birthDay)}</span>
            } else {
                return <span className="small mr-2">{formatAnniversary(anniversary)}</span>
            }

            // if(currBYear.isBefore(moment(), 'day') && currAYear.isAfter(moment(), 'day')) {
            //     return <span className="small mr-2">{formatAnniversary(anniversary)}</span>
            // } else if(currBYear.isAfter(moment(), 'day') && currAYear.isBefore(moment(), 'day')) {
            //     return <span className="small mr-2">{formatBirthDate(birthDay)}</span>
            // } else  {
            //     if(currBYear.isBefore(currAYear, 'day')){
            //         return <span className="small mr-2">{formatBirthDate(birthDay)}</span>
            //     } else if(moment(currAYear).isSame(moment(), 'day')) {
            //         return <span className="small mr-2">{formatAnniversary(anniversary)}</span>
            //     } else if(moment(currBYear).isSame(moment(), 'day')) {
            //         return <span className="small mr-2">{formatBirthDate(birthDay)}</span>
            //     } else {
            //         return <span className="small mr-2">{formatAnniversary(anniversary)}</span>
            //     }
            // }
        } else if (birthDay && !anniversary) {
            return <span className="small mr-2">{formatBirthDate(birthDay)}</span>
        } else if (!birthDay && anniversary) {
            if((moment(currAYear).isSame(moment(), 'day'))) {
                return <span className="small mr-2">Welcome to SDI!</span>
            }
            return <span className="small mr-2">{formatAnniversary(anniversary)}</span>
        }

        return <span className="small mr-2"></span>;
    }

    return (
        <a onClick={handleShow} href="#0" className="text-muted" style={{textDecoration: 'none'}}>
            <div className="row pt-2 pb-2">
                <div className="col-3 c-avatar p-0">
                    {emp.Photo ? 
                    <img className="rounded-circle border user-image-small" src={`data:image/jpeg;base64,${emp.Photo}`} alt=""></img>
                    :
                    <div className="rounded-circle employee-profile-image-dashboard border text-dark" style={{backgroundColor: backgroundColor(emp.Name)}}>
                        {displayNameTable(emp.Name)}
                    </div>}
                </div>
                <div className="col-9 p-0">
                    <div className="d-flex w-100 justify-content-between">
                        <span className="text-sdi-black">{emp.Name}</span>
                        {renderCelebrations(emp.Birthdate, emp.pse__Start_Date__c)}
                    </div>
                    <div className="small text-muted ">
                        <span>{emp.Title}</span>{` | `}<span className="font-weight-bold">{emp.Department}</span>
                    </div>
                </div>
            </div>
        </a>
    )
}


export default EmployeeListItem;