export const setHelpDocsTextFilter = (text = '') => ({
    type: 'SET_HELP_DOCS_TEXT_FILTER',
    text
})

export const setHelpDocsCategoryFilter = (category = '') => ({
    type: 'SET_HELP_DOCS_CATEGORY_FILTER',
    category
})

export const setHelpDocsSubCategoryFilter = (subCategory = '') => ({
    type: 'SET_HELP_DOCS_SUBCATEGORY_FILTER',
    subCategory
})