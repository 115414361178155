import React, { useState, useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import TextInput from "../formFields/TextInput";
import RichTextEditor from "../formFields/RichTextEditor";
import { validationSchema } from "./validationSchema";
import SelectInput from "../formFields/SelectInput";
import DateInput from "../formFields/DateInput";
import TypeaheadInput from "../formFields/TypeaheadInput";
import SwitchInput from "../formFields/SwitchInput";
import {
  selectedMultipleOptions,
  createMultpleOptions,
} from "../../redux/selectors/typeaheadOptions";
import { Typeahead } from "react-bootstrap-typeahead";
import { useHistory } from "react-router-dom";
import moment from "moment";

const laptopRequirements = [
  { Id: "Windows Environment", Name: "Windows Environment" },
  { Id: "MacOS Environment", Name: "MacOS Environment" },
  { Id: "Office Apps", Name: "Office Apps" },
  { Id: "Teams Direct Number", Name: "Teams Direct Number" },
  { Id: "Adobe Creative Suite", Name: "Adobe Creative Suite" },
  { Id: "Monitor", Name: "Monitor" },
  { Id: "Mouse", Name: "Mouse" },
  { Id: "Keyboard", Name: "Keyboard" },
];

const EmployeeForecastForm = ({
  error,
  onSubmit,
  sfUser,
  forecast,
  status,
  resetStatus,
  savedRecordId,
}) => {
  let history = useHistory();
  const [saving, setSaving] = useState(null);
  const [selectedRquirements, setSelectedRquirements] = useState(
    forecast
      ? selectedMultipleOptions(
          laptopRequirements,
          forecast.HW_SW_Requirements__c
        )
      : []
  );

  const [initialValues, setInitialValues] = useState({
    Submitted_By__c: sfUser.Id,
    Job_Title__c:
      forecast && forecast.Job_Title__c ? forecast.Job_Title__c : "",
    Division__c: forecast && forecast.Division__c ? forecast.Division__c : "",
    Type__c: forecast && forecast.Type__c ? forecast.Type__c : "",
    Planned_Start_Date__c:
      forecast && forecast.Planned_Start_Date__c
        ? moment(forecast.Planned_Start_Date__c)
        : "",
    Employee_Name__c:
      forecast && forecast.Employee_Name__c ? forecast.Employee_Name__c : "",
    Employee_Setup_Form_Provided__c:
      forecast && forecast.Employee_Setup_Form_Provided__c
        ? forecast.Employee_Setup_Form_Provided__c
        : "",
    Requires_Laptop__c: forecast ? forecast.Requires_Laptop__c : false,
    // Laptop_Desired_By__c: forecast && forecast.Laptop_Desired_By__c ? moment(forecast.Laptop_Desired_By__c) : '',
    HW_SW_Requirements__c:
      forecast && forecast.HW_SW_Requirements__c
        ? forecast.HW_SW_Requirements__c
        : "",
    Shipping_Required__c:
      forecast && forecast.Shipping_Required__c
        ? forecast.Shipping_Required__c
        : "",
    Other_Important_Info__c:
      forecast && forecast.Other_Important_Info__c
        ? forecast.Other_Important_Info__c
        : "",
    Is_Employee_Hired__c: forecast ? forecast.Is_Employee_Hired__c : false,
  });

  useEffect(() => {
    if (status === "error") {
      setSaving(false);
    }
    if (status === "saved" || status === "updated") {
      setSaving(false);
      resetStatus();
      history.push(`/employeeForecasts/${savedRecordId}`);
    }
  }, [status]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSaving(true);
        onSubmit(values);
        setSubmitting(false);
      }}
    >
      {({
        isSubmitting,
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldValue,
        setFieldTouched,
      }) => {
        return (
          <Form className="needs-validation container">
            {error && <div className="alert alert-danger">{error}</div>}
            {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
            <div className="row">
              <div className="col-sm-6">
                <TextInput
                  label="Job Title"
                  name="Job_Title__c"
                  isRequired={true}
                />
              </div>
              <div className="col-sm-6">
                <SelectInput
                  isRequired={true}
                  label="Division"
                  name="Division__c"
                >
                  <option value="">Choose a division...</option>
                  <option value="Artech">Artech</option>
                  <option value="Finance">Finance</option>
                  <option value="Fuel Media">Fuel Media</option>
                  <option value="Good Things">Good Things</option>
                  <option value="Gord's Imprints">Gord's Imprints</option>
                  <option value="kite">kite</option>
                  <option value="MC">MC</option>
                  <option value="Polite Payroll">Polite Payroll</option>
                  <option value="Operations">Operations</option>
                  <option value="Sports">Sports</option>
                  <option value="SDI Doha">SDI Doha</option>
                  <option value="SDI Doha Sports">SDI Doha Sports</option>
                  <option value="HR">HR</option>
                  <option value="Other">Other</option>
                </SelectInput>
              </div>
              <div className="col-sm-6">
                <SelectInput isRequired={true} label="Type" name="Type__c">
                  <option value="">Choose a type...</option>
                  <option value="Hourly - Field">Hourly - Field</option>
                  <option value="Hourly - Internal">Hourly - Internal</option>
                  <option value="Salary - Field / Remote">
                    Salary - Field / Remote
                  </option>
                  <option value="Salary - Internal">Salary - Internal</option>
                  <option value="Other">Other</option>
                </SelectInput>
              </div>
              <div className="col-sm-6">
                <DateInput
                  label="Planned Start Date"
                  name="Planned_Start_Date__c"
                  isRequired={true}
                  minDate={new Date()}
                />
              </div>
              <div className="col-sm-12">
                <SwitchInput
                  name="Is_Employee_Hired__c"
                  label="Have you already hired someone for this position?"
                />
              </div>
              {values.Is_Employee_Hired__c && (
                <>
                  <div className="col-sm-6">
                    <TextInput
                      label="Employee Name"
                      name="Employee_Name__c"
                      isRequired={true}
                    />
                  </div>
                  <div className="col-sm-6">
                    <SwitchInput
                      name="Employee_Setup_Form_Provided__c"
                      label="Employee Setup Form Provided"
                    />
                  </div>
                </>
              )}
              <div className="col-sm-12">
                <SwitchInput
                  name="Requires_Laptop__c"
                  label="Requires Laptop"
                />
              </div>
              {values.Requires_Laptop__c && (
                <>
                  {/* <div className="col-sm-6">
                                <DateInput 
                                    label="Laptop Desired By" 
                                    name="Laptop_Desired_By__c"
                                    isRequired={true}
                                    minDate={new Date()}
                                />
                            </div> */}
                  <div className="col-sm-6">
                    <TypeaheadInput
                      name="HW_SW_Requirements__c"
                      isRequired={true}
                      touched={touched}
                      selected={selectedRquirements}
                      setFieldTouched={setFieldTouched}
                      choices={createMultpleOptions(
                        laptopRequirements,
                        selectedRquirements
                      )}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      multiple
                      label={"HW/SW Requirements"}
                      placeholder="Choose multiple..."
                    />
                  </div>
                  <div className="col-sm-6">
                    <SelectInput
                      isRequired={true}
                      label="Shipping"
                      name="Shipping_Required__c"
                    >
                      <option value="">Choose an option...</option>
                      <option value="Shipping Required">
                        Shipping Required
                      </option>
                      <option value="Pickup at Head Office">
                        Pickup at Head Office
                      </option>
                    </SelectInput>
                  </div>
                </>
              )}
            </div>
            <div className="row">
              <div className="col">
                <RichTextEditor
                  label="Other Important Information"
                  name="Other_Important_Info__c"
                />
              </div>
            </div>
            <div className="row mt-3 mb-5">
              <div className="col-md-4"></div>
              <div className="col-md-4 text-center">
                <button
                  className="btn btn-primary btn-block text-uppercase"
                  disabled={saving}
                  type="submit"
                >
                  {saving ? (
                    <>
                      <span className="spinner-border spinner-border-sm"></span>
                      {" Submiting..."}
                    </>
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
              <div className="col-md-4"></div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EmployeeForecastForm;
