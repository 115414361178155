export const options = [
    {
        value: 'HR',
        sub_categories: [
            {
                value: 'Pre-Onboarding - Position Requisition'
            },
            {
                value: 'Onboarding - Notice of Employment'
            },
            {
                value: 'Onboarding - Training Checklist'
            },
            {
                value: 'Onboarding - Employee Setup'
            },
            {
                value: 'Employee Changes - Employee Change Form'
            },
            {
                value: 'Disciplinary Action - Warning Letter Request Form'
            },
            {
                value: 'Leave of Absence - Leave of Absence Request Form'
            },
            {
                value: 'Offboarding - Employee Offboarding Form'
            },
            {
                value: 'Offboarding - IT Ops Offboarding Form'
            }
        ]
    },
    {
        value: 'Finance',
        sub_categories: [
            {
                value: 'Payroll - Payroll Change Form'
            }
        ]
    }
]