import { useMsal } from "@azure/msal-react";


const SignOutButtonSecondary = () => {
    const { instance } = useMsal();

    const handleLogout = () => {
        instance.logout();
        localStorage.removeItem('sfToken')
    }


    return (<button className="btn btn-secondary" onClick={handleLogout}>Logout</button>)
}

export default SignOutButtonSecondary;