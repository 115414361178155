import {ActionTypes} from '../actionTypes';
import axios from 'axios';

export const fetchCareersStart = () => ({type: ActionTypes.FETCH_CAREERS_START});
export const fetchCareersSuccess = (careers) => ({type: ActionTypes.FETCH_CAREERS_SUCCESS, careers});
export const fetchCareersError = (error) => ({type: ActionTypes.FETCH_CAREERS_ERROR, error});

export const fetchCareers = () => {
    return async (dispatch) => {
        try {
            dispatch(fetchCareersStart());
            const response = await axios.get('/careers/all')


            if (response.data) {
                dispatch(fetchCareersSuccess(response.data))
            }
        } catch (err) {
            dispatch(fetchCareersError(err));
        }
    }
}