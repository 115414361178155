import {ActionTypes} from '../actionTypes';
import axios from 'axios';

export const saveQuickLinkStart = () => ({type: ActionTypes.SAVE_QUICK_LINK_START});
export const saveQuickLinkSuccess = (link) => ({type: ActionTypes.SAVE_QUICK_LINK_SUCCESS, link});
export const saveQuickLinkError = (error) => ({type: ActionTypes.SAVE_QUICK_LINK_ERROR, error});

export const saveQuickLink = (linkData = {}) => {
    return async (dispatch) => {

        try {

            dispatch(saveQuickLinkStart());

            const response = await axios.post('/quicklinks/create', linkData)

            if (response.data) {
                dispatch(saveQuickLinkSuccess(response.data.link))
            }
        } catch (err) {
            dispatch(saveQuickLinkError(err.message));
        }
    }
}