import * as Yup from 'yup';


export const validationSchema = () => {
    return Yup.object({
        Subject__c: Yup
            .string()
            .max(250, 'Must be 250 characters or less')
            .required('Required'),
        Message__c: Yup
            .string()
            .min(9, 'Required')
            .required('Required')
            .max(2009, 'Must be 2000 characters or less')
            .required('Required')
            .nullable(),
    })
}