import React, { useState } from "react";
import { Button, Tooltip, OverlayTrigger } from "react-bootstrap";

const TooltipIcon = ({id, text, ...props}) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);

    return (
        <OverlayTrigger
        key={id}
        placement="top"
        overlay={
          <Tooltip id={`tooltip-${id}`}>
            {text}
          </Tooltip>
        }>
          <Button variant="link" className="m-0 p-0 text-dark">
            <i className="fas fa-info-circle m-0"></i>
          </Button>
        </OverlayTrigger>
      );
}

export default TooltipIcon;