export const userOptions = (users, user) => {
    return users
        ? users.filter((u) => u.Id !== user.Id).map((user) => ({label: user.Name, id: user.Id}))
        : [] 
}

export const categoryOptions = (categories, type) => {
    return categories
        ? categories.filter((i) => i.Request_Type__c === type).map((category) => ({label: category.Name, id: category.Id}))
        : []
}

export const projectOptions = (projects) => {
    return projects
        ? projects.map((project) => ({label: project.Name, id: project.Id, projectId: project.pse__Project_ID__c, ...project}))
        : []
}

export const clientOptions = (clients) => {
    return clients
        ? clients.map((client) => ({label: client.Name, id: client.Id}))
        : []
}

export const selectedOption = (data, id) => {
    return data ? data.filter((rec) => rec.Id === id).map((filRec) => ({label: filRec.Name, id: filRec.Id, ...filRec})) : []
}

export const selectedMultipleOptions = (data, ids) => {
    const found = []
    
    if(data && ids) {
        const values = ids.split(';')
        values.map((id) => {
            const f = data.find((d) => d.Id === id)
            found.push(f)
        })
    }
    
    return found.length > 0 ? found.map((filRec) => ({label: filRec.Name, id: filRec.Id, ...filRec})) : []
}

export const selectedIcon = (data, id) => {
    return data ? data.filter((rec) => rec.id === id).map((filRec) => ({label: filRec.label, id: filRec.id})) : []
}


export const projectsFilterByCallback = (option, props) => (
    option.label.toLowerCase().indexOf(props.text.toLowerCase()) !== -1 ||
    option.projectId.toLowerCase().indexOf(props.text.toLowerCase()) !== -1
)

export const createMultpleOptions = (options, selected) => {
    return options
        ? options.map((d) => ({label: d.Name, id: d.Id}))
        : []
}

export const createOptions = (data) => {
    return data
        ? data.map((d) => ({label: d.Name, id: d.Id}))
        : []
}

export const projectFilterByFields = ['label', 'projectId'];