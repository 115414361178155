import React, { useEffect, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { useHistory } from "react-router-dom"
import {connect} from 'react-redux'
// import axios from 'axios'

import Footer from './footer/Footer'
import Header from './header/Header'
import Sidebar from './sidebar/Sidebar'
import SignOutButtonSecondary from '../auth/SignOutButtonSecondary'

import { InteractionRequiredAuthError, InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, useIsAuthenticated, useMsal, useAccount } from "@azure/msal-react";
import { loginRequest } from "../auth/authConfig";


//actions
import {fetchUserPhoto} from '../../redux/actions/users/fetchUserPhoto';
import {fetchUser} from '../../redux/actions/users/fetchUser';
import {sfAuth} from '../../redux/actions/sfAuth/sfAuth';
import {fetchServiceRequests} from '../../redux/actions/serviceRequests/fetchServiceRequests';
// import {fetchAccounts} from '../../redux/actions/accounts/fetchAccounts';
import {fetchProjects} from '../../redux/actions/projects/fetchProjects';
import {fetchRequestCategories} from '../../redux/actions/requestCategories/fetchRequestCategories';
import {fetchAllUsers} from '../../redux/actions/users/fetchAllUsers';
import {fetchServiceRequestAttachments} from '../../redux/actions/serviceRequestAttachments/fetchServiceRequestAttachments';
import {fetchBusinessUnits} from '../../redux/actions/businessUnits/fetchBusinessUnits';
import {fetchContacts} from '../../redux/actions/contacts/fetchContacts';
import {fetchEmpTours} from '../../redux/actions/sfAuth/fetchEmpTours';
import {fetchNewsEvents} from '../../redux/actions/newsEvents/fetchNewsEvents';
// import {fetchWow} from '../../redux/actions/wow/fetchWow';
import { fetchHelpDocs } from '../../redux/actions/helpDocs/fetchHelpDocs'
import {updateAppReloadTime, updateAppLoaded} from '../../redux/actions/settings/updateAppSettings'
import {fetchQuickLinks} from '../../redux/actions/quickLinks/fetchQuickLinks';
import {fetchQuote} from '../../redux/actions/sfAuth/fetchQuote'
import {fetchNotifications} from '../../redux/actions/notifications/fetchNotifications'
import {fetchEmployeeForecasts} from '../../redux/actions/employeeForecast/fetchEmployeeForecasts';
import { fetchCareers } from '../../redux/actions/careers/fetchCareers';
import { fetchForms } from '../../redux/actions/forms/fetchForms';
import { fetchMasterEmployeeList } from '../../redux/actions/masterEmployeeList/fetchMasterEmployeeList'

import {getSubordinates} from '../../redux/selectors/peopleDashboardSelector';

import {fetchEmployeePortalLogs} from '../../redux/actions/employeePortalLogs/fetchEmployeePortalLogs';
import {fetchOrgChartBusinessUnits} from '../../redux/actions/orgChartBusinessUnits/fetchOrgChartBusinessUnits';

import {getRadios} from '../../components/performanceDashboards/portalDashboard/dateFunctions'

import quotes from './quotes';

const LayoutContent = (props) => {
    const { instance, inProgress, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    const {isAuthenticated, user, quote} = props.sfAuth;
    const {appLoaded} = props.appSettings;

    const radios = getRadios()

    //quotes[Math.floor(Math.random() * quotes.length)]
    // const [quote, setQuote] = useState({});


    useEffect(() => {
        // const fetchQuote = async () => {
        //     try {
        //         const response = await axios.get('/quote/all')
        //         if(response.data.length === 0) {
        //             return setQuote(quotes[0])
        //         }

        //         setQuote(response.data)

        //     } catch (e) {
        //         console.log(e);
        //         setQuote(quotes[0])
        //     }
        // }
        if(!isAuthenticated) {
            props.dispatch(sfAuth({email: account.username}));
        }

        if(Object.keys(quote).length === 0 && quote.constructor === Object && isAuthenticated) {
            props.dispatch(fetchQuote())
        }

    }, [quote, isAuthenticated])


    useEffect(() => {
        const fetchData = (token, account, isAuthed, user) => {
            props.dispatch(fetchUser(token));
            props.dispatch(fetchUserPhoto(token));

            //sf auth


            if(isAuthed && user && quote) {
                props.dispatch(updateAppReloadTime(new Date()));
                props.dispatch(fetchEmpTours());
                props.dispatch(fetchQuickLinks(user.Company));
                props.dispatch(fetchBusinessUnits());
                props.dispatch(fetchServiceRequests(user.Access));
                props.dispatch(fetchServiceRequestAttachments());
                // props.dispatch(fetchAccounts());
                props.dispatch(fetchProjects());
                props.dispatch(fetchRequestCategories());
                props.dispatch(fetchAllUsers());
                props.dispatch(fetchContacts());
                props.dispatch(fetchNewsEvents());
                // props.dispatch(fetchWow());
                props.dispatch(updateAppLoaded(true));
                props.dispatch(fetchHelpDocs());
                props.dispatch(fetchNotifications());
                props.dispatch(fetchEmployeeForecasts());
                props.dispatch(fetchCareers());
                props.dispatch(fetchForms());
                props.dispatch(fetchMasterEmployeeList());
                props.dispatch(fetchOrgChartBusinessUnits())

                // if(user.Access && (user.Access.includes('ELT') || 
                //     user.Access.includes('Admin') || 
                //     user.Access.includes('HR') || 
                //     user.Access.includes('Manager'))) {
                        
                // }
                const fitlerData = radios.find((i) => i.value === props.userLogsFilter.time)

                props.dispatch(fetchEmployeePortalLogs(fitlerData));
            }
        }

        if (account && inProgress === "none") {
            instance.acquireTokenSilent({
                ...loginRequest,
                account: account
            }).then((response) => {
                if(!appLoaded) {
                    fetchData(response.accessToken, account, isAuthenticated, user);
                }
            }).catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect({
                        ...loginRequest,
                        account: account
                    }).then(function(accessTokenResponse) {
                        // Acquire token interactive success
                        let accessToken = accessTokenResponse.accessToken;
                        // Call your API with token
                        if(!appLoaded) {
                            fetchData(accessToken, account, isAuthenticated, user);
                        }

                    }).catch(function(error) {
                        // Acquire token interactive failure
                        console.log(error);
                    });
                }
                console.log(error);
            });
        }
    }, [account, inProgress, instance, isAuthenticated, appLoaded, user]);


    if (
        props.sfAuth.isAuthenticated &&
        props.techTickets.dataLoaded &&
        // props.accounts.dataLoaded &&
        props.projects.dataLoaded &&
        props.users.dataLoaded &&
        props.requestCategories.dataLoaded &&
        props.serviceRequestAttachments.dataLoaded &&
        props.contacts.dataLoaded &&
        // props.wallOfWow.dataLoaded &&
        props.helpDocs.dataLoaded &&
        props.quickLinks.dataLoaded &&
        props.notifications.dataLoaded &&
        props.employeeForecasts.dataLoaded &&
        props.careers.dataLoaded &&
        props.forms.dataLoaded &&
        props.masterEmployeeList.dataLoaded &&
        props.orgChartBusinessUnits.dataLoaded
    ) {
        return (
        <>
            <Sidebar subordinates={props.subordinates} sfUser={props.sfAuth.user} links={props.quickLinks.data.filter((link) => link.Corporate_Link__c)}/>
            <div className="c-wrapper c-fixed-components ">
                <Header
                    dispatch={props.dispatch}
                    techTickets={props.techTickets.data}
                    notifications={props.notifications.data}
                    appSettings={props.appSettings}
                    userPhoto={props.userPhoto}
                    user={props.auth.user}
                    breadcrumb={props.breadcrumb}
                    hide={props.hide}/>
                <div className="c-body">
                    <main className="c-main">
                        <div className="container-fluid">
                            <div className="fade-in">
                                {props.children}
                            </div>
                        </div>
                    </main>
                    <Footer />
                </div>
            </div>
        </>
        );
    }

    if(!props.sfAuth.isAuthenticated && props.sfAuth.status === "Error") {
        return (
        <div className="align-items-center vertical-center" style={{width: '100%'}}>
            <div className="container text-center" style={{maxWidth: '400px'}}>
                <div className="mb-2">We couldn't find you on Salesforce, Contact Administrator.</div>
                <div><SignOutButtonSecondary /></div>
            </div>
        </div>
        )
    }

    // return (
    //     <div className="align-items-center vertical-center" style={{width: '100%'}}>
    //         <div className="container text-center" >
    //             <div className="mb-3">Loading user information...</div>
    //             <div className="spinner-border" style={{width: '3rem', height: '3rem'}}>
    //                 <span className="sr-only">Loading...</span>
    //             </div>
    //         </div>
    //     </div>
    // )

    return (
        <div className="align-items-center vertical-center " style={{width: '100%'}}>
            <div className="container">
                <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-8">
                        <div className="mb-5 p-1">
                            <h1 className="display-7 text-primary">{quote.Quote__c}</h1>
                            <blockquote className="blockquote-footer">{quote.Author__c}</blockquote>
                        </div>


                        <div className="snippet" data-title=".dot-flashing" >
                            <div className="stage">
                                <div className="dot-flashing" style={{marginRight: 'auto', marginLeft: 'auto'}}></div>
                            </div>
                        </div>
                        <div className="text-center mt-3 h5">LOADING</div>
                    </div>
                    <div className="col-md-2"></div>
                </div>
            </div>
        </div>
    )
}



const Layout = (props) => {
    const isAuthenticated = useIsAuthenticated();
    const { accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    const history = useHistory();

    const handleOnIdle = event => {
        console.log('last active', getLastActiveTime());
        window.location.reload();
        // history.push("/dataloader")
      }


      const { getRemainingTime, getLastActiveTime } = useIdleTimer({
        timeout: 1000 * 60 * 60, //1000 * 60 * 60
        onIdle: handleOnIdle,
        debounce: 500
      })


      const authRequest = {
        ...loginRequest
    };


    return (
        <>
        <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                authenticationRequest={authRequest}
                errorComponent={ErrorComponent}
                loadingComponent={Loading}
            >
            <LayoutContent
                msalContext={props.msalContext}
                account={account}
                dispatch={props.dispatch}
                auth={props.auth}
                appSettings={props.appSettings}
                userPhoto={props.userPhoto}
                breadcrumb={props.breadcrumb}
                hide={props.hide}
                children={props.children}
                sfAuth={props.sfAuth}
                // accounts={props.accounts}
                techTickets={props.techTickets}
                projects={props.projects}
                users={props.users}
                requestCategories={props.requestCategories}
                serviceRequestAttachments={props.serviceRequestAttachments}
                contacts={props.contacts}
                // wallOfWow={props.wallOfWow}
                helpDocs={props.helpDocs}
                quickLinks={props.quickLinks}
                notifications={props.notifications}
                employeeForecasts={props.employeeForecasts}
                careers={props.careers}
                forms={props.forms}
                masterEmployeeList={props.masterEmployeeList}
                subordinates={props.subordinates}
                userLogs={props.userLogs}
                userLogsFilter={props.userLogsFilter}
                orgChartBusinessUnits={props.orgChartBusinessUnits}
                />
        </MsalAuthenticationTemplate>
        </>
    )
}

const mapStateToProps = (state) => {
    
    return {
        sfAuth: state.sfAuth,
        userPhoto: state.auth.userPhoto,
        appSettings: state.settings,
        auth: state.auth,
        techTickets: state.serviceRequests,
        // accounts: state.accounts,
        projects: state.projects,
        users: state.users,
        requestCategories: state.requestCategories,
        serviceRequestAttachments: state.serviceRequestAttachments,
        contacts: state.contacts,
        // wallOfWow: state.wallOfWow,
        helpDocs: state.helpDocs,
        quickLinks: state.quickLinks,
        notifications: state.notifications,
        employeeForecasts: state.employeeForecasts,
        careers: state.careers,
        forms: state.forms,
        masterEmployeeList: state.masterEmployeeList,
        subordinates: getSubordinates(state.masterEmployeeList.data, state.sfAuth.user || ''),
        userLogs: state.userLogs,
        userLogsFilter: state.userLogsFilter,
        orgChartBusinessUnits: state.orgChartBusinessUnits

    }
}

export default connect(mapStateToProps)(Layout)



const ErrorComponent = (props) => {
    return <div>An Error Occurred: {props.error.errorMessage}</div>;
}

const Loading = (props) => {
    return (
        <div className="align-items-center vertical-center" style={{width: '100%'}}>
            <div className="container text-center" >
                <div className="mb-3">Loading application...</div>
                <div className="spinner-border" style={{width: '3rem', height: '3rem'}}>
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        </div>
    )
}