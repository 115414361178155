import {ActionTypes} from '../actions/actionTypes';

const initialState = {
    dataLoaded: null,
    status: null,
    error: null,
    data: [],
}

export default (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.FETCH_SERVICE_REQUEST_ATTACHMENTS_START:
            return {...state, status: 'loading', dataLoaded: false}
        case ActionTypes.FETCH_SERVICE_REQUEST_ATTACHMENTS_SUCCESS:
            return {...state, data: [...action.attachments], status: 'loaded', dataLoaded: true}
        case ActionTypes.FETCH_SERVICE_REQUEST_ATTACHMENTS_ERROR:
            return {...state, error: action.error, status: 'error', dataLoaded: false}
        case ActionTypes.UPLOAD_SERVICE_REQUEST_ATTACHMENT_START:
            return {...state, status: 'uploading'};
        case ActionTypes.UPLOAD_SERVICE_REQUEST_ATTACHMENT_SUCCESS:
            return {...state, data: [...state.data, action.att], status: 'uploaded'}
        case ActionTypes.UPLOAD_SERVICE_REQUEST_ATTACHMENT_ERROR:
            return {...state, error: action.error, status: 'error'}
        case ActionTypes.DELETE_SERVICE_REQUEST_ATTACHMENT_START:
            return {...state, status: 'deleting'}
        case ActionTypes.DELETE_SERVICE_REQUEST_ATTACHMENT_SUCCESS:
            return {...state, data: state.data.filter(({Id}) => Id !== action.id), status: 'deleted'}
        case ActionTypes.DELETE_SERVICE_REQUEST_ATTACHMENT_ERROR:
            return {...state, error: action.error, status: 'error'}
        default:
            return state;
    }
}