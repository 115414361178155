const employeeFiltersReducerDefaultState = {
    text: '',
    department: '',
}



export default (state = employeeFiltersReducerDefaultState, action) => {
    switch (action.type) {
        case 'SET_EMPLOYEE_TEXT_FILTER':
            return {...state, text: action.text}
        case 'SET_EMPLOYEE_DROPDOWN_FILTER':
            return {...state, department: action.text}
        default:
            return state;
    }
}