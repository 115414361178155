import {ActionTypes} from '../actionTypes';
import axios from 'axios';


export const deleteQuickLinkStart = () => ({type: ActionTypes.DELETE_QUICK_LINK_START})
export const deleteQuickLinkSuccess = (Id) => ({type: ActionTypes.DELETE_QUICK_LINK_SUCCESS, Id})
export const deleteQuickLinkError = (error) => ({type: ActionTypes.DELETE_QUICK_LINK_ERROR, error})


export const deleteQuickLink = ({Id}) => {
    return async (dispatch) => {
        try {
            dispatch(deleteQuickLinkStart())
            const response = await axios.delete(`/quicklinks/delete/${Id}`)

            if (response.status === 200) {
                dispatch(deleteQuickLinkSuccess(response.data.id))
            }

        } catch (err) {
            dispatch(deleteQuickLinkError(err))
        }
    }
}