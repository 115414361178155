import React, {Component} from 'react';
import Layout from '../layout/Layout';
import {Formik, Field, Form, FieldArray, ErrorMessage} from 'formik';
import * as Yup from 'yup';

const initialValues = {
    friends: [
        {
            name: '',
            email: ''
        }
    ]
}

const MiniFormik = () =>  {
    return (
        <Layout>
            <div className="card">
                <div className="card-body">
                    
                    <Formik 
                        initialValues={initialValues}
                        validationSchema={Yup.object({
                            friends: Yup.array().of(
                                Yup.object({
                                    name: Yup.string().required('Required'),
                                    email: Yup.string().email('Invalid email!').required('Required!'),
                                })
                            )
                        })}
                        onSubmit={values => {
                            setTimeout(() => {
                                alert(JSON.stringify(values, null, 2));
                            }, 500);
                        }}
                    >
                        {({isSubmitting, values, error, touched}) => ( 
                        <Form>
                            <FieldArray name="friends">
                                {({push, remove}) => (

                                <React.Fragment>
                                {values.friends && values.friends.length > 0 && values.friends.map((friend, index) =>    
                                <div className="row">
                                    <div className="col">
                                        <Field name={`friends[${index}].name`}>
                                            {({field, form}) => 
                                                <input {...field} type="text" placeholder="Nasim Noori" className="form-control" />
                                            }
                                        </Field>
                                        <ErrorMessage name={`friends[${index}].name`}>
                                            {msg => <div>{msg}</div>}
                                        </ErrorMessage>
                                    </div>
                                    <div className="col">
                                        <Field name={`friends[${index}].email`} type="email" className="form-control" placeholder="name@name.com" />
                                        <ErrorMessage name={`friends[${index}].email`}>
                                            {msg => <div>{msg}</div>}
                                        </ErrorMessage>
                                    </div>
                                    <div className="col">
                                        <button onClick={() => remove(index)} type="button" className="btn btn-danger">X</button>
                                    </div>
                                </div>
                                )}

                                <button type="button" onClick={() => push({name: '', email: ''})} className="btn btn-secondary">Add Friend</button>
                                
                                </React.Fragment>
                                )
                        }
                        </FieldArray>
                            <button type="submit" disabled={isSubmitting} className="btn btn-primary">Invite</button>   
                            
                        </Form>)}
                    </Formik>


                </div>
            </div>
        </Layout>
    );
}


export default MiniFormik;