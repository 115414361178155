import * as Yup from 'yup';


export const validationSchema = () => {
    return Yup.object().shape({
        Is_Employee_Hired__c: Yup.boolean(),
        Requires_Laptop__c: Yup.boolean(),
        Job_Title__c: Yup
            .string()
            .max(250, 'Must be 250 characters or less')
            .required('Required'),
        Division__c: Yup.string().required('Required'),
        Type__c: Yup.string().required('Required'),
        Planned_Start_Date__c: Yup.string().required('Required').nullable(),
        Employee_Name__c: Yup.string().when('Is_Employee_Hired__c', (val) => {
            if(val) return Yup.string().required('Required').nullable()
            return Yup.mixed()
        }),
        HW_SW_Requirements__c: Yup.string().when('Requires_Laptop__c', (val) => {
            if(val) return Yup.string().required('Required')
            return  Yup.mixed()
        }),
        Shipping_Required__c: Yup.string().when('Requires_Laptop__c', (val) => {
            if(val) return Yup.string().required('Required').nullable()
            return Yup.mixed()
        })
    })
}
