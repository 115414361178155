const defaultState = {
    text: '',
}



export default (state = defaultState, action) => {
    switch (action.type) {
        case 'SET_QUICK_LINKS_TEXT_FILTER':
            return {...state, text: action.text}
        default:
            return state;
    }
}