import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
// import 'chartjs-plugin-doughnutlabel';
import ChartDataLabels from 'chartjs-plugin-datalabels';



ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.register(ChartDataLabels);
// ChartJS.register(DoughnutLabel)

const options = {
    plugins: {
      ChartDataLabels,
      datalabels: {
        display: true,
        formatter: (value) => {
          return value + '%';
        },
        font: {
            size: 18,
        }
      },
    //   doughnutlabel: {
    //       labels: [{
    //         text: '550',
    //         font: {
    //           size: 20,
    //           weight: 'bold'
    //         }
    //       }, {
    //         text: 'total'
    //       }]
    //   }
    }
}


const data = {
    labels: ['Red', 'Blue'],
    datasets: [
      {
        label: '# of Votes',
        data: [79, 21],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
        //   'rgba(255, 206, 86, 0.2)',
        //   'rgba(75, 192, 192, 0.2)',
        //   'rgba(153, 102, 255, 0.2)',
        //   'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
        //   'rgba(255, 206, 86, 1)',
        //   'rgba(75, 192, 192, 1)',
        //   'rgba(153, 102, 255, 1)',
        //   'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
};

const MCChart = () => {
    return <Doughnut data={data} options={options} />;
}

export default MCChart
