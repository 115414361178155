import React, {useState} from 'react';
import Layout from '../layout/Layout';
import {Link} from 'react-router-dom'
import {connect} from 'react-redux';
import {columns, defaultSorted} from './columns';
import {columnsForApproval, defaultSortedForApproval} from './columnsForApproval';
import {columnsForProjects, defaultSortedForProjects} from './columnsForProjects';
import Datatable from '../layout/datatable';
import {Tabs, Tab, Badge,Nav} from 'react-bootstrap';
import {myRequests, requestsForApproval} from '../../redux/selectors/serviceRequestSelectors';
import DataTable2 from '../layout/DataTable2';
import {setServiceRequestTab} from '../../redux/actions/filters/serviceRequestTabs';
import TourRunner from '../tour/TourRunner';
import {updateEmpTour} from '../../redux/actions/sfAuth/updateEmpTours';
import {ITRequestsSteps} from '../tour/tourContent';
import {ITRequestsMobileSteps} from '../tour/tourMobileContent';


const ServiceRequests = (props) => {
    const {myServiceRequests, serviceRequestsForApproval, approvedProjects, sfAuth, selectedTab} = props;
    // const [access, setAccess] = useState(sfAuth.user.Access ? sfAuth.user.Access.split(";") : []);
    const access = sfAuth.user.Access ? sfAuth.user.Access.split(";") : [];
    
    const breadcrumb = [
        { name:'IT Requests', link: '' }
    ]

    const newForApprovalCount = serviceRequestsForApproval && serviceRequestsForApproval.filter((i) => (
        i.Purch_Approval_Status__c === 'Pending Approval' || 
        i.Proj_Estimate_Approval_Status__c  === 'Pending Approval' || 
        i.Proj_Estimate_Approval_Status__c  === 'Pending Estimate'
    )).length

    return (
        <Layout breadcrumb={breadcrumb}>
            <TourRunner 
                content={ITRequestsSteps} 
                mobileContent={ITRequestsMobileSteps}
                isClosed={sfAuth.tours && sfAuth.tours.IT_Requests__c}
                updateSalesforce={() => {
                    props.dispatch(updateEmpTour(sfAuth.tours.Id, {Id: sfAuth.tours.Id, IT_Requests__c: true}))
                }} 
            />
            <div className="card">
                <div className="card-header bg-sdi-black text-sdi-yellow">
                    IT Requests
                    <div className="float-right">
                        <Link className="btn btn-sm btn-primary " to={`/serviceRequests/new`} data-tour="it-req-1">
                            <i className="fas fa-plus"></i> New IT Request
                        </Link>
                    </div>
                </div>
                <div className="card-body">
                
                    <Tab.Container defaultActiveKey={selectedTab} onSelect={(k) => props.dispatch(setServiceRequestTab(k))} data-tour="it-req-2">
                        <Nav variant="tabs" defaultActiveKey="second">
                            <Nav.Item>
                                <Nav.Link eventKey="myRequests" className="notification-badge-container">
                                    My Requests 
                                </Nav.Link>
                            </Nav.Item>
                            {access && access.includes('ELT') && <Nav.Item>
                                <Nav.Link eventKey="projects" className="notification-badge-container">
                                    Projects 
                                    {/* {newProjectsCount !== 0 ? <span className="bg-danger text-light notification-badge">
                                        {newProjectsCount}
                                    </span> : ''} */}
                                </Nav.Link>
                            </Nav.Item>}
                            <Nav.Item>
                                <Nav.Link eventKey="needApproval" className="notification-badge-container">
                                    Approval Requests
                                    {newForApprovalCount !== 0 ? <span className="bg-danger text-light notification-badge">
                                        {newForApprovalCount}
                                    </span> : ''}
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content>
                            <Tab.Pane eventKey="myRequests">
                                <div className="pt-4" data-tour="it-req-5">
                                    <Datatable
                                        csvFileName={'IT-Requests.csv'}
                                        data={myServiceRequests}
                                        columns={columns}
                                        sortBy={defaultSorted}
                                    />
                                </div>
                            </Tab.Pane>
                            {access && access.includes('ELT') && <Tab.Pane eventKey="projects" >
                                <div className="pt-4">
                                    <DataTable2
                                        access={access}
                                        csvFileName={'IT-Requests.csv'}
                                        data={approvedProjects}
                                        columns={columnsForProjects}
                                        sortBy={defaultSortedForProjects}
                                    />
                                </div>
                            </Tab.Pane>}
                            <Tab.Pane eventKey="needApproval" >
                                <div className="pt-4">
                                    <Datatable
                                        csvFileName={'IT-Requests-For-Approval.csv'}
                                        data={serviceRequestsForApproval}
                                        columns={columnsForApproval}
                                        sortBy={defaultSortedForApproval}
                                    />
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>

                </div>
            </div>
        </Layout>
    )
}


const mapStateToProps = (state, props) => {
    return {
        sfAuth: state.sfAuth,
        selectedTab: state.serviceRequestTabs.key,
        myServiceRequests: myRequests(props.match.params && props.match.params.type === 'pending' ? 
                                        state.serviceRequests.data.filter((item) => item.Status__c !== "Complete" && item.Status__c !== "On Hold" && item.Status__c !== "Cancelled") :
                                        state.serviceRequests.data, state.sfAuth.user) || [],
        serviceRequestsForApproval: requestsForApproval(state.serviceRequests.data, state.sfAuth.user) || [],
        approvedProjects: state.serviceRequests.data.filter((item) => item.Status__c === "Assigned" && item.Parent_Type__c === 'Project Brief')
                                                    .sort((a, b) => {
                                                        if(a.Project_Priority_Rank__c && b.Project_Priority_Rank__c) 
                                                            return a.Project_Priority_Rank__c - b.Project_Priority_Rank__c
                                                    }) || []
    }
}

export default connect(mapStateToProps)(ServiceRequests);