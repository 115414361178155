export const fileSizeString = (fileSizeInBytes) => {
    var i = -1;
    var byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
    do {
        fileSizeInBytes = fileSizeInBytes / 1024;
        i++;
    } while (fileSizeInBytes > 1024);

    return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
};

export const validFile = (fileInput) => {
    var filePath = fileInput.value;
    var allowedExtensions = /(\.doc|\.docx|\.xls|\.xlsx|\.ppt|\.pdf|\.PDF|\.ai|\.eps|\.bmp|\.jpg|\.jpeg|\.png|\.gif)$/i;
    if(!allowedExtensions.exec(filePath)){
        return false;
    } else {
        return true
    }
}

export const handleIcons = (ContentType) => {
    if (ContentType == 'application/pdf') return 'fa-file-pdf text-danger'
    else if (ContentType == 'application/msword' || ContentType == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')
        return 'fa-file-word text-primary'
    else if (ContentType == 'application/vnd.ms-excel' || ContentType == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') 
        return 'fa-file-excel text-success'
    else return 'fa-file-image text-dark'
}


export const handleViewDisabled = (ContentType) => {
    if (ContentType == 'application/msword' || 
    ContentType == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || 
    ContentType == 'application/vnd.ms-excel' || 
    ContentType == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
        return true
    return false
}