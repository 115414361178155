export const columns = [
    {
        dataField: 'Id',
        text: 'Record ID',
        hidden: true,
        searchable: false,
        csvExport: false,
        editable: false
    }, {
        dataField: 'Name',
        text: 'Name',
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => {
            return { width: '15%', textAlign: 'center'};
        },
        style: (colum, colIndex) => {
            return { verticalAlign: 'middle', textAlign: 'center'};
        }
    }, {
        dataField: 'SDI_Company__c',
        text: 'Company',
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => {
            return { width: '15%', textAlign: 'center'};
        },
        style: (colum, colIndex) => {
            return { verticalAlign: 'middle', textAlign: 'center'};
        }
    }, {
        dataField: 'expr0',
        text: 'Total Visits',
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => {
            return { width: '15%', textAlign: 'center'};
        },
        style: (colum, colIndex) => {
            return { verticalAlign: 'middle', textAlign: 'center'};
        }
    }
]

export const defaultSorted = [
    {
        dataField: "Name",
        order: "desc"
    }
];


