import React from 'react';
import {connect} from 'react-redux'
import Layout from '../layout/Layout';
import moment from 'moment';
import {deleteNotification} from '../../redux/actions/notifications/deleteNotification'
import {updateNotification} from '../../redux/actions/notifications/updateNotification'
import { Link } from 'react-router-dom';

const NotificationsList = ({notifications, techTickets, dispatch}) => {

    const breadcrumb = [
        { name:'Notifications', link: '' }
    ]

    


    return (
        <Layout breadcrumb={breadcrumb}>
            <div className="card">
                <div className="card-header bg-sdi-black text-sdi-yellow">
                    Notifications
                </div>
                <div className="card-body" news-tour="3">
                    <div className={`list-group`}>
                        {notifications && notifications.map((i) => (
                            <NotificationsListItem 
                                notification={i} 
                                techTicket={techTickets && techTickets.find((t) => t.Id === i.Tech_Service_Ticket__c)} 
                                key={i.Id} 
                                dispatch={dispatch} />
                        ))}
                    </div>
                </div>
            </div>
        </Layout>
    )
}



const mapStateToProps = (state) => {
    return {
        notifications: state.notifications.data,
        techTickets: state.serviceRequests.data,

    }
}

export default connect(mapStateToProps)(NotificationsList);












const NotificationsListItem = ({notification, techTicket, dispatch}) => {

    const handleDelete = () => {
        dispatch(deleteNotification(notification.Id))
    }


    const handleUpdate = () => {
        dispatch(updateNotification(notification.Id, {Is_Read__c: true}))
    }


    const renderIcon = (type) => {
        switch(type) {
            case 'IT request completed':
                return "cil-info text-info";
            case 'IT request rejected':
                return 'cil-x-circle text-danger';
            case 'IT request approved':
                return 'cil-check-circle text-success';
            case 'IT request updated':
                return 'cil-sync text-success';
            case 'IT request assigned':
                return 'cil-user-plus text-success';
            case 'New Announcement':
                return 'cil-newspaper text-info';
            case 'New Corporate Link':
                return 'cil-link text-primary';
            case 'New Training':
                return 'cil-info text-primary';
            default:
                return 'cil-info text-info'
        }
    }


    const selectUrl = (notification) => {
        switch(notification.Type__c) {
            case 'IT request completed':
                return `/serviceRequests/${notification.Tech_Service_Ticket__c}`;
            case 'IT request rejected':
                return `/serviceRequests/${notification.Tech_Service_Ticket__c}`;
            case 'IT request approved':
                return `/serviceRequests/${notification.Tech_Service_Ticket__c}`;
            case 'IT request updated':
                return `/serviceRequests/${notification.Tech_Service_Ticket__c}`;
            case 'IT request assigned':
                return `/serviceRequests/${notification.Tech_Service_Ticket__c}`;
            case 'New Announcement':
                return `/newsEvents/${notification.Employee_Announcement__c}`;
            case 'New Corporate Link':
                return '/';
            case 'New Training':
                return `/helpDocs/${notification.Training__c}`;
            default:
                return '/notifications'
        }
    }


    const formatName = (type, techTicket = {}) => {
        switch(type) {
            case 'IT request completed':
                return `IT Request ${techTicket.Name && techTicket.Name.split("-").pop()} Completed`;
            case 'IT request rejected':
                return `IT Request ${techTicket.Name && techTicket.Name.split("-").pop()} Rejected`;
            case 'IT request approved':
                return `IT Request ${techTicket.Name && techTicket.Name.split("-").pop()} Approved`;
            case 'IT request updated':
                return `IT Request ${techTicket.Name && techTicket.Name.split("-").pop()} Updated`;
            case 'IT request assigned':
                return `IT Request ${techTicket.Name && techTicket.Name.split("-").pop()} Assigned`;
            default:
                return type
        }
    }


    return (
        <div className={`list-group-item d-flex justify-content-between align-items-center ${notification.Is_Read__c ? "bg-light" : ""}`}>
            
            <div className="flex-grow-1">
                <i className={`${renderIcon(notification.Type__c)} icon me-2 h5 float-left py-3 mr-3`}></i>
                <Link  
                    className="text-dark font-weight-bold"
                    to={selectUrl(notification)}
                    onClick={handleUpdate}
                    >
                        {formatName(notification.Type__c, techTicket)}
                </Link>
                <div className="d-none d-sm-block">{notification.Description__c}</div>
            </div>
            <span>
                <small className="mr-3 badge badge-light badge-pill">{moment(notification.CreatedDate).calendar()}</small>
                {/* <button className="btn btn-light btn-sm" onClick={handleDelete}><i class="cil-x"></i></button> */}
            </span>
        </div>
    )

}