
const icons = [
    {id: "cil-link", label: "Link"},
    {id: "cil-link-alt", label: "Link alt"},
    {id: "cil-external-link", label: "External link"},
    {id: "cil-info", label: "Info"},
    {id: "cil-heart", label: "Heart"},
    {id: "cil-star", label: "Star"},
    {id: "cil-bell", label: "Bell"},
    {id: "cil-plus", label: "Plus"},
    {id: "cil-minus", label: "Minus"},
    {id: "cil-warning", label: "Warning"},
    {id: "cib-adobe-acrobat-reader", label: "Adobe Acrobat Reader"},
    {id: "cib-facebook", label: "Facebook"},
    {id: "cib-github", label: "Github"},
    {id: "cib-google", label: "Google"},
    {id: "cib-linkedin", label: "LinkedIn"},
    {id: "cib-reddit", label: "Reddit"},
    {id: "cib-quora", label: "Quora"},
    {id: "cib-salesforce", label: "Salesforce"},
    {id: "cib-spotify", label: "Spotify"},
    {id: "cib-udemy", label: "Udemy"},
    {id: "cib-twitter", label: "Twitter"},
    {id: "cib-uber", label: "Uber"},
    {id: "cib-windows", label: "Windows"},
    {id: "cib-wordpress", label: "WordPress"},
    {id: "cib-youtube", label: "Youtube"},
    {id: "cib-zoom", label: "Zoom"},
    {id: "cil-bank", label: "Bank"},
    {id: "cil-institution", label: "Institution"},
    {id: "cil-school", label: "School"},
    {id: "cil-bar-chart", label: "Bar Chart"},
    {id: "cil-chart", label: "Chart"},
    {id: "cil-gift", label: "Gift"},
    {id: "cil-wallet", label: "Wallet"},
    {id: "cil-at", label: "At"},
    {id: "cil-bookmark", label: "Bookmark"},
    {id: "cil-book", label: "Book"},
    {id: "cil-image", label: "Image"},
    {id: "cil-library", label: "Library"},
    {id: "cil-birthday-cake", label: "Birthday Cake"},
    {id: "cil-coffee", label: "Coffee"},
    {id: "cil-applications", label: "Applications"},
    {id: "cil-arrow-circle-right", label: "Arrow circle right"},
    {id: "cil-arrow-right", label: "Arrow right"},
    {id: "cil-arrow-thick-right", label: "Arrow thick right"},
    {id: "cil-caret-right", label: "Caret right"},
    {id: "cil-check", label: "Check"},
    {id: "cil-check-circle", label: "Check circle"},
    {id: "cil-chevron-circle-right-alt", label: "Chevron circle right"},
    {id: "cil-chevron-double-right", label: "Cheron double right"},
    {id: "cil-chevron-right", label: "Chevron right"},
    {id: "cil-cog", label: "Cog"},
    {id: "cil-input", label: "Input"},
    {id: "cil-gauge", label: "Gauge"},
    
    {id: "cil-calendar ", label: "Calendar"},
    {id: "cil-clock ", label: "Clock"},
    {id: "cil-flag-alt", label: "Flag"},
    {id: "cil-globe-alt", label: "Globe"},
    {id: "cil-location-pin", label: "Location"},
    {id: "cil-map", label: "Map"},
    {id: "cil-camera", label: "Camera"},
    {id: "cil-media-play", label: "Media Play"},
    {id: "cil-tv", label: "TV"},
    {id: "cil-video", label: "Video"},
    {id: "cil-flower", label: "Flower"},
    {id: "cil-leaf", label: "Leaf"},
    {id: "cil-animal", label: "Animal"},
    {id: "cil-airplane-mode", label: "Airplane mode"},
    {id: "cil-phone", label: "Phone"},
    {id: "cil-pencil", label: "Pencil"},
    {id: "cil-pen", label: "Pen"},
    {id: "cil-bike", label: "Bike"},
    {id: "cil-user", label: "User"},
    {id: "cil-group", label: "Group"},
    {id: "cil-cloudy", label: "Cloudy"},
    {id: "cil-cloud", label: "Cloud"},
    {id: "cil-sun", label: "Sub"},
    {id: "cil-moon", label: "Moon"},
    {id: "cil-rain", label: "Rain"},
    {id: "cil-stream", label: "Stream"},
    
]

export default icons;