import React from 'react';
import Layout from '../layout/Layout';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import EmployeeForecastDetail from './EmployeeForecastDetail';
import {deleteEmployeeForecast} from '../../redux/actions/employeeForecast/deleteForecast';
import {employeeForecastResetStatus} from '../../redux/actions/employeeForecast/resetStatus';
import {deleteConfirmation, deleteConfirmed, deleteError} from '../sweetAlerts/deleteAlerts'

const EmployeeForecast = ({employeeForecast, match, dispatch, history, status}) => {

    const breadcrumb = [
        { name:'Employee Forecast', link: '/employeeForecasts' },
        { name: employeeForecast && employeeForecast.Name && employeeForecast.Name.split("-").pop(), link: '' }
    ]


    const handleDelete = async (Id) => {
        const willDelete = await deleteConfirmation();

        if(willDelete) {
            dispatch(deleteEmployeeForecast({Id})).then(() => {
                history.push(`/employeeForecasts`);
            })
        }
    }

    if(status === 'deleted') {
        deleteConfirmed();
        dispatch(employeeForecastResetStatus())
    }

    return (
        <Layout breadcrumb={breadcrumb}>
            <div className="card">
                <div className="card-header bg-sdi-black text-sdi-yellow">
                    Employee Forecast Details
                    <div className="btn-group float-right" role="group">
                        <Link className={`btn btn-sm btn-primary`} to={`/employeeForecasts/edit/${match.params.id}`}>
                            <i className="fas fa-edit"></i> Edit</Link>
                        <button className={`btn btn-sm btn-secondary`} onClick={() => handleDelete(match.params.id)}>
                            <i className="fas fa-trash-alt"></i> Delete</button>
                    </div>
                </div>
                <div className="card-body" data-tour="it-req-details-1">
                    <EmployeeForecastDetail employeeForecast={employeeForecast} />
                </div>
            </div>
        </Layout>
    )
}



const mapStateToProps = (state, props) => {
    return {
        employeeForecast: state.employeeForecasts.data.find((fc) => fc.Id === props.match.params.id),
        status: state.employeeForecasts.status,
    }
}
export default connect(mapStateToProps)(EmployeeForecast);