import React from 'react'
import {Link} from 'react-router-dom';
import moment from 'moment'
import DeleteServiceRequestLink from './DeleteServiceRequestLink';

export const columnsForApproval = [
    {
        dataField: 'Id',
        text: 'Record ID',
        hidden: true,
        searchable: false,
        csvExport: false,
        editable: false
    }, {
        dataField: 'Name',
        text: 'ID',
        sort: true,
        formatter: (cellContent, row) => {
            return (
                <Link to={`/serviceRequests/${row.Id}`}>{row.Name.split("-").pop()}</Link>
            )
        },
        editable: false,
        headerStyle: (colum, colIndex) => {
            return { width: '10%', textAlign: 'center'};
        },
        style: (colum, colIndex) => {
            return { verticalAlign: 'middle', textAlign: 'center'};
        }
    }, {
        dataField: 'CreatedDate',
        text: 'Date',
        sort: true,
        editable: false,
        formatter: (cellContent, row) => {
            return (row.CreatedDate && moment(row.CreatedDate).format('ll'))
        },
        headerStyle: (colum, colIndex) => {
            return { width: '15%', textAlign: 'center'}
        },
        style: (colum, colIndex) => {
            return { verticalAlign: 'middle', textAlign: 'center'};
        }
    }, {
        dataField: 'Parent_Type__c',
        text: 'Type',
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => {
            return { width: '15%', textAlign: 'center'}
        },
        style: (colum, colIndex) => {
            return { verticalAlign: 'middle', textAlign: 'center'};
        }
    }, {
        dataField: 'Category_Lookup__r.Name',
        text: 'Category',
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => {
            return { width: '20%', textAlign: 'center'};
        },
        style: (colum, colIndex) => {
            return { verticalAlign: 'middle', textAlign: 'center'};
        }
    }, {
        dataField: 'Priority__c',
        text: 'Priority',
        sort: true,
        editable: false,
        formatter: (cellContent, row) => {
            return priorityFormatter(cellContent)
        },
        headerStyle: (colum, colIndex) => {
            return { width: '20%', textAlign: 'center'};
        },
        style: (colum, colIndex) => {
            return { verticalAlign: 'middle', textAlign: 'center', fontWeight: 'bold'};
        }
    }, {
        dataField: 'Status__c',
        text: 'Status',
        sort: true,
        editable: false,
        formatter: (cellContent, row) => {
            return statusFormatter(row)
        },
        headerStyle: (colum, colIndex) => {
            return { width: '20%', textAlign: 'center'};
        },
        style: (colum, colIndex) => {
            return { verticalAlign: 'middle', textAlign: 'center', fontWeight: 'bold'};
        }
    }
]

export const defaultSortedForApproval = [
    {
        dataField: "Name",
        order: "desc"
    }
];

export const currencyFormat = (number, currency) => {
    return new Intl.NumberFormat(`en-US`, 
        { 
            style: 'currency', 
            currency: currency || 'CAD', 
            currencyDisplay: 'code' 
        }
    ).format(number);
}


const statusFormatter = (row) => {
    if(row.Parent_Type__c === 'Purchase Request') {
        return row.Purch_Approval_Status__c;
    } else if (row.Parent_Type__c === 'Project Brief') {
        if(row.Proj_Estimate_Approval_Status__c) {
            return row.Proj_Estimate_Approval_Status__c;
        }
        return row.Proj_Pre_Approval_Status__c;
    }

    return null;
}

const priorityFormatter = (cellContent) => {
    if(cellContent === '3 - Low') {
        return <span className="badge badge-success" style={{width: '50px'}}>Low</span>
    } else if (cellContent === '2 - Medium') {
        return <span className="badge badge-warning" style={{width: '50px'}}>Medium</span>
    } else if (cellContent === '1 - HIGH') {
        return <span className="badge badge-danger" style={{width: '50px'}}>High</span>
    }

    return <span className="badge badge-secondary" style={{width: '50px'}}>{cellContent}</span>
}