import React from 'react';

const Footer = () => {
    return (
        <footer className="c-footer">
            <div>
                &copy; 2021 SDI
            </div>

            {/* <div className="ml-auto">
                Powered by SDI
            </div> */}
        </footer>
    )
}

export default Footer;