import {createStore, combineReducers, applyMiddleware, compose} from 'redux'
import thunk from 'redux-thunk'
import serviceRequests from '../reducers/serviceRequests';
import auth from '../reducers/auth';
import sfAuth from '../reducers/sfAuth';
import accounts from '../reducers/accounts';
import projects from '../reducers/projects';
import users from '../reducers/users';
import requestCategories from '../reducers/requestCategories';
import serviceRequestAttachments from '../reducers/serviceRequestAttachments';
import businessUnits from '../reducers/businessUnits';
import employeeFilters from '../reducers/employeeFilters';
import contacts from '../reducers/contacts';
import newsEvents from '../reducers/newsEvents';
import wallOfWow from '../reducers/wow';
import serviceRequestTabs from '../reducers/serviceRequestTabs';
import settings from '../reducers/settings';
import helpDocs from '../reducers/helpDocs';
import helpDocsFilters from '../reducers/helpDocsFilters';
import formsFilters from '../reducers/formsFilters';
import quickLinks from '../reducers/quickLinks';
import quickLinksFilters from '../reducers/quickLinksFilters';
import notifications from '../reducers/notifications';
import employeeForecasts from '../reducers/employeeForecasts';
import careers from '../reducers/careers';
import forms from '../reducers/forms';
import masterEmployeeList from '../reducers/masterEmployeeList';
import peopleFilters from '../reducers/peopleFilters';
import peopleTimeAwayFilters from '../reducers/peopleTimeAwayFilters';
import userLogs from '../reducers/userLogs';
import userLogsFilter from '../reducers/logsFilters';
import orgChartBusinessUnits from '../reducers/orgChartBusinessUnits';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// export default() => {
    export const store = createStore(
        combineReducers({
            auth,
            sfAuth,
            settings,
            serviceRequests,
            serviceRequestAttachments,
            // accounts,
            projects,
            users,
            employeeFilters,
            serviceRequestTabs,
            requestCategories,
            businessUnits,
            contacts,
            newsEvents,
            // wallOfWow,
            helpDocs,
            helpDocsFilters,
            formsFilters,
            quickLinks,
            quickLinksFilters,
            notifications,
            employeeForecasts,
            careers,
            forms,
            masterEmployeeList,
            peopleFilters,
            peopleTimeAwayFilters,
            userLogs,
            userLogsFilter,
            orgChartBusinessUnits
        }),
        composeEnhancers(applyMiddleware(thunk))
        // applyMiddleware(thunk) //if not using devtools
    );

    // return store;
// }
