import React, {useState, useEffect} from 'react'
import moment from 'moment';
import axios from 'axios';
import download from 'js-file-download';
import HelpDocAttachmentItem from './HelpDocAttachmentItem';
import PDFViewer from './PDFViewer';


const HelpdocsAttachments = ({attachments, setError}) => {
    const [fileData, setFileData] = useState(null);
    const [downloading, setDownloading] = useState(null)

    
    const downloadAttachment = async(file) => {
        try {
            const res = await axios.get(`/serviceRequest/attachment/download`, {
                params: { id: file.Id, name: file.Name },
                responseType: 'arraybuffer'
            })
    
            if (res.status == 200) {
                download(res.data, file.Name);
            }
    
        } catch (error) {
            setError(error.message)
        }
    }

    const viewAttachment = async(file) => {
        setDownloading(true);
        try {
            const res = await axios.get(`/serviceRequest/attachment/download`, {
                params: { id: file.Id, name: file.Name },
                responseType: 'arraybuffer'
            })

            if (res.status == 200) {

                const blob = new Blob([res.data]);
                const url = URL.createObjectURL(blob);
                setFileData(url)
                setDownloading(false)

                // if (modalShow) {
                //     URL.revokeObjectURL(url);
                // }
            }
        } catch (error) {
            setDownloading(false)
            setError(error.message)
            setError(error.message)
        }
    }

    return (
        <>
        <ul class="list-group list-group-flush">

            {attachments.map((attachment) => (
                <HelpDocAttachmentItem download={downloadAttachment} viewAttachment={viewAttachment} attachment={attachment} />
            ))}
        </ul>
        {downloading && <div>Downloading file...</div>}
        {fileData && <PDFViewer file={fileData} /> }
        </>
    )
}

export default HelpdocsAttachments;