import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useField, useFormikContext } from 'formik';
import TooltipIcon from './TooltipIcon';

const DateInput = ({ label, tooltip, tooltipText, isRequired, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
    const [field, meta] = useField(props);
    const { setFieldValue } = useFormikContext();
    return (
        <div className={`form-group ${isRequired && 'required'}`}>
            <label className="control-label font-weight-bold mb-1" htmlFor={props.id || props.name}>{label}{` `}{tooltip && <TooltipIcon text={tooltipText} id={`${props.id}-tooltip`} />}</label>
            <DatePicker
                className={`form-control ${meta.touched && meta.error && 'is-invalid'}`}
                {...field}
                {...props}
                autoComplete='off'
                selected={(field.value && new Date(field.value)) || null}
                onChange={val => {
                    setFieldValue(field.name, val);
                }}
            />
            {meta.touched && meta.error ? (
                <div className="invalid-feedback" style={{display: 'block'}}>{meta.error}</div>
            ) : null}
        </div>
    );
};

export default DateInput;
