import React, {useState} from 'react'
import {Modal, Button} from 'react-bootstrap'
import RichTextToHTML from '../layout/RichTextToHTML';
import moment from 'moment';
import NewsEventsForm from './NewsEventsForm';
import {connect} from 'react-redux';
import {updateNewsEvents, updateNewsEventsSuccess} from '../../redux/actions/newsEvents/updateNewsEvents';
import {uploadNewsEventsAttachment} from '../../redux/actions/newsEvents/uploadNewsEventsAttachment';
import ImageUploadForm from './ImageUploadForm';
import {newsEventsResetStatus} from '../../redux/actions/newsEvents/resetStatus';
import {deleteNewsEventsAttachment} from '../../redux/actions/newsEvents/deleteNewsEventsAttachment';
import NewsEventsImage from './NewsEventImage';
import {deleteConfirmation, deleteConfirmed, deleteError} from '../sweetAlerts/deleteAlerts'
import {deleteNewsEvents} from '../../redux/actions/newsEvents/deleteNewsEvents';

const NewsEventsEdit = ({event, user, dispatch, status, error}) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleDelete = async (Id) => {
        const willDelete = await deleteConfirmation();

        if(willDelete) {
            dispatch(deleteNewsEvents({Id})).then(() => {
                dispatch(newsEventsResetStatus())
            })
        }
    }

    const splitPopName = (name) => {
        return name.split("-").pop();
    }

    const renderModal = () => {
        return (
            <Modal show={show} onHide={handleClose} size="lg" backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title style={{margin: '0 auto'}}>Edit News & Events Post ({splitPopName(event.Name)})</Modal.Title>
                </Modal.Header>

                <Modal.Body>

                    <NewsEventsForm 
                        event={event}
                        user={user}
                        closeModal={handleClose}
                        onSubmit={(data) => {
                            dispatch(updateNewsEvents(event.Id, data))
                        }}
                        resetStatus={() => {
                            dispatch(newsEventsResetStatus())
                        }}
                        status={status}
                        error={error} 
                    />

                    {/* {event.Attachments && event.Attachments.records[0] ? 
                    <NewsEventsImage 
                        event={event} 
                        status={status}
                        image={event.Attachments.records[0]}
                        deleteImage={(id, eventId) => {
                            dispatch(deleteNewsEventsAttachment(id, eventId))
                        }}
                        updateNews={(id, data) => {
                            dispatch(updateNewsEventsSuccess(id, data));
                        }} /> :
                    <ImageUploadForm 
                        status={status}
                        error={error}
                        eventId={event.Id}
                        onSubmit={(data) => {
                            dispatch(uploadNewsEventsAttachment(event.Id, data))
                        }} 
                        resetStatus={() => {
                            dispatch(newsEventsResetStatus())
                        }}
                    />} */}
                    
                </Modal.Body>

                {/* <Modal.Footer style={{display: 'block'}}>
                    <div className="text-center">
                        <Button variant="primary" onClick={handleClose}>
                            Close
                        </Button>
                    </div>
                </Modal.Footer> */}
            </Modal>
        )
    }

    return (
        <div news-tour="7">
            <button className="btn btn-link m-0 p-0" onClick={handleShow} >
                <i className="fas fa-edit"></i> 
            </button>
            {` / `}
            <button className="btn btn-link m-0 p-0" onClick={() => handleDelete(event.Id)}>
                <i className="fas fa-trash-alt text-danger"></i> 
            </button>
            {show && renderModal()}
        </div>
    )
}



const mapStateToProps = (state, props) => {
    return {
        user: state.sfAuth.user,
        status: state.newsEvents.status,
        error: state.newsEvents.error,
        
    }
}

export default connect(mapStateToProps)(NewsEventsEdit);