import React, {useState} from 'react'
import {Modal} from 'react-bootstrap'
import DatatableSm from '../../layout/DatatableSm';
import {columns, defaultSorted} from './logsByVisitorsColumns'

const ChartDataModal = ({data, title}) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const renderModal = () => {
        return (
            <Modal size="md" show={show} onHide={handleClose} centered>
                <Modal.Header closeButton style={{borderBottom: '0 none'}}>
                    <Modal.Title style={{margin: '0 auto'}}>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ul className="list-group">
                    {data && data.sort((a,b) => b.value - a.value).map((item) => {
                        return (
                            <li className="list-group-item d-flex justify-content-between align-items-center" key={item.name}>
                                {item.name}
                                <span className="badge badge-light badge-pill">{item.value}</span>
                            </li>
                        )
                    })}
                    </ul>
                </Modal.Body>
            </Modal>
        )
    }



    return (
        <>
            <a onClick={handleShow} style={{textDecoration: 'none'}} href="#0"><i className="fas fa-list text-dark"></i></a>
            
            
            {show && renderModal()}

        </>
    );
}

export default ChartDataModal;