
export const helpDocsSearch = (docs, {text, category, subCategory}) => {
    // const textArray = text && text.split(' ');

    // const result = []

    // textArray && textArray.map((txt) => {
    //     const found = docs.find((doc) => doc.Name.toLowerCase() === txt.toLowerCase())

    //     result.push(found)
    // })

    // return result.length > 0 ? result : docs;

    // textArray && textArray.map((text) => {
    //     return docs && docs.filter((doc) => doc.Name.toLowerCase().includes(text.toLowerCase()))
    // })

    
    return docs.filter((doc) => {
        

        // let textMatch = doc;

        // for(var txt of textArray) {
        //     if(txt) {
        //         if(doc.Name && (doc.Name.toLowerCase().includes(txt.toLowerCase()) || (doc.Training_Type__c && doc.Training_Type__c.toLowerCase().includes(txt.toLowerCase())))) {
        //             textMatch = true;
        //         } else {
        //             textMatch = false;
        //         }
        //     } else {
        //         textMatch = doc;
        //     }
        // }


        const textMatch = text ? (
            doc.Name && (doc.Name.toLowerCase().includes(text.toLowerCase()) || (doc.Training_Type__c && doc.Training_Type__c.toLowerCase().includes(text.toLowerCase())))
            ) : doc;

        const categoryMatch = category ? (doc.Category__c && doc.Category__c.toLowerCase() === category.toLowerCase()) : doc;
        const subCategoryMatch = subCategory ? (doc.Sub_Category__c && doc.Sub_Category__c.toLowerCase() === subCategory.toLowerCase()) : doc;

        return textMatch && categoryMatch && subCategoryMatch;
    })
};


const isWhiteSpace = (s) => {
    return /\s/g.test(s)
}