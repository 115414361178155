import axios from 'axios'
import {ActionTypes} from '../actionTypes';


export const deleteServiceRequestAttachmentStart = () => ({type: ActionTypes.DELETE_SERVICE_REQUEST_ATTACHMENT_START})
export const deleteServiceRequestAttachmentSuccess = (id) => ({type: ActionTypes.DELETE_SERVICE_REQUEST_ATTACHMENT_SUCCESS, id})
export const deleteServiceRequestAttachmentError = (error) => ({type: ActionTypes.DELETE_SERVICE_REQUEST_ATTACHMENT_ERROR, error})


export const deleteServiceRequestAttachment = (id) => {
    return async (dispatch) => {
        try {
            dispatch(deleteServiceRequestAttachmentStart())
            
            const response = await axios.delete(`/serviceRequest/attachment/delete/${id}`)
            if (response.status == 200) {
                dispatch(deleteServiceRequestAttachmentSuccess(response.data.id))
            }

        } catch (err) {
            dispatch(deleteServiceRequestAttachmentError(err))
        }
    }
}