import React from 'react'
import Account from './Account'
import {NavLink, Link} from 'react-router-dom'
import moment from 'moment'
import onWhiteLogo from '../../../images/Logos/SDI_logo_black.png' 
import Breadcrumb from './Breadcrumb'
import Notifications from './Notifications'
import Tasks from './Tasks'
import Messages from './Messages'

const Header = (props) => {
    return (
        
        <header className="c-header c-header-light c-header-fixed c-header-with-subheader">
            <button
                className="c-header-toggler c-class-toggler d-lg-none mfe-auto"
                type="button"
                data-target="#sidebar"
                data-class="c-sidebar-show">
                <i className="fas fa-bars c-icon c-icon-lg"></i>
            </button>
            <ul className="d-block d-sm-none c-header-nav ">
                <li className="c-header-nav-item px-3 mr-2" style={{paddingTop: '0.6em'}}>
                    <button className="btn btn-link c-header-nav-link" onClick={() => window.location.reload()}><i className="cil-sync" style={{fontSize: '1.09375rem'}}></i></button>
                </li>
            </ul>
            <NavLink className="c-header-brand d-lg-none" to="/">
                <div width="130" height="46" alt="CoreUI Logo">
                    <img height="40" src={onWhiteLogo} alt="" />
                </div>
            </NavLink>
            <button
                className="c-header-toggler c-class-toggler mfs-3 d-md-down-none"
                type="button"
                data-target="#sidebar"
                data-class="c-sidebar-lg-show"
                responsive="true">
                <i className="fas fa-bars c-icon c-icon-lg"></i>
            </button>
            <ul className="c-header-nav  d-md-down-none" data-tour="dash-1">
                <li className="c-header-nav-item px-2 ml-2">
                    {'Last synced at '}<b>{props.appSettings.appReloadTime && moment(props.appSettings.appReloadTime).format('LT')}</b>
                </li>
                <li className="c-header-nav-item px-0">
                    <button className="btn btn-link c-header-nav-link" onClick={() => window.location.reload()}><i className="cil-sync" style={{fontSize: '1.09375rem'}}></i></button>
                </li>
                
                {/* <li className="c-header-nav-item px-3">
                    <a className="c-header-nav-link" href="#">Settings</a>
                </li> */}
            </ul>

            

            {/* <ul className="c-header-nav mfs-auto">
                <li className="c-header-nav-item px-3 c-d-legacy-none">
                    <button
                        className="c-class-toggler c-header-nav-btn"
                        type="button"
                        id="header-tooltip"
                        data-target="body"
                        data-class="c-dark-theme"
                        data-toggle="c-tooltip"
                        data-placement="bottom"
                        title=""
                        data-original-title="Toggle Light/Dark Mode">
                        <i className="far fa-moon c-icon c-d-dark-none"></i>
                        <i className="far fa-sun c-icon c-d-default-none"></i>
                    </button>
                </li>
            </ul> */}

            <ul className="c-header-nav ml-auto mr-4">
                <div className="d-none d-sm-block">
                    <Link to="/profile" style={{color: 'black', textDecoration: 'none'}}>{props.user && props.user.displayName}</Link>
                </div>
                <Notifications notifications={props.notifications} dispatch={props.dispatch} techTickets={props.techTickets} />
                {/* <Tasks/>*/}
                {/* <Messages/>  */}
                <Account userPhoto={props.userPhoto} user={props.user} />
            </ul>
            {props.hide === true ? null : <Breadcrumb breadcrumb={props.breadcrumb}/>}
        </header>
    )
}

export default Header;