import background1 from '../../images/backgrounds/background1.jpg'
import background2 from '../../images/backgrounds/background2.jpeg'
import background3 from '../../images/backgrounds/background3.jpeg'
import background4 from '../../images/backgrounds/background4.jpeg'
import background5 from '../../images/backgrounds/background5.jpeg'
import background6 from '../../images/backgrounds/background6.jpeg'
import background7 from '../../images/backgrounds/background7.jpeg'
import background8 from '../../images/backgrounds/background8.jpeg'
import background9 from '../../images/backgrounds/background9.png'
import background10 from '../../images/backgrounds/background10.jpeg'
import background11 from '../../images/backgrounds/background11.jpg'
import background12 from '../../images/backgrounds/background12.png'
import background13 from '../../images/backgrounds/background13.jpeg'
import background14 from '../../images/backgrounds/background14.jpeg'
import background15 from '../../images/backgrounds/background15.png'
import background16 from '../../images/backgrounds/background16.jpeg'
import background17 from '../../images/backgrounds/background17.jpeg'
import background18 from '../../images/backgrounds/background18.jpeg'


const images = [
    { id: 1, src: background1, title: 'background1', description: 'bar' },
    { id: 2, src: background2, title: 'background2', description: 'bar' },
    { id: 3, src: background3, title: 'background3', description: 'bar' },
    { id: 4, src: background4, title: 'background4', description: 'bar' },
    { id: 5, src: background5, title: 'background5', description: 'bar' },
    { id: 6, src: background6, title: 'background6', description: 'bar' },
    { id: 7, src: background7, title: 'background7', description: 'bar' },
    { id: 8, src: background8, title: 'background8', description: 'bar' },
    { id: 9, src: background9 , title: 'background9', description: 'bar' },
    { id: 10, src: background10 , title: 'background10', description: 'bar' },
    { id: 11, src: background11 , title: 'background11', description: 'bar' },
    { id: 12, src: background12 , title: 'background12', description: 'bar' },
    { id: 13, src: background13 , title: 'background13', description: 'bar' },
    { id: 14, src: background14 , title: 'background14', description: 'bar' },
    { id: 15, src: background15 , title: 'background15', description: 'bar' },
    { id: 16, src: background16 , title: 'background16', description: 'bar' },
    { id: 17, src: background17 , title: 'background17', description: 'bar' },
    { id: 18, src: background18 , title: 'background18', description: 'bar' },
];


export default images;