import {ActionTypes} from '../actionTypes';
import axios from 'axios';

export const fetchAllUsersStart = () => ({type: ActionTypes.FETCH_USERS_START});
export const fetchAllUsersSuccess = (users) => ({type: ActionTypes.FETCH_USERS_SUCCESS, users});
export const fetchAllUsersError = (error) => ({type: ActionTypes.FETCH_USERS_ERROR, error});

export const fetchAllUsers = () => {
    return async (dispatch) => {
        try {
            dispatch(fetchAllUsersStart());
            const response = await axios.get('/users/all')

            if (response.data) {
                const newData = response.data.map((rec) => {
                    const blob = new Blob([rec.Photo]);
                    const url = URL.createObjectURL(blob);
                    return {...rec, PhotoUrl: url}
                })
                dispatch(fetchAllUsersSuccess(newData))
            }
        } catch (err) {
            dispatch(fetchAllUsersError(err));
        }
    }
}