const logsFiltersReducerDefaultState = {
    time: 'LAST_WEEK'
}


// eslint-disable-next-line import/no-anonymous-default-export
export default (state = logsFiltersReducerDefaultState, action) => {
    switch (action.type) {
        case 'SET_LOGS_TIME_FILTER':
            return {...state, time: action.time}
        default:
            return state;
    }
}