import React, {useState} from 'react'
import {updateServiceRequest} from '../../redux/actions/serviceRequests/updateServiceRequest';
import {serviceRequestResetStatus} from '../../redux/actions/serviceRequests/resetStatus';
import { useDispatch, useSelector  } from 'react-redux'

const PriorityEditor = (props) => {
    const [lineValue, setLineValue] = useState(props.value ? props.value : 0)

    const dispatch = useDispatch()
    // const status = useSelector(state => state.orderItems.status);


    const onChange = (e) => {
        const value = e.target.value
        if (!value || value.match(/^\d{1,}?$/)) {
            setLineValue(value)
        }
    }

    const getValue = () => {
        dispatch(updateServiceRequest(props.row.Id, {rank: lineValue, requestType: 'priorityRank'})).then(() => {
            dispatch(serviceRequestResetStatus());
        });
        return parseInt(lineValue, 10);
    }

      const { value, onUpdate, ...rest } = props;
      return [
        <div className="form-row">
            <div className="col">
                <input { ...rest } type="text" key="line" value={lineValue} onChange={onChange}  className="form-control form-control-sm" />
            </div>
            <div className="col">
            <button
                key="submit"
                className="btn btn-sm btn-primary"
                onClick={ () => onUpdate(getValue()) }
            >
                done
            </button>
            </div>
        </div>
      ];
  }



export default PriorityEditor;