import {ActionTypes} from '../actionTypes';
import axios from 'axios';

export const updateNotificationStart = () => ({type: ActionTypes.UPDATE_NOTIFICATION_START});
export const updateNotificationSuccess = (id, updates) => ({type: ActionTypes.UPDATE_NOTIFICATION_SUCCESS, id, updates});
export const updateNotificationError = (error) => ({type: ActionTypes.UPDATE_NOTIFICATION_ERROR, error});



export const updateNotification = (id, updates = {}) => {
    return async (dispatch) => {

        try {
            dispatch(updateNotificationStart());

            const data = {
                ...updates,
                Id: id
            }

            const response = await axios.patch('/notifications/update', data)
            
            if (response.data) {
                dispatch(updateNotificationSuccess(response.data.notification.Id, response.data.notification))
            }
        } catch (err) {
            dispatch(updateNotificationError(err.message));
        }
    }
}