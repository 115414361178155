import moment from "moment"


export const getRadios = () => {
    return [
        { name: 'All', value: 'ALL', startDate: '', endDate: '', days: 1},
        { name: 'This Year', value: 'THIS_YEAR', startDate: yearStart(), endDate: today(), days: calculateDays(yearStart(), today())},
        { name: 'Last Year', value: 'LAST_YEAR', startDate: lastYearStart(), endDate: lastYearEnd(), days: calculateDays(lastYearStart(), lastYearEnd())},
        { name: 'This Month', value: 'THIS_MONTH', startDate: monthStart(), endDate: today(), days: calculateDays(monthStart(), today())},
        { name: 'Last Month', value: 'LAST_MONTH', startDate: lastMonthStart(), endDate: lastMonthEnd(), days: calculateDays(lastMonthStart(), lastMonthEnd())},
        { name: 'This Week', value: 'THIS_WEEK', startDate: weekStart(), endDate: today(), days: calculateDays(weekStart(), today())},
        { name: 'Last Week', value: 'LAST_WEEK', startDate: lastWeekStart(), endDate: lastWeekEnd(), days: calculateDays(lastWeekStart(), lastWeekEnd())},
    ];
}


export const calculateDays = (startDate, endDate) => {
    return moment(endDate).diff(moment(startDate), 'days')
}

export const today = () => {
    return moment().format('YYYY-MM-DD')
}

export const yearStart = () => {
    const start = moment().startOf("year").format("YYYY-MM-DD")

    return start
}

export const lastYearStart = () => {
    const start = moment().subtract(1, 'years').startOf('year').format("YYYY-MM-DD")

    return start
}

export const lastYearEnd = () => {
    const end = moment().subtract(1, 'years').endOf("year").format("YYYY-MM-DD")
    return end
}

export const monthStart = () => {
    const start = moment().startOf("month").format("YYYY-MM-DD")
    
    return start
}

export const lastMonthStart = () => {
    const start = moment().subtract(1, "month").startOf("month").format("YYYY-MM-DD")

    return start
}

export const lastMonthEnd = () => {
    const end = moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD")

    return end
}

export const weekStart = () => {
    const start = moment().startOf("week").format("YYYY-MM-DD")

    return start
}

export const lastWeekStart = () => {
    const start = moment().subtract(1, "week").startOf("week").format("YYYY-MM-DD")

    return start
}

export const lastWeekEnd = () => {
    const end = moment().subtract(1, "week").endOf("week").format("YYYY-MM-DD")
    
    return end
}


