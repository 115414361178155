import {ActionTypes} from '../actionTypes';
import axios from 'axios';

export const fetchNotificationsStart = () => ({type: ActionTypes.FETCH_NOTIFICATIONS_START});
export const fetchNotificationsSuccess = (payload) => ({type: ActionTypes.FETCH_NOTIFICATIONS_SUCCESS, payload});
export const fetchNotificationsError = (error) => ({type: ActionTypes.FETCH_ORG_CONTACTS_ERROR, error});

export const fetchNotifications = () => {
    return async (dispatch) => {
        try {
            dispatch(fetchNotificationsStart());
            const response = await axios.get('/notifications/all')


            if (response.data) {
                dispatch(fetchNotificationsSuccess(response.data))
            }
        } catch (err) {
            dispatch(fetchNotificationsError(err));
        }
    }
}