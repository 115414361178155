import {connect} from 'react-redux';
import moment from 'moment'
import Layout from '../../layout/Layout';
import VacationChart from '../peopleDashboard/VacationChart';
import {getAllocationTotal, getTakenTotal, getBookedTotal, 
    getTakenPercentage, getRemainingDays} from '../../../redux/selectors/peopleDashboardSelector';

const MyDashboard = ({data}) => {

    const breadcrumb = [
        { name:'My Metrics', link: '' }
    ]

    const chartData = (data) => {
        return {
            bookedVacation: getBookedTotal(data.Employee_Time_Away__r, 'Vacation_Salary'),
            takenVacation: getTakenTotal(data.Employee_Time_Away__r, 'Vacation_Salary'),
            remainingVaction: getRemainingDays(data.Employee_Time_Away__r, 'Vacation_Salary') 
        };
    }


    const renderRow = (eta) => {
        return (
            <tr className={`align-middle ${'Taken'}`}>
                <td className="text-center px-1 align-middle">
                    <div className="avatar avatar-md">
                        {eta.Request_Type__c === 'Sick' ? <i className="fas fa-thermometer-half"></i> : <i className="fas fa-plane"></i>}
                    </div>
                </td>
                <td>
                    <div>{eta.Request_Type__c.split('_').join(' ')}</div>
                </td>
                <td className={`text-center align-middle`}>
                    <div className={` ${eta.Request_Status__c === 'APPROVED' ? 'text-success' : 'text-warning'}`}>{eta.Request_Status__c}</div>
                </td>
                <td className="text-right align-middle pr-3">
                    {moment(eta.Time_Away_Date__c).format('ll')}
                </td>
            </tr>
        )
    }


    const filterUsed = (data, type) => {
        const result = data.filter((i) => i.Type__c === 'Used' && i.Status__c === type).sort((left, right) => {
                return moment.utc(left.Time_Away_Date__c).diff(moment.utc(right.Time_Away_Date__c))
        })

        if(result.length > 0) {
            return result.map((eta) => (
                renderRow(eta)
            ))
        }
        
        return <tr><td colSpan="4" className="text-center">No time {type.toLowerCase()}.</td></tr>
    }

    return (
        <Layout breadcrumb={breadcrumb}>
            <div className="row">
                <div className="col-md-8">
                    <div className="card ">
                        <div className="card-header bg-sdi-black text-sdi-yellow">
                            {data.Display_Name__c}
                        </div>
                        <div className="card-body">
                            {data.Employee_Time_Away__r ? 
                            <>
                            <table className="table table-striped table-bordered mb-4">
                                <thead>
                                    <tr className="text-center">
                                        <th>Type</th>
                                        <th>Allocation</th>
                                        <th>Booked</th>
                                        <th>Taken</th>
                                        <th>Remaining</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th>Vacation Salary</th>
                                        <td className="text-center">{getAllocationTotal(data.Employee_Time_Away__r, 'Vacation_Salary')}</td>
                                        <td className="text-center">{getBookedTotal(data.Employee_Time_Away__r, 'Vacation_Salary')}</td>
                                        <td className="text-center">{getTakenTotal(data.Employee_Time_Away__r, 'Vacation_Salary')}</td>
                                        <td className="text-center">{getRemainingDays(data.Employee_Time_Away__r, 'Vacation_Salary')}</td>
                                    </tr>
                                    <tr>
                                        <th>Carry Over</th>
                                        <td className="text-center">{getAllocationTotal(data.Employee_Time_Away__r, 'Carry_Over')}</td>
                                        <td className="text-center">{getBookedTotal(data.Employee_Time_Away__r, 'Carry_Over')}</td>
                                        <td className="text-center">{getTakenTotal(data.Employee_Time_Away__r, 'Carry_Over')}</td>
                                        <td className="text-center">{getRemainingDays(data.Employee_Time_Away__r, 'Carry_Over')}</td>
                                    </tr>
                                    <tr>
                                        <th>Sick</th>
                                        <td className="text-center">{getAllocationTotal(data.Employee_Time_Away__r, 'Sick')}</td>
                                        <td className="text-center">{getBookedTotal(data.Employee_Time_Away__r, 'Sick')}</td>
                                        <td className="text-center">{getTakenTotal(data.Employee_Time_Away__r, 'Sick')}</td>
                                        <td className="text-center">{getRemainingDays(data.Employee_Time_Away__r, 'Sick')}</td>
                                    </tr>
                                    <tr>
                                        <th>Personal</th>
                                        <td className="text-center">{getAllocationTotal(data.Employee_Time_Away__r, 'Personal Day')}</td>
                                        <td className="text-center">{getBookedTotal(data.Employee_Time_Away__r, 'Personal Day')}</td>
                                        <td className="text-center">{getTakenTotal(data.Employee_Time_Away__r, 'Personal Day')}</td>
                                        <td className="text-center">{getRemainingDays(data.Employee_Time_Away__r, 'Personal Day')}</td>
                                    </tr>
                                    <tr>
                                        <th>Float</th>
                                        <td className="text-center">{getAllocationTotal(data.Employee_Time_Away__r, 'Float')}</td>
                                        <td className="text-center">{getBookedTotal(data.Employee_Time_Away__r, 'Float')}</td>
                                        <td className="text-center">{getTakenTotal(data.Employee_Time_Away__r, 'Float')}</td>
                                        <td className="text-center">{getRemainingDays(data.Employee_Time_Away__r, 'Float')}</td>
                                    </tr>
                                    <tr>
                                        <th>Volunteer</th>
                                        <td className="text-center">{getAllocationTotal(data.Employee_Time_Away__r, 'Volunteer Day')}</td>
                                        <td className="text-center">{getBookedTotal(data.Employee_Time_Away__r, 'Volunteer Day')}</td>
                                        <td className="text-center">{getTakenTotal(data.Employee_Time_Away__r, 'Volunteer Day')}</td>
                                        <td className="text-center">{getRemainingDays(data.Employee_Time_Away__r, 'Volunteer Day')}</td>
                                    </tr>
                                    <tr>
                                        <th>Vacation</th>
                                        <td className="text-center">{getAllocationTotal(data.Employee_Time_Away__r, 'Vacation')}</td>
                                        <td className="text-center">{getBookedTotal(data.Employee_Time_Away__r, 'Vacation')}</td>
                                        <td className="text-center">{getTakenTotal(data.Employee_Time_Away__r, 'Vacation')}</td>
                                        <td className="text-center">{getRemainingDays(data.Employee_Time_Away__r, 'Vacation')}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="table table-sm table-striped border">
                                <thead>
                                    <tr className="align-middle">
                                        <th className="text-center" colspan="4">Booked</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filterUsed(data.Employee_Time_Away__r || [], 'Booked')}
                                </tbody>

                                <thead>
                                    <tr className="align-middle">
                                        <th className="text-center" colspan="4">Taken</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filterUsed(data.Employee_Time_Away__r || [], 'Taken')}
                                </tbody>
                            </table>
                            </> : 
                            ''}
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card">
                        <dic className="card-body">
                            {data.Employee_Time_Away__r && <VacationChart data={chartData(data)} />}
                        </dic>
                    </div>
                </div>
            </div>
        </Layout>
    )
}


const mapStateToProps = (state) => {
    return {
        data: state.masterEmployeeList.data.find((user) => user.Employee_Number__c === state.sfAuth.user.EmployeeID) || {}
    }
}

export default connect(mapStateToProps)(MyDashboard);