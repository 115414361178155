import React,{useState, useEffect, useMemo} from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import TextInput from '../formFields/TextInput';
import TextareaInput from '../formFields/TextareaInput';
import {validationSchema} from './validationSchema';
import icons from './icons';
import TypeaheadInput from '../formFields/TypeaheadInput';
import {selectedIcon} from '../../redux/selectors/typeaheadOptions';

const QuickLinksForm = ({link, error, onSubmit, resetStatus, closeModal, status, user}) => {
    const [icon, setIcon] = useState(link ? selectedIcon(icons, link.Icon__c) : []);


    const [initialValues, setInitialValues] = useState({
        Label__c: link ? link.Label__c : '',
        URL__c: link ? link.URL__c : '',
        Icon__c: link ? link.Icon__c : '',
        Icon_Color__c: link ? link.Icon_Color__c : '',
        User__c: link ? link.User__c : user.Id,
    });



    useEffect(() => {
        if(status === 'updated' || status === 'saved') {
            resetStatus();
            closeModal();
        }
    }, [status]);


    return (
        <Formik
            initialValues={ 
                initialValues
            }
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
                    // setSaving(true);
                    let newObj = {}
                    if(!/^https?:\/\//i.test(values.URL__c)) {
                        console.log('here');
                        newObj = {...values, URL__c: 'http://' + values.URL__c}
                    } else {
                        newObj = values;
                    }
                    
                    onSubmit(newObj);
                    setSubmitting(false);
            }}
        >
            {({isSubmitting, values, errors, touched, handleChange, handleBlur, setFieldValue, setFieldTouched}) => {
                return (
                    <Form className="needs-validation container">
                        {status === 'error' &&
                        <div className="alert alert-danger">{error}</div>}
                        {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                    <div className="row">
                        <div className="col-md-6">
                            <TextInput 
                                isRequired={true}
                                type="text"
                                label="Title" 
                                name="Label__c" 
                                    />
                        </div>
                        <div className="col-md-6">
                            <TextInput 
                                isRequired={true}
                                type="text"
                                label="URL" 
                                name="URL__c" 
                                    />
                        </div>
                        <div className="col-md-6">
                            <TypeaheadInput 
                                // disabled={!values.onBehalf}
                                isRequired={true}
                                name="Icon__c" 
                                touched={touched}
                                selected={icon}
                                setFieldTouched={setFieldTouched}
                                choices={icons} 
                                setFieldValue={setFieldValue} 
                                errors={errors}
                                label={'Icon'} 
                                placeholder="Choose an icon..."
                                renderMenuItemChildren={(option) => (
                                    <div>
                                        <span className="mr-3 align-middle"><i className={`${option.id} h5`}></i></span>
                                        <span>{option.label}</span>
                                    </div>
                                )}
                                // tooltip={true}
                                // tooltipText="Search and select the employee this request originated from"
                                />
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="control-label font-weight-bold mb-1">Icon Color</label>
                                <Field type="color" className="form-control" name="Icon_Color__c" value={values.Icon_Color__c} />
                            </div>
                        </div>
                        
                    </div>
                    <div className="row mb-3">
                        <div className="col-md-4"></div>
                        <div className="col-md-4 text-center">
                            <button 
                                className="btn btn-primary btn-block"
                                disabled={status === 'saving' || status === 'updating'} 
                                type="submit">
                            {status === 'saving' || status === 'updating' ? 
                            <>
                                <span className="spinner-border spinner-border-sm"></span>
                                {' Saving...'}
                            </>
                            : 'Save'}
                            </button>
                        </div>
                        <div className="col-md-4"></div>
                    </div>
                </Form>
                )
            }}
        </Formik>
    )
}

export default QuickLinksForm;