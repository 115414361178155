
import React, {useState} from 'react'
import {Modal, Button} from 'react-bootstrap'
import {connect} from 'react-redux';
import {saveQuickLink} from '../../redux/actions/quickLinks/saveQuickLink';
import {quickLinksResetStatus} from '../../redux/actions/quickLinks/resetStatus';
import QuickLinksForm from './QuickLinksForm';


const AddQuickLink2 = ({dispatch, status, error, user}) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const renderModal = () => {
        return (
            <Modal show={show} onHide={handleClose} size="lg" backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title style={{margin: '0 auto'}}>New Link</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <QuickLinksForm 
                        user={user}
                        onSubmit={(data) => {
                            dispatch(saveQuickLink(data))
                        }}
                        resetStatus={() => {
                            dispatch(quickLinksResetStatus())
                        }}
                        error={error}
                        status={status}
                        closeModal={handleClose}
                         />
                    
                </Modal.Body>
            </Modal>
        )
    }

    return (
        <>
        <div className="col-6 col-sm-3 col-md-3 col-lg-2 mb-3 px-2">
            <a className="text-center p-0 bg-white rounded h-100 d-block text-dark text-decoration-none border shadow-sm link-dash" href="#0" onClick={handleShow}>
                <div className="py-2">
                    <div className="pt-2">
                        <i className={`c-icon c-icon-2xl cil-plus`}></i>
                    </div>
                    <div>
                        New
                    </div>
                </div>
            </a>
        </div>
            {show && renderModal()}
        </>
    )
}



const mapStateToProps = (state, props) => {
    return {
        user: state.sfAuth.user,
        status: state.quickLinks.status,
        error: state.quickLinks.error,
        
    }
}

export default connect(mapStateToProps)(AddQuickLink2);