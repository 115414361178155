export const dashboardSteps = [
    {
        selector: 'intro',
        content: () => (
            <div className="text-center mt-3">
                <div>Hello! Welcome to the Employee Portal home page!</div> 
                <div className="font-weight-bold">Let's take a tour!</div>
            </div>
        ),
    },
    {
        selector: '[data-tour="dash-1"]',
        content: ({goTo, inDOM}) => (
            <div className="text-center mt-3">
                <div>Last data update. You can manually refresh the portal data by clicking the refresh icon.</div> 
            </div>
        ),
    },
    {
        selector: '[data-tour="dash-2"]',
        content: ({goTo, inDOM}) => (
            <div className="text-center mt-3">
                <div>Tap to access and update your profile. You can also log out here if needed.</div> 
            </div>
        ),
    },
    {
        selector: '[data-tour="dash-3"]',
        content: () => (
            <div className="text-center mt-3">
                <div>Tap to view your open IT requests</div> 
            </div>
        )
    },
    {
        selector: '[data-tour="dash-4"]',
        content: () => (
            <div className="text-center mt-3">
                <div>Click the right or left arrows to explore the current news and events</div> 
            </div>
        )
    },
    // {
    //     selector: '[data-tour="dash-5"]',
    //     content: () => (
    //         <div className="text-center mt-3">
    //             <div>List of Wall of Wow winners for the past 12 months. Click the right or left arrows to explore the list.</div> 
    //         </div>
    //     )
    // },
    {
        selector: '[data-tour="dash-6"]',
        content: () => (
            <div className="text-center mt-3">
                <div>List of corporate and personal links</div> 
            </div>
        )
    },
    {
        selector: '[data-tour="dash-7"]',
        content: () => (
            <div className="text-center mt-3">
                <div>Tap to add a new link to your my links section</div> 
            </div>
        )
    },
    {
        selector: '[data-tour="dash-8"]',
        content: () => (
            <div className="text-center mt-3">
                <div>Search for a link by entering keywords you would expect to find in the link name</div> 
            </div>
        )
    },
    {
        selector: '[data-tour="dash-9"]',
        content: () => (
            <div className="text-center mt-3">
                <div>Click on the name to open the link in a new window</div> 
            </div>
        )
    },
    {
        selector: '[data-tour="dash-10"]',
        content: () => (
            <div className="text-center mt-3">
                <div>Tap to edit or delete your custom link</div> 
            </div>
        )
    },
    {
        selector: '[data-tour="dash-11"]',
        content: () => (
            <div className="text-center mt-3">
                <div>Search for an employee by entering the name, department or title</div> 
            </div>
        )
    },
    {
        selector: '[data-tour="dash-12"]',
        content: () => (
            <div className="text-center mt-3">
                <div>Tap to view the employee profile</div> 
            </div>
        )
    },
    {
        selector: '[data-tour="dash-13"]',
        content: () => (
            <div className="text-center mt-3">
                <div>Tap to view and track the status of your IT requests and submit a new request </div> 
            </div>
        )
    },
    {
        selector: '[data-tour="dash-14"]',
        content: () => (
            <div className="text-center mt-3">
                <div>Tap to access the company employee directory</div> 
            </div>
        )
    },
    {
        selector: '[data-tour="dash-15"]',
        content: () => (
            <div className="text-center mt-3">
                <div>Tap to view a list of the business critical tools links</div> 
            </div>
        )
    },
    {
        selector: '[data-tour="dash-16"]',
        content: () => (
            <div className="text-center mt-3">
                <div>Tap to access the company training library</div> 
            </div>
        )
    }
]; 


export const ITRequestsSteps = [
    {
        selector: '[data-tour="it-req-1"]',
        content: () => (
            <div className="text-center mt-3">
                <div>Tap to create a new IT Request</div> 
            </div>
        ),
    },
    {
        selector: '[data-tour="it-req-2"]',
        content: () => (
            <div className="text-center mt-3">
                <div>Tap the tabs to view a list of your requests or a list of requests that requires your approval</div> 
            </div>
        ),
    },
    {
        selector: '[data-tour="it-req-3"]',
        content: () => (
            <div className="text-center mt-3">
                <div>Filter request list by search string (searches all visible fields)</div> 
            </div>
        ),
    },
    {
        selector: '[data-tour="it-req-4"]',
        content: () => (
            <div className="text-center mt-3">
                <div>Tap to export the summary list to CSV file format</div> 
            </div>
        ),
    },
    {
        selector: '[data-tour="it-req-5"]',
        content: () => (
            <div className="text-center mt-3">
                <div>Summary list of your requests (sorted by date)</div> 
            </div>
        ),
    },
    {
        selector: '[data-tour="it-req-6"]',
        content: () => (
            <div className="text-center mt-3">
                <div>Tap on the request ID to view all the details of the request</div> 
            </div>
        ),
    },
    {
        selector: '[data-tour="it-req-7"]',
        content: () => (
            <div className="text-center mt-3">
                <div>Tap the icons to Edit or Delete the request</div> 
            </div>
        ),
    }
]



export const ITRequestsDetailsSteps = [
    {
        selector: '[data-tour="it-req-details-1"]',
        content: () => (
            <div className="text-center mt-3">
                <div>The details of your request will be displayed on this section</div> 
            </div>
        ),
    },
    {
        selector: '[data-tour="it-req-details-2"]',
        content: () => (
            <div className="text-center mt-3">
                <div>The current status details of your request will be presented here</div> 
            </div>
        ),
    },
    {
        selector: '[data-tour="it-req-details-3"]',
        content: () => (
            <div className="text-center mt-3">
                <div>The files uploaded to the request will be listed here. Tap on "Upload" to add files to your request.</div> 
            </div>
        ),
    },
    {
        selector: '[data-tour="it-req-details-4"]',
        content: () => (
            <div className="text-center mt-3">
                <div>Tap to go back to your previous page.</div> 
            </div>
        ),
    }
]


export const empDirectorySteps = [
    {
        selector: '[data-tour="emp-directory-1"]',
        content: () => (
            <div className="text-center mt-3">
                <div>Search for an employee by entering the name, department or title</div> 
            </div>
        ),
    },
    {
        selector: '[data-tour="emp-directory-3"]',
        content: () => (
            <div className="text-center mt-3">
                <div>Tap to view more details for that employee</div> 
            </div>
        ),
    }
]



export const newsEventsSteps = [
    {
        content: () => (
            <div className="text-center mt-3">
                <div>Welcome to the "News & Events" module. This section is only visible to ELT members to post news on the portal home page. Let's take a tour!</div> 
            </div>
        ),
    }, {
        selector: '[news-tour="2"]',
        content: () => (
            <div className="text-center mt-3">
                <div>Tap to create a new post</div> 
            </div>
        ),
    }, {
        selector: '[news-tour="3"]',
        content: () => (
            <div className="text-center mt-3">
                <div>Summary list of your posts with the most recent on the top of the list.</div> 
            </div>
        ),
    }, {
        selector: '[news-tour="4"]',
        content: () => (
            <div className="text-center mt-3">
                <div>Search for a post by any of the parameters on the table.</div> 
            </div>
        ),
    }, {
        selector: '[news-tour="5"]',
        content: () => (
            <div className="text-center mt-3">
                <div>Tap to export the summary list to CSV file format</div> 
            </div>
        ),
    }, {
        selector: '[news-tour="6"]',
        content: () => (
            <div className="text-center mt-3">
                <div>Tap on the post ID to view all the details of the post.</div> 
            </div>
        ),
    }, {
        selector: '[news-tour="7"]',
        content: () => (
            <div className="text-center mt-3">
                <div>Tap the icons to Edit or Delete the post.</div> 
            </div>
        ),
    }, 
]



export const trainingMaterialsSteps = [
    {
        selector: '[data-tour="training-1"]',
        content: () => (
            <div className="text-center mt-3">
                <div>The library is organized in cards where each card provides various training related materials.</div> 
            </div>
        ),
    },{
        selector: '[data-tour="training-2"]',
        content: () => (
            <div className="text-center mt-3">
                <div>Icons visually classify the training card.</div> 
            </div>
        ),
    }, {
        selector: '[data-tour="training-3"]',
        content: () => (
            <div className="text-center mt-3">
                <div>Search by entering keywords you would expect to find in the training name.</div> 
            </div>
        ),
    },
    {
        selector: '[data-tour="training-4"]',
        content: () => (
            <div className="text-center mt-3">
                <div>Tap to view all the training details and its materials.</div> 
            </div>
        ),
    }
]