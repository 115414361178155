import {ActionTypes} from '../actionTypes';
import axios from 'axios';
import jwt_decode from "jwt-decode";
import setAuthToken from '../../../components/auth/setAuthToken';

export const sfAuthStart = () => ({type: ActionTypes.SF_AUTH_START});
export const sfAuthSuccess = (user) => ({type: ActionTypes.SF_AUTH_SUCCESS, user});
export const sfAuthError = (error) => ({type: ActionTypes.SF_AUTH_ERROR, error});

export const sfAuth = (email) => {
    return async (dispatch) => {
        try {
            dispatch(sfAuthStart());
            const response = await axios.post('/user/login', email);

            if (response.data) {
                localStorage.setItem("sfToken", response.data);
                setAuthToken(response.data);
                const decoded = jwt_decode(response.data);
                dispatch(sfAuthSuccess(decoded));
            }
        } catch (err) {
            dispatch(sfAuthError(err));
        }
    }
}