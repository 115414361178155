import {ActionTypes} from '../actions/actionTypes';

const initialState = {
    dataLoaded: null,
    status: null,
    error: null,
    data: []
}

export default (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.CONTACT_RESET_STATUS:
            return {...state, status: null, error: null}
        case ActionTypes.FETCH_CONTACTS_START:
            return {...state, status: 'loading', dataLoaded: false}
        case ActionTypes.FETCH_CONTACTS_SUCCESS:
            return {...state, data: [...action.contacts], status: 'loaded', dataLoaded: true}
        case ActionTypes.FETCH_CONTACTS_ERROR:
            return {...state, error: action.error, status: 'error', dataLoaded: false}
        case ActionTypes.UPDATE_CONTACT_START:
            return {...state, status: 'updating', error: null}
        case ActionTypes.UPDATE_CONTACT_ERROR:
            return {...state, error: action.error, status: 'error'}
        case ActionTypes.UPDATE_CONTACT_SUCCESS:
            return {...state, data: state.data.map((request) => {
                if(request.Id === action.id) {
                    return {
                        ...request,
                        ...action.updates
                    }
                } else {
                    return request;
                }
            }), status: 'updated', error: null};
        default:
            return state;
    }
}