import React, {useEffect} from 'react';
import Layout from '../layout/Layout';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import ServiceRequestDetail from './ServiceRequestDetail';
import {selectedOption} from '../../redux/selectors/typeaheadOptions';
import ServiceRequestAttachments from './attachments/ServiceRequestAttachments';
import {deleteConfirmation, deleteConfirmed, deleteError} from '../sweetAlerts/deleteAlerts'
import {deleteServiceRequest} from '../../redux/actions/serviceRequests/deleteServiceRequest';
import {serviceRequestResetStatus} from '../../redux/actions/serviceRequests/resetStatus';
import ServiceRequestStatus from './ServiceRequestStatus';
import TextInput from '../formFields/TextInput';
import PurchaseRequestApproval from './PurchaseRequestApproval';
import ProjectRequestApproval from './ProjectRequestApproval';
import ProjectRequestEstApproval from './ProjectRequestEstApproval';
import {findRequest} from '../../redux/selectors/serviceRequestSelectors';
import TourRunner from '../tour/TourRunner';
import {updateEmpTour} from '../../redux/actions/sfAuth/updateEmpTours';
import {ITRequestsDetailsSteps} from '../tour/tourContent';
import {ITRequestsDetailsMobileSteps} from '../tour/tourMobileContent';
import NotFound from '../NotFound';




const ServiceRequestItem = (props) => {
    const {serviceRequest, projects, clients, users, me, sfAuth} = props;

    const handleDelete = async (Id) => {
        const willDelete = await deleteConfirmation();

        if(willDelete) {
            props.dispatch(deleteServiceRequest({Id})).then(() => {
                props.history.push(`/serviceRequests`);
            })
        }
    }

    if(props.status === 'deleted') {
        deleteConfirmed();
        props.dispatch(serviceRequestResetStatus())
    } 
    
    if(props.status === 'error') deleteError(props.error);

    const breadcrumb = [
        { name:'IT Requests', link: '/serviceRequests' },
        { name: serviceRequest && serviceRequest.Name && serviceRequest.Name.split("-").pop(), link: '' }
    ]


    if(!serviceRequest.Id) {
        return (
            <NotFound />
        )
    }

    


    return (
        <Layout breadcrumb={breadcrumb}>
            <TourRunner 
                content={ITRequestsDetailsSteps} 
                mobileContent={ITRequestsDetailsMobileSteps}
                isClosed={sfAuth.tours && sfAuth.tours.IT_Request_Details__c}
                updateSalesforce={() => {
                    props.dispatch(updateEmpTour(sfAuth.tours.Id, {Id: sfAuth.tours.Id, IT_Request_Details__c: true}))
                }} 
            />
            {
            (serviceRequest.Parent_Type__c === 'Purchase Request' && 
            (serviceRequest.Purch_Approval_Status__c !== 'Approved' && serviceRequest.Purch_Approval_Status__c !== 'Rejected') && 
            (me.Id === serviceRequest.Purch_Approver__c || me.Id === serviceRequest.Purch_Secondary_Approver__c)) ?
            <PurchaseRequestApproval serviceRequest={serviceRequest} /> : null }

            {
            (serviceRequest.Parent_Type__c === 'Project Brief' && 
            (serviceRequest.Proj_Pre_Approval_Status__c !== 'Approved' && serviceRequest.Proj_Pre_Approval_Status__c !== 'Rejected') && 
            (me.Id === serviceRequest.Proj_Pre_Approver__c)) ?
            <ProjectRequestApproval serviceRequest={serviceRequest} /> : null }

            {
            (serviceRequest.Parent_Type__c === 'Project Brief' && 
            (serviceRequest.Proj_Pre_Approval_Status__c === 'Approved') &&
            (serviceRequest.Proj_Estimate_Approval_Status__c !== 'Approved' && serviceRequest.Proj_Estimate_Approval_Status__c !== 'Rejected') && 
            (me.Id === serviceRequest.Proj_Estimate_Approver__c) &&
            (serviceRequest.Send_Estimate_Approval__c === true)) ?
            <ProjectRequestEstApproval serviceRequest={serviceRequest} /> : null }


            <div className="card">
                <div className="card-header bg-sdi-black text-sdi-yellow">
                    Request Details
                    <div className="btn-group float-right" role="group">
                        <Link className={`btn btn-sm btn-primary ${serviceRequest.Locked && 'disabled'}`} to={`/serviceRequests/edit/${props.match.params.id}`}>
                            <i className="fas fa-edit"></i> Edit</Link>
                        <a className={`btn btn-sm btn-secondary ${serviceRequest.Locked && 'disabled'}`} onClick={() => handleDelete(props.match.params.id)}>
                            <i className="fas fa-trash-alt"></i> Delete</a>
                    </div>
                </div>
                <div className="card-body" data-tour="it-req-details-1">
                    <ServiceRequestDetail 
                        project={selectedOption(projects, serviceRequest.PSA_Project__c)[0]}
                        // client={selectedOption(clients, serviceRequest.Client__c)[0]}
                        onBehalfUser={selectedOption(users, serviceRequest.Request_For__c)[0]}
                        // vendor={selectedOption(clients, serviceRequest.Vendor__c)[0]}
                        serviceRequest={serviceRequest} />
                </div>
            </div>
            <div className="card">
                <div className="card-header bg-sdi-black text-sdi-yellow">Request Status</div>
                <div className="card-body" data-tour="it-req-details-2">
                    <ServiceRequestStatus 
                        serviceRequest={serviceRequest}
                        assignedUser={selectedOption(users, serviceRequest.Assigned_User__c)[0]}
                        projPreApprover={selectedOption(users, serviceRequest.Proj_Pre_Approver__c)[0]}
                        projEstApprover={selectedOption(users, serviceRequest.Proj_Estimate_Approver__c)[0]} />
                </div>
            </div>
            <ServiceRequestAttachments serviceRequest={serviceRequest} />
        </Layout>
    )
}

const mapStateToProps = (state, props) => {
    return {
        serviceRequest: findRequest(state.serviceRequests.data, props.match.params.id),
        projects: state.projects.data,
        // clients: state.accounts.data,
        users: state.users.data,
        status: state.serviceRequests.status,
        me: state.sfAuth.user,
        sfAuth: state.sfAuth,
    }
}
export default connect(mapStateToProps)(ServiceRequestItem);