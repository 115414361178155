import {ActionTypes} from '../actionTypes';
import axios from 'axios';

export const fetchBusinessUnitsStart = () => ({type: ActionTypes.FETCH_BUSINESS_UNITS_START});
export const fetchBusinessUnitsSuccess = (data) => ({type: ActionTypes.FETCH_BUSINESS_UNITS_SUCCESS, data});
export const fetchBusinessUnitsError = (error) => ({type: ActionTypes.FETCH_BUSINESS_UNITS_ERROR, error});


export const fetchBusinessUnits = () => {
    return async (dispatch) => {
        try {
            dispatch(fetchBusinessUnitsStart());
            const response = await axios.get('/businessUnits/all')

            if (response.data) {
                dispatch(fetchBusinessUnitsSuccess(response.data))
            }
        } catch (err) {
            dispatch(fetchBusinessUnitsError(err));
        }
    }
}