import React from 'react';
import Layout from '../layout/Layout';
import {connect} from 'react-redux';
import ServiceRequestAttachments from './attachments/ServiceRequestAttachments';

const AttachmentForm = ({serviceRequest}) => {
    const breadcrumb = [
        { name:'IT Requests', link: '/serviceRequests' },
        { name:'Attachments', link: '' }
    ]

    return (
        <Layout breadcrumb={breadcrumb}>
            <ServiceRequestAttachments serviceRequest={serviceRequest} form={true} />
        </Layout>
    )
}


const mapStateToProps = (state, props) => {
    return {
        serviceRequest: state.serviceRequests.data.find((req) => req.Id === props.match.params.id) || {}
    }
}

export default connect(mapStateToProps)(AttachmentForm);
