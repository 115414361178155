import {ActionTypes} from '../actionTypes';
import axios from 'axios';

export const updateEmpTourStart = () => ({type: ActionTypes.UPDATE_EMP_TOUR_START});
export const updateEmpTourSuccess = (id, updates) => ({type: ActionTypes.UPDATE_EMP_TOUR_SUCCESS, id, updates});
export const updateEmpTourError = (error) => ({type: ActionTypes.UPDATE_EMP_TOUR_ERROR, error});

export const updateEmpTour = (id, requestData = {}) => {
    return async (dispatch) => {


        try {
            dispatch(updateEmpTourStart());
            const response = await axios.patch('/employeeTours/update', requestData)
            
            if (response.data) {
                dispatch(updateEmpTourSuccess(response.data.tours.Id, response.data.tours))
            }
        } catch (err) {
            dispatch(updateEmpTourError(err.message));
        }
    }
}