import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Doughnut, Bar } from 'react-chartjs-2';
import ChartDataModal from './ChartDataModal';


const LogsWedgetChart = ({title, data, chartType}) => {

    return (
        <div className={`card`}>
            <div className="card-body">
                <div className="d-flex justify-content-between">
                    <div className="h5">{title}</div>
                    <small className="text-uppercase h5 text-muted">
                        <ChartDataModal data={data.data} title={title} />
                    </small>
                </div>

                <Chart labels={title} chartData={data.chartData} chartType={chartType} />
            </div>
        </div>
    )
}

export default LogsWedgetChart



const Chart = ({chartData, chartType}) => {
    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    );


    const options = {
        indexAxis: 'x',
        responsive: true,
        plugins: {
            datalabels: {
                color: 'black',
                display: true,
                formatter: (val) => {
                    if(chartType === 'bar') {
                        return val
                    }
                    return `${val}%`
                }
            },
            legend: {
                display: chartType === 'bar' ? false : true,
                position: 'top',
                
            },
            tooltip: {
                callbacks: {
                    label: (tooltipItem) => {
                        if(chartType === 'bar') {
                            return `${tooltipItem.dataset.label}: ${tooltipItem.parsed.y}`
                        }
                        return `${tooltipItem.dataset.label}: ${tooltipItem.parsed}%`
                    }, 
                    title: () => null,
                }
            },
            title: {
                display: false,
                text: 'Chart.js Bar Chart',
            },
        },
        scales: {
            x: {
                display: chartType === 'bar' ? true : false,
            },
            y: {
                display: false,
            }
        }
    };



    const data = {
        labels: chartData.labels,
        datasets: [
          {
            label: 'Total Visits',
            data: chartData.data,
            backgroundColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 0.5,
          }
        ],
    };


    if(chartType === 'bar') {
        return (
            <Bar options={options} data={data} />
        )
    }

    return (
        <Doughnut options={options} data={data} />
    )
}