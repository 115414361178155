import React, {useState} from 'react';
import moment from 'moment';
import DetailsModal from '../layout/DetailsModal';
import RichTextToHTML from '../layout/RichTextToHTML';


const ServiceRequestDetail = (props) => {
    const [seeingMore, setSeeingMore] = useState(false)
    const {serviceRequest, project, client, onBehalfUser, vendor} = props;


    

    const projectDetails = project && {
        'Name': project.Name,
        'Project ID': project.pse__Project_ID__c,
        'Department': project.pse__Group__r.Name,
        'Project Manager': project.pse__Project_Manager__r && project.pse__Project_Manager__r.Name,
        'Purchase Manager': project.Purchase_Manager__r && project.Purchase_Manager__r.Name
    }

    const clientDetials = client && {
        'Name': client.Name,

        'Address': `${client.BillingAddress ? client.BillingAddress.street : ''} ${client.BillingAddress ? client.BillingAddress.city : ''} ${client.BillingAddress ? client.BillingAddress.state : ''}`,
        '': `${client.BillingAddress ? client.BillingAddress.postalCode : ''} - ${client.BillingAddress ? client.BillingAddress.country : ''}`,
        'Phone': client.Phone,
        'Terms': client.Payment_Terms__c,
    }

    const vendorDetials = vendor && {
        'Name': vendor.Name,

        'Address': `${vendor.BillingAddress ? vendor.BillingAddress.street : ''} ${vendor.BillingAddress ? vendor.BillingAddress.city : ''} ${vendor.BillingAddress ? vendor.BillingAddress.state : ''}`,
        '': `${vendor.BillingAddress ? vendor.BillingAddress.postalCode : ''} - ${vendor.BillingAddress ? vendor.BillingAddress.country : ''}`,
        'Phone': vendor.Phone,
        'Terms': vendor.Payment_Terms__c,
    }

    const onBehalfUserDetails = onBehalfUser && {
        'Name': onBehalfUser.Name,
        'Title': onBehalfUser.Title,
        'Department': onBehalfUser.Department,
        'Email': onBehalfUser.Email
    }


    const priorityFormatter = (cellContent) => {
        if(cellContent === '3 - Low') {
            return <span className="badge badge-success" style={{width: '70px'}}>Low</span>
        } else if (cellContent === '2 - Medium') {
            return <span className="badge badge-warning" style={{width: '70px'}}>Medium</span>
        } else if (cellContent === '1 - HIGH') {
            return <span className="badge badge-danger" style={{width: '70px'}}>High</span>
        }
    
        return <span className="badge badge-secondary" style={{width: '70px'}}>{cellContent}</span>
    }

    return (
        <>
            <div className="row">
                <div className="col-6 col-md-3 mb-3">
                    <div className="text-uppercase font-weight-light">
                        Date
                    </div>
                    <div className="font-weight-bold">{serviceRequest.CreatedDate && moment(serviceRequest.CreatedDate).format('LL')}</div>
                </div>
                <div className="col-6 col-md-3 mb-3">
                    <div className="text-uppercase font-weight-light">
                        Created By
                    </div>
                    <div className="font-weight-bold">{serviceRequest.Created_By__r && serviceRequest.Created_By__r.Name}</div>
                </div>

                <div className="col-6 col-md-3 mb-3">
                    <div className="text-uppercase font-weight-light">
                        On Behalf Of
                    </div>
                    <div className="font-weight-bold">{onBehalfUserDetails && <DetailsModal buttonText={onBehalfUserDetails && onBehalfUserDetails.Name} title={'On Behalf Of'} data={onBehalfUserDetails} />}</div>
                </div>
                
                <div className="col-6 col-md-3 mb-3">
                    <div className="text-uppercase font-weight-light">
                        Request Type
                    </div>
                    <div className="font-weight-bold">{serviceRequest.Parent_Type__c}</div>
                </div>

                <div className="col-6 col-md-3 mb-3">
                    <div className="text-uppercase font-weight-light">
                        Category
                    </div>
                    <div className="font-weight-bold">{serviceRequest.Category_Lookup__r && serviceRequest.Category_Lookup__r.Name}</div>
                </div>
                <div className="col-6 col-md-3 mb-3">
                    <div className="text-uppercase font-weight-light">
                        Priority
                    </div>
                    <div className="font-weight-bold">{priorityFormatter(serviceRequest.Priority__c)}</div>
                </div>
                <div className="col-6 col-md-3 mb-3">
                    <div className="text-uppercase font-weight-light">
                        Needed By
                    </div>
                    <div className="font-weight-bold">{serviceRequest.Needed_By__c && moment(serviceRequest.Needed_By__c).format('LL')}</div>
                </div>
                <div className="col-6 col-md-3 mb-3">
                    <div className="text-uppercase font-weight-light">
                        Project
                    </div>
                    <div className="font-weight-bold">{projectDetails && <DetailsModal buttonText={projectDetails && projectDetails.Name} title={'Project'} data={projectDetails} />}</div>
                </div>
                
                

                {(serviceRequest.Parent_Type__c === 'Purchase Request' || serviceRequest.Parent_Type__c === 'Quote Request') && <>
                {/* <div className="col-6 col-md-3 mb-3">
                    <div className="text-uppercase font-weight-light">
                        Vendor
                    </div>
                    <div className="font-weight-bold">{vendorDetials && <DetailsModal buttonText={vendorDetials && vendorDetials.Name} title={'Vendor'} data={vendorDetials} />}</div>
                </div>   */}
                <div className="col-6 col-md-3 mb-3">
                    <div className="text-uppercase font-weight-light">
                        Brand
                    </div>
                    <div className="font-weight-bold">{serviceRequest.Brand__c}</div>
                </div>
                <div className="col-6 col-md-3 mb-3">
                    <div className="text-uppercase font-weight-light">
                        Model
                    </div>
                    <div className="font-weight-bold">{serviceRequest.Model__c}</div>
                </div>
                <div className="col-6 col-md-3 mb-3">
                    <div className="text-uppercase font-weight-light">
                        Quantity
                    </div>
                    <div className="font-weight-bold">{serviceRequest.Quantity__c !== 0 && serviceRequest.Quantity__c}</div>
                </div></>}

                {
                (
                    serviceRequest.Parent_Type__c === 'Purchase Request' || 
                    serviceRequest.Parent_Type__c === 'Quote Request' ||
                    serviceRequest.Parent_Type__c === 'Support Request'
                ) && <>
                <div className="col-6 col-md-6 mb-3">
                    <div className="text-uppercase font-weight-light">
                        Brief Description
                    </div>
                    <div className="font-weight-bold">{serviceRequest.Brief_Desc__c}</div>
                </div>                    

                <div className="col-6 col-md-6 mb-3">
                    <div className="text-uppercase font-weight-light">
                        Details
                    </div>
                    <RichTextToHTML html={serviceRequest.Detail__c} />
                </div></>}


                {(serviceRequest.Parent_Type__c === 'Purchase Request') && 
                <div className="col-12 col-md-12 mb-3">
                    <div className="text-uppercase font-weight-light">
                        Delivery Details
                    </div>
                    <div className="font-weight-bold">{serviceRequest.Delivery_Details__c}</div>
                </div>}


            {serviceRequest.Parent_Type__c === 'Project Brief' && <>
                {/* <div className="col-6 col-md-3 mb-3">
                    <div className="text-uppercase font-weight-light">
                        Client
                    </div>
                    <div className="font-weight-bold">{clientDetials && <DetailsModal buttonText={clientDetials && clientDetials.Name} title={'Client'} data={clientDetials} />}</div>
                </div> */}
                <div className="col-6 col-md-3 mb-3">
                    <div className="text-uppercase font-weight-light">
                        Project Type
                    </div>
                    <div className="font-weight-bold">{serviceRequest.Project_Type__c}</div>
                </div>
                <div className="col-6 col-md-3 mb-3">
                    <div className="text-uppercase font-weight-light">
                        Budget
                    </div>
                    <div className="font-weight-bold">{serviceRequest.Project_Budget__c}</div>
                </div>
                <div className="col-6 col-md-3 mb-3">
                    <div className="text-uppercase font-weight-light">
                        Project Title
                    </div>
                    <div className="font-weight-bold">{serviceRequest.Project_Title__c}</div>
                </div>               
            </>}
            </div>

            {serviceRequest.Parent_Type__c === 'Project Brief' && <>
            
            <div className="row mb-3">
                <div className="col-md">
                    <div className="text-uppercase font-weight-light">
                        Importance
                    </div>
                    <RichTextToHTML html={serviceRequest.Project_Importance__c} />
                    {/* <div className="font-weight-bold">{serviceRequest.Project_Importance__c}</div> */}
                </div>

                <div className="col-md">
                    <div className="text-uppercase font-weight-light">
                        Problem
                    </div>
                    <RichTextToHTML html={serviceRequest.Project_Problem__c} />
                    {/* <div className="font-weight-bold">{serviceRequest.Project_Problem__c}</div> */}
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md">
                    <div className="text-uppercase font-weight-light">
                        Goals
                    </div>
                    <RichTextToHTML html={serviceRequest.Project_Goals__c} />
                    {/* <div className="font-weight-bold">{serviceRequest.Project_Goals__c}</div> */}
                </div>

                <div className="col-md">
                    <div className="text-uppercase font-weight-light">
                        Success Criteria
                    </div>
                    <RichTextToHTML html={serviceRequest.Project_Success__c} />
                    {/* <div className="font-weight-bold">{serviceRequest.Project_Success__c}</div> */}
                </div>
            </div></>}
            {/* </>} */}
            {/* {seeingMore
                ? <button className="btn btn-link" onClick={() => setSeeingMore(false)}>see less →</button>
                : <button className="btn btn-link" href="#" onClick={() => setSeeingMore(true)}>see more →</button>} */}
        </>
    )
}

export default ServiceRequestDetail;