import {ActionTypes} from '../actionTypes';
import axios from 'axios';
import moment from 'moment';

export const saveServiceRequestStart = () => ({type: ActionTypes.SAVE_SERVICE_REQUEST_START});
export const saveServiceRequestSuccess = (request) => ({type: ActionTypes.SAVE_SERVICE_REQUEST_SUCCESS, request});
export const saveServiceRequestError = (error) => ({type: ActionTypes.SAVE_SERVICE_REQUEST_ERROR, error});

export const saveServiceRequest = (requestData = {}) => {
    return async (dispatch) => {
        const { onBehalfOf = '',
                onBehalf = false,
                requestType = '',
                category = '',
                project = '',
                priority = '',
                neededBy = '',
                description = '',
                requestDetails = '',
                createdBy = '',
                // client = '',
                projectTitle = '',
                projectType = '',
                projectImportance = '',
                projectProblem = '',
                projectGoal = '',
                projectSuccess = '',
                projectBudget = '',
                deliveredDate = '',
                deliveryDetails = '',
                productModal = '',
                productBrand = '',
                productQuantity = null,
                // vendor = '',
                purchApprover = '',
                purchSecondApprover = '',
                projPreApprover = '',
                projEstApprover = '',
                
                purchApprovedBy = '',
                purchApprovalNotes = '',
                projPreApprovalNotes = '',
            } = requestData;

            const request = {
                On_Behalf__c: onBehalf,
                Request_For__c: onBehalfOf,
                Parent_Type__c: requestType,
                Category_Lookup__c: category,
                PSA_Project__c: project,
                Priority__c: priority,
                Brief_Desc__c: description || '',
                Detail__c: requestDetails,
                Created_By__c: createdBy,
                
            }

            const supportRequest = {
                ...request,
                Needed_By__c: moment(neededBy).format("YYYY-MM-DD"),
            }

            const purchaseRequest = {
                ...request,
                Needed_By__c: moment(neededBy).format("YYYY-MM-DD"),
                Delivery_Details__c: deliveryDetails,
                // Vendor__c: vendor,
                Purch_Approver__c: purchApprover,
                Purch_Secondary_Approver__c: purchSecondApprover,
                Model__c: productModal,
                Brand__c: productBrand,
                Quantity__c: productQuantity,

                Purch_Approved_By__c: purchApprovedBy,
                Purch_Approval_Notes__c: purchApprovalNotes

            }

            const quoteRequest = {
                ...request,
                Needed_By__c: moment(neededBy).format("YYYY-MM-DD"),
                Delivery_Details__c: deliveryDetails,
                // Vendor__c: vendor,
                Model__c: productModal,
                Brand__c: productBrand,
                Quantity__c: productQuantity
            }

            const projectRequest = {
                ...request, 
                // Client__c: client,
                Project_Title__c: projectTitle,
                Project_Type__c: projectType,
                Project_Importance__c: projectImportance,
                Project_Problem__c: projectProblem,
                Project_Goals__c: projectGoal,
                Project_Success__c: projectSuccess,
                Project_Budget__c: projectBudget,
                Needed_By__c: moment(deliveredDate).format("YYYY-MM-DD") ,
                Proj_Pre_Approver__c: projPreApprover,
                Proj_Estimate_Approver__c: projEstApprover,

                Proj_Pre_Approval_Notes__c: projPreApprovalNotes
            }

        try {
            dispatch(saveServiceRequestStart());

            let data = null;
            if(requestData.requestType === 'Project Brief') {
                data = projectRequest;
            } else if(requestData.requestType === 'Support Request') {
                data = supportRequest;
            } else if(requestData.requestType === 'Purchase Request') {
                data = purchaseRequest;
            } else if(requestData.requestType === 'Quote Request') {
                data = quoteRequest;
            } else {
                data = request;
            }

            // console.log(data);


            const response = await axios.post('/serviceRequest/create', data)

            if (response.data) {
                dispatch(saveServiceRequestSuccess({...response.data.request}))
            }
        } catch (err) {
            dispatch(saveServiceRequestError(err.response.data));
        }
    }
}