import React, {useState} from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

const Filters = () => {
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;

    console.log(startDate);
    return (
        <div className="card">
            <div className="card-body">
                <div className="row">
                    <div className="col-sm-3">
                        <select className="form-control">
                            <option value="Revenue">Revenue</option>
                            <option value="COGS">COGS</option>
                            <option value="Sellex">Sellex</option>
                            <option value="Opex">Opex</option>
                        </select>
                    </div>
                    <div className="col-sm-3">
                        <DatePicker
                            className="form-control"
                            selectsRange
                            // inline
                            // withPortal
                            // shouldCloseOnSelect={false}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(update) => {
                                setDateRange(update);
                            }}
                            isClearable={true}
                            dateFormat="yyyy/MM"
                            showMonthYearPicker
                        />
                    </div>
                    
                    <div className="col-sm-3">
                        <select className="form-control">
                            <option value="EX">EX</option>
                            <option value="EX USA">EX USA</option>
                            <option value="Sports">Sports</option>
                            <option value="C&L">C&L</option>
                            <option value="Enterprise">Enterprise</option>
                            <option value="Main USA">Main USA</option>
                            <option value="IA360">IA360</option>
                            <option value="Kite">Kite</option>
                            <option value="MC">MC</option>
                            <option value="PS">PS</option>
                        </select>
                    </div>
                    <div className="col-sm-3">
                        End Period
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Filters
