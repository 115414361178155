import React, {useEffect, useState} from 'react';
import Layout from '../layout/Layout';
import {connect} from "react-redux";
import HelpDocsDetails from '../helpDocs/HelpDocsDetails'
import canadaFlag from '../../images/canada-flag.png';
import usaFlag from '../../images/usa-flag.png';
import qatarFlag from '../../images/qatar-flag.png';

const CovidProtocols = ({helpDocs}) => {

    const breadcrumb = [
        { name:'Covid-19 Protocols', link: '' }
    ]

    const formatTrainingIcon = (type) => {
        if(type === 'Technical') return <i className="fa fa-cogs c-icon c-icon-xxl text-dark"></i>;
        else if(type === 'Corporate') return <i className="fa fa-user-tie c-icon c-icon-xxl text-dark"></i>;
        else if(type === 'Legislative') return <i className="fa fa-gavel c-icon c-icon-xxl text-dark"></i>;

        return <i className="fa fa-file-pdf c-icon c-icon-xxl text-dark"></i>
    }

    const renderAttachmentIcons = (ContentType) => {
        if (ContentType === 'application/pdf') {
            return <i className="fas fa-file-pdf text-pdf mr-1 h6 p-1"></i>
        } else if (ContentType === 'application/msword' || ContentType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
            return <i className="fas fa-file-word text-primary mr-1 h6 p-1"></i>
        } else if (ContentType === 'application/vnd.ms-excel' || ContentType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            return <i className="fas fa-file-excel text-success mr-1 h6 p-1"></i>
        } else if(ContentType === "application/vnd.openxmlformats-officedocument.presentationml.presentation") {
            return <i className="fas fa-file-powerpoint text-powerpoint mr-1 h6 p-1"></i>
        }
        return <i className="fas fa-file-alt text-powerpoint mr-1 h6 p-1"></i>
    }

    const renderIcons = (doc) => {
        const attTypesRaw = [];
        doc.Attachments && doc.Attachments.records.forEach((att) => {
            attTypesRaw.push(att.ContentType);
        });
        const attTypes = [...new Set(attTypesRaw)];

        return (
            <>
            {/* {doc.Training_Type__c === 'Technical' && <i className="fas fa-cogs text-dark mr-1 h6 p-1"></i>}
            {doc.Training_Type__c === 'Corporate' && <i className="fas fa-user-tie text-danger mr-1 h6 p-1"></i>}
            {doc.Training_Type__c === 'Legislative' && <i className="fas fa-gavel text-law mr-1 h6 p-1"></i>} */}
            {(doc.Training_Region__c === 'SDI Canada' || doc.Training_Region__c === 'SDI Canada & USA') && <img src={canadaFlag} style={{height: '22px', marginBottom: '.2rem', padding: '.29rem'}} className="mr-1" alt="" />} 
            {(doc.Training_Region__c === 'SDI USA' || doc.Training_Region__c === 'SDI Canada & USA') &&  <img src={usaFlag} style={{height: '22px', marginBottom: '.2rem', padding: '.29rem'}} className="mr-1" alt="" />}
            {doc.Training_Region__c === 'SDI Doha' && <img src={qatarFlag} style={{height: '22px', marginBottom: '.2rem', padding: '.29rem'}} className="mr-1" alt="" />}
            {doc.Training_Region__c === 'ALL SDI' && <i className="fas fa-globe-americas text-primary mr-1 h6 p-1"></i>}
            {doc.Video_URL__c && <i className="fas fa-video text-info mr-1 h6 p-1"></i>}
            {attTypes && attTypes.map((type) => {
                return renderAttachmentIcons(type);
            })}
            </>
        )
    }

    return (
        <Layout breadcrumb={breadcrumb}>
            <div className="card">
                <div className="card-body" >
                    <div className="my-4 mx-sm-5">
                    <div className="row" >
                        {helpDocs && helpDocs.map((doc) => (
                        <div className="col-md-6 col-lg-4" data-tour="training-1">
                            <div className="card">
                                <div className="card-body p-3">
                                    <div className="d-flex align-items-center">
                                        <div className="p-2 mfe-3">
                                            {formatTrainingIcon(doc.Training_Type__c)}
                                        </div>
                                        <div>
                                            <div className="font-weight-bold small mb-2">{doc.Name}</div>
                                            <div data-tour="training-2">
                                                {renderIcons(doc)}
                                            </div>
                                            <div className="text-muted text-uppercase font-weight-bold small">
                                                {doc.Training_Type__c && <div><i className="fas fa-arrow-right mr-2"></i>{doc.Training_Type__c}</div>}
                                                {/* <div><i className="cil-chevron-right mr-2"></i>{moment(doc.CreatedDate).format('ll')}</div> */}
                                            </div>                                        
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer px-3 py-2" data-tour="training-4">
                                    <HelpDocsDetails data={doc} />
                                </div>
                            </div>
                        </div>
                        ))}
                    </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}



const mapStateToProps = (state) => {
    return {
        helpDocs: state.helpDocs.data.filter((i) => i.Type__c === 'Protocol' || i.Type__c === 'Form'),
    }
}


export default connect(mapStateToProps)(CovidProtocols);