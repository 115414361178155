import {ActionTypes} from '../actions/actionTypes';

const initialState = {
    isAuthenticated: false,
    tours: {},
    user: {},
    quote: {},
    error: null,
    status: null,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SF_AUTH_START:
            return {...state, status: 'loading'}
        case ActionTypes.SF_AUTH_SUCCESS:
            return {...state, user: action.user, isAuthenticated: !(Object.keys(action.user).length === 0 && action.user.constructor === Object), status: 'Authenticated'}
        case ActionTypes.SF_AUTH_ERROR:
            return {...state, error: action.error, status: 'Error'}
        case ActionTypes.FETCH_EMP_TOURS_START:
            return {...state, status: 'loading-tours'}
        case ActionTypes.FETCH_EMP_TOURS_SUCCESS:
            return {...state, tours: action.tours, status: 'loaded-tours'}
        case ActionTypes.FETCH_EMP_TOURS_ERROR:
            return {...state, error: action.error, status: 'error'}
        case ActionTypes.UPDATE_EMP_TOUR_START:
            return {...state, status: 'updating-tour', error: null}
        case ActionTypes.UPDATE_EMP_TOUR_ERROR:
            return {...state, error: action.error, status: 'error'}
        case ActionTypes.UPDATE_EMP_TOUR_SUCCESS:
            return {...state, tours: (state.tours.Id === action.id) ? action.updates : state.tours, status: 'updated-tours', error: null};
        case ActionTypes.FETCH_EMP_QUOTE_SUCCESS:
            return {...state, quote: action.quote, status: 'loaded-quote'}
        default:
            return state;
    }
}