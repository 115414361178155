

export const selectPurchaseApprover = (projects, projectId, unitLeader) => {
    const selectedProject = projects ? projects.find((rec) => rec.Id === projectId) : null;

    if(selectedProject) {
        if(!selectedProject.Purchase_Manager_Active__c && !selectedProject.Project_Manager_Active__c) {
            console.log('leader');
            return unitLeader || null;
        } else if(selectedProject.Purchase_Manager__r) {
            console.log('purchase manager');
            return selectedProject.Purchase_Manager__r.pse__Salesforce_User__c;
        } else if (selectedProject.pse__Project_Manager__r) {
            console.log('project manager');
            console.log(selectedProject.pse__Project_Manager__r.pse__Salesforce_User__c);
            return selectedProject.pse__Project_Manager__r.pse__Salesforce_User__c;
        } else {
            return null
        }
    }
}

export const selectPurchaseSecondApprover = (projects, projectId, unitLeader) => {
    const selectedProject = projects ? projects.find((rec) => rec.Id === projectId) : null;


    if(selectedProject && selectedProject.Purchase_Manager__r) {
        if(!selectedProject.Purchase_Manager_Active__c || !selectedProject.Project_Manager_Active__c) {
            return unitLeader || null
        } else {
            return selectedProject.pse__Project_Manager__r.pse__Salesforce_User__c
        }

        // if(selectedProject.pse__Project_Manager__r) {
            
        // } else {
        //     return null
        // }
    }

    return null
}