import React, {useState, useEffect} from 'react'
import {Modal, Button} from 'react-bootstrap'
import {Document, Outline, Page, pdfjs} from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ImageModal = props => {
    const [numPages,
        setNumPages] = useState(null);
    const [pageNumber,
        setPageNumber] = useState(1)

    const onDocumentLoadSuccess = ({numPages}) => {
        setNumPages(numPages)
        setPageNumber(1)
    }


    const fileTtype = props.fileDetails
        ? props.fileDetails.ContentType
        : ''

    return (
        <div>
            <Modal
                show={props.show}
                onHide={() => props.setShow(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title">
                <Modal.Header closeButton>
                    <Modal.Title style={{margin: '0 auto'}} id="example-custom-modal-styling-title">
                        {`Attachment `}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{textAlign: 'center'}}>
                    {fileTtype == 'application/pdf'
                        ? <div >
                                <Document
                                    file={{
                                    url: props.data
                                }}
                                    onLoadSuccess={onDocumentLoadSuccess}>
                                    {Array.from(new Array(numPages), (el, index) => (<Page className="pdf-page" key={`page_${index + 1}`} pageNumber={index + 1}/>),)}
                                </Document>

                            </div>
                        : <img
                            style={{
                            maxWidth: '100%'
                        }}
                            id="img"
                            src={props.data}/>
}

                </Modal.Body>
            </Modal>
        </div>
    );
}

export default ImageModal