import { ActionTypes } from "../actionTypes";
import axios from "axios";

export const fetchOrgChartBusinessUnitsStart = () => ({
  type: ActionTypes.FETCH_ORG_CHART_BUSINESS_UNITS_START,
});
export const fetchOrgChartBusinessUnitsSuccess = (data) => ({
  type: ActionTypes.FETCH_ORG_CHART_BUSINESS_UNITS_SUCCESS,
  data,
});
export const fetchOrgChartBusinessUnitsError = (error) => ({
  type: ActionTypes.FETCH_ORG_CHART_BUSINESS_UNITS_ERROR,
  error,
});

export const fetchOrgChartBusinessUnits = () => {
  return async (dispatch) => {
    try {
      dispatch(fetchOrgChartBusinessUnitsStart());
      const response = await axios.get("/orgChartBusinessUnits/all");

      if (response.data) {
        dispatch(fetchOrgChartBusinessUnitsSuccess(response.data));
      }
    } catch (err) {
      dispatch(fetchOrgChartBusinessUnitsError(err));
    }
  };
};
