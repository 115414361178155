import {ActionTypes} from '../actionTypes';
import axios from 'axios';

export const fetchAccountsStart = () => ({type: ActionTypes.FETCH_ACCOUNTS_START});
export const fetchAccountsSuccess = (accounts) => ({type: ActionTypes.FETCH_ACCOUNTS_SUCCESS, accounts});
export const fetchAccountsError = (error) => ({type: ActionTypes.FETCH_ACCOUNTS_ERROR, error});

export const fetchAccounts = () => {
    return async (dispatch) => {
        try {
            dispatch(fetchAccountsStart());
            const response = await axios.get('/accounts/all')

            if (response.data) {
                dispatch(fetchAccountsSuccess(response.data))
            }
        } catch (err) {
            dispatch(fetchAccountsError(err));
        }
    }
}