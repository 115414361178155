import {ActionTypes} from '../actionTypes';
import axios from 'axios';

export const updateNewsEventsStart = () => ({type: ActionTypes.UPDATE_NEWS_EVENTS_START});
export const updateNewsEventsSuccess = (id, updates) => ({type: ActionTypes.UPDATE_NEWS_EVENTS_SUCCESS, id, updates});
export const updateNewsEventsError = (error) => ({type: ActionTypes.UPDATE_NEWS_EVENTS_ERROR, error});



export const updateNewsEvents = (id, requestData = {}) => {
    return async (dispatch) => {

        try {
            dispatch(updateNewsEventsStart());

            const data = {
                ...requestData,
                Id: id
            }

            const response = await axios.patch('/newsEvents/update', data)
            
            if (response.data) {
                dispatch(updateNewsEventsSuccess(response.data.event.Id, response.data.event))
            }
        } catch (err) {
            dispatch(updateNewsEventsError(err.message));
        }
    }
}