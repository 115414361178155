import axios from 'axios'
import {ActionTypes} from '../actionTypes';


export const fetchServiceRequestAttachmentsStart = () => ({type: ActionTypes.FETCH_SERVICE_REQUEST_ATTACHMENTS_START})
export const fetchServiceRequestAttachmentsSuccess = (attachments) => ({type: ActionTypes.FETCH_SERVICE_REQUEST_ATTACHMENTS_SUCCESS, attachments})
export const fetchServiceRequestAttachmentsError = (error) => ({type: ActionTypes.FETCH_SERVICE_REQUEST_ATTACHMENTS_ERROR, error})


export const fetchServiceRequestAttachments = () => {
    return async (dispatch) => {
        try {
            dispatch(fetchServiceRequestAttachmentsStart())
            const response = await axios.get('/serviceRequest/attachments')

            if (response.data) {
                let attachemets = [];

                response.data.map((att) => {
                    att.Attachments && att.Attachments.records.map((i) => {
                        delete i.attributes
                        let attachment = {
                            ServiceRequestId: att.Id,
                            ServiceRequestName: att.Name,
                            ...i
                        }

                        attachemets.push(attachment)
                    })
                })

                dispatch(fetchServiceRequestAttachmentsSuccess(attachemets))
            }

        } catch (err) {
            dispatch(fetchServiceRequestAttachmentsError(err))
        }
    }
}

