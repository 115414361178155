import React from 'react';
import DOMPurify from 'dompurify';



const RichTextToHTML = (props) => {
    DOMPurify.addHook('afterSanitizeAttributes', function (node) {
        // set all elements owning target to target=_blank
        if ('target' in node) {
          node.setAttribute('target', '_blank');
        }
        // set non-HTML/MathML links to xlink:show=new
        if (
          !node.hasAttribute('target') &&
          (node.hasAttribute('xlink:href') || node.hasAttribute('href'))
        ) {
          node.setAttribute('xlink:show', 'new');
        }
    });
    
    const createMarkup = (html) => {
        return  {
          __html: DOMPurify.sanitize(html)
        }
     }
    return (
        <div dangerouslySetInnerHTML={createMarkup(props.html)}></div>
    )
}

export default RichTextToHTML;