import React from 'react';
import Layout from '../layout/Layout';
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import {columns, defaultSorted} from './columns'
import Datatable from '../layout/datatable';
import NewsEventsNew from './NewsEventsNew';
import TourRunner from '../tour/TourRunner';
import {updateEmpTour} from '../../redux/actions/sfAuth/updateEmpTours';
import {newsEventsSteps} from '../tour/tourContent';
import {newsEventsMobileSteps} from '../tour/tourMobileContent';

const NewsEventsList = ({newsEvents, dispatch, sfAuth}) => {

    const breadcrumb = [
        { name:'News & Events', link: '' }
    ]

    return (
        <Layout breadcrumb={breadcrumb}>
            <TourRunner 
                content={newsEventsSteps} 
                mobileContent={newsEventsMobileSteps}
                isClosed={sfAuth.tours && sfAuth.tours.News_Events__c}
                updateSalesforce={() => {
                    dispatch(updateEmpTour(sfAuth.tours.Id, {Id: sfAuth.tours.Id, News_Events__c: true}))
                }} 
            />
            <div className="card">
                <div className="card-header bg-sdi-black text-sdi-yellow">
                    News & Events
                    <div className="float-right">
                        <NewsEventsNew />
                    </div>
                </div>
                <div className="card-body" news-tour="3">
                    <Datatable
                        csvFileName={'news-events.csv'}
                        data={newsEvents}
                        columns={columns}
                        sortBy={defaultSorted}
                    />
                </div>
            </div>
        </Layout>
    )
}


const mapStateToProps = (state, props) => {
    const user = state.sfAuth.user;
    return {
        sfAuth: state.sfAuth,
        newsEvents: state.newsEvents.data && state.newsEvents.data.filter((item) => item.Created_By__c === user.Id)
    }
}

export default connect(mapStateToProps)(NewsEventsList);