import {ActionTypes} from '../actionTypes';
import axios from 'axios';

export const fetchFormsStart = () => ({type: ActionTypes.FETCH_FORMS_START});
export const fetchFormsSuccess = (forms) => ({type: ActionTypes.FETCH_FORMS_SUCCESS, forms});
export const fetchFormsError = (error) => ({type: ActionTypes.FETCH_FORMS_ERROR, error});

export const fetchForms = () => {
    return async (dispatch) => {
        try {
            dispatch(fetchFormsStart());
            const response = await axios.get('/forms/all')


            if (response.data) {
                dispatch(fetchFormsSuccess(response.data))
            }
        } catch (err) {
            console.log(err);
            dispatch(fetchFormsError(err));
        }
    }
}