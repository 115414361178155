import FormsItem from "./FormsItem"

const FormsList = ({forms}) => {
    return (
        <div className="row" >
            {forms && forms.map((form) => (
                <FormsItem form={form} key={form.Id} />
            ))}
        </div>
    )
}

export default FormsList
