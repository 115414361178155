import React, {useEffect, useState} from 'react';
import Layout from '../layout/Layout';
import {connect} from "react-redux";
import moment from 'moment';
import {setHelpDocsTextFilter} from '../../redux/actions/filters/helpDocsFilters';
import {helpDocsSearch} from '../../redux/selectors/helpDocsSelectors';
import HelpDocsDetails from './HelpDocsDetails'
import Pagination from '../layout/Pagination';
import canadaFlag from '../../images/canada-flag.png';
import usaFlag from '../../images/usa-flag.png';
import qatarFlag from '../../images/qatar-flag.png';
import TourRunner from '../tour/TourRunner';
import {trainingMaterialsSteps} from '../tour/tourContent';
import {updateEmpTour} from '../../redux/actions/sfAuth/updateEmpTours';
import {trainingMaterialsMobileSteps} from '../tour/tourMobileContent';
import HelpDocsFilter from './HelpDocsFilter';
import { Link } from 'react-router-dom';


const HelpDocs = ({helpDocs, dispatch, filters, sfAuth}) => {
    const [records, setRecords] = useState([]);
    const [recordsPerPage, setRecordsPerPage] = useState(9);
    const [currentPage, setCurrentPage] = useState(1);

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = records.slice(indexOfFirstRecord, indexOfLastRecord);
    const setPage = (pageNumber) => setCurrentPage(pageNumber);

    useEffect(() => {
        setRecords(helpDocs);
    }, [helpDocs])

    const breadcrumb = [
        { name:'Training Library', link: '' }
    ]

    const formatTrainingIcon = (type) => {
        if(type === 'Technical') return <i className="fa fa-cogs c-icon c-icon-xxl text-dark"></i>;
        else if(type === 'Corporate') return <i className="fa fa-user-tie c-icon c-icon-xxl text-dark"></i>;
        else if(type === 'Legislative') return <i className="fa fa-gavel c-icon c-icon-xxl text-dark"></i>;

        return <i className="fa fa-file-pdf c-icon c-icon-xxl text-dark"></i>
    }

    const renderAttachmentIcons = (ContentType) => {
        if (ContentType === 'application/pdf') {
            return <i key={ContentType} className="fas fa-file-pdf text-pdf mr-1 h6 p-1"></i>
        } else if (ContentType === 'application/msword' || ContentType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
            return <i key={ContentType} className="fas fa-file-word text-primary mr-1 h6 p-1"></i>
        } else if (ContentType === 'application/vnd.ms-excel' || ContentType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            return <i key={ContentType} className="fas fa-file-excel text-success mr-1 h6 p-1"></i>
        } else if(ContentType === "application/vnd.openxmlformats-officedocument.presentationml.presentation") {
            return <i key={ContentType} className="fas fa-file-powerpoint text-powerpoint mr-1 h6 p-1"></i>
        }
        return <i key={ContentType} className="fas fa-file-alt text-powerpoint mr-1 h6 p-1"></i>
    }


    const renderIcons = (doc) => {
        const attTypesRaw = [];
        doc.Attachments && doc.Attachments.records.forEach((att) => {
            attTypesRaw.push(att.ContentType);
        });
        const attTypes = [...new Set(attTypesRaw)];

        return (
            <>
            {/* {doc.Training_Type__c === 'Technical' && <i className="fas fa-cogs text-dark mr-1 h6 p-1"></i>}
            {doc.Training_Type__c === 'Corporate' && <i className="fas fa-user-tie text-danger mr-1 h6 p-1"></i>}
            {doc.Training_Type__c === 'Legislative' && <i className="fas fa-gavel text-law mr-1 h6 p-1"></i>} */}
            {(doc.Training_Region__c === 'SDI Canada' || doc.Training_Region__c === 'SDI Canada & USA') && <img src={canadaFlag} style={{height: '22px', marginBottom: '.2rem', padding: '.29rem'}} className="mr-1" alt="" />} 
            {(doc.Training_Region__c === 'SDI USA' || doc.Training_Region__c === 'SDI Canada & USA') &&  <img src={usaFlag} style={{height: '22px', marginBottom: '.2rem', padding: '.29rem'}} className="mr-1" alt="" />}
            {doc.Training_Region__c === 'SDI Doha' && <img src={qatarFlag} style={{height: '22px', marginBottom: '.2rem', padding: '.29rem'}} className="mr-1" alt="" />}
            {doc.Training_Region__c === 'ALL SDI' && <i className="fas fa-globe-americas text-primary mr-1 h6 p-1"></i>}
            {doc.Video_URL__c && <i className="fas fa-video text-info mr-1 h6 p-1"></i>}
            {attTypes && attTypes.map((type) => {
                return renderAttachmentIcons(type);
            })}
            </>
        )
    }

    return (
        <Layout breadcrumb={breadcrumb}>
            <TourRunner 
                content={trainingMaterialsSteps} 
                mobileContent={trainingMaterialsMobileSteps}
                isClosed={sfAuth.tours && sfAuth.tours.Training_Materials__c}
                updateSalesforce={() => {
                    dispatch(updateEmpTour(sfAuth.tours.Id, {Id: sfAuth.tours.Id, Training_Materials__c: true}))
                }} 
            />
            <div className="card">
                <div className="card-body" >
                
                    <HelpDocsFilter dispatch={dispatch} setCurrentPage={setCurrentPage} filters={filters} helpDocs={helpDocs} />
                
                <div className="row" >
                    {currentRecords && currentRecords.map((doc) => (
                    <div className="col-md-6 col-lg-4" data-tour="training-1" key={doc.Id}>
                        <div className="card">
                            <div className="card-body p-3">
                                <div className="d-flex align-items-center">
                                    <div className="p-2 mfe-3">
                                        {formatTrainingIcon(doc.Training_Type__c)}
                                    </div>
                                    <div>
                                        <div className="font-weight-bold small mb-2">{doc.Name}</div>
                                        <div data-tour="training-2">
                                            {renderIcons(doc)}
                                        </div>
                                        <div className="text-muted text-uppercase font-weight-bold small">
                                            {doc.Training_Type__c && <div><i className="fas fa-arrow-right mr-2"></i>{doc.Training_Type__c}</div>}
                                            {/* <div><i className="cil-chevron-right mr-2"></i>{moment(doc.CreatedDate).format('ll')}</div> */}
                                        </div>                                        
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer px-3 py-2" data-tour="training-4">
                                <Link className="btn-block text-muted d-flex justify-content-between align-items-center" to={`/helpDocs/${doc.Id}`}>
                                    <span className="small font-weight-bold">View Details</span>
                                    <i className="c-icon cil-chevron-right" style={{textDecoration: 'none'}}></i>
                                </Link>
                                {/* <HelpDocsDetails data={doc} /> */}
                            </div>
                        </div>
                    </div>
                    ))}
                </div>
                    <Pagination 
                        totalRecords={helpDocs.length}
                        recordsPerPage={recordsPerPage}
                        setPage={setPage}
                        currentPage={currentPage}
                    />
                </div>
            </div>
        </Layout>
    )
}

const mapStateToProps = (state) => {
    return {
        sfAuth: state.sfAuth,
        helpDocs: helpDocsSearch(state.helpDocs.data.filter((i) => i.Type__c !== 'Protocol'), state.helpDocsFilters),
        filters: state.helpDocsFilters,
    }
}


export default connect(mapStateToProps)(HelpDocs);