import {ActionTypes} from '../actionTypes';
import axios from 'axios';

export const fetchRequestCategoriesStart = () => ({type: ActionTypes.FETCH_REQUEST_CATEGORIES_START});
export const fetchRequestCategoriesSuccess = (categories) => ({type: ActionTypes.FETCH_REQUEST_CATEGORIES_SUCCESS, categories});
export const fetchRequestCategoriesError = (error) => ({type: ActionTypes.FETCH_REQUEST_CATEGORIES_ERROR, error});

export const fetchRequestCategories = () => {
    return async (dispatch) => {
        try {
            dispatch(fetchRequestCategoriesStart());
            const response = await axios.get('/requestCategories/all')

            if (response.data) {
                dispatch(fetchRequestCategoriesSuccess(response.data))
            }
        } catch (err) {
            dispatch(fetchRequestCategoriesError(err));
        }
    }
}