import React,{useState, useEffect, useMemo} from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import TextInput from '../formFields/TextInput';
import TextareaInput from '../formFields/TextareaInput';
import {validationSchema} from './validationSchema';
import DateInput from '../formFields/DateInput';
import moment from 'moment';

const AboutMeForm = ({contact, error, onSubmit, resetStatus, closeModal, status}) => {
    const [isOpen, setIsOpen] = useState(true);

    const [initialValues, setInitialValues] = useState({
        Employee_Portal_About_Me__c: contact ? contact.Employee_Portal_About_Me__c : '',
        Title: contact ? contact.Title : '',
        Birthdate: contact ? moment(contact.Birthdate) : '',
        Department: contact ? contact.Department : ''
    });



    useEffect(() => {
        if(status === 'updated' && isOpen === false) {
            resetStatus();
            closeModal();
        }
    }, [status]);


    return (
        <Formik
            initialValues={ 
                initialValues
            }
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
                    // setSaving(true);
                    onSubmit(values);
                    setSubmitting(false);
            }}
        >
            {({isSubmitting, values, errors, touched, handleChange, handleBlur, setFieldValue, setFieldTouched}) => {
                return (
                    <Form className="needs-validation container">
                        {status === 'error' &&
                        <div className="alert alert-danger">{error}</div>}
                        {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                    <div className="row">
                        <div className="col-sm-6">
                            <TextInput 
                                label="Title" 
                                name="Title"
                                isRequired={true}
                            />
                        </div>
                        <div className="col-sm-6">
                            <TextInput 
                                label="Department" 
                                name="Department"
                                isRequired={true}
                            />
                        </div>
                        <div className="col-sm-6">
                            <DateInput 
                                label="Birthdate" 
                                name="Birthdate"
                                isRequired={true}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <TextareaInput 
                                label="About me" 
                                name="Employee_Portal_About_Me__c" 
                                rows="3" 
                                    />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-md-4"></div>
                        <div className="col-md-4 text-center">
                            <button 
                                onClick={() => setIsOpen(false)}
                                className="btn btn-primary btn-block"
                                disabled={status === 'saving' || status === 'updating'} 
                                type="submit">
                            {status === 'saving' || status === 'updating' ? 
                            <>
                                <span className="spinner-border spinner-border-sm"></span>
                                {' Saving...'}
                            </>
                            : 'Save'}
                            </button>
                        </div>
                        <div className="col-md-4"></div>
                    </div>
                </Form>
                )
            }}
        </Formik>
    )
}

export default AboutMeForm;