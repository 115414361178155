import {connect} from 'react-redux'
import Layout from '../layout/Layout';
import moment from 'moment';
import { Link } from 'react-router-dom';

const CareersList = ({careers}) => {
    const breadcrumb = [
        { name:'Job Opportunities', link: '' }
    ]


    return (
        <Layout breadcrumb={breadcrumb}>
            <div className="card">
                <div className="card-header bg-sdi-black text-sdi-yellow">
                    Job Opportunities
                </div>
                <div className="card-body" news-tour="3">
                    {careers && careers.map((career) => <CareersItem career={career} />)}
                </div>
            </div>
        </Layout>
    )
}

const mapStateToProps = (state) => {
    return {
        careers: state.careers.data
    }
}

export default connect(mapStateToProps)(CareersList)


const CareersItem = ({career}) => {
    return (
        <div className="card">
            <div className="card-body">
                <div className="d-flex justify-content-between">
                    <div>
                        <div className="h5">
                            <a href={career.Job_Details_Url__c} className="text-dark" alt="" target="_blank" rel="noreferrer">{career.Job_Title__c}</a>
                        </div>
                        <div className="small">
                            <span className="font-weight-bold">Location: </span>
                            <span>{career.City__c && career.City__c+', '}</span>
                            <span>{career.State__c && career.State__c+', '}</span>
                            <span>{career.Country__c && career.Country__c}</span>
                            <span className="px-3">|</span>
                            <span className="font-weight-bold">Date Posted: </span>
                            <span>{moment(career.Date_Posted__c).format('ll')}</span>
                        </div>
                    </div>
                    <div>
                        <a href={career.Apply_Url__c} alt="" className="btn btn-sm btn-primary" target="_blank" rel="noreferrer">Apply now</a>
                    </div>
                </div>
            </div>
        </div>
    )
}
