import React,{useState, useEffect, useMemo} from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import TextInput from '../formFields/TextInput';
import {validationSchema, validationSchemaSupport, validationSchemaProject, validationSchemaPurchase, validationSchemaQuote} from './validationSchema';
import SwitchInput from '../formFields/SwitchInput';
import TypeaheadInput from '../formFields/TypeaheadInput';
import DateInput from '../formFields/DateInput';
import SelectInput from '../formFields/SelectInput';
import TextareaInput from '../formFields/TextareaInput';
import {userOptions, categoryOptions, projectOptions, clientOptions, selectedOption, projectsFilterByCallback,
        projectFilterByFields} from '../../redux/selectors/typeaheadOptions';
import {selectPurchaseApprover, selectPurchaseSecondApprover} from '../../redux/selectors/projectSelectors'
import { useHistory } from "react-router-dom";
import UserDetails from './UserDetails';
import ProjectDetails from './ProjectDetails';
import AccountDetails from './AccountDetails';
import RichTextEditor from '../formFields/RichTextEditor';
import moment from 'moment';

const ServiceRequestForm = ({businessUnit, savedRecordId, clients, vendors, projects, users, user, categories, onSubmit, status, error, serviceRequest, resetStatus}) => {
    let history = useHistory();
    const [requestTypeVal, setRequestTypeVal] = useState(serviceRequest ? serviceRequest.Parent_Type__c : 'Support Request');
    const [saving, setSaving] = useState(null);
    // const [onBehalfOfUser, setOnBehalfOfUser] = useState(selectedOption(users, serviceRequest ? serviceRequest.Request_For__c : user.Id));
    const [onBehalfOfUser, setOnBehalfOfUser] = useState(selectedOption(users, serviceRequest ? serviceRequest.Request_For__c : []));
    const [selectedProject, setSelectedProject] = useState(serviceRequest ? selectedOption(projects, serviceRequest.PSA_Project__c) : []);
    const [selectedCategory, setSelectedCategory] = useState(serviceRequest ? selectedOption(categories, serviceRequest.Category_Lookup__c) : []);
    // const [selectedClient, setSelectedClient] = useState(serviceRequest ? selectedOption(clients, serviceRequest.Client__c) : []);
    // const [selectedVendor, setSelectedVendor] = useState(serviceRequest ? selectedOption(vendors, serviceRequest.Vendor__c) : []);
    

    // const [projectId, setProjectId] = useState(selectedProject[0] ? selectedProject[0].Id : '');
    // const [purchApprover, setPurchaseApprover] = useState(serviceRequest ? serviceRequest.Purch_Approver__c : selectPurchaseApprover(projects, projectId))

    const [initialValues, setInitialValues] = useState({
        createdBy: serviceRequest ? serviceRequest.Created_By__c : user.Id,
        onBehalf: serviceRequest ? serviceRequest.On_Behalf__c : false,
        onBehalfOf: serviceRequest ? serviceRequest.Request_For__c : '',
        requestType: serviceRequest ? serviceRequest.Parent_Type__c : 'Support Request',
        category: serviceRequest ? serviceRequest.Category_Lookup__c : '',
        project: serviceRequest ? serviceRequest.PSA_Project__c : '',
        priority: serviceRequest ? serviceRequest.Priority__c : '',
        neededBy: serviceRequest ? moment(serviceRequest.Needed_By__c): '',
        description: serviceRequest ? serviceRequest.Brief_Desc__c : '',
        requestDetails: serviceRequest ? serviceRequest.Detail__c : '',
        deliveryDetails: serviceRequest ? serviceRequest.Delivery_Details__c : '',
        productModal: serviceRequest ? serviceRequest.Model__c : '',
        productBrand: serviceRequest ? serviceRequest.Brand__c : '',
        productQuantity: serviceRequest ? serviceRequest.Quantity__c : '',
        // client: serviceRequest ? serviceRequest.Client__c : '',
        // vendor: serviceRequest ? serviceRequest.Vendor__c : '',
        projectTitle: serviceRequest ? serviceRequest.Project_Title__c : '',
        projectType: serviceRequest ? serviceRequest.Project_Type__c : '',
        projectImportance: serviceRequest ? serviceRequest.Project_Importance__c : '',
        projectProblem: serviceRequest ? serviceRequest.Project_Problem__c : '',
        projectGoal: serviceRequest ? serviceRequest.Project_Goals__c : '',
        projectSuccess: serviceRequest ? serviceRequest.Project_Success__c : '',
        projectBudget: serviceRequest ? serviceRequest.Project_Budget__c : '',
        deliveredDate: serviceRequest ? moment(serviceRequest.Needed_By__c) : '',

        projPreApprover: serviceRequest ? serviceRequest.Proj_Pre_Approver__c : businessUnit.Executive_Leader__c,
        projEstApprover: serviceRequest ? serviceRequest.Proj_Estimate_Approver__c : businessUnit.Executive_Leader__c,
        purchApprover: serviceRequest ? serviceRequest.Purch_Approver__c : '',
        purchSecondApprover: serviceRequest ? serviceRequest.Purch_Secondary_Approver__c : '',

        purchApprovedBy: serviceRequest ? serviceRequest.Purch_Approved_By__c : '',
        purchApprovalNotes: serviceRequest ? serviceRequest.Purch_Approval_Notes__c : '',
        projPreApprovalNotes: serviceRequest ? serviceRequest.Proj_Pre_Approval_Notes__c : ''
    });


    // const resetValues = (requestType) => {
    //     setInitialValues({
    //         createdBy: user.Id,
    //     onBehalf: false,
    //     onBehalfOf: '',
    //     requestType: requestType,
    //     category: '',
    //     project: '',
    //     priority: '',
    //     neededBy: '',
    //     description: '',
    //     requestDetails: '',
    //     deliveryDetails: '',
    //     productModal: '',
    //     productBrand: '',
    //     productQuantity: null,
    //     // client: '',
    //     // vendor: '',
    //     projectTitle: '',
    //     projectType: '',
    //     projectImportance: '',
    //     projectProblem: '',
    //     projectGoal: '',
    //     projectSuccess: '',
    //     projectBudget: '',
    //     deliveredDate: '',

    //     projPreApprover: businessUnit.Executive_Leader__c,
    //     projEstApprover: businessUnit.Executive_Leader__c,
    //     purchApprover: '',
    //     purchSecondApprover: '',

    //     purchApprovedBy: '',
    //     purchApprovalNotes: '',
    //     projPreApprovalNotes: ''
    //     })
    // }
    


    useEffect(() => {
        if(status === "error") {
            setSaving(false);
        }
        if(status === "saved" || status === "updated") {
            setSaving(false);
            resetStatus()
            //history.push(`/serviceRequests/${savedRecordId}`);
            history.push(`/serviceRequest/attachments/${savedRecordId}`);
        }
    }, [status])

    const pickValidationSchema = (type) => {
        if(type === 'Project Brief') {
            return validationSchemaProject
        } else if (type === 'Support Request') {
            return validationSchemaSupport
        } else if (type === 'Purchase Request') {
            return validationSchemaPurchase
        } else if (type === 'Quote Request') {
            return validationSchemaQuote
        } else {
            return validationSchema
        }
    }

    const validateProject = (id) => {
        const prj = projects.find((project) => project.Id === id) || {}

        if(prj.Project_Manager_Active__c !== true && prj.Purchase_Manager_Active__c !== true) {
            return false
        }

        return true;
    }

    return (
        <Formik
            initialValues={ 
                initialValues
            }
            validationSchema={pickValidationSchema(requestTypeVal) }//requestTypeVal === 'Project Brief' ? validationSchemaProject : validationSchema}
            onSubmit={async (values, actions) => {
                const proj = await validateProject(values.project);

                // if(!proj) {
                //     actions.setFieldError('project', 'Either project manger or purchase manager is inactive on Salesforce.');
                //     actions.setFieldValue('project', '', false);
                    
                // } else {
                    // console.log(values);
                    setSaving(true);
                    onSubmit(values);
                    actions.setSubmitting(false);
                // }    
                
            }}
        >
            {({isSubmitting, values, errors, touched, handleChange, handleBlur, setFieldValue, setFieldTouched, validateField, handleReset}) => {
                // console.log(validationSchema.fileds);
                return (
                    <Form className="needs-validation container">
                        {status === 'error' &&
                        <div className="alert alert-danger">{error}</div>}
                        <div className="row mb-3">
                            <div className="col-sm-8">
                                <div className="row mb-3">
                                    <div className="col text-center">
                                        {/* <div className="btn-group"> */}
                                            <button 
                                                style={{width: '110px'}}
                                                type="button" 
                                                className={`btn btn-outline-primary btn-sm m-1 ${requestTypeVal === 'Support Request' && 'active'}`} 
                                                disabled={serviceRequest}
                                                onClick={() => {
                                                    // handleReset();
                                                    setRequestTypeVal('Support Request');
                                                    setFieldValue('requestType','Support Request');
                                                    setFieldValue('category', '');
                                                    // setFieldValue('client', '');
                                                    // setFieldValue('vendor', '');
                                                }}>Support</button>
                                            <button 
                                                style={{width: '110px'}}
                                                type="button" 
                                                name="requestType" 
                                                disabled={serviceRequest}
                                                className={`btn btn-outline-primary btn-sm m-1 ${requestTypeVal === 'Purchase Request' && 'active'}`} 
                                                onClick={() => {
                                                    // handleReset('Purchase Request');
                                                    setRequestTypeVal('Purchase Request');
                                                    setFieldValue('requestType','Purchase Request')
                                                    setFieldValue('category', '');
                                                    // setFieldValue('client', '')
                                                    // setFieldValue('vendor', '');
                                                }}>Purchase</button>
                                            <button 
                                                style={{width: '110px'}}
                                                type="button" 
                                                disabled={serviceRequest}
                                                name="requestType" 
                                                className={`btn btn-outline-primary btn-sm m-1 ${requestTypeVal === 'Quote Request' && 'active'}`} 
                                                onClick={() => {
                                                    // handleReset('Quote Request');
                                                    setRequestTypeVal('Quote Request');
                                                    setFieldValue('requestType','Quote Request')
                                                    setFieldValue('category', '');
                                                    // setFieldValue('client', '')
                                                    // setFieldValue('vendor', '');
                                                }}>Quote</button>
                                            <button 
                                                style={{width: '110px'}}
                                                type="button" 
                                                name="requestType" 
                                                disabled={serviceRequest}
                                                value="Project Brief" 
                                                className={`btn btn-outline-primary btn-sm m-1 ${requestTypeVal === 'Project Brief' && 'active'}`}  
                                                onClick={() => {
                                                    // handleReset('Project Brief');
                                                    setRequestTypeVal('Project Brief');
                                                    setFieldValue('requestType','Project Brief')
                                                    setFieldValue('category', '');
                                                    // setFieldValue('client', '')
                                                    // setFieldValue('vendor', '');
                                                }}>Project Brief</button>
                                        {/* </div> */}
                                    </div>
                                </div>
                                {requestTypeVal === 'Support Request' &&
                                    <div className="mb-4 text-center text-muted">
                                        A support request is a request for assistance; this spans hardware, software, cloud applications like Salesforce or Dayforce etc.; support requests generally do not require more than a day of work.
                                    </div>
                                } 

                                {requestTypeVal === 'Purchase Request' &&
                                    <div className="mb-4 text-center text-muted">
                                        A purchase request is intended for purchases only; this spans items such as hardware, domain purchases, software licenses etc.
                                    </div>
                                } 

                                {requestTypeVal === 'Quote Request' &&
                                    <div className="mb-4 text-center text-muted">
                                        A quote request may be desired in advance of a purchase request. A quote request is intended to provide insight into the cost of an item for a cost benefit assessment.
                                    </div>
                                } 

                                {requestTypeVal === 'Project Brief' &&
                                    <div className="mb-4 text-center text-muted">
                                        A project brief is a more complex ask typically requiring more resources and time to complete; creating or revising solutions often fall into the project brief category, implementing a new third party solution would also be classified as a project and require a project brief.
                                    </div>
                                }                   


                                {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                                {/* <h4 className="mb-3">Request Details</h4> */}
                                <div className="row">
                                    <div className="col-sm-6">
                                        <SwitchInput 
                                        name="onBehalf" 
                                        label="On Behalf" 
                                        tooltip={true}
                                        tooltipText="Check this if this request has originated from another employee, however you are managing the fulfillment of it"
                                        onClick={ () => {
                                            setOnBehalfOfUser([])
                                            setFieldValue('onBehalfOf', '');
                                        }} />
                                    </div>
                                    <div className="col-sm-6">
                                        {values.onBehalf && 
                                        <TypeaheadInput 
                                            // disabled={!values.onBehalf}
                                            name="onBehalfOf" 
                                            touched={touched}
                                            selected={onBehalfOfUser ? onBehalfOfUser : []}
                                            setFieldTouched={setFieldTouched}
                                            choices={userOptions(users, user)} 
                                            setFieldValue={setFieldValue} 
                                            errors={errors}
                                            label={'On Behalf Of'} 
                                            placeholder="Choose a user..."
                                            tooltip={true}
                                            tooltipText="Search and select the employee this request originated from"/>
                                            }
                                    </div>
                                </div>
        
                                <div className="row">
                                    <div className="col-sm-6">
                                        <SelectInput 
                                            isRequired={true} 
                                            label="Category" 
                                            name="category"
                                            tooltip={true}
                                            tooltipText="Select the category of this request">
                                            <option value="">Choose a category...</option>
                                            {categoryOptions(categories, values.requestType).map((item) => (
                                                <option value={`${item.id}`}>{item.label}</option>
                                            ))}
                                        </SelectInput>
                                        {/* <TypeaheadInput 
                                            name="category" 
                                            isRequired={true}
                                            touched={touched}
                                            selected={selectedCategory}
                                            setFieldTouched={setFieldTouched}
                                            choices={categoryOptions(categories, values.requestType)} 
                                            setFieldValue={setFieldValue} 
                                            errors={errors}
                                            label={'Category'} 
                                            placeholder="Choose a category..."
                                            tooltip={true}
                                            tooltipText="Select the category of this request"/> */}
                                    </div>
                                    <TextInput name="purchApprover" hidden/>
                                    <TextInput name="purchSecondApprover" hidden/>
                                    <TextInput name="purchApprovedBy" hidden/>
                                    <TextInput name="purchApprovalNotes" hidden/>
                                    <TextInput name="projPreApprovalNotes" hidden/>
                                    
                                    
                                    <div className="col-sm-6">
                                        <TypeaheadInput 
                                            filterBy={projectsFilterByCallback}
                                            // validate={validateProject}
                                            name="project" 
                                            labelKey="label"
                                            isRequired={requestTypeVal === 'Purchase Request' ? true : false}
                                            touched={touched}
                                            selected={selectedProject}
                                            setFieldTouched={setFieldTouched}
                                            choices={projectOptions(projects)} 
                                            setFieldValue={setFieldValue} 
                                            errors={errors}
                                            label={'Project'} 
                                            renderMenuItemChildren={(option) => (
                                                <div>
                                                    <div className="font-weight-bold">{option.projectId}</div>
                                                    <div>
                                                        <small>{option.label}</small>
                                                    </div>
                                                </div>
                                            )}
                                            placeholder="Choose a project..."
                                            tooltip={true}
                                            tooltipText="Seach and select the project this request is connected to if applicable"/>
                                    </div>
                                </div>
                                
        
                                <div className="row">
                                    <div className="col-sm-6">
                                        <SelectInput 
                                            isRequired={true} 
                                            label="Priority" 
                                            name="priority"
                                            tooltip={true}
                                            tooltipText="Select the priority of this request">
                                            <option value="">Choose a priority...</option>
                                            <option value="3 - Low">Low</option>
                                            <option value="2 - Medium">Medium</option>
                                            <option value="1 - HIGH">High</option>
                                        </SelectInput>
                                    </div>
                                    <div className="col-sm-6">
                                    {requestTypeVal === 'Project Brief' ? 
                                        <DateInput 
                                            isRequired={true} 
                                            label="Needed By" 
                                            name="deliveredDate" 
                                            minDate={new Date()}
                                            tooltip={true}
                                            tooltipText="Select the date you would like this request to be completed by" />
                                     : 
                                        <DateInput 
                                            isRequired={true} 
                                            label="Needed By" 
                                            name="neededBy" 
                                            minDate={new Date()}
                                            tooltip={true}
                                            tooltipText="Select the date you would like this request to be completed by" />
                                    }
                                    </div>
                                </div>
                                
        
                                {requestTypeVal === 'Project Brief' ? null : 
                                <div className="row">
                                    <div className="col">
                                        <TextInput 
                                            isRequired={true} 
                                            label="Brief Description" 
                                            name="description" 
                                            type="text" 
                                            placeholder=""
                                            tooltip={true}
                                            tooltipText="In a few words, how you would describe this request?" />
                                    </div>
                                </div>
                                }

                                {(requestTypeVal === 'Purchase Request' || requestTypeVal === 'Quote Request') &&
                                <div className="row">
                                    
                                    <div className="col-sm-6"></div>
                                </div>}
                                {/* {requestTypeVal === 'Project Brief' ? null : 
                                <div className="row">
                                    <div className="col">
                                        <TextareaInput 
                                            label="Details" 
                                            name="requestDetails" 
                                            rows="3" 
                                            isRequired={true}
                                             />
                                    </div>
                                </div>
                                } */}

                                
                                {(requestTypeVal === 'Purchase Request' || requestTypeVal === 'Quote Request') &&
                                <div className="row">
                                    {/* <div className="col-sm-6">
                                        <TypeaheadInput 
                                            name="vendor" 
                                            touched={touched}
                                            selected={selectedVendor}
                                            setFieldTouched={setFieldTouched}
                                            choices={clientOptions(vendors)} 
                                            setFieldValue={setFieldValue} 
                                            errors={errors}
                                            label={'Vendor'} 
                                            placeholder="Choose a vendor..."
                                            tooltip={true}
                                            tooltipText="Search and select an existing vendor if applicable"/>
                                    </div> */}
                                    <div className="col-sm-6">
                                        <TextInput 
                                            label="Brand" 
                                            name="productBrand"
                                            type="text"
                                            tooltip={true}
                                            tooltipText="Enter the brand details if applicable" />
                                    </div>
                                    <div className="col-sm-6">
                                        <TextInput 
                                            label="Model" 
                                            name="productModal"
                                            type="text"
                                            tooltip={true}
                                            tooltipText="Enter the model details if applicable" />
                                    </div>
                                    
                                    <div className="col-sm-6">
                                        <TextInput 
                                            label="Quantity" 
                                            name="productQuantity"
                                            type="text"
                                            tooltip={true}
                                            tooltipText="Enter the quantity details if applicable" />
                                    </div>
                                </div>}

                                {requestTypeVal === 'Project Brief' ? null : 
                                <div className="row">
                                    <div className="col">
                                        <RichTextEditor 
                                            label="Details"
                                            name="requestDetails"
                                            isRequired={true}
                                            tooltip={true}
                                            tooltipText="Enter all the necessary details of this request"
                                             />
                                    </div>
                                </div>
                                }
                                
                                {(requestTypeVal === 'Purchase Request') &&
                                <div className="row">
                                    <div className="col">
                                        <TextareaInput 
                                            label="Delivery Details" 
                                            name="deliveryDetails" 
                                            rows="3" 
                                            tooltip={true}
                                            tooltipText="Enter the delivery details if applicable "
                                             />
                                    </div>
                                </div>}


                                {requestTypeVal === 'Project Brief' && <>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <SelectInput 
                                            isRequired={true} 
                                            label="Project Type" 
                                            name="projectType"
                                            tooltip={true}
                                            tooltipText="Select the option that best describes the type of project">
                                            <option value="--None--">--None--</option>
                                            <option value="New Solution">New Solution</option>
                                            <option value="Copy of an Existing Solution">Copy of an Existing Solution</option>
                                            <option value="Enhancement to a Solution">Enhancement to a Solution</option>
                                        </SelectInput>
                                    </div>
                                    <div className="col-sm-6">
                                        <TextInput 
                                            isRequired={true} 
                                            label="What is the budget for this project?"
                                            name="projectBudget" 
                                            type="text" 
                                            placeholder=""
                                            tooltip={true}
                                            tooltipText="Even if it's only a rough guide, a budget will give us an idea of the kind of solution we can offer "
                                             />
                                    </div>
                                    {/* <div className="col-sm-6">
                                        <TypeaheadInput 
                                            name="client" 
                                            isRequired={values.category === 'aFa4u000000PCukCAG' ? true : false}
                                            touched={touched}
                                            selected={selectedClient}
                                            setFieldTouched={setFieldTouched}
                                            choices={clientOptions(clients)} 
                                            setFieldValue={setFieldValue} 
                                            errors={errors}
                                            label={'Client'} 
                                            placeholder="Choose a client..."
                                            tooltip={true}
                                            tooltipText="Search and select the client this project is for if applicable"
                                            />
                                    </div> */}
                                    <div className="col">
                                        <TextInput 
                                            isRequired={true} 
                                            label="Project Title" 
                                            name="projectTitle" 
                                            type="text" 
                                            placeholder=""
                                            tooltip={true}
                                            tooltipText="How do you refer to this project?" />
                                    </div>
                                    
                                </div>
        
                                <div className="row">
                                    <div className="col">
                                        <RichTextEditor 
                                            label="Why is this project important?"
                                            name="projectImportance"
                                            isRequired={true}
                                            tooltip={true}
                                            tooltipText="Describe the importance of this project for the business"
                                             />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <RichTextEditor 
                                            label="What’s the problem you are trying to solve?"
                                            name="projectProblem"
                                            isRequired={true}
                                            tooltip={true}
                                            tooltipText="Describe the problem you need to solve"
                                             />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <RichTextEditor 
                                            label="What are the major outcomes you are expecting the project to achieve?"
                                            name="projectGoal"
                                            isRequired={true}
                                            tooltip={true}
                                            tooltipText="List the actual, specific deliverables at the end of a project"
                                             />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <RichTextEditor 
                                            label="What criteria will you and your client use to determine project success?"
                                            name="projectSuccess"
                                            isRequired={true}
                                            tooltip={true}
                                            tooltipText="How will you and/or your client measure the project success?"
                                             />
                                    </div>
                                </div>
        
                                
                                </>}
        
        
                                <div className="row mt-3 mb-5">
                                    <div className="col-md-4"></div>
                                    <div className="col-md-4 text-center">
                                        <button 
                                        onClick={() => {
                                            const purchaseApprover = selectPurchaseApprover(projects, values.project, businessUnit.Executive_Leader__c);
                                            const purchaseSecondApprover = selectPurchaseSecondApprover(projects, values.project, businessUnit.Executive_Leader__c);
                                            setFieldValue('purchApprover', purchaseApprover);
                                            setFieldValue('purchSecondApprover', purchaseSecondApprover);

                                            if(values.createdBy === purchaseApprover) {
                                                setFieldValue('purchApprovedBy', users.find((user) => user.Id === purchaseApprover).Name);
                                                setFieldValue('purchApprovalNotes', 'Automatically Approved');
                                            }
                                            
                                            
                                            if(values.projPreApprover === values.createdBy) {
                                                setFieldValue('projPreApprovalNotes', 'Automatically Approved');
                                            }
                                        }}
                                        className="btn btn-primary btn-block text-uppercase"
                                        disabled={saving} type="submit">
                                        {saving ? 
                                        <>
                                            <span className="spinner-border spinner-border-sm"></span>
                                            {' Saving...'}
                                        </>
                                        : 'Save & Next'}
                                        </button>
                                    </div>
                                    <div className="col-md-4"></div>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <UserDetails user={selectedOption(users, values.onBehalfOf)[0] || selectedOption(users, user.Id)[0]} />
                                <ProjectDetails project={selectedOption(projects, values.project)[0] || {}} />
                                {/* {values.vendor && <AccountDetails title="Vendor Details" data={selectedOption(vendors, values.vendor)[0] || {}} />}
                                {values.client && <AccountDetails title="Client Details" data={selectedOption(clients, values.client)[0] || {}} />} */}
                            </div>
                        </div>
                    </Form>
                    )
            } }
        </Formik>
    )
}


export default ServiceRequestForm;