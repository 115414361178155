import React, {useState, useEffect} from 'react'
import {Modal, Button} from 'react-bootstrap'
import VacationChart from './VacationChart'
import moment from 'moment'

const PeopleTimeAwayModal = ({data}) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);




    const chartData = (vacBalance) => {
        return {
            bookedVacation: vacBalance.vacation_salary_booked,
            takenVacation: vacBalance.vacation_salary_taken,
            remainingVaction: vacBalance.vacation_salary_allocation - (vacBalance.vacation_salary_booked + vacBalance.vacation_salary_taken) 
        };
    }


    const renderRow = (eta) => {
        return (
            <tr className={`align-middle ${'Taken'}`} key={eta.Id}>
                <td className="text-center px-1 align-middle">
                    <div className="avatar avatar-md">
                        {eta.Request_Type__c === 'Sick' ? <i className="fas fa-thermometer-half"></i> : <i className="fas fa-plane"></i>}
                    </div>
                </td>
                <td>
                    <div>{eta.Request_Type__c.split('_').join(' ')}</div>
                </td>
                <td className={`text-center align-middle`}>
                    <div className={` ${eta.Request_Status__c === 'APPROVED' ? 'text-success' : 'text-warning'}`}>{eta.Request_Status__c}</div>
                </td>
                <td className="text-right align-middle pr-3">
                    {moment(eta.Time_Away_Date__c).format('ll')}
                </td>
            </tr>
        )
    }


    const filterUsed = (data, type) => {
        const result = data.filter((i) => i.Type__c === 'Used' && i.Status__c === type).sort((left, right) => {
                return moment.utc(left.Time_Away_Date__c).diff(moment.utc(right.Time_Away_Date__c))
        })

        if(result.length > 0) {
            return result.map((eta) => (
                renderRow(eta)
            ))
        }
        
        return <tr><td colSpan="4" className="text-center">No time {type.toLowerCase()}.</td></tr>
    }



    const renderModal = () => {
        return (
            <Modal size="lg" show={show} onHide={handleClose}>
                <Modal.Header closeButton style={{borderBottom: '0 none'}}>
                    <Modal.Title style={{margin: '0 auto'}}>{data.First_Name__c+' '+data.Last_Name__c}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="row">
                        <div className="col-md-6">
                            <table className="table">
                                <thead className="text-center">
                                    <tr>
                                        <th>Type</th>
                                        <th>Allocation</th>
                                        <th>Booked</th>
                                        <th>Taken</th>
                                        <th>Remaining</th>
                                        
                                    </tr>
                                </thead>
                                <tbody className="text-center">
                                    <tr>
                                        <th>Vacation Salary</th>
                                        <td>{data.vacation_salary_allocation}</td>
                                        <td>{data.vacation_salary_booked !== 0 && data.vacation_salary_booked}</td>
                                        <td>{data.vacation_salary_taken !== 0 && data.vacation_salary_taken}</td>
                                        <td>{data.vacation_salary_allocation - (data.vacation_salary_booked + data.vacation_salary_taken)}</td>
                                    </tr>
                                    <tr>
                                        <th>Carry Over</th>
                                        <td>{data.vacation_caryover_allocation}</td>
                                        <td>{data.vacation_caryover_booked !== 0 && data.vacation_caryover_booked}</td>
                                        <td>{data.vacation_caryover_taken !== 0 && data.vacation_caryover_taken}</td>
                                        <td>{data.vacation_caryover_allocation - (data.vacation_caryover_booked + data.vacation_caryover_taken)}</td>
                                    </tr>
                                    <tr>
                                        <th>Sick</th>
                                        <td>{data.sick_allocation}</td>
                                        <td>{data.sick_booked !== 0 && data.sick_booked}</td>
                                        <td>{data.sick_taken !== 0 && data.sick_taken}</td>
                                        <td>{data.sick_allocation - (data.sick_booked + data.sick_taken)}</td>
                                    </tr>
                                    <tr>
                                        <th>Personal</th>
                                        <td>{data.personal_day_allocation}</td>
                                        <td>{data.personal_day_booked !== 0 && data.personal_day_booked}</td>
                                        <td>{data.personal_day_taken !== 0 && data.personal_day_taken}</td>
                                        <td>{data.personal_day_allocation - (data.personal_day_booked + data.personal_day_taken)}</td>
                                    </tr>
                                    <tr>
                                        <th>Float</th>
                                        <td>{data.float_allocation}</td>
                                        <td>{data.float_booked !== 0 && data.float_booked}</td>
                                        <td>{data.float_taken !== 0 && data.float_taken}</td>
                                        <td>{data.float_allocation - (data.float_booked + data.float_taken)}</td>
                                    </tr>
                                    <tr>
                                        <th>Volunteer</th>
                                        <td>{data.volunteer_day_allocation}</td>
                                        <td>{data.volunteer_day_booked !== 0 && data.volunteer_day_booked}</td>
                                        <td>{data.volunteer_day_taken !== 0 && data.volunteer_day_taken}</td>
                                        <td>{data.volunteer_day_allocation - (data.volunteer_day_booked + data.volunteer_day_taken)}</td>
                                    </tr>
                                    <tr>
                                        <th>Vacation</th>
                                        <td>{data.vacation_allocation}</td>
                                        <td>{data.vacation_booked !== 0 && data.vacation_booked}</td>
                                        <td>{data.vacation_taken !== 0 && data.vacation_taken}</td>
                                        <td>{data.vacation_allocation - (data.vacation_booked + data.vacation_taken)}</td>
                                    </tr>
                                </tbody>

                            </table>
                        </div>
                        <div className="col-m-6 m-auto">{data.Employee_Time_Away__r && <VacationChart data={chartData(data)} />}</div>
                    </div>
                     
                    <table className="table table-sm table-striped border">
                        <thead>
                            <tr className="align-middle">
                                <th className="text-center" colSpan="4">Booked</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filterUsed(data.Employee_Time_Away__r || [], 'Booked')}
                        </tbody>

                        <thead>
                            <tr className="align-middle">
                                <th className="text-center" colspan="4">Taken</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filterUsed(data.Employee_Time_Away__r || [], 'Taken')}
                        </tbody>
                    </table>
                    
                </Modal.Body>
            </Modal>
        )
    }


    return (
        <>
            <a onClick={handleShow} href="#0">
                {data.First_Name__c+' '+data.Last_Name__c}
            </a>

            {show && renderModal()}

        </>
    );
}

export default PeopleTimeAwayModal;