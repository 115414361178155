import React from "react";
import { connect } from "react-redux";
import Layout from "../layout/Layout";
import { Link } from "react-router-dom";
import EmployeeTable from "./EmployeeTable";
import NewsEvents from "./NewsEvents";
import WallOfWow from "./WallOfWow";
import { dashboardSteps } from "../tour/tourContent";
import { dashboardMobileSteps } from "../tour/tourMobileContent";
import TourRunner from "../tour/TourRunner";
import { updateEmpTour } from "../../redux/actions/sfAuth/updateEmpTours";
import { myRequests } from "../../redux/selectors/serviceRequestSelectors";
import { filterEventsByDate } from "../../redux/selectors/newsEventsSelectors";
import QuickLinks from "../quickLinks/QuickLinks";
import AddQuickLink from "../quickLinks/AddQuickLink";
import CareersDashboardList from "../careers/CareersDashboardList";
import QuickLinkList from "../quickLinks/QuickLinkList";

const Dashboard = (props) => {
  const { user, userPhoto } = props.auth;

  const displayName = (givenName, surname) => {
    return givenName?.charAt(0) + surname?.charAt(0);
  };

  return (
    <Layout hide={true}>
      <TourRunner
        content={dashboardSteps}
        mobileContent={dashboardMobileSteps}
        isClosed={props.sfAuth.tours && props.sfAuth.tours.Dashboard__c}
        updateSalesforce={() => {
          props.dispatch(
            updateEmpTour(props.sfAuth.tours.Id, {
              Id: props.sfAuth.tours.Id,
              Dashboard__c: true,
            })
          );
        }}
      />

      <div className="mb-3 p-2 header-dashboard">
        <div className="row">
          <div className="col-md-6">
            <div className="pt-1 mb-0 text-muted d-flex justify-content-start align-items-center">
              {userPhoto ? (
                <div>
                  <img
                    className="rounded-circle d-none d-sm-block border border-3"
                    alt=""
                    src={userPhoto}
                    style={{ width: "7em" }}
                  />
                </div>
              ) : (
                <div>
                  <div className="rounded-circle d-none d-sm-block dashboard-profile-image">
                    {user && displayName(user?.givenName, user?.surname)}
                  </div>
                </div>
              )}
              <div className="ml-3">
                <h1 className="display-4 text-sdi-black text-account-name">
                  Hi, {user && user.givenName}
                </h1>
                <p className="lead text-sdi-black">{user && user.jobTitle}</p>
              </div>
            </div>
          </div>
          <div className="col-md-6 d-flex justify-content-end align-items-center">
            <div
              className="overflow-hidden m-0 mr-3 d-none d-md-block"
              data-tour="dash-3"
            >
              <div className="card-body p-0 py-2 pr-3 d-flex align-items-center bg-sdi-dark-grey">
                <div className="ml-2">
                  <div className="text-muted font-weight-bold text-value text-sdi-light-grey">
                    {props.pendingRequests.length}
                    <i className="fas fa-ticket-alt c-icon c-icon-xl text-sdi-white float-right mr-1"></i>
                  </div>
                  <div className="text-muted text-uppercase font-weight-bold small text-sdi-light-grey text-right">
                    <Link
                      to="/serviceRequestTypes/pending"
                      className="text-light"
                    >
                      Open IT Requests
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-8 pr-md-2">
          <div className="card mb-3 shadow" data-tour="dash-4">
            <div className="card-body p-1">
              <NewsEvents newsEvents={props.newsEvents} />
            </div>
          </div>
          <QuickLinkList />
          {/* <div className="card mb-3">
                        <div className="card-header mb-0 h6 bg-sdi-black text-sdi-yellow font-weight-bold">
                            Employee Directory
                        </div>
                        <div className="card-body p-2 ">
                            <EmployeeTable />
                        </div>
                    </div>                     */}
        </div>
        <div className="col-md-4 pl-md-2">
          {/* <WallOfWow data={props.wallOfWow} /> */}
          <div className="card mb-3">
            <div className="card-header h6 mb-0 font-weight-bold bg-sdi-black text-sdi-yellow">
              Job Opportunities
            </div>
            <div className="card-body p-2 pb-0">
              <CareersDashboardList careers={props.careers} />
            </div>
          </div>
          {/* <div className="card" data-tour="dash-6">
                        <div className="card-header h6 mb-0 font-weight-bold bg-sdi-black text-sdi-yellow">
                            My Links
                            <div className="float-right" data-tour="dash-7">
                                <AddQuickLink />
                            </div>
                        </div>
                        <div className="card-body p-2 pb-0" >
                            <QuickLinks />
                        </div>
                    </div> */}
          <div className="card mb-3">
            <div className="card-header mb-0 h6 bg-sdi-black text-sdi-yellow font-weight-bold">
              Employee Directory
            </div>
            <div className="card-body p-2 ">
              <EmployeeTable />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    sfAuth: state.sfAuth,
    recentServiceRequests:
      state.serviceRequests.data
        .sort((a, b) => new Date(b.CreatedDate) - new Date(a.CreatedDate))
        .slice(0, 5) || [],
    pendingRequests:
      myRequests(
        state.serviceRequests.data.filter(
          (item) =>
            item.Status__c !== "Complete" &&
            item.Status__c !== "On Hold" &&
            item.Status__c !== "Cancelled"
        ),
        state.sfAuth.user
      ) || [],
    newsEvents: filterEventsByDate(state.newsEvents.data) || [],
    careers: state.careers.data || [],
    // wallOfWow: state.wallOfWow.data,
    links: state.quickLinks.data,
  };
};

export default connect(mapStateToProps)(Dashboard);
