import {ActionTypes} from '../actionTypes';
import axios from 'axios';

export const fetchEmpToursStart = () => ({type: ActionTypes.FETCH_EMP_TOURS_START});
export const fetchEmpToursSuccess = (tours) => ({type: ActionTypes.FETCH_EMP_TOURS_SUCCESS, tours});
export const fetchEmpToursError = (error) => ({type: ActionTypes.FETCH_EMP_TOURS_ERROR, error});

export const fetchEmpTours = () => {
    return async (dispatch) => {
        try {
            dispatch(fetchEmpToursStart());
            const response = await axios.get('/employeeTours/all')


            if (response.data) {
                dispatch(fetchEmpToursSuccess(response.data))
            }
        } catch (err) {
            dispatch(fetchEmpToursError(err));
        }
    }
}