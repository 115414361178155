import * as Yup from 'yup';


export const validationSchema = () => {
    return Yup.object({
        Type__c: Yup.string().required('Required'),
        Visible_From_Date__c: Yup.string().required('Required'),
        Visible_To_Date__c: Yup.string().required('Required'),
        Title__c: Yup
            .string()
            .max(100, 'Must be 100 characters or less')
            .required('Required'),
        // Brief_Description__c: Yup
        //     .string()
        //     .max(100, 'Must be 100 characters or less')
        //     .required('Required'),
        Description__c: Yup
            .string()
            .max(131000, 'Uploaded image is too large').nullable()
    })
}