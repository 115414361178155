// import { AuthenticationActions, AuthenticationState } from 'react-aad-msal';
import {ActionTypes} from '../actions/actionTypes';


const initialState = {
  isAuthenticated: null,
  user: null,
  userPhoto: null,
  userState: null,
  photoState: null,
  userError: null
};

export default (state = initialState, action) => {
  switch (action.type) {
      case ActionTypes.AUTHENTICATE_USER:
        return {...state, isAuthenticated: true}
      case ActionTypes.FETCH_USER_START:
        return {...state, userState: 'user-loading'};
      case ActionTypes.FETCH_USER_SUCCESS:
        return {...state, userState: 'user-loaded', user: action.user};
      case ActionTypes.FETCH_USER_ERROR:
        return {...state, userState: 'error', userError: action.error };
      case ActionTypes.FETCH_USER_PHOTO_START:
        return {...state, photoState: 'photo-loading'};
      case ActionTypes.FETCH_USER_PHOTO_SUCCESS:
        return {...state, photoState: 'photo-loaded', userPhoto: action.photo};
      case ActionTypes.FETCH_USER_PHOTO_ERROR:
        return {...state, userState: 'error', userError: action.error };
      default:
        return state;
    }
}



// const initialState = {
//     initializing: false,
//     initialized: false,
//     idToken: null,
//     accessToken: null,
//     state: AuthenticationState.Unauthenticated,
//     user: null,
//     userPhoto: null,
//     userState: null,
//     userError: null
// };

// export default (state = initialState, action) => {
//     switch (action.type) {
//         case AuthenticationActions.Initializing:
//             return { ...state, initializing: true, initialized: false, };
//         case AuthenticationActions.Initialized:
//           return { ...state, initializing: false, initialized: true, };
//         case AuthenticationActions.AcquiredIdTokenSuccess:
//           return { ...state, idToken: action.payload, };
//         case AuthenticationActions.AcquiredAccessTokenSuccess:
//           return { ...state, accessToken: action.payload, };
//         case AuthenticationActions.AcquiredAccessTokenError:
//           return { ...state, accessToken: null, };
//         case AuthenticationActions.LoginSuccess:
//           return { ...state, account: action.payload.account };
//         case AuthenticationActions.LoginError:
//         case AuthenticationActions.AcquiredIdTokenError:
//         case AuthenticationActions.LogoutSuccess:
//           return { ...state, idToken: null, accessToken: null, account: null };
//         case AuthenticationActions.AuthenticatedStateChanged:
//           return { ...state, state: action.payload, };
//         case ActionTypes.FETCH_USER_START:
//           return {...state, userState: 'loading'};
//         case ActionTypes.FETCH_USER_SUCCESS:
//           return {...state, userState: 'loaded', user: action.user, userPhoto: action.userPhoto};
//         case ActionTypes.FETCH_USER_ERROR:
//           return {...state, userState: 'error', userError: action.error };
//         default:
//           return state;
//       }
// }