import React, {useState} from 'react'
import {Modal, Button} from 'react-bootstrap'
import RichTextToHTML from '../layout/RichTextToHTML';
import moment from 'moment';
import images from './images'


const NewsEventsDetails = ({event}) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const image = images.find((image) => image.title === event.Background_Image__c);

    const splitPopName = (name) => {
        return name.split("-").pop();
    }

    const renderModal = () => {
        return (
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title style={{margin: '0 auto'}}>News & Events Post - {splitPopName(event.Name)}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {/* <img src={event.ImageUrl} className="d-block w-100 "/> */}
                    {image && <img alt="" src={image.src} className="d-block w-100 "/>}
                    <div className="row mt-3">
                        <div className="col-6 col-md-4 mb-3">
                            <div className="text-uppercase font-weight-light">
                                Posted On
                            </div>
                            <div className="font-weight-bold">{moment(event.CreatedDate).format('LL')}</div>
                        </div>
                        <div className="col-6 col-md-4 mb-3">
                            <div className="text-uppercase font-weight-light">
                                Posted By
                            </div>
                            <div className="font-weight-bold">{event.Created_By__r && event.Created_By__r.Name}</div>
                        </div>
                        <div className="col-6 col-md-4 mb-3">
                            <div className="text-uppercase font-weight-light">
                                Type
                            </div>
                            <div className="font-weight-bold">{event.Type__c}</div>
                        </div>
                        <div className="col-6 col-md-4 mb-3">
                            <div className="text-uppercase font-weight-light">
                                Title
                            </div>
                            <div className="font-weight-bold">{event.Title__c}</div>
                        </div>
                        
                        {/* <div className="col-6 col-md-4 mb-3">
                            <div className="text-uppercase font-weight-light">
                                Display on Home Page
                            </div>
                            <div className="font-weight-bold">{event.Display_on_Home_Page__c}</div>
                        </div> */}
                        <div className="col-6 col-md-4 mb-3">
                            <div className="text-uppercase font-weight-light">
                                Display from
                            </div>
                            <div className="font-weight-bold">{moment(event.Visible_From_Date__c).format('LL')}</div>
                        </div>
                        <div className="col-6 col-md-4 mb-3">
                            <div className="text-uppercase font-weight-light">
                                Display to
                            </div>
                            <div className="font-weight-bold">{moment(event.Visible_To_Date__c).format('LL')}</div>
                        </div>
                        
                    </div>
                    <div className="text-uppercase font-weight-light">
                        Brief Description
                    </div>
                    <div className="font-weight-bold">{event.Brief_Description__c}</div>
                    <div className="text-uppercase font-weight-light mt-2">
                        Description
                    </div>
                    <RichTextToHTML html={event.Description__c} />
                </Modal.Body>

                <Modal.Footer style={{display: 'block'}}>
                    <div className="text-center">
                        <Button variant="primary" onClick={handleClose}>
                            Close
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        )
    }

    return (
        <div>
            <button news-tour="6" className="btn btn-link font-weight-normal p-0 m-0" onClick={handleShow}>{splitPopName(event.Name)}</button>
            {show && renderModal()}
        </div>
    )
}


export default NewsEventsDetails;