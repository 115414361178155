import React from 'react';


const CareersDashboardPagination = ({recordsPerPage, totalRecords, setPage, currentPage}) => {
    const pageNumbers = [];

    for(let i = 1; i <= Math.ceil(totalRecords / recordsPerPage); i++){
        pageNumbers.push(i);
    }


    return (
        <nav>
            <ul className="pagination pagination-sm d-flex justify-content-end mb-0">
                {/* <div className="mr-3">this</div> */}
                <li className={`page-item ${pageNumbers.indexOf(currentPage) === 0 && "disabled"}`}>
                    <button className="page-link" onClick={
                        () => setPage(currentPage-1)
                        }>
                        <i className="fas fa-chevron-left"></i>
                    </button>
                </li>
                {/* {pageNumbers.map(number => (
                    <li key={number} className={`page-item ${number === currentPage && "active"}`}>
                        <a href="#" className="page-link" onClick={() => setPage(number)}>
                            {number}
                        </a>
                    </li>
                ))} */}
                <li className={`page-item ${pageNumbers.length === currentPage && "disabled"}`}>
                    <button className="page-link" onClick={() => setPage(currentPage+1)}>
                        <i className="fas fa-chevron-right"></i>
                    </button>
                </li>
            </ul>
        </nav>
    )
}


export default CareersDashboardPagination;