import {ActionTypes} from '../actionTypes';
import axios from 'axios';

export const fetchContactsStart = () => ({type: ActionTypes.FETCH_CONTACTS_START});
export const fetchContactsSuccess = (contacts) => ({type: ActionTypes.FETCH_CONTACTS_SUCCESS, contacts});
export const fetchContactsError = (error) => ({type: ActionTypes.FETCH_CONTACTS_ERROR, error});

export const fetchContacts = () => {
    return async (dispatch) => {
        try {
            dispatch(fetchContactsStart());
            const response = await axios.get('/contacts/all')

            if (response.data) {
                const newData = response.data.map((item) => ({...item, ImageUrl: null}));
                dispatch(fetchContactsSuccess(newData))
            }
        } catch (err) {
            dispatch(fetchContactsError(err));
        }
    }
}